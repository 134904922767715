/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import './style.scss';
import InlineSVG from '../InlineSVG';

const NotScannedBadge = ( ) => {
  return (
    <div className="notScannedBadge">
      <InlineSVG type="notScanned" />
      <span>Not scanned by DeepSurface</span>
    </div>
  );
};

export default NotScannedBadge;