/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../../../shared/EmptyState';
import Loading from '../../../shared/Loading';
import { isNotEmpty } from '../../../shared/Utilities';
import ExploreItem from './ExploreItem';

const SelectedList = ( {
  items,
  selected,
  setSelected,
  exploreItems,
  setExploreItems,
  displayExploreItemNameFor,
  loading,
  toggleSelection,
  setExternalHoverIDs,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( items )
          ? <ul>
            {
              Object.keys( items ).map( type => {
                const _items =  items[type];
                if ( isNotEmpty( _items ) && Array.isArray( _items ) ) {
                  return _items.map( ( item, index ) => {
                    return  <ExploreItem
                      key={index}
                      item={item}
                      type={type}
                      selected={selected}
                      setSelected={setSelected}
                      isSelected
                      displayExploreItemNameFor={displayExploreItemNameFor}
                      exploreItems={exploreItems}
                      setExploreItems={setExploreItems}
                      toggleSelection={toggleSelection}
                      items={items}
                      setExternalHoverIDs={ setExternalHoverIDs }
                    />;
                  } );
                }
              } )
            }
          </ul>
          : <div className="loadingWrapper">
            {
              loading
                ? <div className="loadingWrapper">
                  <Loading />
                </div>
                : <EmptyState message="No selected items" />
            }
          </div>
      }
    </React.Fragment>
  );
};

export default SelectedList;