/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './style.scss';
import { FullScreenVisualContext } from '../../Contexts/FullScreenVisual';
import Modal from '../Modal';
import { isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

const FullScreenBody = () => {
  const [
    visualContent,
    ,
    showVisual,
    ,
    ,
    exitFullScreenVisual,
    fullScreenClass,
    ,
    ,
  ] = React.useContext( FullScreenVisualContext );

  React.useEffect( () => {
    window.addEventListener( 'hashchange', exitFullScreenVisual );
    // need to flesh out printing a bit more
    // window.addEventListener( 'afterprint', handleAfterPrint );
    // window.addEventListener( 'beforeprint', handleBeforePrint );
    return () => {
      window.removeEventListener( 'hashchange', exitFullScreenVisual );
      // need to flesh out printing a bit more
      // window.removeEventListener( 'afterprint', handleAfterPrint );
      // window.addEventListener( 'beforeprint', handleBeforePrint );
    };
  }, [] );

  // need to flesh out printing a bit more
  // const handleBeforePrint = () => {
  //   const root = document.getElementById( 'react_container' );
  //   const portal = document.getElementById( 'reactModalPortal' );

  //   if ( isNotEmpty( root ) ) {
  //     root.classList.add( 'printingFullScreenVisual' );
  //   }
  //   if ( isNotEmpty( portal ) ) {
  //     portal.classList.add( 'printingFullScreenVisual' );
  //   }
  // };

  // need to flesh out printing a bit more
  // const handleAfterPrint = () => {
  //   const root = document.getElementById( 'react_container' );
  //   const portal = document.getElementById( 'reactModalPortal' );

  //   if ( isNotEmpty( root ) ) {
  //     root.classList.remove( 'printingFullScreenVisual' );
  //   }
  //   if ( isNotEmpty( portal ) ) {
  //     portal.classList.remove( 'printingFullScreenVisual' );
  //   }
  // };

  return (
    <React.Fragment>
      {
        isNotEmpty( visualContent ) &&
        <div className={ `fullScreenVisualContentWrapper ${fullScreenClass}` }>
          { visualContent }
          <button
            className="fullScreenVisualToggleButton"
            onClick={ exitFullScreenVisual }
          >
            { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
          </button>
          {/* <button
            className="fullScreenVisualPrintButton"
            onClick={ () => window.print() }
          >
            <InlineSVG type="print" />
          </button> */}
        </div>
      }
    </React.Fragment>
  );
};

const FullScreenVisual = ( ) => {

  const [
    ,
    ,
    showVisual,
    setShowVisual,
    ,
    ,
    fullScreenClass,
    ,
    ,
  ] = React.useContext( FullScreenVisualContext );

  return (
    <React.Fragment>
      <Modal
        visible={ showVisual }
        setVisible={ setShowVisual }
        size="fullscreen"
        elementClass={ `fullScreenVisualModal ${fullScreenClass}` }
        needsActions={false}
        body={ <FullScreenBody /> }
      />
    </React.Fragment>
  );
};

export default FullScreenVisual;