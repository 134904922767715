/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SetupPage from '../../../../shared/SetupComponents/SetupPage';

import { getFieldValues } from '../../../../shared/Form/Shared';

import {
  HelpTrigger,
} from '../../../HelpDocumentation/ContextualHelp/index.js';

import {
  recordData,
} from './data';

import {
  isNotEmpty,
  paramsToFilters,
} from '../../../../shared/Utilities';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';

const Certificates = () => {

  const PURPOSE_OPTIONS = {
    '*': 'all',
    scan: 'scanning',
    api: 'API',
    sso: 'SSO',
    notifications: 'Notifications',
    smtp: 'SMTP',
    pam: 'PAM',
    rules: 'Rules',
  };

  const optionsTypeMap = {
    '*': {
      '*': 'all',
    },
    scan: {
      '*': 'all',
      winrm: 'WinRM',
      rdp: 'RDP',
      ldap: 'LDAP',
    },
    api: {
      '*': 'all',
      tenable: 'Tenable',
      qualys: 'Qualys',
      rapid7: 'Rapid7',
      greenbone: 'Greenbone',
    },
    sso: {
      '*': 'all',
      ldap: 'LDAP',
      oauth: 'oauth',
      saml: 'SAML',
    },
    notifications: {},
    smtp: {},
    pam: {
      'thycotic-secret-server': 'Delinea (Thycotic) Secret Server',
      'cyberark-vault': 'CyberArk Vault',
    },
    rules: {
      // eslint-disable-next-line camelcase
      vuln_tally: 'Vulnerability Tally',
      // eslint-disable-next-line camelcase
      slv_tally: 'SLV Tally',
    },
    sync: {
      // eslint-disable-next-line camelcase
      sync_license: 'Sync License',
      // eslint-disable-next-line camelcase
      download_feed: 'Download Feed',
    },
  };

  const EMPTY_FIELDS = {
    selector: {
      fields: [
        {
          type: 'select',
          label: 'Certificate Type',
          attribute: 'type',
          defaultValue: 'x509',
          options: {
            x509: 'X.509 Public Key Certificate',
          },
          disableOnEdit: true,
        },
      ],
    },

    x509: {
      fields: [
        {
          includeIf: { attribute: 'type', value: 'x509' },
          type: 'text',
          label: 'Identity',
          attribute: 'identity',
          help: <HelpTrigger helpKey="identity" />,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'type', value: 'x509' },
          type: 'select',
          label: 'Purpose',
          attribute: 'purpose',
          required: true,
          defaultValue: '*',
          options: PURPOSE_OPTIONS,
          help: <HelpTrigger helpKey="purpose" />,
        },
        {
          includeIf: { attribute: 'type', value: 'x509' },
          type: 'select',
          label: 'Sub Purpose',
          attribute: 'subpurpose',
          defaultValue: '*',
          options: {},
          conditionalOptions: { attribute: 'purpose', options: optionsTypeMap },
          help: <HelpTrigger helpKey="purpose" />,
        },
        {
          includeIf: { attribute: 'type', value: 'x509' },
          type: 'file',
          label: 'Certificate',
          attribute: 'key',
          required: true,
          defaultValue: '',
          encoding: 'base64',
          mime: 'application/x-x509-user-cert, text/plain',
          help: <HelpTrigger helpKey="certificate" />,
        },
      ],
    },
  };

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ certificates, setCertificates ] = React.useState( [] );
  const [ fields, setFields ] = React.useState( null );

  React.useEffect( () => {
    setFields( EMPTY_FIELDS );
  }, [] );

  const onRefresh = () => {

    const filters = paramsToFilters();

    const project = 'default';
    const model = 'base';
    const filterParams = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'identity',
        'purpose',
        'subpurpose',
        'type',
        'created',
        'key',
      ],
      // eslint-disable-next-line camelcase
      field_map: {},
    };

    if ( isNotEmpty( filters ) ) {
      Object.entries( filters ).map( ( [ key, val ] ) => {
        if ( filterParams.extra_columns.includes( key ) ) {
          if ( val !== '*' ) {
            filterParams.field_map[key] = val;
          }
        }
      } );
    }
    makeRequest( 'SEARCH', '/certificate', { project, model, ...filterParams } ).then( response => {
      if ( response && response['results'] ) {
        const sorted = response['results'].sort( ( a, b ) => {
          const f = 'id';
          // eslint-disable-next-line
          return ( a[f] > b[f] ) ? 1 : ( ( b[f] > a[f] ) ? -1 : 0 );
        } );
        setCertificates( sorted );
      } else {
        setCertificates( [] );
      }
    } );
  };

  const onSave = (
    certificate,
    isValid,
    fieldStates,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const includedValues = getFieldValues( fieldStates, 'certificate' );

      const newCertifcate = {};

      Object.keys( includedValues ).map( attr => {
        newCertifcate[attr] = includedValues[attr];
      } );

      // manually add a copy things not in the form
      newCertifcate.source = 'manual';

      if ( isNotEmpty( certificate ) ) {
        // we are updating, add the id
        newCertifcate.id = certificate.id;
      }

      makeRequest( 'UPSERT', '/certificate', {'records':[ newCertifcate ]} ).then( response => {
        onRefresh();
        if ( response['errors'] ) {
          response.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
      } );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={certificates}
          setRecords={setCertificates}
          recordType="certificate"
          recordData={recordData}
          fields={fields}
          alternateItemLayout
        />
      }
    </React.Fragment>
  );
};

export default Certificates;
