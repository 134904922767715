/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

export const data = {
  filterInputs: [
    {
      type: 'hidden',
      attribute: 'item',
    },
    {
      type: 'debouncedText',
      label: 'Filter User Risk',
      attribute: 'keywords',
      placeholder: 'Enter search term...',
      helpItem: <HelpTrigger helpKey="keywords" />,
    },
    {
      type: 'select',
      label: 'Risk Items in report',
      attribute: 'item_count',
      value: 100,
      options: {
        10: '10',
        25: '25',
        50: '50',
        100: '100',
      },
      helpItem: <HelpTrigger helpKey="item_count" />,
    },
  ],
};

/* eslint-disable camelcase */
export const filterSet = {
  filters: {
    rownums: [ 0, 100 ],
    extra_columns: [
      'name',
      'node_id',
      'domain_user_analysis.domain_name',
      'domain_user_analysis.risk',
      'domain_user_analysis.active_hosts',
      'domain_user_analysis.domain_groups',
      'sid',
    ],
    order_by: [
      [
        'domain_user_analysis.risk',
        'DESC',
      ],
      [
        'name',
        'ASC',
      ],
    ],
  },
  project: 'default',
  model: 'base',
};
/* eslint-enable camelcase */
