/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';

import { capitalize, isNotEmpty } from '../../../shared/Utilities';
import NodeHeader from './Header/NodeHeader';
import EdgeHeader from './Header/EdgeHeader';
import ScopeHeader from './Header/ScopeHeader';
import VulnerabilityHeader from './Header/VulnerabilityHeader';
import PatchHeader from './Header/PatchHeader';
import InlineSVG from '../../../shared/InlineSVG';
import NodeContent from './Content/NodeContent';
import EdgeContent from './Content/EdgeContent';
import ScopeContent from './Content/ScopeContent';
import VulnerabilityContent from './Content/VulnerabilityContent';
import PatchContent from './Content/patchContent';
import NodeOptions from './Options/NodeOptions';
import EdgeOptions from './Options/EdgeOptions';
import ScopeOptions from './Options/ScopeOptions';
import Draggable from 'react-draggable';

import './style.scss';
import PathsFromHere from './Content/PathsFromHere';
import SignatureHeader from './Header/SignatureHeader';
import SignatureContent from './Content/SignatureContent';
import { getRecord } from '../../../shared/RecordCache';
import EmptyLoading from '../../../shared/EmptyLoading';
import { makeRequest } from '../../../../legacy/io';

const RecordCardContent = ( {
  loading,
  type,
  options,
  context,
  collapsed,
  setCollapsed,
  handleClose,
  selectItem,
  displayExploreItemNameFor,
  style,
  setStyle,
  containerRef,
  pathsFromHere,
  setExternalHoverIDs,
  selectedPathID,
  setSelectedPathID,
  show,
  // setShow,
  selectedEdgeVulnerability,
  typesWithOptionsMenu,
  setSelectingSecondaryItem,
  editItem,
  copyItem,
  deleteItem,
  fullRecord,
  additionalContent,
  setAdditionalContent,
  setSelectedEdgeVulnerability,
} ) => {

  const getDetailsLinkFor = ( record, type ) => {
    if ( isNotEmpty( record ) && isNotEmpty( record.id ) && isNotEmpty( type ) ) {
      if ( type === 'host' || type === 'scope' ) {
        // eslint-disable-next-line max-len
        return `#.=risk_insight&report=hosts&item_count=100&order_by=filtered_risk&order_direction=DESC&sensitive_assets=any&current_page=1&accepted_risk=false&item=${record.id}&panel_tab=risk_and_remediation`;
      }
      if ( type === 'patch' ) {
        // eslint-disable-next-line max-len
        return `#.=risk_insight&report=patches&risk_type=risk&item_count=100&order_by=filtered_risk&order_direction=DESC&superseded=unsuperseded&current_page=1&accepted_risk=false&item=${record.id}&panel_tab=risk_and_remediation`;
      }
      if ( type === 'vulnerability' ) {
        // eslint-disable-next-line max-len
        return `#.=risk_insight&report=vulnerabilities&item_count=100&order_by=filtered_risk&order_direction=DESC&patchable=any&current_page=1&accepted_risk=false&item=${record.id}&panel_tab=risk_and_remediation`;
      }
    }
  };

  return (
    <React.Fragment>
      <EmptyLoading
        payload={fullRecord}
        loading={loading}
        emptyMessage="No Record Details Available"
      />
      {
        isNotEmpty( fullRecord ) &&
        <div className={ `contentGridWrapper ${ isNotEmpty( additionalContent ) ? 'twoColumn' : '' }` }>
          <div className="mainContent">
            <div className={ `recordCardHeader ${ options?.isDraggable ? 'isDraggable' : ''} ${type}` }>
              {
                type === 'signature' &&
                <SignatureHeader
                  signature={fullRecord}
                  options={options}
                  context={context}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
              {
                type === 'node' &&
                <NodeHeader
                  node={fullRecord}
                  options={options}
                  context={context}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
              {
                type === 'edge' &&
                <EdgeHeader
                  edge={fullRecord}
                  options={options}
                  context={context}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
              {
                ( type === 'scope' || type === 'host' ) &&
                <ScopeHeader
                  scope={fullRecord}
                  options={options}
                  context={context}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
              {
                ( type === 'vulnerability' && !options?.includeSignature ) &&
                <VulnerabilityHeader
                  vulnerability={fullRecord}
                  options={options}
                  context={context}
                  selectItem={selectItem}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
              {
                type === 'patch' &&
                <PatchHeader
                  patch={fullRecord}
                  options={options}
                  context={context}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  handleClose={handleClose}
                />
              }
            </div>
            <div className="recordCardContent">
              {
                type === 'signature' &&
                <SignatureContent
                  signature={fullRecord}
                  options={options}
                  context={context}
                  selectItem={selectItem}
                  displayExploreItemNameFor={ displayExploreItemNameFor }
                  style={style}
                  setStyle={setStyle}
                  containerRef={containerRef}
                />
              }
              {
                type === 'node' &&
                <React.Fragment>
                  <NodeContent
                    node={fullRecord}
                    style={style}
                    setStyle={setStyle}
                    containerRef={containerRef}
                  />
                  {
                    isNotEmpty( pathsFromHere ) &&
                    <PathsFromHere
                      pathsFromHere={ pathsFromHere }
                      setExternalHoverIDs={setExternalHoverIDs}
                      displayExploreItemNameFor={displayExploreItemNameFor}
                      selectedPathID={ selectedPathID }
                      setSelectedPathID={ setSelectedPathID }
                    />
                  }
                </React.Fragment>
              }
              {
                type === 'edge' &&
                <EdgeContent
                  edge={fullRecord}
                  options={options}
                  context={context}
                  selectItem={selectItem}
                  additionalContent={ additionalContent }
                  setAdditionalContent={ setAdditionalContent }
                  displayExploreItemNameFor={ displayExploreItemNameFor }
                  show={show}
                  style={style}
                  setStyle={setStyle}
                  containerRef={containerRef}
                  selectedEdgeVulnerability={selectedEdgeVulnerability}
                  setSelectedEdgeVulnerability={setSelectedEdgeVulnerability}
                />
              }
              {
                ( type === 'scope' || type === 'host' ) &&
                <ScopeContent
                  scope={fullRecord}
                  options={options}
                  context={context}
                  style={style}
                  setStyle={setStyle}
                  containerRef={containerRef}
                />
              }
              {
                type === 'vulnerability' &&
                <React.Fragment>
                  <VulnerabilityContent
                    vulnerability={fullRecord}
                    options={options}
                    context={context}
                    style={style}
                    setStyle={setStyle}
                    containerRef={containerRef}
                  />
                </React.Fragment>
              }
              {
                type === 'patch' &&
                <PatchContent
                  patch={fullRecord}
                  options={options}
                  context={context}
                  style={style}
                  setStyle={setStyle}
                  containerRef={containerRef}
                />
              }
              {
                (
                  isNotEmpty( options )
                  && options.include_details_link === true
                ) &&
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fullDetailsLink"
                  href={ getDetailsLinkFor( fullRecord, type ) }
                >
                  See Full Record Details
                </a>
              }
            </div>
            {
              ( context === 'explore' && typesWithOptionsMenu.includes( type ) ) &&
              <div className="recordCardOptions">
                <div className="recordCardOptionsHeader">
                  <InlineSVG type="filterAlt" />
                  <h3>
                    { capitalize( type === 'edge' ? 'segment' : type ) } options
                  </h3>
                </div>
                <ul>
                  {
                    type === 'node' &&
                    <NodeOptions
                      node={fullRecord}
                      selectItem={ selectItem }
                      setSelectingSecondaryItem={ setSelectingSecondaryItem }
                      editItem={ editItem }
                      copyItem={ copyItem }
                      deleteItem={deleteItem }
                    />
                  }
                  {
                    type === 'edge' &&
                    <EdgeOptions
                      edge={fullRecord}
                      selectItem={ selectItem }
                      editItem={ editItem }
                      deleteItem={ deleteItem }
                    />
                  }
                  {
                    type === 'scope' &&
                    <ScopeOptions
                      scope={fullRecord}
                      editItem={ editItem }
                      copyItem={ copyItem }
                      selectItem={ selectItem }
                    />
                  }
                </ul>
              </div>
            }
          </div>
          {
            isNotEmpty( additionalContent ) &&
            <div className="additionalContentContainer">
              <div className="additionalContentHeader">
                <h2>Additional Information</h2>
                <button
                  className="recordCardCloseButton additionalContent roundGlyphButton light"
                  onClick={ () => {
                    setAdditionalContent( null );
                    setSelectedEdgeVulnerability( null );
                  } }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              </div>
              { additionalContent }
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

const RecordCard = ( {
  record,
  type,
  context='explore',
  options={},
  show=true,
  setShow=() => {},
  onCloseCallback=() => {},
  // these are all for explore
  editItem=() => {},
  deleteItem=() => {},
  copyItem=() => {},
  selectItem=() => {},
  // addSegment=() => {},
  setSelectingSecondaryItem=() => {},
  pathsFromHere,
  setExternalHoverIDs=() => {},
  displayExploreItemNameFor,
  collapsed=false,
  setCollapsed=() => {},
  selectedPathID=null,
  setSelectedPathID=null,
  fetchRecordDetails=false,
} ) => {

  // eslint-disable-next-line
  const [ style, setStyle ] = React.useState( {} );
  const [ additionalContent, setAdditionalContent ] = React.useState( null );
  const [ selectedEdgeVulnerability, setSelectedEdgeVulnerability ] = React.useState( null );

  const [ loading, setLoading ] = React.useState( false );
  const [ fullRecord, setFullRecord ] = React.useState( null );

  const containerRef = React.useRef( null );

  const recordCardRoot = document.getElementById( 'reactRecordCardPortal' );

  const typesWithOptionsMenu = [
    'edge',
    'scope',
    'node',
  ];

  // position the modal near to the clicked item (naive setting, this will be overwritten once the content loads)
  React.useEffect( () => {

    const multiplier = isNotEmpty( additionalContent ) ? 64 : 32;
    const width = multiplier * 16;

    if ( context === 'explore' ) {
      setStyle( { top: ( 16 * 6.5 ), bottom: 'unset', left: 'unset', right: 16, width } );
    } else if (
      ( isNotEmpty( record ) && isNotEmpty( record.clickEvent ) )
      || ( isNotEmpty( options ) &&  isNotEmpty( options.clickEvent ) )
    ) {
      let left = 'unset';
      let right = 16;
      let top = 16;
      let bottom = 'unset';

      if ( context !== 'explore' ) {
        const _clickEvent = options.clickEvent || record.clickEvent;

        const { pageX, pageY } = _clickEvent;
        left = pageX + 16;
        right = 'unset';
        top = pageY;
        bottom = 'unset';
      }

      setStyle( { top, bottom, left, right, width } );
    }
  }, [ options, type, context, containerRef, additionalContent ] );


  React.useEffect( () => {
    setAdditionalContent( null );
    if ( isNotEmpty( setSelectedPathID ) ) {
      setSelectedPathID( null );
    }
    if ( isNotEmpty( setExternalHoverIDs ) ) {
      setExternalHoverIDs( [] );
    }

    if ( show === false ) {
      setFullRecord( null );
      setSelectedEdgeVulnerability( null );
    }
  }, [ show ] );

  const getFullDetails = async ( record, type ) => {
    setLoading( true );

    if ( type === 'signature' && isNotEmpty( record.id ) ) {

      const params = {
        model: 'base',
        project: 'default',
        filters: {
          // eslint-disable-next-line camelcase
          extra_columns: [
            'scanner',
            'signature',
            'title',
            'signature_analysis.risk',
            'manual_html',
            'scanner_rating',
            'description',
            'service_info',
            'urls',
            'recommendation',
            'modified',
          ],
          rownums: [],
          // eslint-disable-next-line camelcase
          order_by: [
            [ 'modified', 'DESC' ],
            [ 'signature_analysis.risk', 'DESC' ],
          ],
          // eslint-disable-next-line camelcase
          id_list: [ record.id ],
        },
      };
      const signatureResponse = await makeRequest( 'SEARCH', '/model/signature', params );

      if ( isNotEmpty( signatureResponse ) && isNotEmpty( signatureResponse.results ) ) {
        setFullRecord( { ...signatureResponse.results[0], clickEvent: record.clickEvent } );
      } else {
        setFullRecord( record );
      }
    } else {
      const recordResponse = await getRecord( type, record.id, {}, true );
      setLoading( false );
      if ( isNotEmpty( recordResponse ) ) {

        let _record = { ...recordResponse, clickEvent: record.clickEvent };
        if ( type === 'host' ) {
          _record = { ..._record, type: 'host' };
        }
        setFullRecord( _record );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( type ) ) {
      // the record passed in is not the full record, need to retrieve the full details of the record
      if ( fetchRecordDetails && isNotEmpty( record.id ) ) {
        getFullDetails( record, type );
      } else {
        setFullRecord( record );
      }
    } else {
      setFullRecord( null );
    }

  }, [ record, type, fetchRecordDetails ] );

  const handleClose = () => {
    setShow( false );
    onCloseCallback();
  };

  if ( context === 'dashboard' ) {
    return (
      <div
        // eslint-disable-next-line max-len
        className={ `${ collapsed ? 'collapsed' : '' } recordCardContainer recordCardType_${type} cardContext_${context} ${ show ? 'visible' : '' }` }
        style={ style }
      >
        {
          <RecordCardContent
            loading={loading}
            setLoading={setLoading}
            type={type}
            options={options}
            context={context}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            handleClose={handleClose}
            selectItem={selectItem}
            displayExploreItemNameFor={displayExploreItemNameFor}
            style={style}
            setStyle={setStyle}
            containerRef={containerRef}
            pathsFromHere={pathsFromHere}
            setExternalHoverIDs={setExternalHoverIDs}
            selectedPathID={selectedPathID}
            setSelectedPathID={setSelectedPathID}
            show={show}
            setShow={setShow}
            selectedEdgeVulnerability={selectedEdgeVulnerability}
            typesWithOptionsMenu={typesWithOptionsMenu}
            setSelectingSecondaryItem={setSelectingSecondaryItem}
            editItem={editItem}
            copyItem={copyItem}
            deleteItem={deleteItem}
            fullRecord={fullRecord}
            additionalContent={additionalContent}
            setAdditionalContent={setAdditionalContent}
            setSelectedEdgeVulnerability={setSelectedEdgeVulnerability}
          />
        }
      </div>
    );
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        ( isNotEmpty( type ) && show ) &&
        <React.Fragment>
          {
            options?.include_shade &&
            <div className="recordCardContainerShade" onClick={ handleClose } />
          }
          {
            ( isNotEmpty( options ) && options.isDraggable )
              ? <Draggable
                disabled={collapsed}
                handle=".recordCardHeader"
              >
                <div
                  // eslint-disable-next-line max-len
                  className={ `${ collapsed ? 'collapsed' : '' } recordCardContainer recordCardType_${type} cardContext_${context} ${ show ? 'visible' : '' }` }
                  style={ style }
                  ref={ containerRef }
                >
                  {
                    <RecordCardContent
                      loading={loading}
                      setLoading={setLoading}
                      type={type}
                      options={options}
                      context={context}
                      collapsed={collapsed}
                      setCollapsed={setCollapsed}
                      handleClose={handleClose}
                      selectItem={selectItem}
                      displayExploreItemNameFor={displayExploreItemNameFor}
                      style={style}
                      setStyle={setStyle}
                      containerRef={containerRef}
                      pathsFromHere={pathsFromHere}
                      setExternalHoverIDs={setExternalHoverIDs}
                      selectedPathID={selectedPathID}
                      setSelectedPathID={setSelectedPathID}
                      show={show}
                      setShow={setShow}
                      selectedEdgeVulnerability={selectedEdgeVulnerability}
                      typesWithOptionsMenu={typesWithOptionsMenu}
                      setSelectingSecondaryItem={setSelectingSecondaryItem}
                      editItem={editItem}
                      copyItem={copyItem}
                      deleteItem={deleteItem}
                      fullRecord={fullRecord}
                      additionalContent={additionalContent}
                      setAdditionalContent={setAdditionalContent}
                      setSelectedEdgeVulnerability={setSelectedEdgeVulnerability}
                    />
                  }
                </div>
              </Draggable>
              : <div
                // eslint-disable-next-line max-len
                className={ `${ collapsed ? 'collapsed' : '' } recordCardContainer recordCardType_${type} cardContext_${context} ${ show ? 'visible' : '' }` }
                style={ style }
                ref={ containerRef }
              >
                {
                  <RecordCardContent
                    loading={loading}
                    setLoading={setLoading}
                    type={type}
                    options={options}
                    context={context}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    handleClose={handleClose}
                    selectItem={selectItem}
                    displayExploreItemNameFor={displayExploreItemNameFor}
                    style={style}
                    setStyle={setStyle}
                    containerRef={containerRef}
                    pathsFromHere={pathsFromHere}
                    setExternalHoverIDs={setExternalHoverIDs}
                    selectedPathID={selectedPathID}
                    setSelectedPathID={setSelectedPathID}
                    show={show}
                    setShow={setShow}
                    selectedEdgeVulnerability={selectedEdgeVulnerability}
                    typesWithOptionsMenu={typesWithOptionsMenu}
                    setSelectingSecondaryItem={setSelectingSecondaryItem}
                    editItem={editItem}
                    copyItem={copyItem}
                    deleteItem={deleteItem}
                    fullRecord={fullRecord}
                    additionalContent={additionalContent}
                    setAdditionalContent={setAdditionalContent}
                    setSelectedEdgeVulnerability={setSelectedEdgeVulnerability}
                  />
                }
              </div>
          }
        </React.Fragment>
      }
    </React.Fragment>,
    recordCardRoot,
  );
};

export default RecordCard;