/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { pagesMap } from '../components/App/Routing';
import { useLocalStorage } from '../shared/Utilities';

export const NavigationContext = React.createContext();

export const NavigationProvider = ( { children } ) => {

  const [ expandedLeftNavMenus, setExpandedLeftNavMenus ] = React.useState( {
    reporting: false,
    // eslint-disable-next-line camelcase
    risk_insight: false,
    remediation: false,
    explore: false,
    activity: false,
    scanning: false,
    setup: false,
  } );

  const [ routing, setRouting ] = React.useState( pagesMap );
  const [ expandLeftNavigation, setExpandLeftNavigation ] = useLocalStorage( 'dsExpandLeftNavigation', false );
  const [ leftNavHovered, setLeftNavHovered ] = React.useState( false );

  const data = [
    expandLeftNavigation,
    setExpandLeftNavigation,
    routing,
    setRouting,
    expandedLeftNavMenus,
    setExpandedLeftNavMenus,
    leftNavHovered,
    setLeftNavHovered,
  ];

  return (
    <NavigationContext.Provider value={ data }>
      { children }
    </NavigationContext.Provider>
  );
};
