/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import { isNotEmpty } from '../shared/Utilities';

export const TagsContext = React.createContext();

export const TagsProvider = ( { children } ) => {

  const [ tags, setTags ] = React.useState( {} );

  const refreshTags = () => {
    makeRequest( 'SEARCH', '/project/default/asset_tag', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'label', 'color' ],
      // eslint-disable-next-line camelcase
      order_by:[ [ 'label', 'ASC' ] ],
    } ).then( response => {
      const _tags = {};
      if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
        response.results.map( t => _tags[t.id] = t );
      }
      if ( isNotEmpty( _tags ) ) {
        setTags( _tags );
      }
    } );
  };

  React.useEffect( () => {
    // eslint-disable-next-line
    let isMounted = true;

    refreshTags();

    return () => {
      isMounted = false;
    };
  }, [] );

  const data = [
    tags,
    refreshTags,
  ];

  return (
    <TagsContext.Provider value={ data }>
      { children }
    </TagsContext.Provider>
  );
};