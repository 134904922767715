/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Modal from '../../../shared/Modal';

import './FileUploader.scss';

import {
  init_file_manager,
} from '../../../../legacy/dom';

const FileUploaderBody = ( {
  record,
  showUploader,
} ) => {
  const uploaderContainer = React.useRef( null );

  React.useEffect( () => {
    if ( record && showUploader ) {
      const uploaderPath = `/var/lib/deepsurface/scan_results/${record['tool']}/${record['id']}`;
      init_file_manager( uploaderContainer.current, uploaderPath );
    }
  }, [ record, showUploader ] );

  return (
    <div ref={uploaderContainer} className="uploaderContainer"></div>
  );
};

const FileUploaderAction = ( {
  finishUploader,
} ) => {
  return (
    <button
      className="submitButton"
      onClick={finishUploader}
    >
      Finish
    </button>
  );
};

const FileUploader = ( {
  record,
  showUploader,
  setShowUploader,
  finishUploader,
} ) => {
  return (
    <React.Fragment>
      <Modal
        elementClass="legacyFileUploader"
        visible={showUploader}
        setVisible={setShowUploader}
        header={'Import Scan Files'}
        size="medium"
        body={<FileUploaderBody
          record={record}
          showUploader={showUploader}
        />
        }
        action={<FileUploaderAction
          finishUploader={finishUploader}
          record={record}
        />}
      />
    </React.Fragment>
  );
};

export default FileUploader;
