/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import SetupPage from '../../../shared/SetupComponents/SetupPage';
import { makeRequest } from '../../../../legacy/io';

const recordData = { values: { } };

const AcceptedRiskPlans = ( ) => {

  const [ records, setRecords ] = React.useState( false );

  const params = {
    rownums: [ 0, 100 ],
    // eslint-disable-next-line camelcase
    extra_columns: [
      'object_id',
      'object_label',
      'item_ids',
      'owner',
      'expiration',
      'type',
      'reason',
    ],
  };

  // 1) Whenever the data changes, this gets called, on page load or after a save/edit
  const onRefresh = async () => {
    const response = await makeRequest( 'SEARCH', 'model/base/accepted_risk', params );

    if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
      setRecords( response.results );
    } else {
      setRecords( null );
    }
  };

  return (
    <React.Fragment>
      <SetupPage
        onRefresh={onRefresh}
        onSave={ () => {} }
        records={records}
        setRecords={setRecords}
        recordType="accepted_risk_plan"
        recordData={recordData}
        alternateItemLayout
        // noCreate
      />
    </React.Fragment>
  );
};

export default AcceptedRiskPlans;