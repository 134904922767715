/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { RemediationContext } from '../../../Contexts/Remediation';

import SetupPage from '../../../shared/SetupComponents/SetupPage';
import {
  getActiveIntegrations,
  isNotEmpty,
  userDisplayName,
} from '../../../shared/Utilities';
import { recordData } from './data';

import './style.scss';
import { makeRequest } from '../../../../legacy/io';
import { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';

const RemediationPlans = ( ) => {

  const [ remediationPlans, setRemediationPlans ] = React.useState( [] );
  const [ users, setUsers ] = React.useState( null );
  const [ activeIntegrations, setActiveIntegrations ] = React.useState( null );

  const [ workingDraftPlan, setWorkingDraftPlan, , setAllRemediationDrafts ] = React.useContext( RemediationContext );

  // on init, sets up the page and help
  React.useEffect( () => {
    const _allUsers = {};
    makeRequest( 'SEARCH', '/user', {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'authentication_provider_id',
        'username',
        'given_name',
        'family_name',
        'api_key',
        'setup_wizard_disabled',
        'email_address',
      ],
      'order_by':[ [ 'username', 'ASC' ] ],
    } ).then( response => {
      if ( response ) {
        if ( response['results'] && response['results'].length ) {

          response.results.map( user => {
            _allUsers[user.id] = userDisplayName( user );
          } );
        }
      }
      setUsers( _allUsers );
    } );
    getActiveIntegrations().then( setActiveIntegrations );
    // check for any relevant config alerts and add them to the recordData so that the page can display them
    getConfigurationAlertsFor( [ 'remediation-email-export', 'remediation-ticketing' ] ).then( response => {
      if ( isNotEmpty( response ) ) {
        recordData.notifications = [
          {
            type: 'alert',
            // eslint-disable-next-line max-len
            message: <p>The latest attempt to export remediation tasks resulted in errors and configuration alerts have been issued. For further information please refer to the relevant configuration alerts concerning <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=remediation-email-export">Remediation Email Exports</a> and/or <a target="_blank" rel="noopener noreferrer" href="#.=activity&page=configuration_alerts&source=remediation-ticketing">Remediation Ticketing Exports</a>.</p>,
          },
        ];
      }
    } );
  }, [] );

  const onRefresh = async () => {
    const response = await makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} );
    const sortedResults = {
      draft: [],
      active: [],
      closed: [],
    };

    if ( response && isNotEmpty( response.results ) ) {
      response.results.map( plan => {
        sortedResults[plan.status].push( plan );
      } );
      setRemediationPlans( sortedResults );
      if ( isNotEmpty( sortedResults.draft ) ) {
        setAllRemediationDrafts( sortedResults.draft );
        const currentDraftID = workingDraftPlan?.id;

        if ( currentDraftID && !sortedResults.draft.find( p => p.id === currentDraftID ) ) {
          setWorkingDraftPlan( sortedResults.draft[0] );
        }
      } else {
        setAllRemediationDrafts( null );
        setWorkingDraftPlan( null );
      }
    } else {
      setRemediationPlans( {} );
      setAllRemediationDrafts( null );
      setWorkingDraftPlan( null );
    }
  };

  return (
    <React.Fragment>
      <SetupPage
        onRefresh={onRefresh}
        onSave={ () => {} }
        records={remediationPlans}
        setRecords={setRemediationPlans}
        recordType="remediation_plan"
        recordData={recordData}
        users={users}
        alternateItemLayout
        activeIntegrations={activeIntegrations}
      />
    </React.Fragment>
  );
};

export default RemediationPlans;