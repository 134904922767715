/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { getWidgetDescription } from '../../shared';

import './WidgetDescription.scss';

const WidgetDescription = ( {
  item,
  data,
  settings,
} ) => {

  const [ description, setDescription ] = React.useState( null );

  const setupDescription = async () => {
    const _description = getWidgetDescription( item, data, settings );
    if ( isNotEmpty( _description ) ) {
      setDescription( _description );
    } else {
      setDescription( null );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( item ) ) {
      setupDescription();
    }
  }, [ item, data, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( description ) &&
        <div className="widgetDescriptionContainer">
          { description }
        </div>
      }
    </React.Fragment>
  );
};

export default WidgetDescription;