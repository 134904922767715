/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import Modal from '../../../shared/Modal';
import { isNotEmpty } from '../../../shared/Utilities';
import { selectExploreItems } from './Shared';
import Form from '../../../shared/Form';
import { getFieldValues } from '../../../shared/Form/Shared';
import Loading from '../../../shared/Loading';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { addRecords, updateRecords } from '../../../shared/RecordCache.js';


const DEFAULT_VALUES = {
  label: '',
  // eslint-disable-next-line camelcase
  node_label: '',
  // eslint-disable-next-line camelcase
  scope_id: '',
  type: 'user',
  details: '',
  impact: '',
  flags: {},
};

const EMPTY_FIELDS = [
  // used on edit
  {
    type: 'text',
    attribute: 'label',
    label: 'Label',
    required: true,
    defaultValue: '',
    help: <HelpTrigger helpKey="node_label" />,
  },
  // used on new
  {
    type: 'text',
    attribute: 'node_label',
    label: 'Label',
    required: true,
    defaultValue: '',
    help: <HelpTrigger helpKey="node_label" />,
  },
  {
    type: 'searchResults',
    attribute: 'scope_id',
    label: 'Scope',
    placeholder: 'Find scope by name...',
    recordType: 'scope',
    required: true,
    defaultValue: '',
    help: <HelpTrigger helpKey="node_scope" />,
  },
  {
    type: 'select',
    attribute: 'type',
    label: 'Type',
    required: true,
    defaultValue: 'user',
    options: {
      user: 'User',
      group: 'Group',
      // eslint-disable-next-line camelcase
      domain_user: 'Domain User',
      // eslint-disable-next-line camelcase
      domain_group: 'Domain Group',
      // eslint-disable-next-line camelcase
      service_user: 'Service User',
      file: 'File(s)',
      database: 'Database',
      // eslint-disable-next-line camelcase
      shared_folder_permission: 'Simplified Shared Folder Permission',
    },
    help: <HelpTrigger helpKey="node_type" />,
  },
  {
    type: 'textarea',
    attribute: 'details',
    label: 'Description',
    defaultValue: '',
    help: <HelpTrigger helpKey="node_description" />,
  },
  {
    type: 'number',
    attribute: 'combined_impact',
    label: 'Impact',
    defaultValue: '',
    htmlProps: { min: 0, step: 10000 },
    help: <HelpTrigger helpKey="node_impact" />,
  },
  {
    type: 'hidden',
    attribute: 'flags',
    defaultValue: {},
  },
];

const ModalBody = ( {
  fields,
  setUpdatedForm,
  selectedItem,
  setIsValid,
  isLoading,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( fields ) && isNotEmpty( selectedItem ) ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          setIsValid={setIsValid}
          recordType="explore_node"
          existingRecord={selectedItem}
        />
      }
      { isLoading && <Loading text="Saving..." />}
    </React.Fragment>
  );
};

const ModalAction = ( {
  isValid,
  updatedForm,
  setShow,
  selectedItem,
  fetchAndRedrawGraph,
  setIsLoading,
} ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const save = async () => {
    if ( isNotEmpty( updatedForm ) ) {
      setIsLoading( true );
      const values = getFieldValues( updatedForm?.fieldStates, 'explore_node' );
      let nodeResponse;
      const _nodeValues = {};

      // need to create a node
      if ( isNotEmpty( selectedItem ) && selectedItem.isScope ) {

        Object.entries( values ).map( ( [ attr, val ] ) => {
          // the attr that the field is READING is the node_analysis.combined_impact
          // but the attr that the field is WRITING to is node.impact. VERY IMPORTANT
          if ( attr === 'combined_impact' ) {
            if ( val === 'null' ) {
              _nodeValues.impact = 0;
            } else {
              _nodeValues.impact = val || 0;
            }
          // the 'label' field was being pre-filled with the selected record... which is the scope label
          } else if ( attr === 'node_label' ) {
            _nodeValues.label = val || '';
          } else {
            _nodeValues[attr] = val || '';
          }
        } );

        // DMC - 2022-08-04 backend regression was introduced that does not allow flags to be null, this prevents that
        if ( isNotEmpty( selectedItem ) ) {
          _nodeValues.flags = selectedItem.flags || {};
        }
        nodeResponse = await addRecords( 'node', [ { ..._nodeValues, id: uuidv4() } ] );
      // need to update a node
      } else {

        Object.entries( values ).map( ( [ attr, val ] ) => {
          if ( val !== selectedItem[attr] ) {
            // the attr that the field is READING is the node_analysis.combined_impact
            // but the attr that the field is WRITING to is node.impact. VERY IMPORTANT
            if ( attr === 'combined_impact' ) {
              if ( val === 'null' ) {
                _nodeValues.impact = 0;
              } else {
                _nodeValues.impact = val || 0;
              }
            // the 'label' field was being pre-filled with the selected record... which is the scope label
            } else if ( attr === 'node_label' ) {
              _nodeValues.label = val || '';
            } else {
              _nodeValues[attr] = val || '';
            }
          }
        } );

        // DMC - 2022-08-04 backend regression was introduced that does not allow flags to be null, this prevents that
        if ( isNotEmpty( selectedItem ) ) {
          _nodeValues.flags = selectedItem.flags || {};
        }

        nodeResponse = await updateRecords( 'node', [ { ..._nodeValues, id: selectedItem.id } ] );
      }

      if ( isNotEmpty( nodeResponse ) ) {
        if ( nodeResponse.errors ) {
          nodeResponse.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        } else {
          setShow( false );
          selectExploreItems( 'node', nodeResponse );
          fetchAndRedrawGraph( true );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully saved node',
          } );
        }
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'Error saving node',
        } );
      }
      setIsLoading( false );
    }
  };
  return (
    <React.Fragment>
      <button
        disabled={!isValid}
        className={ `${isValid ? '' : 'disabled'}`}
        onClick={ save }
      >
        Save node
      </button>
    </React.Fragment>
  );
};

const EditNode = ( { selectedItem, selectedItemType, show, setShow, fetchAndRedrawGraph } ) => {

  const [ fields, setFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ isLoading, setIsLoading ] = React.useState( false );

  const setupForm = item => {

    let _fields = Array.from( EMPTY_FIELDS );

    if ( isNotEmpty( item ) ) {
      // we are editing an existing node
      if ( item.isNode ) {
        _fields = _fields.filter( f => f.attribute !== 'node_label' );
        _fields.map( f => {
          // the 'label' field was being pre-filled with the selected record... which is the scope label
          if ( f.attribute === 'node_label' ) {
            f.defaultValue = item.label || '';
          } else {
            f.defaultValue = item[f.attribute] || '';
          }
        } );
        const scopeField = _fields.find( f => f.attribute === 'scope_id' );
        scopeField.disabled = true;
      // we are actually creating a new node on a selected scope
      } else if ( item.isScope ) {
        _fields = _fields.filter( f => f.attribute !== 'label' );
        _fields.map( f => {
          // need to reset the defaults in order to clear out previous values
          f.defaultValue = DEFAULT_VALUES[f.attribute] || '';
        } );

        const scopeField = _fields.find( f => f.attribute === 'scope_id' );
        scopeField.defaultValue = item.id;
        scopeField.disabled = false;
      }

      setFields( _fields );
    } else {
      setFields( null );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( selectedItem ) && show ) {
      setShow( true );

      if ( isNotEmpty( selectedItem ) ) {
        setupForm( selectedItem );
      }
    } else {
      setFields( [] );
    }
  }, [ selectedItem, show, selectedItemType ] );

  return (
    <React.Fragment>
      <Modal
        elementClass="exploreModal"
        visible={show}
        setVisible={setShow}
        header={`${ isNotEmpty( selectedItem ) && selectedItem.isNode ? 'Edit' : 'Add' } Node`}
        size="small"
        body={ <ModalBody
          selectedItem={selectedItem}
          fields={fields}
          setUpdatedForm={setUpdatedForm}
          setIsValid={setIsValid}
          isLoading={isLoading}
        />}
        action={  <ModalAction
          isValid={isValid}
          setShow={setShow}
          updatedForm={updatedForm}
          selectedItem={selectedItem}
          fetchAndRedrawGraph={fetchAndRedrawGraph}
          setIsLoading={setIsLoading}
        />}
      />
    </React.Fragment>
  );
};

export default EditNode;