import React from 'react';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import { isInteger } from '../../../shared/Form/Validators';
import RatingBadge from '../../../shared/RatingBadge/index.js';

export const  EMPTY_FIELDS = {
  scanDefaults: {
    header: 'Scan Default Settings',
    help: <HelpTrigger helpKey="scan_group_default_settings" />,
    fields: [
      {
        type: 'duration',
        label: 'Avoid re-scan period',
        expectedUnit: 'h',
        attribute: 'avoid_rescan_period',
        required: true,
        defaultValue: '',
        htmlProps: { min: 0, step: 1 },
        help: <HelpTrigger helpKey="avoid_rescan_period" />,
      },
      {
        type: 'duration',
        label: 'Maximum scan duration',
        expectedUnit: 'm',
        attribute: 'maximum_duration',
        required: true,
        defaultValue: '',
        htmlProps: { min: 0, step: 1 },
        help: <HelpTrigger helpKey="maximum_duration" />,
      },
      {
        type: 'number',
        label: 'Scan parallelism',
        attribute: 'scan_workers',
        required: true,
        defaultValue: '',
        htmlProps: { min: 0, step: 1, max: 200 },
        help: <HelpTrigger helpKey="scan_workers" />,
      },
      {
        type: 'checkbox',
        label: 'Collect domain information',
        attribute: 'scan_domains',
        defaultValue: '',
        help: <HelpTrigger helpKey="scan_domains" />,
      },
      {
        type: 'checkbox',
        label: 'Grant scanner MSSQL privileges',
        attribute: 'mssql_grant_privileges',
        defaultValue: '',
        help: <HelpTrigger helpKey="mssql_grant_privileges" />,
      },
      {
        type: 'checkbox',
        label: 'WinRM: Allow HTTP Scanning',
        attribute: 'winrm_allow_http',
        defaultValue: '',
        help: <HelpTrigger helpKey="winrm_allow_http" />,
      },
      {
        type: 'checkbox',
        label: <React.Fragment>
          <span>Enable NAS Scan</span>
          <RatingBadge rating="beta" />
        </React.Fragment>,
        attribute: 'enable_nas_scan',
        defaultValue: false,
        help: <HelpTrigger helpKey="enable_nas_scan" />,
      },
      {
        type: 'number',
        label: <React.Fragment>
          <span>NAS Scanning Depth</span>
          <RatingBadge rating="beta" />
        </React.Fragment>,
        attribute: 'nas_scan_depth',
        defaultValue: 1,
        help: <HelpTrigger helpKey="nas_scan_depth" />,
        validators: [ isInteger ],
        htmlProps: { min: 1, step: 1, max: 10 },
      },
    ],
  },
  settings: {
    header: 'Other Settings',
    fields: [
      {
        type: 'number',
        label: 'Maximum scan parallelism (globally)',
        attribute: 'scan_max_workers',
        required: true,
        defaultValue: '',
        htmlProps: { min: 1, max: 64, step: 1 },
        help: <HelpTrigger helpKey="scan_max_workers" />,
      },
      {
        type: 'duration',
        label: 'Maximum Wait time when a scan is stopped',
        expectedUnit: 's',
        attribute: 'scan_hard_stop_time',
        required: true,
        defaultValue: '',
        htmlProps: { min: 0, max: 43200, step: 1 },
        help: <HelpTrigger helpKey="scan_hard_stop_time" />,
      },
    ],
  },
};
