/** *************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import FilterForm from '../../../shared/FilterForm';

import { v4 as uuidv4 } from 'uuid';

import './style.scss';
import {
  isNotEmpty,
  paramsToFilters,
  decodeURLHash,
  encodeURLHash,
  formatUnixTime,
} from '../../../shared/Utilities';

import IndeterminantPagination, { getRowNums, pageIterator } from '../../../shared/Pagination/IndeterminantPagination';
import { makeRequest } from '../../../../legacy/io';
import PageHeader from '../../../shared/PageHeader';
import Modal from '../../../shared/Modal';
import EmptyLoading from '../../../shared/EmptyLoading';
import ActivityList from '../ActivityList';
import ScanLogViewer from './ScanLogViewer';
import InlineSVG from '../../../shared/InlineSVG';

const FIELDS = [
  {
    type: 'debouncedText',
    attribute: 'identifier',
    label: 'Identifier',
    placeholder: 'Find log by identifier',
  },
  {
    type: 'debouncedText',
    attribute: 'keywords',
    label: 'Keywords',
    placeholder: 'Filter logs by keywords',
  },
  {
    type: 'hidden',
    attribute: 'item',
  },
  {
    type: 'hidden',
    attribute: 'item_count',
    value: 100,
    // shouldTriggerRefresh: true,
  },
];

const LogModalBody = ( { selectedIdentifier } ) => {
  return (
    <React.Fragment>
      <div className="logOutputHeader">
        <div className="iconWrapper">
          <InlineSVG type="scanning_nav" />
        </div>
        Scan Log
      </div>
      <ScanLogViewer logIdentifier={selectedIdentifier} withinModal />
    </React.Fragment>
  );
};

const ScanLogs = ( ) => {
  const [ selectedIdentifier, setSelectedIdentifier ] = React.useState( '' );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  const [ showLogModal, setShowLogModal ] = React.useState( false );

  const [ loading, setLoading ] = React.useState( false );

  let isMounted = true;

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  const refreshLogItems =  async () => {

    const onCorrectPage = decodeURLHash()['page'] === 'scan_logs';

    if ( onCorrectPage ) {
      setLoading( true );
      const filterValues = paramsToFilters();

      const _rowNums = getRowNums( filterValues );

      const params = {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'identifier', 'created', 'modified' ],
        rownums: _rowNums,
      };

      if ( isNotEmpty( filterValues ) ) {
        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'keywords' ) {
            params.keywords = isNotEmpty( val ) ? val : '';
          }
          if ( key === 'identifier' ) {
            // eslint-disable-next-line camelcase
            params.field_map = { identifier: val };
          }
        } );
      }

      const logsResponse = await makeRequest( 'SEARCH', '/project/default/scan_log', params );
      const pagedResults = pageIterator( logsResponse?.results, filterValues );
      const currentPageNumber = pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1;
      if ( isMounted ) {
        setCurrentPageNumber( currentPageNumber );
        let _currentPageResults = [ ...pagedResults.firstPage ];
        if ( isNotEmpty( filterValues.item ) && isMounted ) {
          setSelectedIdentifier( filterValues.item );
          setShowLogModal( true );
          const currentIdentifiers = _currentPageResults.map( r => r.identifier );
          if ( !currentIdentifiers.includes( filterValues.item ) ) {
            _currentPageResults = [ { identifier: filterValues.item }, ..._currentPageResults ];
          }
        }
        setCurrentPageResults( adjustPageResults( _currentPageResults ) );
        setNextPageResults( adjustPageResults( pagedResults.secondPage ) );
      }
      setLoading( false );
    }
  };

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = ( results ) => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  const transformRowData = ( row ) => {
    return {
      label: row.identifier,
      subLabel: row.label,
      timestamp: formatUnixTime( row.created ),
      id: uuidv4(),
      original: row,
    };
  };

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    refreshLogItems();
  };

  const showLogFor = async ( log ) => {
    setSelectedIdentifier( log?.original?.identifier );
    setShowLogModal( true );
  };

  return (
    <React.Fragment>
      <Modal
        elementClass="scanLogOutputModal"
        visible={showLogModal}
        setVisible={setShowLogModal}
        body={ <LogModalBody
          selectedIdentifier={selectedIdentifier}
          showLogModal={showLogModal}
        /> }
      />
      <PageHeader elementClass="scanLogsHeaderWrapper">
        <FilterForm
          inputs={FIELDS}
          onRefresh={refreshLogItems}
          reportType="scanning"
        />
        <IndeterminantPagination
          currentPageNumber={currentPageNumber}
          nextPageResults={nextPageResults}
          goToPage={goToPage}
          elementClass="riskInsightPagination"
        />
      </PageHeader>
      <EmptyLoading
        loading={loading}
        payload={currentPageResults}
        emptyMessage="No scan logs matched the current filters"
      />
      {
        isNotEmpty( currentPageResults ) &&
        <ActivityList
          items={ currentPageResults }
          viewItem={ showLogFor }
          itemType="scanLog"
        />
      }
      {/* <div className="logsWrapper">
        <div className="availableLogItems">
          <h3>
            {
              isNotEmpty( recordBounds )
                ? <span>Logs ({recordBounds[0]} - {recordBounds[1]})</span>
                : <span>Logs</span>
            }
          </h3>
          <ul>
            {
              isNotEmpty( currentPageResults ) &&
              <React.Fragment>
                {
                  currentPageResults.map( ( logItem, i ) => {
                    return  <LogItem
                      logItem={logItem}
                      key={i}
                      selectedIdentifier={selectedIdentifier}
                      setSelectedIdentifier={setSelectedIdentifier}
                      getSelectedItem={ getSelectedItem }
                      setRawLogContent={setRawLogContent}
                    />;
                  } )
                }

              </React.Fragment>
            }
          </ul>

        </div>
        <LogOutput
          rawLogContent={rawLogContent}
          selectedIdentifier={selectedIdentifier}
        />
      </div> */}

    </React.Fragment>
  );
};

export default ScanLogs;