/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import FilterForm from '../FilterForm';

const RecordFilters = ( {
  onRefresh,
  recordData,
} ) => {
  return (
    <React.Fragment>
      <FilterForm
        inputs={recordData.filters}
        onRefresh={onRefresh}
        horizontal
      />
    </React.Fragment>
  );
};

export default RecordFilters;