/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isNotEmpty,
  reportTypeDisplayName,
  // riskToRating,
} from '../../../shared/Utilities';

import './GraphContextMenu.scss';
// import RatingBadge from '../../../shared/RatingBadge';
import InlineSVG from '../../../shared/InlineSVG';
import { ADVERSARY_NODE, GLOBAL_SCOPE } from './shared';
import { getNodeIcon } from '../../RecordDetails/shared';
import ScopeOptions from '../../RecordDetails/RecordCard/Options/ScopeOptions';
import EdgeOptions from '../../RecordDetails/RecordCard/Options/EdgeOptions';
import NodeOptions from '../../RecordDetails/RecordCard/Options/NodeOptions';

const GraphContextMenu = ( {
  item,
  type,
  selectItem,
  editItem,
  deleteItem,
  setSelectingSecondaryItem,
  copyItem,
  setContextMenuItem,
  setContextMenuType,
  saveGraphLayout,
  savedLayout,
  graphData,
  restoreGraphLayout,
} ) => {

  const SPACER = 16;
  const WIDTH = 16 * 18;
  const HEIGHTS = {
    edge: 16 * 9,
    scope: 16 * 13.25,
    node: 16 * 15.25,
  };

  const [ style, setStyle ] = React.useState( { width: WIDTH } );
  const [ fromNodeLabel, setFromNodeLabel ] = React.useState( null );
  const [ toNodeLabel, setToNodeLabel ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && type === 'edge' ) {
      if ( isNotEmpty( item.includedEdges ) ) {
        const [ firstEdge ] = item.includedEdges;

        const lastEdge = item.includedEdges[ item.includedEdges.length - 1 ];

        setFromNodeLabel( firstEdge?.fromNodeFull?.label );
        setToNodeLabel( lastEdge?.toNodeFull?.label );
      } else if ( isNotEmpty( item.fromNode ) && isNotEmpty( item.toNode ) ) {
        setFromNodeLabel( item.fromNode?.label );
        setToNodeLabel( item.toNode?.label );
      } else {
        setFromNodeLabel( 'N/A' );
        setToNodeLabel( 'N/A' );
      }
    }

    if ( isNotEmpty( item ) && isNotEmpty( item.clickEvent ) ) {
      const { pageX, pageY } = item.clickEvent;

      let height = HEIGHTS[type] || 16 * 16;
      let left = pageX + SPACER;
      let top = pageY;

      if ( type === 'node' && item.id === ADVERSARY_NODE.id ) {
        height = 16 * 9;
      }
      if ( ( pageX + WIDTH + SPACER ) >= window.innerWidth ) {
        left = pageX - SPACER - WIDTH;
      }
      if ( ( pageY + height ) >= window.innerHeight ) {
        top = window.innerHeight - height - SPACER;
      }

      setStyle( { width: WIDTH, height, top, left } );

      // figure out where to place the menu
    }
  }, [ item, type ] );

  const optionCallback = () => {
    setContextMenuItem( null );
    setContextMenuType( null );
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( item ) && isNotEmpty( type ) ) &&
        <React.Fragment>
          <div
            className="exploreGraphContextMenuShade"
            onClick={ optionCallback }
          />
          <div
            className={ `exploreGraphContextMenu ${type}`}
            style={ style }
          >
            <div className="contextMenuHeader">
              {
                type === 'svg' &&
                <h2>
                  <InlineSVG type="filterAlt" elementClass="recordTypeHeaderIcon" />
                  Graph Options
                </h2>
              }
              {
                type === 'scope' &&
                <React.Fragment>
                  <h2>
                    <InlineSVG type="hostsAlt" elementClass="recordTypeHeaderIcon" />
                    { reportTypeDisplayName( item, 'scope' ) }
                  </h2>
                  {/* <RatingBadge rating={ riskToRating( item.risk ) } /> */}
                </React.Fragment>
              }
              {
                type === 'edge' &&
                <React.Fragment>
                  <h2>
                    <InlineSVG type="segmentsAlt" elementClass="recordTypeHeaderIcon" />
                    <strong>{ fromNodeLabel }</strong>
                    <span>to</span>
                    <strong>{ toNodeLabel }</strong>
                  </h2>
                  {/* <RatingBadge rating={ edgeRiskClass( item ) } /> */}
                </React.Fragment>
              }
              {
                type === 'node' &&
                <React.Fragment>
                  <h2>
                    <svg
                      width={ 24 }
                      height={ 24 }
                      // eslint-disable-next-line max-len
                      viewBox="0 0 32 32"
                      fill="none"
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={ `recordTypeHeaderIcon ${item.id === ADVERSARY_NODE.id ? 'adversaryNode' : '' }` }
                    >
                      { getNodeIcon( item ) }
                    </svg>
                    {
                      item.combined_impact > 0 &&
                      <span className="statWarning">
                        <InlineSVG type="warningAlt" />
                      </span>
                    }
                    { item.label }
                  </h2>
                  {/* <RatingBadge rating={ item.rating } /> */}
                </React.Fragment>
              }
            </div>
            <ul>
              {
                type === 'svg' &&
                <React.Fragment>
                  <li
                    onClick={ () => editItem( 'scope', { parent: GLOBAL_SCOPE.id } ) }
                  >
                    Add scope
                  </li>
                  <li
                    onClick={ () => editItem( 'node', { ...GLOBAL_SCOPE, isScope: true } ) }
                  >
                    Add node
                  </li>
                  <li
                    onClick={ saveGraphLayout }
                  >
                    Save current layout
                  </li>
                  {
                    isNotEmpty( savedLayout ) &&
                    <li
                      onClick={ () => restoreGraphLayout( graphData ) }
                    >
                      Restore saved layout
                    </li>
                  }
                </React.Fragment>
              }
              {
                type === 'scope' &&
                <ScopeOptions
                  scope={item}
                  editItem={editItem}
                  copyItem={copyItem}
                  selectItem={selectItem}
                  optionCallback={ optionCallback }
                />
              }
              {
                type === 'edge' &&
                <EdgeOptions
                  edge={item}
                  editItem={editItem}
                  deleteItem={deleteItem}
                  selectItem={selectItem}
                  optionCallback={ optionCallback }
                />
              }
              {
                type === 'node' &&
                <NodeOptions
                  node={item}
                  setSelectingSecondaryItem={setSelectingSecondaryItem}
                  editItem={editItem}
                  copyItem={copyItem}
                  selectItem={selectItem}
                  deleteItem={deleteItem}
                  optionCallback={ optionCallback }
                />
              }
            </ul>
          </div>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default GraphContextMenu;