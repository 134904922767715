/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  HelpTrigger,
} from '../../../components/HelpDocumentation/ContextualHelp/index.js';

export const filters = [
  {
    type: 'select',
    asToggle: true,
    toggleClass: 'large',
    value: 'agent',
    label: 'Scanning Mode',
    attribute: 'scanning_mode',
    options: {
      agent: 'Agent/User Managed',
      agentless: 'Credentialed',
    },
    helpItem: <HelpTrigger helpKey="scanning_mode" />,
  },
  {
    type: 'debouncedText',
    label: 'Host Wildcard',
    placeholder: 'example: OR-*SQL*',
    attribute: 'host_globs',
    helpItem: <HelpTrigger helpKey="host_wildcard" />,
  },
  // {
  //   type: 'select',
  //   label: 'Version',
  //   attribute: 'version',
  //   allowBlank: true,
  //   blankDisplayText: 'All',
  //   helpItem: <HelpTrigger helpKey="agent_version" />,
  // },
  {
    type: 'select',
    label: 'Tags',
    attribute: 'asset_tag_ids',
    value: [],
    multiple: true,
    allowBlank: true,
    blankDisplayText: 'All',
    helpItem: <HelpTrigger helpKey="tag" />,
  },
  {
    type: 'hidden',
    attribute: 'sort_by',
    value: 'local_name',
  },
  {
    type: 'hidden',
    attribute: 'sort_direction',
    value: 'DESC',
  },
  {
    type: 'hidden',
    attribute: 'page_number',
    value: 0,
  },
  {
    type: 'select',
    label: 'Records per page',
    attribute: 'item_count',
    value: 100,
    asButton: true,
    options: {
      10: 'View 10',
      25: 'View 25',
      50: 'View 50',
      100: 'View 100',
    },
  },

];

export const filtersAgentless = filters.filter( filter =>  filter.attribute !== 'version' );

export const scanningModeOptionsMap = {
  agent: {
    baseParams: {
      rownums: [ 0, 100 ],
      // eslint-disable-next-line camelcase
      extra_columns: [
        'host_id',
        'local_name',
        'vendor',
        'ip_addresses',
        'last_credential',
        'last_successful_scan',
        'last_scan',
        'last_scan_processed_time',
        'version',
        'last_acs_update',
      ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'last_successful_scan', 'ASC' ],
        [ 'vendor', 'DESC' ],
        [ 'local_name', 'ASC' ],
        [ 'version', 'DESC' ],
      ],
    },
    sortableColumns: {
      // eslint-disable-next-line camelcase
      host_name: 'local_name',
      // eslint-disable-next-line camelcase
      last_scanned: 'last_successful_scan',
      // eslint-disable-next-line camelcase
      last_check_in: 'last_acs_update',
      // eslint-disable-next-line camelcase
      last_processed: 'last_scan_processed_time',
      version: 'version',
    },
  },
  agentless: {
    baseParams: {
      rownums: [ 0, 100 ],
      // eslint-disable-next-line camelcase
      extra_columns: [
        'local_name',
        'vendor',
        'ip_addresses',
        'last_credential',
        'last_successful_scan',
        'last_scan_processed_time',
        'last_scanned_address',
        'product_name',
        'scan_groups',
      ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'local_name', 'DESC' ],
      ],
    },
    sortableColumns: {
      name: 'local_name',
      // eslint-disable-next-line camelcase
      last_scanned: 'last_successful_scan',
      // eslint-disable-next-line camelcase
      last_processed: 'last_scan_processed_time',
    },
  },
};
