/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import './style.scss';
import TagItem from './Item';
import InlineSVG from '../../../shared/InlineSVG';

const AllTagsModal = ( { tags, show, setShow } ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( tags ) && show ) &&
        <React.Fragment>
          <div className="allAssetTagsMask" onClick={ () => setShow( false ) } />
          <div className="allAssetTagsModal">
            <h2>
              Tags ({ Object.values( tags ).length })
              <button
                onClick={ () => setShow( false ) }
                className="roundGlyphButton light"
              >
                <InlineSVG type="close" />
              </button>
            </h2>
            <ul>
              {
                Object.values( tags ).map( ( t, i ) => {
                  return <TagItem tag={t} key={i} />;
                } )
              }
            </ul>
          </div>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

const TagList = ( { tags, elementClass='', truncation=null, showEmptyState=false } ) => {

  const [ truncatedTags, setTruncatedTags ] = React.useState( null );
  const [ truncationLink, setTruncationLink ] = React.useState( null );
  const [ showAllTags, setShowAllTags ] = React.useState( false );

  React.useEffect( () => {
    if ( isNotEmpty( tags ) ) {
      if ( isNotEmpty( truncation ) ) {
        const truncated = {};
        Object.values( tags ).map( ( t, i ) => {
          if ( isNotEmpty( t ) && i < truncation ) {
            truncated[t.id] = t;
          }
        } );
        setTruncatedTags( truncated );
        if ( truncation < Object.values( tags ).length ) {
          setTruncationLink( <button
            className="showAllTagsButton"
            onClick={ () => setShowAllTags( true ) }
          >
            { `+ ${Object.values( tags ).length - truncation} more` }
          </button>,
          );
        }
      } else {
        setTruncatedTags( tags );
      }
    }
  }, [ tags, truncation ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( truncatedTags )
          ? <div className="assetTagsModalAndListWrapper">
            <ul className={ `assetTagListWrapper ${elementClass}` } >
              {
                Object.values( truncatedTags ).map( ( tag, index ) => {
                  return <TagItem tag={tag} key={index} />;
                } )
              }
            </ul>
            {
              isNotEmpty( truncationLink ) &&
          <React.Fragment>
            { truncationLink }
            <AllTagsModal show={showAllTags} setShow={setShowAllTags} tags={tags} />
          </React.Fragment>
            }
          </div>
          : <React.Fragment>
            {
              showEmptyState &&
              <ul className={ `assetTagListWrapper ${elementClass}` } >
                <li className="assetTagListItem emptyTag">Not Tagged</li>
              </ul>
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default TagList;