/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isEmpty, isNotEmpty } from '../Utilities';

import './Bar.scss';

const Bar = ( {
  data,
  onHover=null,
  onClick=null,
  maxOverride=null,
  hoveredSeriesIdentifier,
  setHoveredSeriesIdentifier,
  svgAspectRatio=null,
} ) => {

  const [ max, setMax ] = React.useState( 1 );
  const [ gap, setGap ] = React.useState( 0 );
  const [ barWidth, setBarWidth ] = React.useState( 0 );
  const [ containerHeight, setContainerHeight ] = React.useState( null );
  const containerWidth = 100;

  const minHeight = 1;

  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) ) {

      if ( isNotEmpty( svgAspectRatio ) ) {
        setContainerHeight( containerWidth / svgAspectRatio );
      } else {
        setContainerHeight( 50 );
      }

      let _max = 1;

      const _barCount = Object.values( data ).length;
      const _barPlusGap = containerWidth / _barCount;
      const _gap = _barCount >= 50 ? _barPlusGap * 0.15 : _barPlusGap * 0.1;

      const _barWidth = _barPlusGap - _gap;

      if ( maxOverride ) {
        _max = maxOverride;
      } else {
        Object.values( data ).map( ( barData ) => {
          if ( barData.value > _max ) {
            _max = barData.value;
          }
        } );
      }
      setBarWidth( _barWidth );
      setMax( _max );
      setGap( _gap );
    }
  }, [ data, svgAspectRatio ] );

  const xOffset = 0;
  const yOffset = 0;

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {

      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHover ) ) {
        onHover( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHover ) ) {
        onHover( null );
      }
    }
  };

  return (
    <div className="svgBarWrapper">
      {
        (
          isNotEmpty( data )
          && isNotEmpty( max )
          && isNotEmpty( containerHeight )
          && isFinite( containerHeight )
          && isNotEmpty( containerWidth )
        ) &&
        <svg
          viewBox={ `0 0 ${containerWidth} ${containerHeight}` }
          xmlns="http://www.w3.org/2000/svg"
          className="barChart"
          preserveAspectRatio="none"
          fill="none"
        >
          {
            Object.values( data ).map( ( barData, index ) => {
              const _max = max <= 0 ? 1 : max;

              let height = 0;

              const strokeWidth = 0.25;

              height = ( containerHeight - yOffset ) * ( barData.value / _max );

              if ( height < minHeight || isEmpty( height ) || isNaN( height ) ) {
                height = minHeight;
              }

              if ( height === containerHeight - yOffset ) {
                height = height - strokeWidth;
              }

              const y = ( ( containerHeight - yOffset ) - height );

              return <g
                key={index}
                // eslint-disable-next-line max-len
                className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } fullBarWrapper ${ isNotEmpty( onClick ) ? 'clickable' : '' }` }
                onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
              >
                {/* full bar vertical background */}
                <rect
                  id={
                    barData?.original?.id
                      ? `hoverIndicatorID_${barData?.original?.id}`
                      : `randomIndicator_${Math.random()}`
                  }
                  // eslint-disable-next-line max-len
                  className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barContainer`}
                  y="0"
                  x={ xOffset + ( ( barWidth + gap ) * index ) }
                  width={barWidth}
                  rx="0"
                  height={ containerHeight - yOffset }
                  fill={ globalColors['grey--background'] }
                  onMouseEnter={ () => handleMouseEnter( barData ) }
                  onMouseLeave={ handleMouseLeave }

                />
                {/* actual bar segment */}
                <rect
                  id={
                    barData?.original?.id
                      ? `barSegmentID_${barData?.original?.id}`
                      : `randomSegment_${Math.random()}`
                  }
                  // eslint-disable-next-line max-len
                  className={ `${ barData.outline ? 'outline' : '' } ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barSegment`}
                  y={ y === 0 ? strokeWidth : y - ( strokeWidth / 2 ) }
                  x={ xOffset + ( ( barWidth + gap ) * index ) + ( strokeWidth / 2 ) }
                  width={ barWidth === 0 ? 0 : barWidth - ( strokeWidth ) }
                  height={ height }
                  fill={ barData.fill }
                  stroke={ barData.stroke }
                  strokeWidth={ strokeWidth }
                  onMouseEnter={ () => handleMouseEnter( barData ) }
                  onMouseLeave={ handleMouseLeave }
                />
              </g>;
            } )
          }
        </svg>
      }
    </div>
  );
};

export default Bar;