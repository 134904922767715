/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import StreamDownload from '../../../shared/StreamDownload';
import InlineSVG from '../../../shared/InlineSVG';
import { capitalize, formatUnixTime, isNotEmpty } from '../../../shared/Utilities';

const DOES_NOT_EXPIRE = 30_000_000_000;

const downloadReportName = report => {

  let timestamp = new Date();
  if ( isNotEmpty( report.last_finished ) ) {
    timestamp = new Date( report.last_finished * 1000 );
  }

  const date = formatUnixTime( timestamp / 1000, false, null, null, '-' );

  if ( isNotEmpty( report ) ) {
    if ( isNotEmpty( report.label ) ) {
      const _split = report.label.split( '---' );

      return `${_split[0]}---${date}`;
    }
    return `${capitalize( report.type )}_Report---${date}`;
  }
};

const ReportStatus = ( { report } ) => {
  return (
    <React.Fragment>
      {
        report.state === 'new' &&
        <InlineSVG type="newItem" version="primary" elementClass="new" />
      }
      {
        report.state === 'finished' &&
        <InlineSVG type="checkmarkCircle" version="status--green" elementClass="complete" />
      }
      {
        report.state === 'failed' &&
        <InlineSVG type="warning" version="status--red" elementClass="failed" />
      }
      {
        report.state === 'running' &&
        <InlineSVG type="cycle" version="status--blue" elementClass="running" />
      }
    </React.Fragment>
  );
};

const RunDownloadButton = ( { report, updateAndRunReport } ) => {

  const [ text, setText ] = React.useState( 'Run' );
  // eslint-disable-next-line max-len
  const type = report.format === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  React.useEffect( () => {
    if ( isNotEmpty( report ) ) {
      if ( report.state === 'finished' ) {
        setText( 'Download' );
      }
      if ( report.state === 'running' ) {
        setText( 'Running...' );
      }
    }
  }, [ report ] );

  const handleButtonAction = report => {
    if ( report.state === 'running' || report.state === 'failed' ) {
      return false;
    }

    // need to update the report name with a timestamp and then run it
    if ( report.state === 'new' ) {
      updateAndRunReport( report );
    }
  };

  return (
    <React.Fragment>
      {
        report.state === 'finished'
          ? <StreamDownload
            url={`/model/base/exported_report/${report.id}`}
            type={type}
            filename={`${downloadReportName( report )}.${report.format}`}
          />
          : <React.Fragment>
            {
              report.state !== 'failed' &&
              <button
                className={`taskRunnerButton ${report.state}`}
                onClick={ () => handleButtonAction( report ) }
              >
                {
                  report.state === 'running' &&
                  <React.Fragment>
                    <InlineSVG type="cycle" elementClass="running" />
                  </React.Fragment>
                }
                {
                  report.state === 'new' &&
                  <React.Fragment>
                    <InlineSVG type="play" elementClass="start" />
                  </React.Fragment>
                }
                { text }
              </button>
            }
          </React.Fragment>
      }
    </React.Fragment>
  );
};

const ExportItem = ( {
  report,
  focusedReport,
  removeReportFocus,
  displayReportName,
  allUsers,
  displaySchedule,
  displayUsers,
  deleteReport,
  reRunReport,
  updateAndRunReport,
  refreshList,
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( report ) &&
        <li
          // eslint-disable-next-line max-len
          className={ `${isNotEmpty( focusedReport ) && focusedReport.id === report.id ? 'focus' : '' } setupRecordItem export_item alternateLayout    ` }
          onClick={ removeReportFocus }
        >

          <section className="mainDetailsSection">
            <div className="column">
              <h2>
                <ReportStatus report={report} />
                { displayReportName( report ) }

              </h2>
              <div className="reportDetails">
                <span>
                  <InlineSVG
                    type={ report.format }
                    elementClass="reportTypeImage"
                    size="xlarge"
                    version="special"
                  />
                  { `${report.format} report` }
                </span>
                <span>
                  <InlineSVG type="groupsAlt" />
                  { allUsers[report.owner] }
                </span>
              </div>
              <span>
                <strong>Last Run: </strong>
                { report.last_finished > 0 ? formatUnixTime( report.last_finished ) : 'N/A' }
              </span>
              <span>
                <strong>Expires: </strong>
                { report.expiration === DOES_NOT_EXPIRE ? 'N/A' : formatUnixTime( report.expiration ) }
              </span>
            </div>
          </section>
          <section className="doubleSection">
            <strong className="sectionLabel">
              Schedule
            </strong>
            <span>{ displaySchedule( report ) }</span>
            <strong className="sectionLabel">
              Recipients
            </strong>
            <span>{ displayUsers( report ) }</span>
          </section>
          <div className="itemActions">
            <a
              className="recordItemAction"
              href={ report.reportEditURL }
              title={'Edit Report Configuration'}
            >
              <InlineSVG type="edit" />
            </a>
            <button
              className="recordItemAction"
              onClick={ () => deleteReport( report ) }
              title={'Delete Report Configuration'}
            >
              <InlineSVG type="delete" />
            </button>
            {
              ( report.state === 'finished' || report.state === 'failed' ) &&
              <button
                onClick={ () => reRunReport( report ) }
                className="taskRunnerButton rerun"
              >
                <InlineSVG type="refresh" version="primary" elementClass="rerun" />
                <InlineSVG type="cycle" version="primary" elementClass="running" />
                Re-run
              </button>
            }
            <RunDownloadButton
              report={ report }
              refreshList={refreshList}
              updateAndRunReport={updateAndRunReport}
            />
          </div>
        </li>
      }
    </React.Fragment>
  );
};

export default ExportItem;