/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import {
  isNotEmpty,
} from '../../../../Utilities';

import './style.scss';
import Summary from './Summary';
import RecipientModal from './RecipientModal';
import { makeRequest } from '../../../../../../legacy/io';

const Recipients = ( {
  field,
  originalValue,
  onChange,
  existingRecord,
} ) => {

  const [ validUsers, setValidUsers ] = React.useState( null );
  const [ hasFetchedUsers, setHasFetchedUsers ] = React.useState( false );

  const [ loadingUsers, setLoadingUsers ] = React.useState( false );

  const [ availableRecipients, setAvailableRecipients ] = React.useState( null );
  const [ selectedRecipients, setSelectedRecipients ] = React.useState( null );

  // gets available recipients and existing selections with all the data needed
  React.useEffect( () => {
    if ( !hasFetchedUsers ) {
      setLoadingUsers( true );
      const _validUsers = {};

      makeRequest( 'SEARCH', '/user', {
        // eslint-disable-next-line camelcase
        extra_columns: [ 'username', 'given_name', 'family_name', 'email_address' ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'username', 'ASC' ] ],
      } ).then( response => {
        setHasFetchedUsers( true );
        setLoadingUsers( false );
        if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
          response.results.map( user => {
            if ( isNotEmpty( user.email_address ) ) {
              _validUsers[user.id] = user;
            }
          } );
          setValidUsers( _validUsers );
        }
      } );
    }

  }, [ field, originalValue ] );

  // once all the valid users have been grabbed,
  // mark which ones have already been selected with an existing record
  React.useEffect( () => {
    const _available = {};
    const _selected = {};
    if ( isNotEmpty( existingRecord ) && isNotEmpty( validUsers ) ) {
      Object.entries( validUsers ).map( ( [ id, user ] ) => {
        if ( existingRecord.email_recipients && existingRecord.email_recipients.includes( id ) ) {
          _selected[id] = user;
        } else {
          _available[id] = user;
        }
      } );
    } else if ( isNotEmpty( validUsers ) ) {
      Object.entries( validUsers ).map( ( [ id, user ] ) => {
        _available[id] = user;
      } );
    }
    setAvailableRecipients( _available );
    setSelectedRecipients( _selected );
  }, [ existingRecord, validUsers ] );

  // any time the selected recipients changes, fire the onchange
  React.useEffect( () => {
    let _value = [];
    if ( isNotEmpty( selectedRecipients ) ) {
      _value = Object.keys( selectedRecipients );
    }
    onChange( field, _value );
  }, [ selectedRecipients ] );

  return (
    <React.Fragment>
      <Summary
        loadingUsers={loadingUsers}
        selectedRecipients={selectedRecipients}
        hasFetchedUsers={hasFetchedUsers}
        validUsers={validUsers}
      />
      <RecipientModal
        loadingUsers={loadingUsers}
        selectedRecipients={selectedRecipients}
        setSelectedRecipients={setSelectedRecipients}
        availableRecipients={availableRecipients}
        setAvailableRecipients={setAvailableRecipients}
        hasFetchedUsers={hasFetchedUsers}
        validUsers={validUsers}
      />
    </React.Fragment>
  );
};

export default Recipients;