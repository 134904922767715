/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

import {
  removeFromURLHash,
  isNotEmpty,
  isEmpty,
  decodeURLHash,
} from '../Utilities';
import InlineSVG from '../InlineSVG';

export const getModalPosition = ( clickEvent, modalWidth ) => {

  if ( isNotEmpty( clickEvent ) && isNotEmpty( modalWidth ) ) {
    const spacer = 16;
    const { clientX, clientY } = clickEvent;
    const { innerWidth, innerHeight } = window;

    let left, top;

    // first try to place the modal just to the right of click
    if ( ( clientX + spacer + modalWidth ) < innerWidth ) {
      left = clientX + spacer;
    // if that does not fit, see if it will fit to the left
    } else if ( ( clientX - spacer - modalWidth ) > 0 ) {
      left = clientX - modalWidth - spacer;
    // if that doesn't work, just place it on the left edge + the space and it will do what it does
    } else {
      left = spacer;
    }


    // first try to place the modal just above the click
    if ( clientY - ( spacer * 4 ) + ( innerHeight * 0.8 ) < innerHeight ) {
      top = clientY - ( spacer * 4 );
    // if that does not fit, put it the normal position from the top of the screen
    } else {
      top = innerHeight * 0.1;
    }
    return ( { left, top } );
  }
  return ( {} );
};

const Modal = ( {
  visible,
  setVisible,
  header,
  body,
  action,
  size='medium',
  elementClass='',
  elementID='',
  modalStyle={ },
  needsActions=true,
  shouldClearSelection=true,
  headerIconKey='default',
} ) => {

  const availableIconKeys = [

  ];

  const [ iconKey, setIconKey ] = React.useState( 'default' );

  const modalRoot = document.getElementById( 'reactModalPortal' );

  React.useEffect( ( ) => {
    document.addEventListener( 'keydown', escFunction );

    return () => {
      document.removeEventListener( 'keydown', escFunction );
    };
  }, [  ] );

  const escFunction = React.useCallback( ( e ) => {
    // esc key
    if ( e.keyCode === 27 ) {
      closeModal();
    }
  }, [] );

  const closeModal = () => {
    setVisible( false );
    if ( shouldClearSelection ) {
      // for setup pages, will do nothing if not present
      removeFromURLHash( 'selected_record' );
    }
  };

  React.useEffect( () => {
    if ( isEmpty( headerIconKey ) || !availableIconKeys.includes( headerIconKey )  ) {
      const hash = decodeURLHash();

      const leftNavSection = hash['.'];

      if ( isNotEmpty( leftNavSection ) ) {
        setIconKey( leftNavSection );
      } else {
        setIconKey( 'default' );
      }
    } else if ( headerIconKey === 'default' ) {
      setIconKey( 'default' );
    } else {
      setIconKey( headerIconKey );
    }
  }, [ headerIconKey ] );

  // The portal allows this container to live at the root of the body and helps
  // avoid any z-index issues
  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        onClick={ closeModal }
        // eslint-disable-next-line max-len
        className={ `modalShade ${visible ? 'visible' : ''} ${ isNotEmpty( elementClass ) ? `${elementClass}--shade` : '' }` }
      ></div>
      <div
        // eslint-disable-next-line max-len
        className={`modalContainer ${visible ? 'visible' : ''} ${size} ${elementClass} ${ isEmpty( header ) ? 'noHeader' : '' }`}
        id={elementID}
        style={ modalStyle }
      >
        {
          isEmpty( header )
            ? <button
              onClick={ closeModal }
              className="closeButton noHeader"
            >
              {
                elementClass === 'riskOverTimePointModal'
                  ? <InlineSVG type="close" version="light" />
                  : <InlineSVG type="close" />
              }

            </button>
            : <div className={ `modalHeader ${ isEmpty( header ) ? 'noHeader' : '' }` }>
              {
                isNotEmpty( header ) &&
                <h2>
                  <div className="modalHeaderIconWrapper">
                    <InlineSVG elementClass="modalHeaderIcon" type={ `modalIcon_${iconKey}` } />
                  </div>
                  { header }
                </h2>
              }
              <button
                onClick={ closeModal }
                className="closeButton"
              >

                {
                  elementClass === 'riskOverTimePointModal'
                    ? <InlineSVG type="close" version="light" />
                    : <InlineSVG type="close" />
                }

              </button>
            </div>
        }

        <div className="modalBody" id={ `${elementID}_body` }>
          { body }
        </div>
        {
          needsActions &&
          <div className="modalActions">
            { action }
            <button
              onClick={ closeModal }
              className={` ${isNotEmpty( action ) ? '' : 'asPrimary' } cancelButton`}
            >
              {
                isNotEmpty( action )
                  ? <span>Cancel</span>
                  : <span>Okay</span>
              }
            </button>
          </div>
        }

      </div>
    </React.Fragment>,
    modalRoot,
  );
};

export default Modal;
