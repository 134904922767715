/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import SetupForm from '../../../../shared/SetupComponents/SetupForm';
import Form from '../../../../shared/Form';
import {
  formatTZOffset,
  getGlobalSettings,
  isNotEmpty,
  isTruthy,
  updateGlobalSettings,
} from '../../../../shared/Utilities';
import { getFieldValues } from '../../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

import './style.scss';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';
import { canConfigure } from '../../../App/AccessControl.js';
import { makeRequest } from '../../../../../legacy/io.js';

const Admin = ( ) => {

  let isMounted = true;

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ fields, setFields ] = React.useState( null );
  const [ record, setRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // this is set in the form onChange callback
  const [ updatedForm, setUpdatedForm ] = React.useState( null );

  // fields object that represents what the Form component will turn into inputs,
  // with all of the necessary validation and help requirements
  const EMPTY_FIELDS = {
    project: {
      fields: [
        {
          type: 'number',
          label: 'Maximum analysis job parallelism',
          attribute: 'analysis_max_workers',
          required: true,
          defaultValue: '',
          htmlProps: { min: 0, max: 256, step: 1 },
          help: <HelpTrigger helpKey="analysis_max_workers" />,
        },
        {
          type: 'number',
          label: 'The risk ratio at which lower-risk paths are discarded',
          attribute: 'analysis_path_tail_ratio',
          required: true,
          defaultValue: '',
          htmlProps: { min: 0, max: 1, step: 0.001 },
          help: <HelpTrigger helpKey="analysis_path_tail_ratio" />,
        },
        {
          type: 'number',
          label: 'Target number of paths for analysis',
          attribute: 'analysis_target_paths',
          required: true,
          defaultValue: '',
          htmlProps: { min: 1, max: 1000000, step: 1 },
          help: <HelpTrigger helpKey="analysis_target_paths" />,
        },
        {
          type: 'number',
          label: 'Target risk level',
          attribute: 'risk_target',
          required: true,
          defaultValue: '',
          htmlProps: { min: 0, step: 10 },
          help: <HelpTrigger helpKey="risk_target" />,
        },
      ],
    },
    global: {
      fields: [
        {
          type: 'duration',
          label: 'Admin session maximum duration',
          expectedUnit: 's',
          attribute: 'admin_session_active_timeout',
          required: true,
          defaultValue: '',
          htmlProps: { min: 60, step: 1, max: 60 * 60 * 24 * 7 },
          help: <HelpTrigger helpKey="admin_session_active_timeout" />,
        },
        {
          type: 'duration',
          label: 'Admin session inactive time-out',
          expectedUnit: 's',
          attribute: 'admin_session_inactive_timeout',
          required: true,
          defaultValue: '',
          htmlProps: { min: 60, step: 1, max: 60 * 60 * 24 * 7 },
          help: <HelpTrigger helpKey="admin_session_inactive_timeout" />,
        },
        {
          type: 'checkbox',
          label: 'Send rule feed telemetry',
          attribute: 'send_telemetry',
          defaultValue: '',
          help: <HelpTrigger helpKey="send_telemetry" />,
        },
        {
          type: 'time',
          label: 'Time of day for rule feed update',
          attribute: 'update_feed_time',
          defaultValue: '',
          required: true,
          help: <HelpTrigger helpKey="update_feed_time" />,
        },
        {
          type: 'checkbox',
          label: 'Include Speculative Hijacking?',
          attribute: 'speculative_hijacking',
          defaultValue: true,
          help: <HelpTrigger helpKey="speculative_hijacking" />,
        },
      ],
    },
  };

  // the init of the form, called on page load, once the global context has loaded
  React.useEffect( () => {
    onRefresh( );
    makeRequest( 'INDEX', '/global_setting', {} ).then( response => {
      if ( response && response.results ) {

        const tz = response.results.server_tz;
        const offset = response.results.server_tzoffset;

        const _fields = { ...EMPTY_FIELDS };

        const feedTimeField = _fields.global.fields.find( f => f.attribute === 'update_feed_time' );

        if ( isNotEmpty( feedTimeField ) ) {
          feedTimeField.label = <div>
            <span>{ feedTimeField.label }</span>
            <span>server time: <strong>{tz} (UTC{formatTZOffset( offset )})</strong></span>
          </div>;
        }

        setFields( _fields );
      }
    } );
  }, [] );

  React.useEffect( ( ) => {
    // important functionality that automatically
    // saves the form when navigating away from the page if the form is valid
    const parentEl = document.getElementById( 'pageContent' );
    parentEl.onleave = async () => {
      if ( isValid && isMounted && canConfigure( licenseInfo ) ) {
        onSave( false );
        return true;
      }
    };

    return () => {
      isMounted = false;
      if ( parentEl ) {
        parentEl.onleave = null;
      }
    };
  }, [ updatedForm ] );

  // refreshes the form whenever one of the following happens:
  // 1. initial page load
  // 2. manual revert button is clicked in the footer
  // 3. a settings is changed and saved
  const onRefresh = async( ) => {

    const _global = await getGlobalSettings( 'global' );
    const _project = await getGlobalSettings( 'project' );

    // we are combining the global settings and the project settings into one object for the form,
    // need to parse out and set all the correct attributes
    setRecord( {
      // project settings
      // eslint-disable-next-line camelcase
      analysis_max_workers: _project?.settings?.analysis_max_workers,
      // eslint-disable-next-line camelcase
      analysis_path_tail_ratio: _project?.settings?.analysis_path_tail_ratio,
      // eslint-disable-next-line camelcase
      analysis_target_paths: _project?.settings?.analysis_target_paths,
      // eslint-disable-next-line camelcase
      risk_target: _project?.settings?.risk_target,

      // global settings
      // eslint-disable-next-line camelcase
      admin_session_active_timeout: _global?.admin_session_active_timeout,
      // eslint-disable-next-line camelcase
      admin_session_inactive_timeout: _global?.admin_session_inactive_timeout,
      // the server gives some inconsistent responses for true or false, need to normalize
      // eslint-disable-next-line camelcase
      send_telemetry: isTruthy( _global?.send_telemetry ),
      // eslint-disable-next-line camelcase
      update_feed_time: _global?.update_feed_time,
      // eslint-disable-next-line camelcase
      speculative_hijacking: isTruthy( _global?.speculative_hijacking ),
    } );
  };

  // called when clicking save in the footer, or on page leave if the form is valid
  const onSave = async ( shouldRefresh=true ) => {
    // checks for validity and makes sure we have the latest values
    if ( isValid && isNotEmpty( updatedForm ) && isNotEmpty( updatedForm.fieldStates ) ) {
      const _values = getFieldValues( updatedForm.fieldStates, 'admin_settings' );

      // these attributes are nested in project.settings
      const isProjectSettings = [
        'analysis_max_workers',
        'analysis_path_tail_ratio',
        'analysis_target_paths',
        'risk_target',
      ];

      const _project = { settings: {} };
      const _global = { };

      // put the attrs and values into the correct spot
      Object.entries( _values ).map( ( [ attr, val ] ) => {
        if ( isProjectSettings.includes( attr ) ) {
          _project.settings[attr] = val;
        } else {
          _global[attr] = val;
        }
      } );

      const updatedGlobal = await updateGlobalSettings( 'global', _global );
      const updatedProject = await updateGlobalSettings( 'project', _project );

      // success
      if ( isNotEmpty( updatedGlobal.results ) && isNotEmpty( updatedProject.results ) ) {
        // only refresh if needed, ie, not when navigating away
        if (
          shouldRefresh
          && isMounted
        ) {
          onRefresh( );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );
        }
      // formatted error
      } else if ( isNotEmpty( updatedGlobal.errors ) || isNotEmpty( updatedProject.errors ) ) {
        const _errors = [];
        updatedGlobal?.errors.map( e => {
          _errors.push( e );
        } );
        updatedProject?.errors.map( e => {
          _errors.push( e );
        } );
        if ( shouldRefresh && isMounted ) {
          addFlashMessage( {
            type: 'success',
            body: 'Successfully updated settings.',
          } );

          _errors.map( ( e ) => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        }
      // likely 500
      } else if ( shouldRefresh && isMounted ) {
        addFlashMessage( {
          type: 'alert',
          body: 'There was an error saving settings, please check your connection and try again.',
        } );
      }
    }
  };

  return (
    <SetupForm elementClass="adminSettings">
      {
        ( isNotEmpty( fields ) && isNotEmpty( record ) ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          existingRecord={record}
          recordType={'general_settings'}
          setIsValid={setIsValid}
        />
      }
      <div className="formActionsContainer">
        <div className="formActions">
          <button
            className={ `revertButton ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
            onClick={onRefresh}
            disabled={!canConfigure( licenseInfo )}
          >
            Revert
          </button>
          <button
            disabled={!isValid | !canConfigure( licenseInfo )}
            className={!canConfigure( licenseInfo ) ? 'disabled' : ''}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      </div>
    </SetupForm>
  );
};

export default Admin;