/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './style.scss';

import {
  isEmpty,
  isNotEmpty,
} from '../../../../Utilities';

import SearchResults from '../SearchResults';
import { getRecords } from '../../../../RecordCache';

const InputBody = ( {
  items,
  value,
  formState,
  onChange,
  setItems,
  field,
} ) => {

  const fieldRef = React.useRef( null );

  const handleSelect = ( { id, local_name } ) => {
    // eslint-disable-next-line camelcase
    const _items = { [id]: local_name, ...items };

    onChange( field, Object.keys( _items ) );
    setItems( _items );
  };

  return (
    <SearchResults
      field={ { ...field, selectCallback: handleSelect, clearOnSelect: true } }
      value={value}
      alreadySelectedItems={ items }
      setItems={setItems}
      formState={formState}
      onChange={onChange}
      fieldRef={fieldRef}
      needsLabel={false}
    />
  );
};

const SearchList = ( {
  field,
  fields,
  originalValue,
  externalFormState,
  formState,
  onChange,
  existingRecord,
} ) => {

  const [ items, setItems ] = React.useState( {} );

  // sets the items, or things that have been selected already, used on init
  React.useEffect( () => {
    if ( isNotEmpty( field ) && isNotEmpty( originalValue ) && isNotEmpty( field.recordType ) ) {
      const params =  {
        // eslint-disable-next-line camelcase
        id_list: originalValue,
        // eslint-disable-next-line camelcase
        extra_columns: [
          'label',
        ],
      };
      getRecords(
        field.recordType === 'host' ? 'scope' : field.recordType,
        params,
      ).then( response => {
        if ( isNotEmpty( response ) ) {
          const _items = {};
          response.map( i => {
            _items[i.id] = i.label;
          } );
          setItems( _items );
        }
      } );
    } else {
      setItems( [] );
    }
  }, [ existingRecord, field, originalValue ] );

  // this gets called when the value is adjusted externally
  // and is currently used when removing an applied filter in the tag editor v3 interface
  React.useEffect( () => {
    if (
      isNotEmpty( externalFormState )
      && isNotEmpty( externalFormState.fieldStates )
    ) {
      const _externalField = externalFormState.fieldStates[field.attribute];

      if ( isNotEmpty( _externalField ) ) {
        const { updatedValue } = _externalField;

        if ( isEmpty( updatedValue ) ) {
          setItems( [] );
        } else {
          const params =  {
            // eslint-disable-next-line camelcase
            id_list: updatedValue,
            // eslint-disable-next-line camelcase
            extra_columns: [
              'label',
            ],
          };
          getRecords(
            field.recordType === 'host' ? 'scope' : field.recordType,
            params,
          ).then( response => {
            if ( isNotEmpty( response ) ) {
              const _items = {};
              response.map( i => {
                _items[i.id] = i.label;
              } );
              setItems( _items );
            }
          } );
        }
      }
    }
  }, [ externalFormState ] );

  return (
    <div
      className="searchListContainer asVertical"
    >
      <InputBody
        items={items}
        setItems={setItems}
        field={field}
        fields={fields}
        originalValue={originalValue}
        formState={formState}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchList;
