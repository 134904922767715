/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../../../InlineSVG';
import { isNotEmpty, itemIsString } from '../../../Utilities';

const TextArea = ( {
  field,
  fields,
  externalFormState,
  setExternalFormState=() => {},
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      if ( field.needsSplit ) {
        fieldRef.current.value = ( originalValue && originalValue.join( ',' ) ) || '';
      } else {
        fieldRef.current.value = originalValue || '';
      }
    }
  }, [ existingRecord, originalValue, fieldRef ] );

  // if the value is adjusted externally ( ie. the tag editor remove filter button click )
  // then adjust the value of this as well
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
      && isNotEmpty( externalFormState )
      && isNotEmpty( externalFormState.fieldStates )
    ) {
      const _externalField = externalFormState.fieldStates[field.attribute];

      if ( _externalField.modifiedExternally ) {

        if ( isNotEmpty( _externalField ) ) {
          if ( field.needsSplit ) {
            fieldRef.current.value = ( _externalField && _externalField.updatedValue?.join( ',' ) ) || '';
          } else {
            fieldRef.current.value = _externalField.updatedValue || '';
          }
        }
        _externalField.modifiedExternally = false;
        setExternalFormState( externalFormState );
      }
    }
  }, [ externalFormState ] );

  // need to split the string and turn it into an array if necessary
  const handleOnChange = ( field, value ) => {
    let _value = value;
    if ( field.needsSplit && isNotEmpty( value ) && itemIsString( value ) ) {
      _value = value.replace( /\n/g, ',' );
      if ( isNotEmpty( _value ) ) {
        _value = _value.split( /[ ,]+/ );
      }
      if ( isNotEmpty( _value ) ) {
        const _valueAsMap = [];
        _value.map( chunk => {
          if ( isNotEmpty( chunk ) ) {
            _valueAsMap.push( chunk );
          }
        } );
        if ( isNotEmpty( _valueAsMap ) ) {
          _value = _valueAsMap;
        } else {
          _value = [];
        }
      }
    }
    if ( _value === '\n' ) {
      _value = [];
    }

    onChange( field, _value );
  };

  return (
    <React.Fragment>
      <textarea
        ref={fieldRef}
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState.fieldStates[field.attribute]?.disabled}
        onChange={ e => handleOnChange( field, e.target.valueAsNumber || e.target.value ) }
        { ...field.htmlProps }
      />
      {
        ( field.removable ) &&
        <button
          disabled={field.disabled || formState.fieldStates[field.attribute]?.disabled}
          className="removeFieldButton"
          onClick={ () => field.onRemove( field, fields ) }
        >
          <InlineSVG type="close" />
        </button>
      }
    </React.Fragment>
  );
};

export default TextArea;
