/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import { getNodeIconDescription } from '../shared';
import { ADVERSARY_NODE } from '../../../Explore/Graph/shared';
import InlineSVG from '../../../../shared/InlineSVG';
import { getNodeIcon } from '../../shared';

const NodeHeader = ( { node, options, collapsed, setCollapsed, handleClose } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( node ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <InlineSVG type="draggable" elementClass="draggableIcon" />
              }
              <div className={ `recordIconWrapper ${ node.id === ADVERSARY_NODE.id ? 'red' : 'default' }` } >
                <svg
                  width={ 24 }
                  height={ 24 }
                  viewBox="0 0 32 32"
                  fill="none"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  // eslint-disable-next-line max-len
                  className={ `recordTypeHeaderIcon ${node.id === ADVERSARY_NODE.id ? 'adversaryNode' : '' }` }
                >
                  { getNodeIcon( node ) }
                </svg>
              </div>
              <span className="label">
                { node.label }
              </span>
            </h2>
            <span className="right">
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button
                  className="recordCardCloseButton roundGlyphButton light"
                  onClick={ handleClose }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              }
            </span>
          </div>
          <div className={ `recordCardHeaderRow__dividerBar ${ node.isSensitive ? 'red' : 'default' }` } />
          <div className="recordCardHeaderRow">
            <div className="left"></div>
            {
              ( isNotEmpty( options ) && options.isCollapsible ) &&
              <button
                onClick={ () => setCollapsed( !collapsed ) }
                className="panelCollapseButton roundGlyphButton light"
              >
                {
                  collapsed
                    ? <InlineSVG type="expand" />
                    : <InlineSVG type="collapse" />
                }
              </button>
            }
          </div>
          <p className="iconDescription">
            {
              getNodeIconDescription( node ).map( ( section, i ) => {
                return <React.Fragment key={i}>{ section }</React.Fragment>;
              } )
            }
          </p>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default NodeHeader;