/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { isNotEmpty } from '../Utilities';

const PageCreateButton = ( { children, elementClass='', placement='right' } ) => {

  const [ root, setRoot ] = React.useState( null );


  let timeout = null;

  const findRoot = placement => {
    let _root = document.getElementById( 'pageCreatebuttonRoot' );

    if ( placement === 'left' ) {
      _root = document.getElementById( 'pageCreatebuttonRootLeft' );
    }
    if ( isNotEmpty( _root ) ) {
      setRoot( _root );
    } else {
      timeout = setTimeout( () => {
        findRoot();
      }, 100 );
    }
  };

  React.useEffect( () => {
    findRoot( placement );

    return () => clearTimeout( timeout );
  }, [ placement ] );

  if ( isNotEmpty( root ) ) {
    return ReactDOM.createPortal(
      <div id="pageCreateButtonContainer" className={ `${elementClass} ${placement}` } >
        { children }
      </div>,
      root,
    );
  }
  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default PageCreateButton;