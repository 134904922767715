/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

export const vulnerabilityAttributes = [
  'description',
  'urls',
  'identifier',
  'effort',
];

export const escalationAttributes = [
  'user_interaction',
  'disruption',
  'exploit_development',
  'identification',
  'certainty',
  'completeness',
];

export const selectInputs = [
  'user_interaction',
  'disruption',
  'exploit_development',
  'identification',
  'certainty',
  'completeness',
  'effort',
];

export const ESCALATION_DEFAULTS = {
  // eslint-disable-next-line camelcase
  user_interaction: 'none',
  disruption: 'none',
  // eslint-disable-next-line camelcase
  exploit_development: 'none',
  identification: 'implied',
  certainty: 'certain',
  completeness: 'complete',
};

export const EMPTY_FIELDS = {
  vulnerability: {
    header: 'Vulnerability Fields',
    help: <HelpTrigger helpKey="vulnerability_fields" />,
    fields: [
      {
        type: 'textarea',
        attribute: 'description',
        label: 'Description',
        help: <HelpTrigger helpKey="vulnerability_description" />,
        defaultValue: '',
      },
      {
        type: 'textarea',
        attribute: 'urls',
        label: 'References',
        help: <HelpTrigger helpKey="vulnerability_references" />,
        defaultValue: '',
      },
    ],
  },
  exploitLeft: {
    header: 'Exploit Likelihood Metrics',
    help: <HelpTrigger helpKey="likelihood_metrics" />,
    fields: [
      {
        type: 'select',
        attribute: 'user_interaction',
        label: 'User Interaction',
        required: true,
        options: {
          none: 'None',
          minimal: 'Minimal',
          moderate: 'Moderate',
          delayed: 'Delayed',
          extensive: 'Extensive',
        },
        help: <HelpTrigger helpKey="user_interaction" />,
        defaultValue: 'none',
      },
      {
        type: 'select',
        attribute: 'disruption',
        label: 'Disruption',
        required: true,
        options: {
          none: 'None',
          minimal: 'Minimal',
          moderate: 'Moderate',
          extensive: 'Extensive',
        },
        help: <HelpTrigger helpKey="disruption" />,
        defaultValue: 'none',
      },
      {
        type: 'select',
        attribute: 'exploit_development',
        label: 'Exploit Development',
        required: true,
        options: {
          none: 'None',
          minimal: 'Minimal',
          easy: 'Easy',
          hard: 'Difficult',
          // eslint-disable-next-line camelcase
          easy_re: 'Reverse Engineering',
          // eslint-disable-next-line camelcase
          hard_re: 'Difficult Reverse Engineering',
          insider: 'Insider Knowledge Required',
        },
        help: <HelpTrigger helpKey="exploit_development" />,
        defaultValue: 'none',
      },
    ],
  },
  exploitRight: {
    fields: [
      {
        type: 'select',
        attribute: 'identification',
        label: 'Identification',
        required: true,
        options: {
          implied: 'Implied',
          scan: 'Automated Scanner',
          obvious: 'Manually Obvious',
          'guess-and-check': 'Guess & check',
          tricky: 'Manually Tricky',
          subtle: 'Manually Subtle',
          difficult: 'Manually Difficult',
        },
        help: <HelpTrigger helpKey="identification" />,
        defaultValue: 'implied',
      },
      {
        type: 'select',
        attribute: 'certainty',
        label: 'Certainty',
        required: true,
        options: {
          certain: 'Certain',
          likely: 'Likely Vulnerable',
          unknown: 'Unknown',
          possible: 'Possibly Vulnerable',
          unlikely: 'Unlikely Vulnerable',
        },
        help: <HelpTrigger helpKey="certainty" />,
        defaultValue: 'certain',
      },
      {
        type: 'select',
        attribute: 'completeness',
        label: 'Completeness',
        required: true,
        options: {
          complete: 'Complete Execution',
          write: 'Write Content',
          read: 'Read Content',
          // eslint-disable-next-line camelcase
          limited_write: 'Limited Write',
          // eslint-disable-next-line camelcase
          limited_read: 'Limited Read',
          synergy: 'Synergy Only',
        },
        help: <HelpTrigger helpKey="completeness" />,
        defaultValue: 'complete',
      },
    ],
  },
};