/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, riskToRating } from '../../../shared/Utilities';
import ScanningStatus from '../Host/ScanningStatus';
import VulnerabilityInstancesBreakdown from '../VulnerabilityInstancesBreakdown';
import HostDetails from '../Host/Details';
import PatchDetails from '../Patch/Details';
import VulnerabilityDetails from '../Vulnerability/Details';
import UserDetails from '../User/Details';
import Statistics from '../Statistics';
import CVSSBreakdown from '../CVSSBreakdown';
import { collapsibleSectionDataMap } from '../shared';
import CollapsibleSection from '../Sections/CollapsibleSection';
import ExploitStatusBreakdownSection from '../ExploitStatusBreakdownSection';
import SensitiveAssetItem from '../Node/SensitiveAssetItem';

const Body = ( {
  record,
  recordInstanceData,
  recordType,
  riskType,
  isCollapsed,
  // thirdPartyData,
  latestThirdParty,
  options,

  // path recordType vars
  pathSensitiveAsset,
  pathTopVulnerabilities,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isCollapsed() === false ) &&
        <div className={ `recordDetails__InformationPanel__Body ${recordType}` }>
          {
            recordType === 'host' &&
            <React.Fragment>
              {
                ( isNotEmpty( options ) && options.include_scanning_status ) &&
                <ScanningStatus record={record} recordType={recordType} latestThirdParty={latestThirdParty} />
              }
              {
                (
                  isNotEmpty( options )
                  && options.include_vulnerability_instances
                  && isNotEmpty( recordInstanceData )
                ) &&
                <VulnerabilityInstancesBreakdown
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                (
                  isNotEmpty( options )
                  && options.include_exploit_status_breakdown
                  && isNotEmpty( recordInstanceData )
                ) &&
                <ExploitStatusBreakdownSection
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_cvss_breakdown && isNotEmpty( recordInstanceData ) ) &&
                <CVSSBreakdown
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_details ) &&
                <React.Fragment>
                  <HostDetails record={record} recordType={recordType} />
                  {
                    // eslint-disable-next-line max-len
                    Object.entries( collapsibleSectionDataMap[recordType].information ).map( ( [ sectionKey, sectionInfo ], index ) => {
                      return <CollapsibleSection
                        key={index}
                        sectionKey={sectionKey}
                        sectionInfo={sectionInfo}
                        record={record}
                        recordType={recordType}
                        recordInstanceData={recordInstanceData}
                        // setSelectedSignature={setSelectedSignature}
                        // setSelectedNode={setSelectedNode}
                        // setPathEdge={setPathEdge}
                        // setModalContext={setModalContext}
                        // relatedPaths={relatedPaths}
                        riskType={riskType}
                        // setHoveredListItemID={ setHoveredListItemID }
                        // setHoveredListItemType={ setHoveredListItemType }
                        // setHoveredListItemRating={ setHoveredListItemRating }
                      />;
                    } )
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
          {
            recordType === 'patch' &&
            <React.Fragment>
              {
                (
                  isNotEmpty( options )
                  && options.include_vulnerability_instances
                  && isNotEmpty( recordInstanceData )
                ) &&
                <VulnerabilityInstancesBreakdown
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_details ) &&
                <React.Fragment>
                  <PatchDetails record={record} recordType={recordType} />
                  {
                    // eslint-disable-next-line max-len
                    Object.entries( collapsibleSectionDataMap[recordType].information ).map( ( [ sectionKey, sectionInfo ], index ) => {
                      return <CollapsibleSection
                        key={index}
                        sectionKey={sectionKey}
                        sectionInfo={sectionInfo}
                        record={record}
                        recordType={recordType}
                        recordInstanceData={recordInstanceData}
                        // setSelectedSignature={setSelectedSignature}
                        // setSelectedNode={setSelectedNode}
                        // setPathEdge={setPathEdge}
                        // setModalContext={setModalContext}
                        // relatedPaths={relatedPaths}
                        riskType={riskType}
                        // setHoveredListItemID={ setHoveredListItemID }
                        // setHoveredListItemType={ setHoveredListItemType }
                        // setHoveredListItemRating={ setHoveredListItemRating }
                      />;
                    } )
                  }
                </React.Fragment>
              }
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                (
                  isNotEmpty( options )
                  && options.include_exploit_status_breakdown
                  && isNotEmpty( recordInstanceData )
                ) &&
                <ExploitStatusBreakdownSection
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_cvss_breakdown && isNotEmpty( recordInstanceData ) ) &&
                <CVSSBreakdown
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
            </React.Fragment>
          }
          {
            recordType === 'vulnerability' &&
            <React.Fragment>
              {
                (
                  isNotEmpty( options )
                  && options.include_vulnerability_instances
                  && isNotEmpty( recordInstanceData )
                ) &&
                <VulnerabilityInstancesBreakdown
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
              {
                ( isNotEmpty( options ) && options.include_details ) &&
                <React.Fragment>
                  <VulnerabilityDetails record={record} recordType={recordType} />
                </React.Fragment>
              }
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
            </React.Fragment>
          }
          {
            recordType === 'user' &&
            <React.Fragment>
              {
                ( isNotEmpty( options ) && options.include_details ) &&
                <React.Fragment>
                  <UserDetails record={record} recordType={recordType} />
                </React.Fragment>
              }
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={record}
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                />
              }
            </React.Fragment>
          }
          {
            recordType === 'path' &&
            <React.Fragment>
              {
                isNotEmpty( pathSensitiveAsset ) &&
                <div className="recordDetails__InformationPanel__Body_Section">
                  <div className="bodySectionItem">
                    <label>
                      <span>Vulnerable Sensitive Asset</span>
                    </label>
                    <SensitiveAssetItem asset={pathSensitiveAsset} />
                  </div>
                </div>
              }
              {
                ( isNotEmpty( options ) && options.include_statistics ) &&
                <Statistics
                  record={ { ...record, pathTopVulnerabilities, pathSensitiveAsset } }
                  recordType={recordType}
                  recordInstanceData={recordInstanceData}
                  riskType={riskType}
                  riskClass={ riskToRating( record[riskType] ) }
                  includeRisk

                />
              }
            </React.Fragment>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Body;