/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';

const EdgeOptions = ( {
  edge,
  selectItem,
  editItem,
  deleteItem,
  optionCallback=() => {},
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( edge ) &&
        <React.Fragment>
          <li
            onClick={ () => selectItem( 'edge', edge, optionCallback ) }
          >
            Add segment fragment to graph
          </li>
          <li
            onClick={ () => editItem( 'edge', edge, optionCallback ) }
          >
            Edit segment
          </li>
          <li
            className="delete"
            onClick={ () => deleteItem( 'edge', edge, optionCallback ) }
          >
            Delete segment
          </li>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EdgeOptions;