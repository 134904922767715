/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Modal from '../../../shared/Modal';
import Notification from '../../../shared/Notification';

import {
  cleanup_filename,
} from '../../../../legacy/util';
import {
  isEmpty,
  isNotEmpty,
} from '../../../shared/Utilities';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';

const ModalAction = ( {
  scannerToRegister,
} ) => {

  const [ downloadOptions, setDownloadOptions ] = React.useState( {} );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  React.useEffect( () => {
    if ( isNotEmpty( scannerToRegister ) ) {
      makeRequest( 'COMPUTE', '/subordinate_scanner', { id: scannerToRegister.id } ).then( response => {
        if ( response && response.results ) {
          const data = response.results;
          const filename = `${cleanup_filename( scannerToRegister.label )}_${scannerToRegister.id}.dsreg`;
          const blob = new Blob( [ data ], {type: 'application/octet-stream'} );
          const href = window.URL.createObjectURL( blob );
          setDownloadOptions( {
            href,
            filename,
          } );
        } else {
          addFlashMessage( {
            // eslint-disable-next-line max-len
            body: 'Unexpected error occurred while trying to download subordinate scanner registration configuration',
            type: 'alert',
          } );
        }
      } );
    }
  }, [ scannerToRegister ] );

  return (
    <a
      className={ `${isEmpty( downloadOptions ) ? 'disabled' : ''}`}
      download={downloadOptions.filename}
      href={downloadOptions.href}
    >
      Download registration
    </a>
  );
};

const ModalBody = ( { scannerToRegister } ) => {
  return (
    <React.Fragment>
      <Notification options={{
        message: 'Protect the downloaded configuration file as you would a password or other credential.',
        type: 'warning',
      }}/>
      <p>
        The "{scannerToRegister.label}" subordinate scanner has been created but
        is not yet been registered with this DeepSurface console. Please download
        the registration configuration file, copy it to the DeepSurface virtual
        machine for your subordinate scanner, and complete the following steps.
      </p>
      <pre>
        sudo deepsurface-install
      </pre>
      <p>
        At the "Would you like to install the Deep Surface main console ..." prompt,
        choose "SUBORDINATE" You will be prompted to browse to the .dsreg file you
        just created (or paste the contents).
      </p>
      <p>
        NOTE: This registration configuration file can be used to register a single
        scanner one time only. If a previously registered subordinate scanner is
        replaced with a new one, delete the old scanner record and create a new one
        to obtain a new registration configuration. If you have difficulty registering
        the scanner due to network connectivity constraints, run the following command to
        see additional options:
      </p>
      <pre>
        deepsurface-register-as-subordinate -h
      </pre>

    </React.Fragment>
  );
};

const RegistrationModal = ( {
  showModal,
  setShowModal,
  scannerToRegister,
  setFlashOptions,
} ) => {

  return (
    <React.Fragment>
      <Modal
        elementClass="subordinateScannerRegistration"
        visible={showModal}
        setVisible={setShowModal}
        header="Subordinate Scanner Registration Instructions"
        body={<ModalBody scannerToRegister={scannerToRegister} setFlashOptions={setFlashOptions} />}
        action={
          <ModalAction scannerToRegister={scannerToRegister} setFlashOptions={setFlashOptions} />
        }
      />
    </React.Fragment>
  );
};

export default RegistrationModal;