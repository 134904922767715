/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getDimensionsAndOffset, isEmpty, isNotEmpty } from '../../../shared/Utilities';

import Trigger from './Trigger';
import Menu from './Menu';

import './style.scss';

const defaultCollapsedState = { host: true, patch: true, vulnerability: true };

const AddToPlanMenu = ( {
  items,
  itemsKey,
  callback=() => {},
  asMinimalButton,
  withCount=true,
  planOptions=[ 'remediation', 'acceptedRisk' ],
  buttonText='Add to plan',
  elementClass='',
  positionOverride=null,
} ) => {
  const [ show, setShow ] = React.useState( false );
  const [ totalCount, setTotalCount ] = React.useState( 0 );
  const [ collapsed, setCollapsed ] = React.useState( defaultCollapsedState );
  const [ menuPosition, setMenuPosition ] = React.useState( { left: 0, top: 0 } );
  const [ menuContext, setMenuContext ] = React.useState( null );

  const menuRef = React.useRef( null );
  const triggerRef = React.useRef( null );

  // when the modal opens, need to wait for it to render, and then position it so that it is on the screen
  React.useEffect( ( ) => {

    const spacer = 16;

    if ( isNotEmpty( positionOverride ) ) {
      setMenuPosition( positionOverride );
    }

    if (
      show
      && isEmpty( positionOverride )
      && isNotEmpty( menuRef )
      && isNotEmpty( menuRef.current )
      && isNotEmpty( triggerRef )
      && isNotEmpty( triggerRef.current )
      && isNotEmpty( getDimensionsAndOffset( menuRef.current ) )
      && isNotEmpty( getDimensionsAndOffset( menuRef.current ).height )
    ) {
      setTimeout( () => {
        const menuOffset = getDimensionsAndOffset( menuRef.current );
        const triggerOffset = getDimensionsAndOffset( triggerRef.current );

        if ( isNotEmpty( menuOffset ) && isNotEmpty( triggerOffset ) ) {

          const bottomCheck = triggerOffset.top + triggerOffset.height + spacer + menuOffset.height;
          const rightCheck = triggerOffset.left + triggerOffset.width;

          let top = triggerOffset.top + spacer + triggerOffset.height;
          // eslint-disable-next-line
          let left = triggerOffset.left;

          // too low
          if ( bottomCheck > window.innerHeight ) {
            // first try to put it above
            top = triggerOffset.top - spacer - menuOffset.height;
            // if it is now too high... need to do something else
            if ( top < 0 ) {
              // eslint-disable-next-line
              top = window.innerHeight - spacer - menuOffset.height;
              left = triggerOffset.left + triggerOffset.width + spacer;
            }
          }
          // too far right
          if ( rightCheck > window.innerHeight ) {
            left = triggerOffset.left + triggerOffset.width - menuOffset.width;
          }

          setMenuPosition( { top, left } );
        }
      }, menuContext === 'acceptedRisk' ? 50 : 10 );

    }
  }, [ show, menuRef, collapsed, triggerRef, menuContext, positionOverride ] );

  const content = () => {
    return (
      <React.Fragment>
        <Trigger
          asMinimalButton={asMinimalButton}
          withCount={withCount}
          items={items}
          triggerRef={triggerRef}
          show={show}
          setShow={setShow}
          totalCount={totalCount}
          buttonText={buttonText}
          elementClass={elementClass}
        />
        {
          show &&
          <Menu
            menuRef={menuRef}
            show={show}
            setShow={setShow}
            menuPosition={menuPosition}
            setMenuPosition={setMenuPosition}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            menuContext={menuContext}
            setMenuContext={setMenuContext}
            items={items}
            itemsKey={itemsKey}
            callback={callback}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
            planOptions={planOptions}
          />
        }
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {
        elementClass === 'leftNavItem'
          ? <li
            // eslint-disable-next-line max-len
            className={ `${elementClass} ${asMinimalButton ? 'asMinimalButton' : '' } addToPlanMenuWrapper ${( isNotEmpty( items ) && withCount ) ? 'asButtonWithCount' : ''}` }
          >
            { content() }
          </li>
          : <div
            // eslint-disable-next-line max-len
            className={ `${elementClass} ${asMinimalButton ? 'asMinimalButton' : '' } addToPlanMenuWrapper ${( isNotEmpty( items ) && withCount ) ? 'asButtonWithCount' : ''}` }
          >
            { content() }
          </div>
      }

    </React.Fragment>
  );
};

export default AddToPlanMenu;