/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, getDimensionsAndOffset, isEmpty, isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import { ADVERSARY_NODE } from '../../../Explore/Graph/shared';

import './NodeContent.scss';

const NodeContent = ( { node, setStyle, containerRef, options, context } ) => {

  const [ alertListItems, setAlertListItems ] = React.useState( [] );

  React.useEffect( () => {
    if ( isNotEmpty( node ) ) {

      let _node = node;

      if ( isNotEmpty( node.original ) ) {
        _node = node.original;
      }

      const _alertListItems = [];

      if (
        isNotEmpty( _node.extra )
        && isNotEmpty( _node.extra['user_flags'] )
        && isNotEmpty( _node.extra['user_flags']['DONT_EXPIRE_PASSWD'] )
      ) {
        _alertListItems.push( <span>This user account's password does not expire.</span> );
      }

      if ( isNotEmpty( _node.flags ) ) {
        if ( _node.flags['recently_active'] ) {
          _alertListItems.push(
            <span className="nodeInformationPiece">
              This user account has recently logged in to this system.
            </span>,
          );
        }
        if ( _node.flags['has_rdp_access'] ) {
          _alertListItems.push(
            <span className="nodeInformationPiece">
              This user account has Remote Desktop (RDP) access to this system.
            </span>,
          );
        }
        if ( _node.flags['rdp_user'] ) {
          _alertListItems.push(
            <span className="nodeInformationPiece">
              This user has recently used an RDP client to connect to another host.
            </span>,
          );
        }
        if ( _node.flags['outlook_user'] ) {
          _alertListItems.push(
            <span className="nodeInformationPiece">
              {/* eslint-disable-next-line max-len */}
              This user has recently used the Outlook desktop mail client and may be susceptible to related vulnerabilities.
            </span>,
          );
        }
        if ( _node.flags['windowsmail_user'] ) {
          _alertListItems.push(
            <span className="nodeInformationPiece">
              {/* eslint-disable-next-line max-len */}
              This user has recently used the Microsoft Windows Mail desktop mail client and may be susceptible to related vulnerabilities.
            </span>,
          );
        }

        if ( _node.flags['browser_user'] ) {
          const bMap = {
            // eslint-disable-next-line camelcase
            ie_user: 'Internet Explorer',
            // eslint-disable-next-line camelcase
            edge_user: 'Edge',
            // eslint-disable-next-line camelcase
            chrome_user: 'Chrome',
            // eslint-disable-next-line camelcase
            firefox_user: 'Firefox',
            // eslint-disable-next-line camelcase
            safari_user: 'Safari',
          };
          const browsers = [];

          for ( const bflag in bMap ) {
            if ( _node.flags[bflag] ) {
              browsers.push( bMap[bflag] );
            }
          }
          _alertListItems.push(
            <span className="nodeInformationPiece">
              {/* eslint-disable-next-line max-len */}
              This user account has recently used the following browsers on this system, which could make them susceptible to any related vulnerabilities: {browsers.join( ', ' )}
            </span>,
          );
        }
      }

      if ( isNotEmpty( _alertListItems ) ) {
        setAlertListItems( _alertListItems );
      } else {
        setAlertListItems( [] );
      }
    }
  }, [ node ] );

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( node )
      && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( node.clickEvent ) ) {
        _clickEvent = node.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 32 * 16;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ node, containerRef, options, context ] );

  return (
    <React.Fragment>
      {
        ( ( isNotEmpty( node.combined_impact ) && node.combined_impact > 0 ) || node.isSensitive ) &&
        <p className="details">
          {/* eslint-disable-next-line max-len */}
          <span>Impact: </span><strong className="impact">{ formatNumber( node.combined_impact || node.original?.impact ) }</strong>
        </p>
      }
      {
        isNotEmpty( node.details ) &&
        <p className="details">{ node.details }</p>
      }
      {
        isNotEmpty( node.original?.details ) &&
        <p className="details">{ node.original.details }</p>
      }
      {
        ( node.id === ADVERSARY_NODE.id && isEmpty( node.details ) ) &&
        <p className="details">
          Represents an external attacker attempting to gain access to sensitive assets.
        </p>
      }
      {
        isNotEmpty( alertListItems ) &&
        <ul>
          {
            alertListItems.map( ( item, index ) => {
              return <li key={index}>
                <InlineSVG type="notificationAlert" />
                { item }
              </li>;
            } )
          }
        </ul>
      }
    </React.Fragment>
  );
};

export default NodeContent;