/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';

import './style.scss';
import Header from './Header';
import Body from './Body';

const InformationPanel = ( {
  record,
  recordType,
  riskType,
  isCollapsed,
  renderContext,
  recordInstanceData,
  thirdPartyData,
  currentScannerSignature,
  options,
  selectRecord,
  toggleCollapse,
  latestThirdParty,

  // path recordType vars
  pathSensitiveAsset,
  pathTopVulnerabilities,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) ) &&
        <div
          className={ `recordDetailsInformationPanel ${recordType} ${isCollapsed() ? 'isCollapsed' : ''}`}
        >
          <Header
            record={record}
            recordType={recordType}
            riskType={riskType}
            isCollapsed={isCollapsed}
            renderContext={renderContext}
            options={options}
            selectRecord={selectRecord}
            toggleCollapse={toggleCollapse}
            recordInstanceData={recordInstanceData}
          />
          {
            isCollapsed() === false &&
            <Body
              record={record}
              recordType={recordType}
              riskType={riskType}
              isCollapsed={isCollapsed}
              renderContext={renderContext}
              options={options}
              recordInstanceData={recordInstanceData}
              thirdPartyData={thirdPartyData}
              latestThirdParty={latestThirdParty}
              currentScannerSignature={currentScannerSignature}

              // path recordType vars
              pathSensitiveAsset={pathSensitiveAsset}
              pathTopVulnerabilities={pathTopVulnerabilities}
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default InformationPanel;