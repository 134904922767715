/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { isNotEmpty } from '../shared/Utilities';

export const BreakpointContext = React.createContext();

export const BreakpointProvider = ( { children } ) => {

  const [ breakpointClass, setBreakpointClass ] = React.useState( {} );

  const breakpointMap = {
    xsmall: 320, // anything below will get hardcoded to 'xxsmall`
    small: 480,
    medium: 768,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1800, // greater than 1800 width
  };

  // used to help throttle the change
  let windowWidth = window.innerWidth;

  const updateClass = ( onPageLoad=false ) => {
    const adjustmentThreshold = 10;
    const currentWidth = window.innerWidth;
    if ( ( Math.abs( currentWidth - windowWidth ) > adjustmentThreshold ) || onPageLoad ) {

      // starting at the small, loop through the possible breakpoints and set the correct class accordingly
      for ( let index = 0; index < Object.values( breakpointMap ).length; index++ ) {
        const current = Object.values( breakpointMap )[index];
        const currentClass = Object.keys( breakpointMap )[index];

        const next = Object.values( breakpointMap )[index + 1];

        // we are larger than the minimum
        if ( currentWidth > current ) {
          // we are not at the last bp
          if ( isNotEmpty( next ) ) {
            // we are lower than the next bp, set the currentClass and exit
            if ( currentWidth < next ) {
              setBreakpointClass( currentClass );
              break;
            }
            continue;
          // we are at the last bp, set xlarge and exit
          } else {
            setBreakpointClass( currentClass );
            break;
          }
        // we are below the first
        } else {
          setBreakpointClass( 'xxsmall' );
          break;
        }
      }

      windowWidth = currentWidth;
    }
  };

  // registers the onresize listener for setting the breakpoint class
  React.useEffect( () => {
    updateClass( true );
    window.addEventListener( 'resize', updateClass );
    return () => window.removeEventListener( 'resize', updateClass );
  }, [] );

  const data = [
    breakpointClass,
  ];

  return (
    <BreakpointContext.Provider value={ data }>
      { children }
    </BreakpointContext.Provider>
  );
};
