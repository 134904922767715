/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupPage from '../../../../shared/SetupComponents/SetupPage';

import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

import {
  isValidPort, isWindowsDomain,
} from '../../../../shared/Form/Validators';

import {
  recordData,
} from './data';
import { isNotEmpty } from '../../../../shared/Utilities';
import { getFieldValues } from '../../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';

const AuthenticationProviders = () => {

  const SSL_OPTIONS = {
    ldaps: 'SSL/TLS',
    starttls: 'StartTLS',
  };

  const EMPTY_FIELDS = {
    selector: {
      fields: [
        {
          type: 'select',
          label: 'Provider Type',
          attribute: 'type',
          defaultValue: 'saml2',
          options: {
            'saml2': 'SAML 2.0',
            'ldap-ntlm': 'LDAP with NTLM',
          },
          disableOnEdit: true,
        },
      ],
    },

    'ldap-ntlm': {
      fields: [
        {
          includeIf: { attribute: 'type', value: 'ldap-ntlm'},
          type: 'text', label: 'Name',
          attribute: 'ldap-ntlm_label',
          required: true,
          help: <HelpTrigger helpKey="name" />,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'type', value: 'ldap-ntlm'},
          type: 'collection',
          label: 'Server',
          placeholder: 'Server',
          attribute: 'ldap-ntlm_servers',
          required: true,
          help: <HelpTrigger helpKey="servers" />,
          defaultValue: [],
        },
        {
          includeIf: { attribute: 'type', value: 'ldap-ntlm'},
          type: 'select',
          label: 'Encryption protocol',
          attribute: 'ldap-ntlm_protocol',
          required: true,
          options: SSL_OPTIONS,
          help: <HelpTrigger helpKey="encryption_protocol" />,
          defaultValue: 'ldaps',
        },
        {
          includeIf: [
            { attribute: 'type', value: 'ldap-ntlm'},
            { attribute: 'ldap-ntlm_protocol', value: 'ldaps' },
          ],
          type: 'number',
          label: 'Port number',
          attribute: 'ldap-ntlm_port-ldaps',
          required: true,
          htmlProps: { min: 1, step: 1, max: 65535 },
          validators: [ isValidPort ],
          help: <HelpTrigger helpKey="port_number" />,
          defaultValue: 636,
        },
        {
          includeIf: [
            { attribute: 'type', value: 'ldap-ntlm'},
            { attribute: 'ldap-ntlm_protocol', value: 'starttls' },
          ],
          type: 'number',
          label: 'Port number',
          attribute: 'ldap-ntlm_port-starttls',
          required: true,
          htmlProps: { min: 1, step: 1, max: 65535 },
          validators: [ isValidPort ],
          help: <HelpTrigger helpKey="port_number" />,
          defaultValue: 389,
        },
        {
          includeIf: { attribute: 'type', value: 'ldap-ntlm'},
          type: 'text',
          label: 'Windows Domain',
          attribute: 'ldap-ntlm_domain',
          help: <HelpTrigger helpKey="windows_domain" />,
          defaultValue: '',
          validators:[ isWindowsDomain ],
        },
        {
          includeIf: { attribute: 'type', value: 'ldap-ntlm'},
          type: 'checkbox',
          label: 'Trust on First Use (TOFU)',
          attribute: 'ldap-ntlm_tofu',
          help: <HelpTrigger helpKey="trust_on_first_use" />,
          defaultValue: true,
        },
      ],
    },

    'saml2': {
      fields: [
        {
          includeIf: { attribute: 'type', value: 'saml2'},
          type: 'text',
          label: 'Name',
          attribute: 'saml2_label',
          required: true,
          help: <HelpTrigger helpKey="name" />,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'type', value: 'saml2'},
          type: 'acsURL',
          label: 'Assertion Consumer Service URL',
          attribute: 'saml2_acs_host_port',
          required: true,
          help: <HelpTrigger helpKey="acs_url" />,
          providerID: '',
          defaultValue: `${window.location.hostname}${ isNotEmpty( window.location.port )
            ? `:${window.location.port}`
            : ''}`,
        },
        {
          includeIf: { attribute: 'type', value: 'saml2'},
          type: 'file',
          label: 'Metadata XML',
          attribute: 'saml2_metadata',
          needsPreview: true,
          help: <HelpTrigger helpKey="metadata_xml" />,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'type', value: 'saml2'},
          type: 'hidden',
          attribute: 'saml2_id',
          defaultValue: '',
        },
      ],
    },
  };

  // ContextualHelp getters and setters
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ providers, setProviders ] = React.useState( [] );
  const [ fields, setFields ] = React.useState( null );

  React.useEffect( () => {
    setFields( EMPTY_FIELDS );
  }, [] );

  const onRefresh = () => {
    makeRequest( 'SEARCH', '/project/default/authentication_provider', {
      'extra_columns':[ 'type', 'label', 'settings' ],
      'order_by': [ [ 'name', 'ASC' ] ],
    } ).then( response => {
      if ( response && response['results'] ) {
        setProviders( response['results'] );
      } else {
        setProviders( [] );
      }
    } );
  };

  const onSave = (
    provider,
    isValid,
    fieldStates,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const providerSettingsAttrs = [
        'domain',
        'port-starttls',
        'port-ldaps',
        'protocol',
        'servers',
        'tofu',
        'metadata',
        'acs_host_port',
      ];

      const selectedType = fieldStates.type.updatedValue;

      const includedValues = getFieldValues( fieldStates, 'authentication_provider' );

      const newProvider = {};

      Object.keys( includedValues ).map( attr => {

        let attribute = attr;

        if ( providerSettingsAttrs.includes( attr ) ) {
          if ( attr === 'port-starttls' || attr === 'port-ldaps' ) {
            attribute = 'port';
          }

          if ( newProvider.settings ) {
            newProvider.settings[attribute] = includedValues[attr];
          } else {
            newProvider.settings = { [attribute]: includedValues[attr]};
          }

        } else {
          newProvider[attribute] = includedValues[attr];
        }
      } );

      // add the type
      newProvider.type = selectedType;

      if ( isNotEmpty( provider ) ) {
        // we are updating, add the id
        newProvider.id = provider.id;
      } else if ( selectedType === 'saml2' ) {
        newProvider.id = includedValues.id;
      }
      // eslint-disable-next-line max-len
      makeRequest( 'UPSERT', '/project/default/authentication_provider', { 'records': [ newProvider ] } ).then( response => {
        onRefresh();
        if ( response['errors'] ) {
          addFlashMessage( {
            body: response['errors'],
            type: 'alert',
          } );
        }
      } );
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={providers}
          setRecords={setProviders}
          recordType="authentication_provider"
          recordData={recordData}
          useForm={true}
          fields={fields}
          alternateItemLayout
        />
      }
    </React.Fragment>

  );
};

export default AuthenticationProviders;
