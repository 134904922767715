/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';

const Details = ( { record } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <div className="recordDetails__InformationPanel__Body_Section details patch">
          <div className="bodySectionItem">
            <label>Description</label>
            { record.description || '--' }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Details;