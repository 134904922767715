/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

export const recordData = {
  display: {
    // label: 'Name',
    // type: 'Type',
  },
};
