
/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty, recordTypeDisplayName } from '../../../shared/Utilities';
import { makeRequest } from '../../../../legacy/io';
import EvidenceItem from './EvidenceItem';

import './VulnerabilityEvidence.scss';
import EmptyLoading from '../../../shared/EmptyLoading';

const VulnerabilityEvidence = ( { record, recordType, thirdPartySettings } ) => {

  const [ evidenceData, setEvidenceData ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( null );

  const typeForRecordType = recordType => {
    if ( recordType === 'host' ) {
      return 'host_vulnerability';
    }
    if ( recordType === 'vulnerability' ) {
      return 'vulnerability_host';
    }
    return null;
  };

  const setupData = async () => {
    setLoading( true );
    /* eslint-disable camelcase */
    const record_type = typeForRecordType( recordType );

    if ( isNotEmpty( record_type ) ) {
      const params = {
        order_by: [
          [ 'filtered_risk', 'DESC' ],
          [ 'id', 'ASC' ],
        ],
        record_id: record.id,
        record_type,
        rows: [ 0, 100 ],
      };

      const response = await makeRequest( 'POST', '/fe/insights/evidence', params );
      if ( isNotEmpty( response ) ) {
        setEvidenceData( response );
      }
    }
    setLoading( false );
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) ) {
      setupData();
    }
  }, [ record, recordType ] );

  const headerForEvidenceRecordAndType = ( evidenceData, record, recordType ) => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) ) {
      return <span>
        <strong>{ recordTypeDisplayName( record, recordType ) }: </strong>
        <span>Vulnerability Evidence</span>
        <div className="evidenceCount">
          { isNotEmpty( evidenceData ) ? `(${ formatNumber( evidenceData.length ) })` : '(0)'}
        </div>
      </span>;
    }
    return 'Vulnerability Evidence';
  };

  return (
    <React.Fragment>
      <div className="recordDetails__MainPanel_BodyRow evidenceSummary">
        <h3 className="mainPanel__SectionHeader">
          { headerForEvidenceRecordAndType( evidenceData, record, recordType ) }
        </h3>
      </div>
      <EmptyLoading
        payload={ evidenceData }
        loading={ loading }
        loadingMessage="Loading vulnerability evidence..."
        emptyMessage="No vulnerability evidence for this item"
      />
      {
        isNotEmpty( evidenceData )
          && <div className="evidenceList">
            {
              evidenceData.map( ( e, i ) => {
                return <EvidenceItem
                  evidence={e}
                  record={record}
                  recordType={recordType}
                  thirdPartySettings={thirdPartySettings}
                  key={i}
                />;
              } )
            }
          </div>
      }
    </React.Fragment>
  );
};

export default VulnerabilityEvidence;