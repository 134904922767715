/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
export const recordData = {
  display: {
    // type: 'Type',
    // purpose: 'Purpose',
    // identity: 'Identity',
    // created: 'Added On',
  },
  filters: [
    {
      type: 'debouncedText',
      attribute: 'identity',
      label: 'Identity',
    },
    {
      type: 'select',
      attribute: 'purpose',
      label: 'Purpose',
      options: {
        '*': 'all',
        scan: 'scanning',
        api: 'API',
        sso: 'SSO',
        notifications: 'Notifications',
        smtp: 'SMTP',
        pam: 'PAM',
        rules: 'Rules',
      },
    },
    {
      type: 'select',
      attribute: 'subpurpose',
      label: 'Sub Purpose',
      options: {
        '*' : 'all',
        'winrm' : 'WinRM',
        'rdp' : 'RDP',
        'tenable' : 'Tenable',
        'qualys' : 'Qualys',
        'rapid7' : 'Rapid7',
        'greenbone' : 'Greenbone',
        'ldap' : 'LDAP',
        'oauth': 'oauth',
        'saml' : 'SAML',
        'thycotic-secret-server': 'Delinea (Thycotic) Secret Server',
        'cyberark-vault': 'CyberArk Vault',
        // eslint-disable-next-line camelcase
        vuln_tally: 'Vulnerability Tally',
        // eslint-disable-next-line camelcase
        slv_tally: 'SLV Tally',
        // eslint-disable-next-line camelcase
        sync_license: 'Sync License',
        // eslint-disable-next-line camelcase
        download_feed: 'Download Feed',
      },
    },
  ],
};