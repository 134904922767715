/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
export const integrationIconMap = {
  jira: 'jiraLogo',
  email: 'mail',
  tanium: 'taniumLogo',
};
export const recordData = {
  display: {},
};
