import { makeRequest } from '../../legacy/io';
import { v4 as uuidv4 } from 'uuid';
import { getGlobalSettings, isNotEmpty } from './Utilities';

export const getGlobalRisk = async ( options ) => {

  const riskHistoryResponse = await (
    makeRequest(
      'FETCH',
      '/project/default/model/base/chart/risk_remediation_history',
      {
        width: window.innerWidth * 0.8,
        height: 100,
        days: options?.days || '60',
      },
    )
  );
  if ( isNotEmpty( riskHistoryResponse ) ) {
    let max = 0;
    if (
      isNotEmpty( riskHistoryResponse )
      && isNotEmpty( riskHistoryResponse.results ) && isNotEmpty( riskHistoryResponse.results.data_points )
    ) {
      const _data = { };
      riskHistoryResponse.results.data_points.map( ( point, i ) => {

        const id = uuidv4();

        let addedHosts = 0;
        let removedHosts = 0;
        if ( isNotEmpty( point.scan_events ) ) {
          point.scan_events.map( e => {
            if ( e.event === 'identified' ) {
              addedHosts += 1;
            }
            if ( e.event === 'expired' ) {
              removedHosts -= 1;
            }
          } );
        }

        if ( point.risk >= max ) {
          max = point.risk;
        }

        _data[id] = {
          id,
          originalIndex: i,
          totalPoints: riskHistoryResponse.results.data_points.length,
          risk: point.risk,
          timestamp: point.timestamp,
          addedEscalations: isNotEmpty( point.import_events )
            ? point.import_events.added_instances
            : 0,
          removedEscalations: isNotEmpty( point.import_events )
            ? 0 - point.import_events.removed_instances
            : 0,
          addedHosts,
          removedHosts,
          original: point,
        };
      } );
      return ( {
        original: riskHistoryResponse.results.data_points,
        transformed: _data,
        max,
        yAxis: 'risk',
        xAxis: 'timestamp',
      } );
    }
  }
};

export const getTargetRisk = async () => {
  const projectResponse = await getGlobalSettings( 'project' );

  if ( isNotEmpty( projectResponse ) && isNotEmpty( projectResponse.settings ) ) {
    window.localStorage.setItem( 'targetRisk', projectResponse.settings.risk_target );
    return projectResponse.settings.risk_target;
  }
  return {};
};