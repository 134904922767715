/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import InlineSVG from '../../InlineSVG';

import './TextSearch.scss';

import {
  encodeURLHash,
  isNotEmpty,
  triggerHashRefresh,
} from '../../Utilities';

const TextSearch = ( {
  input,
  value,
  handleChange,
} ) => {

  const inputRef = React.useRef( null );

  const handleKeyPress = e => {
    const _isFocused = document.activeElement
      && document.activeElement.id === `${input.attribute}_${input.type}`;
    if ( _isFocused && e.keyCode === 13 ) {
      encodeURLHash( { [input.attribute]: e.target.value } );
      triggerHashRefresh();
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      inputRef.current.value = value;
    }
    window.addEventListener( 'keyup', handleKeyPress );
    return () => {
      window.removeEventListener( 'keyup', handleKeyPress );
    };
  }, [ value, inputRef ] );

  const clearIfEmpty = e => {
    if ( e.target.value === '' ) {
      handleSearch();
    }
  };

  const handleSearch = () => {
    const _value = inputRef?.current?.value || '';
    handleChange( input.attribute, _value );
  };

  return (
    <label>
      <span className="labelWrapper">
        { input.label }
        {
          input.required &&
          <span className="required">*</span>
        }
        { input.helpItem }
      </span>
      <div className="searchInputWrapper">
        <input
          id={ `${input.attribute}_${input.type}` }
          placeholder={ input.placeholder ? input.placeholder : ''}
          disabled={input.disabled}
          type="text"
          ref={inputRef}
          onChange={ clearIfEmpty }
          { ...input.htmlProps }
        />
        <button onClick={ handleSearch } >
          <InlineSVG type="search" version="light" />
        </button>
      </div>
    </label>
  );
};

export default TextSearch;
