/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { isNotEmpty } from '../../../Utilities';

const Email = ( {
  field,
  formState,
  originalValue,
  onChange,
  setIsFocused,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  return (
    <div className={ 'emailFieldWrapper'}>
      <input
        onFocus={ () => setIsFocused( true ) }
        onBlur={ () => setIsFocused( false ) }
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
        type={field.type}
        onChange={ e => onChange( field, e.target.value ) }
        ref={fieldRef}
        { ...field.htmlProps }
      />
      <div className="editIcon" />
    </div>
  );
};

export default Email;
