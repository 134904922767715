/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import EmptyState from '../../../../shared/EmptyState';
import Loading from '../../../../shared/Loading';
import { isNotEmpty, riskToRating } from '../../../../shared/Utilities';
import Form from '../../../../shared/Form';
import { getFieldValues } from '../../../../shared/Form/Shared';
import { getRecords } from '../../../../shared/RecordCache.js';
import RiskPercentageBar from '../../../../shared/RiskPercentageBar/index.js';
import RiskReduction from '../../../../shared/RiskReduction/index.js';

const NewTab = ( {
  newVulnChoice,
  setNewVulnChoice,
  selectedResult,
  setSelectedResult,
  vulnNotIDs,
  vulnerabilityFields,
  setVulnerabilityFields,
  setUpdatedVulnerability,
  setVulnerabilityIsValid,
} ) => {

  const [ searchFields, setSearchFields ] = React.useState( null );
  const [ updatedSearch, setUpdatedSearch ] = React.useState( null );

  const [ loadingVulns, setLoadingVulns ] = React.useState( true );

  const [ vulnerabilityResults, setVulnerabilityResults ] = React.useState( [] );
  const [ highestRisk, setHighestRisk ] = React.useState( null );

  let isMounted = false;

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  // update the returned list of vulns when the search input changes
  React.useEffect( () => {
    isMounted = true;
    if ( isNotEmpty( updatedSearch ) && newVulnChoice === 'existing' && isMounted ) {
      const values = getFieldValues( updatedSearch.fieldStates, 'new_vulnerability' );

      if ( isNotEmpty( values ) && isNotEmpty( values.keywords ) ) {
        refreshVulnerabilityResults( values.keywords );
      } else {
        refreshVulnerabilityResults();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [ updatedSearch, newVulnChoice, vulnNotIDs ] );

  // debounced vuln search
  const refreshVulnerabilityResults = async( keywords='' ) => {
    setLoadingVulns( true );
    setSelectedResult( null );
    const results = await getRecords(
      'vulnerability',
      // eslint-disable-next-line camelcase
      { keywords, not_id_list: vulnNotIDs, extra_columns: [ 'description', 'identifier', 'urls' ] },
    );
    if ( isMounted ) {
      setLoadingVulns( false );

      const _results = results.sort( ( a, b ) => b.risk - a.risk );
      const _highest = Math.max( ..._results.map( r => r.risk ) );

      setHighestRisk( _highest );
      setVulnerabilityResults( _results );
    }
  };

  // check for selection state in tab 2
  const isSelected = result => {
    if ( isNotEmpty( selectedResult ) ) {
      return selectedResult.id === result.id;
    }
    return false;

  };

  // select/deselect a vuln in tab 2 adding an existing vuln
  const toggleSelectResult = result => {
    if ( isSelected( result ) ) {
      setSelectedResult( null );
    } else {
      setSelectedResult( result );
    }
  };

  // set the form up for whether a user is choosing an existing vuln, or creating a new one
  React.useEffect( () => {
    if ( newVulnChoice === 'new' ) {
      const _fields = {
        vulnerability: {
          header: 'Vulnerability Fields',
          help: <HelpTrigger helpKey="vulnerability_fields" />,
          fields: [
            {
              type: 'text',
              required: true,
              attribute: 'identifier',
              label: 'Identifier',
              help: <HelpTrigger helpKey="vulnerability_identifier" />,
              defaultValue: '',
            },
            {
              type: 'textarea',
              attribute: 'description',
              label: 'Description',
              help: <HelpTrigger helpKey="vulnerability_description" />,
              defaultValue: '',
            },
            {
              type: 'textarea',
              attribute: 'urls',
              label: 'References',
              help: <HelpTrigger helpKey="vulnerability_references" />,
              defaultValue: '',
            },
            {
              type: 'select',
              attribute: 'effort',
              label: 'Remediation Effort',
              help: <HelpTrigger helpKey="remediation_effort" />,
              defaultValue: 'minimal',
              options: {
                minimal: 'Minimal',
                low: 'Low',
                moderate: 'Moderate',
                high: 'High',
                nofix: 'N/A',
              },
            },
            // the following are all hacks because new notNull constraints were added to the db
            {
              type: 'hidden',
              attribute: 'cvss_base_score',
              defaultValue: 0,
            },
            {
              type: 'hidden',
              attribute: 'public_notes',
              defaultValue: '',
            },
            {
              type: 'hidden',
              attribute: 'internal_notes',
              defaultValue: '',
            },
            {
              type: 'hidden',
              attribute: 'allows_escalation',
              defaultValue: 'false',
            },
            {
              type: 'hidden',
              attribute: 'cve_tags',
              defaultValue: [],
            },
            {
              type: 'hidden',
              attribute: 'cisa_exploited',
              defaultValue: 'false',
            },
            {
              type: 'hidden',
              attribute: 'rule_priority',
              defaultValue: 0.0,
            },
          ],
        },
      };

      setVulnerabilityFields( _fields );
    }

    if ( newVulnChoice === 'existing' ) {
      const _fields = [
        {
          type: 'debouncedText',
          attribute: 'keywords',
          label: 'Filter Vulnerabilities',
          placeholder: 'Filter Vulnerabilities by keyword...',
          defaultValue: '',
        },
      ];


      setSearchFields( _fields );
    }
  }, [ newVulnChoice ] );

  return (
    <React.Fragment>
      <h2>Edit Segment: Add Vulnerability</h2>
      <div className="stepDirections">
        <span>Add an existing vulnerability to this segment or create your own custom one.</span>
        <div className={ `${newVulnChoice} toggleWrapper`}>
          <button
            onClick={ () => setNewVulnChoice( 'existing' ) }
            className={ `${newVulnChoice === 'existing' ? 'toggled' : ''} toggleButton` }
          >
            Add an existing vulnerability
          </button>
          <button
            onClick={ () => setNewVulnChoice( 'new' ) }
            className={ `${newVulnChoice === 'new' ? 'toggled' : ''} toggleButton` }
          >
            Create a new vulnerability
          </button>
        </div>
      </div>

      {
        newVulnChoice === 'existing' &&
        <React.Fragment>
          {
            loadingVulns &&
            <div className="loadingWrapperPlaceholder">
              <Loading />
            </div>
          }
          {
            isNotEmpty( searchFields ) &&
            <Form
              fields={searchFields}
              onChangeCallback={setUpdatedSearch}
            />
          }
          {
            ( isNotEmpty( vulnerabilityResults ) && isNotEmpty( highestRisk ) )
              ? <div className="panels">
                <div className="panel results">
                  <label>
                    Vulnerabilities <strong>({vulnerabilityResults.length})</strong>
                  </label>
                  <ul>
                    {
                      vulnerabilityResults.map( ( result, index ) => {
                        return  <li key={index}
                          className={ `vulnerabilityResult ${ isSelected( result ) ? 'selected' : '' }` }
                          onClick={ () => toggleSelectResult( result ) }
                        >
                          <span className="name">
                            { result.identifier }
                          </span>
                          <RiskPercentageBar
                            totalRisk={highestRisk}
                            itemRisk={ result.risk || 0 }
                            itemRating={ riskToRating( result.risk || 0 ) }
                            minimalVariant
                          />
                          <RiskReduction item={ result } riskType="risk" />
                        </li>;
                      } )
                    }
                  </ul>
                </div>
                <div className="panel vulnerabilityDetails">
                  {
                    isNotEmpty( selectedResult )
                      ? <div className="selectedResultWrapper">
                        <pre>
                          { selectedResult.description }
                        </pre>
                        {
                          isNotEmpty( selectedResult.urls ) &&
                          <React.Fragment>
                            <strong>References</strong>
                            <ul>
                              {
                                selectedResult.urls.split( '\n' ).map( ( url, i ) => {
                                  if ( url?.startsWith( 'http' ) ) {
                                    return  <li key={i}>
                                      <a href={url} target="blank" rel="noopener noreferrer" >
                                        { url }
                                      </a>
                                    </li>;
                                  }
                                } )
                              }
                            </ul>
                          </React.Fragment>
                        }
                      </div>
                      : <EmptyState message="Select a vulnerabity to see more details and add" />
                  }
                </div>
              </div>
              : <EmptyState message="Your search did not return any vulnerabilities" />
          }
        </React.Fragment>
      }
      {
        newVulnChoice === 'new' &&
        <React.Fragment>
          {
            isNotEmpty( vulnerabilityFields ) &&
            <Form
              fields={vulnerabilityFields}
              setIsValid={setVulnerabilityIsValid}
              onChangeCallback={setUpdatedVulnerability}
            />
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default NewTab;