export const recordData = {
  inputs: {
    default: [
      { type: 'text', label: 'name', attribute: 'label' },
    ],
  },
  values: {
    label: {
      value: '',
      isValid: false,
    },
  },
};