/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InsightTable from '../../Table';

import {
  isNotEmpty,
  decodeURLHash,
  encodeURLHash,
  removeFromURLHash,
  paramsToFilters,
  isInequalityType,
  dateToDays,
  isEmpty,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue.js';
import { getRecord, getRecords } from '../../../../shared/RecordCache';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';
import RecordDetails from '../../../RecordDetails/index.js';
import { keyToAttrMap, multipleOrderByOptions } from '../../../Reporting/Dashboards/shared.js';
import ReportHeader from '../../ReportHeader/index.js';
import { defaultRemdiationItems } from '../../Table/shared.js';

const Vulnerabilities = ( ) => {

  const [ selectedRecord, setSelectedRecord ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const isMounted = true;

  const fetchExistingRecord = async( recordID ) => {
    const tagIDs = decodeURLHash()['asset_tag_ids'];

    const _record = (
      await getRecord(
        'vulnerability',
        recordID,
        // eslint-disable-next-line camelcase
        isNotEmpty( tagIDs ) ? { asset_tag_ids: tagIDs } : {},
        true,
      )
    );
    return _record;
  };

  const selectRecord = async ( recordID ) => {
    if ( isNotEmpty( recordID ) ) {

      const fetchedRecord = await fetchExistingRecord( recordID );

      if ( isNotEmpty( fetchedRecord ) ) {
        setSelectedRecord( fetchedRecord );
        encodeURLHash( { item: recordID } );
      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'The record you are looking for is no longer part of the risk model',
        } );
        setSelectedRecord( {} );
        removeFromURLHash( 'item' );
        onRefresh();
      }
    } else {
      setSelectedRecord( {} );
      removeFromURLHash( 'item' );
      onRefresh();
    }
  };

  /* eslint-disable camelcase */
  const onRefresh = async( adjustedFilters=[] ) => {

    const onCorrectPage = decodeURLHash()['report'] === 'vulnerabilities';

    if ( onCorrectPage ) {
      setLoading( true );

      // if the filter that was just changed is not the current page, set it back to page 1
      if ( isNotEmpty( adjustedFilters ) && !adjustedFilters.some( attr => attr === 'current_page ' ) ) {
        encodeURLHash( { current_page: 1 } );
      }

      const filterValues = paramsToFilters();

      const params = {
        rownums: [ 0, 100 ],
        model: 'base',
        group_type: 'vulnerability',
        project: 'default',
        filters: { },
      };

      const _rowNums = getRowNums( filterValues );

      const _orderBy = filterValues?.order_by || [ 'filtered_risk' ];
      const _orderDirection = filterValues?.order_direction || 'DESC';

      if ( isNotEmpty( filterValues ) ) {

        const gt_map = {};
        const lt_map = {};

        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'patchable' ) {
            params.filters[key] = val;
          } else if ( key === 'published_before' && isNotEmpty( val ) ) {
            params.filters[key] = val;
          } else if ( key === 'published_after' && isNotEmpty( val ) ) {
            params.filters[key] = val;
          } else if ( key === 'keywords' ) {
            params.filters['keywords'] = val;
          } else if ( key === 'asset_tag_ids' ) {
            // eslint-disable-next-line camelcase
            params.filters['asset_tag_ids'] = isNotEmpty( val ) ? val : null;
          } else if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          } else if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;
            if ( isNotEmpty( unit ) && unit !== 'null' && unit !== null && !isNaN( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
          } else if ( key === 'age_start' ) {
            let daysAgo = dateToDays( val );
            if ( daysAgo < 0 || isEmpty( daysAgo ) ) {
              daysAgo = 0;
            }
            lt_map.age = daysAgo;
          } else if ( key === 'age_end' ) {
            let daysAgo = dateToDays( val );
            if ( daysAgo < 0 || isEmpty( daysAgo ) ) {
              daysAgo = 0;
            }
            gt_map.age = daysAgo;
          } else {
            params.filters[key] = val;
          }
        } );

        // add in translated inequality values
        if ( isNotEmpty( gt_map ) ) {
          params.filters.gt_map = gt_map;
        }
        if ( isNotEmpty( lt_map ) ) {
          params.filters.lt_map = lt_map;
        }
      }
      if (
        isNotEmpty( _orderBy )
        && isNotEmpty( _orderDirection )
      ) {
        if ( multipleOrderByOptions.includes( _orderBy ) ) {
          const _orderByParams = [];
          _orderBy.split( '_' ).map( ( orderBySectionKey ) => {
            _orderByParams.push( [ keyToAttrMap[orderBySectionKey], _orderDirection ] );
          } );
          params.order_by = _orderByParams;
        } else {
          params.order_by = [ [ _orderBy, _orderDirection ] ];
        }
        params.order_by.push( [ 'identifier', 'ASC' ] );
      }

      let recordList = [];
      let pagedResults = [];

      if ( isNotEmpty( filterValues.item ) ) {
        if ( isMounted ) {
          selectRecord( filterValues.item );
        }
      } else {
        recordList = await getRecords( 'VULNERABILITY', params, true );
        pagedResults = pageIterator( recordList, filterValues );
      }
      if ( isMounted ) {
        setCurrentPageNumber(
          pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
        );
        setCurrentPageResults( pagedResults.firstPage );
        setNextPageResults( pagedResults.secondPage );
      }
      setLoading( false );
    }
  };
  /* eslint-enable camelcase */

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedRecord )
          ? <RecordDetails
            record={selectedRecord}
            recordType="vulnerability"
            riskType="filtered_risk"
            selectRecord={selectRecord}
            options={ {
              isCollapsible: true,
              renderContext: 'risk_insight',
              // eslint-disable-next-line camelcase
              include_vulnerability_instances: true,
              // eslint-disable-next-line camelcase
              include_details: true,
              // eslint-disable-next-line camelcase
              include_statistics: true,
            } }
          />
          : <React.Fragment>
            <PageHeader>
              <ReportHeader
                reportType="vulnerability"
                onRefresh={onRefresh}
                visualCollapsed={visualCollapsed}
                setVisualCollapsed={setVisualCollapsed}
                currentPageNumber={currentPageNumber}
                nextPageResults={nextPageResults}
                selectRecord={selectRecord}
                currentPageResults={currentPageResults}
                loading={loading}
                remediationItems={remediationItems}
                setRemediationItems={setRemediationItems}
              />
            </PageHeader>
            <InsightTable
              records={currentPageResults}
              nextRecords={nextPageResults}
              currentPageNumber={currentPageNumber}
              loading={loading}
              reportType="vulnerability"
              onRefresh={onRefresh}
              refreshTable={onRefresh}
              visualCollapsed={visualCollapsed}
              selectedRecord={selectedRecord}
              selectRecord={selectRecord}
              remediationItems={remediationItems}
              setRemediationItems={setRemediationItems}
            />
          </React.Fragment>
      }
    </React.Fragment>
  );
};

export default Vulnerabilities;