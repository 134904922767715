/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import { makeRequest } from '../../legacy/io';
import { isEmpty, isNotEmpty, uniqueArray } from './Utilities';

// --------------------------------------------------------
// Internal Methods, fn, and vars -------------------------
// --------------------------------------------------------
const project = 'default';
const model = 'base';

const _defaultParamsForType = ( type, useInsightEndpoint ) => {
  let _params = {};

  if ( type === 'scope' ) {

    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'host',
        filters: {
          // eslint-disable-next-line camelcase
          extra_columns: [
            // 'scope_analysis.sensitive_nodes',
            // 'scope_analysis.patches',
            // 'scope_analysis.vulnerabilities',
            'scope_analysis.risk',
            'scope_analysis.ancestor_labels',
            'modified',
            'label',
          ],
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'scope_analysis.risk', 'DESC' ],
          [ 'label', 'ASC' ],
        ],
        rownums: [ 0, 100 ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          // 'scope_analysis.sensitive_nodes',
          // 'scope_analysis.patches',
          // 'scope_analysis.vulnerabilities',
          'scope_analysis.risk',
          'scope_analysis.ancestor_labels',
          'ancestor_labels',
          'modified',
          'label',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'scope_analysis.risk', 'DESC' ],
          [ 'label', 'ASC' ],
        ],
      };
    }

  }

  if ( type === 'patch' ) {
    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'patch',
        filters: {
          // eslint-disable-next-line camelcase
          extra_columns: [
            'vendor',
            'identifier',
            'description',
            'modified',
            'patch_analysis.vulnerabilities',
            'supersedes',
          ],
        },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'patch_analysis.risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
        rownums: [ 0, 100 ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vendor',
          'identifier',
          'description',
          'modified',
          'patch_analysis.vulnerabilities',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'patch_analysis.risk', 'DESC' ],
          [ 'vendor', 'ASC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    }

  }

  if ( type === 'path' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'keywords',
        'risk',
        'modified',
        'edges',
        'node_labels',
      ],
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'risk', 'DESC' ],
      ],
    };
  }

  if ( type === 'vulnerability' ) {
    if ( useInsightEndpoint ) {
      _params = {
        // eslint-disable-next-line camelcase
        group_type: 'vulnerability',
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.risk',
          'identifier',
          'modified',
        ],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'vulnerability_analysis.risk', 'DESC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    } else {
      _params = {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.risk',
          'identifier',
          'modified',
        ],
        // not_field_map: {
        //   effort: 'nofix',
        // },
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'vulnerability_analysis.risk', 'DESC' ],
          [ 'identifier', 'DESC' ],
        ],
      };
    }

  }

  if ( type === 'node' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [
        'impact',
        'label',
        'scope_id',
        'name',
        'type',
        'node_analysis.risk',
        'node_analysis.combined_impact',
      ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'node_analysis.risk', 'DESC' ] ],
    };
  }

  if ( type === 'edge' ) {
    _params = {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'from_node', 'to_node', 'edge_analysis.risk' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'risk', 'DESC' ] ],
    };
  }

  return _params;
};
// utility function to parse whether an attr has '_analysis' in it
const _isAnalysisAttribute = ( type, attribute ) => attribute?.startsWith( `${type}_analysis` );

// --------------------------------------------------------
// Internal io Functions ----------------------------------
// --------------------------------------------------------
// main search method (adapted from original search_model_records in io.js)
// if new insight endpoints are desired, it hits the new 'INSIGHT' method, otherwise, it falls back to old behavior
const _search = async ( type, filters, useInsightEndpoint, singleRecord=false ) => {

  let response;

  const params = {
    filters,
    project,
    model,
  };

  const start = new Date().getTime();

  if ( useInsightEndpoint ) {
    if ( singleRecord ) {
      const _type = type === 'scope' ? 'host' : type;

      const params = { filters: { [`${_type}_id`]: filters.id_list[0] } };

      if ( _type === 'patch' ) {
        // eslint-disable-next-line camelcase
        params.filters.risk_type = filters.risk_type;
      }

      // eslint-disable-next-line max-len, camelcase
      response = await makeRequest( _type.toUpperCase(), '/model/base/insight_details', params );
    } else {
      console.log( filters );
      response = await makeRequest( type.toUpperCase(), '/model/base/insight', filters );
    }

  } else {
    response = await makeRequest( 'SEARCH', `/model/${type}`, params );
  }

  const seconds = ( new Date().getTime() - start ) / 1000.0;

  if ( isNotEmpty( response ) ) {
    console.log( 'Search time: '+ seconds, type, params, response['results'] );
    return response.results;
  }

  return [];
};

// main add/create method (adapted from add_model_records in io.js)
const _create = async ( type, additions ) => {
  const params = {
    additions,
    project,
    model,
  };

  const response = await makeRequest( 'ADD', `/model/${type}`, params );
  return response;
};

// main update method (adapted from update_model_records in io.js)
const _update = async ( type, changes ) => {
  const params = {
    changes,
    project,
    model,
  };

  const response = await makeRequest( 'UPDATE', `/model/${type}`, params );
  return response;
};

// main delete (named 'remove' because delete is not allowed)
// method (adapted from delete_model_records in io.js)
const _remove = async ( type, ids ) => {
  const params = {
    ids,
    project,
    model,
  };

  const response = await makeRequest( 'DELETE', `/model/${type}`, params );
  return response;
};

// --------------------------------------------------------
// Internal Caching Functions -----------------------------
// --------------------------------------------------------
const _addToCache = ( key, value ) => {
  window.recordCache?.delete( key );

  // check if we have reached the cache limit, if so, remove the last and add, otherwise just add
  if ( window.recordCache && window.recordCache.size >= window.CACHE_CAPACITY ) {
    window.recordCache?.delete( window.recordCache && window.recordCache.keys().next().value );
  }

  window.recordCache?.set( key, value );

};

const _removeFromCache = ( key ) => {
  window.recordCache?.delete( key );
};

const _deleteRecordsAndCache = async ( type, IDs ) => {

  const response = await _remove( type, IDs );

  if ( isNotEmpty( response ) ) {
    const { errors } = response;

    if ( errors ) {
      return { errors };
    }
    // remove each id from the cache
    IDs.map( id => {
      window.recordCache?.delete( id );
    } );
  }
};

const _addRecordsAndCache = async ( type, params ) => {

  const response = await _create( type, params );

  if ( isNotEmpty( response ) ) {
    const { errors, results } = response;

    if ( isNotEmpty( errors ) ) {
      return { errors };
    } else if ( isNotEmpty( results ) ) {
      // store each item in the cache
      results.map( record => {
        _addToCache( record.id, record );
      } );
      return response.results;
    }
  }
};

const _updateRecordsAndCache = async ( type, params ) => {

  const response = await _update( type, params );

  if ( isNotEmpty( response ) ) {
    const { errors, results } = response;

    if ( isNotEmpty( errors ) ) {
      return { errors };
    } else if ( isNotEmpty( results ) ) {
      // store each item in the cache
      results.map( record =>  _addToCache( record.id, record ) );
      return results;
    }
  }
};

const _fetchRecordsAndCache = async ( type, additionalParams={}, useInsightEndpoint, singleRecord=false ) => {
  let params = {};

  // need to first see if we are looking for a single record using the new(er) instance_details endpoints, if so,
  // need to make sure we always fetch the record because... otherwise it has been cached and might be missing fields
  // I cannot rely on the params being different anymore
  if ( useInsightEndpoint === true && singleRecord === true ) {
    // eslint-disable-next-line max-len, camelcase
    const fetchedRecords = await _search( type, { risk_type: additionalParams.risk_type, id_list: additionalParams.id_list }, useInsightEndpoint, singleRecord );

    if ( isNotEmpty( fetchedRecords ) ) {
      const [ record ] = fetchedRecords;
      _addToCache( record.id, record );
      return fetchedRecords;
    }
    return ( {} );
  // we are asking for specific records with an id_list,
  // this is the real performance gain, if we are asking for any that have
  // already been fetched, we will only ask for the new ones.
  } else if (
    isNotEmpty( additionalParams )
    && isNotEmpty( additionalParams.id_list )
    && isEmpty( additionalParams.id_field )
  ) {

    let newRecordIDsToFetch = [];
    let toReturn = [];

    // eslint-disable-next-line camelcase, max-len
    additionalParams.id_list = additionalParams.id_list.filter( id => id !== undefined || id !== 'undefined' );

    additionalParams.id_list.map( id => {
      const item = window.recordCache && window.recordCache.get( id );
      // record is already in the cache
      if ( isNotEmpty( item ) ) {
        // this checks to make sure the existing record has all the columns that we are asking
        // for, if it does not, we will add the id to the list of records that need fetching,
        // so that it has all the expected data that may be missing from the cache
        if ( isNotEmpty( additionalParams.extra_columns ) ) {
          // loop through each attribute, and see if it is not on the record, if even one is
          // missing we need to refetch
          // eslint-disable-next-line
          for ( const [ index, ec ] of additionalParams.extra_columns.entries() ) {
            let attr = ec;

            if ( _isAnalysisAttribute( type, attr ) ) {
              // eslint-disable-next-line
              attr = attr.split( '.' )[1];
            }
            // pop it to the top of the cache
            if ( item[attr] !== undefined && item[attr] !== null ) {
              // if we are only asking for one thing... we need to replace the ids, otherwise, add to it
              if ( additionalParams.id_list.length === 1 ) {
                toReturn = [ item ];
              } else {
                toReturn.push( item );
              }
              _removeFromCache( id );
              _addToCache( id, item );
            // if this attr is not present, need to refetch
            } else {
              // if we are only asking for one thing... we need to replace the ids, otherwise, add to it
              if ( additionalParams.id_list.length === 1 ) {
                newRecordIDsToFetch = [ id ];
              } else {
                newRecordIDsToFetch.push( id );
              }
              break;
            }
          }
        }
      // record is not in the cache
      } else {
        newRecordIDsToFetch.push( id );
      }
    } );

    // we still have more IDs for uncached records, need to fetch any missing ones
    if ( isNotEmpty( newRecordIDsToFetch ) ) {
      params = {
        ...params,
        ...additionalParams,
        // eslint-disable-next-line camelcase
        id_list: newRecordIDsToFetch,
      };

      // get the additional records and map them
      const fetchedRecords = await _search( type, params, useInsightEndpoint );

      if ( additionalParams.id_list?.length === 1 ) {
        // store each item in the cache
        fetchedRecords.map( record => {
          toReturn = [ record ];
          _removeFromCache( record.id );
          _addToCache( record.id, record );
        } );
      } else {
        // store each item in the cache
        fetchedRecords?.map( record => {
          toReturn.push( record );
          _addToCache( record.id, record );
        } );
      }

      return uniqueArray( toReturn );

    // all the records we are asking for are already cached
    }
    additionalParams.id_list.map( id => {
      const item = window.recordCache && window.recordCache.get( id );
      if ( isNotEmpty( item ) ) {
        toReturn.push( item );
      }
    } );

    return uniqueArray( toReturn );

  // we are not asking for specific ids, need to do the default fetch
  }
  params = {
    ...params,
    ...additionalParams,
  };

  const fetchedRecords = await _search( type, params, useInsightEndpoint );

  // store each item in the cache
  if ( isNotEmpty( fetchedRecords ) ) {
    fetchedRecords.map( record => {
      _addToCache( record.id, record );
    } );
  }

  return fetchedRecords;
};

// takes the existing params, and merges them with any additional ones, more control than just
// overwriting, need to take into account different data types (arrays, objects, strings)
const _mergeParams = ( existing, additional ) => {

  const combined = { ...existing };

  if ( isNotEmpty( additional ) ) {
    Object.entries( additional ).map( ( [ key, val ] ) => {
      // this filter already exists
      if ( isNotEmpty( combined[key] ) ) {
        // this value is an array, the two need to be merged ( most likely extra_columns )
        if ( Array.isArray( val ) ) {
          // for all array types, need to merge them,
          // rownums/order_by need to be overwritten though
          if ( key === 'rownums' || key === 'order_by' ) {
            combined[key] = val;
          } else {
            combined[key] = [ ...combined[key], ...val ];
          }
        } else if ( val.constructor === Object ) {
          combined[key] = { ...combined[key], ...val };
        } else if ( typeof val === 'string' || val instanceof String ) {
          combined[key] = val;
        } else {
          combined[key] = val;
        }
      // the filter does not already exist, add it
      } else {
        combined[key] = val;
      }
    } );
  }
  return combined;
};

// --------------------------------------------------------
// Exported Functions -------------------------------------
// --------------------------------------------------------
export const clearCache = () => {
  window.recordCache.clear();
};

// external addition to cache, takes an array of
// records and replaces any existing records with these
export const addRecordsToCache = records => {
  records.map( record => {
    const { id } = record;

    if ( isNotEmpty( id ) && isNotEmpty( record ) ) {
      _addToCache( id, record );
    }
  } );
};

// gets a collection of a particular record type
export const getRecords = async ( type, additionalParams={}, useInsightEndpoint=false ) => {

  let params = {
    rownums: [ 0, 100 ],
  };

  const typeFilters = _defaultParamsForType( type, useInsightEndpoint );

  params = _mergeParams( { ...params, ...typeFilters }, additionalParams );

  const records = await _fetchRecordsAndCache( type, params, useInsightEndpoint );
  return records;
};

// gets a single record of a particular type
export const getRecord = async ( type, id, additionalParams={}, useInsightEndpoint=false ) => {
  if ( isNotEmpty( id ) ) {
    let params = {
      rownums: [ 0, 1 ],
    };

    const typeFilters = _defaultParamsForType( type, useInsightEndpoint );

    params = _mergeParams( { ...params, ...typeFilters }, additionalParams );
    params = {
      ...params,
      // eslint-disable-next-line camelcase
      id_list: [ id ],
    };

    const records = await _fetchRecordsAndCache( type, params, useInsightEndpoint, true );

    return records[0];
  }
};

// updates existing records of a particular type
export const updateRecords = async ( type, changes=[] ) => {
  const records = await( _updateRecordsAndCache( type, changes ) );
  return uniqueArray( records );
};

// creates new records of an existing type
export const addRecords = async ( type, additions=[] ) => {
  const records = await( _addRecordsAndCache( type, additions ) );
  return uniqueArray( records );
};

// removes records of a particular type
export const deleteRecords = async ( type, removals=[] ) => {
  const records = await( _deleteRecordsAndCache( type, removals ) );
  return uniqueArray( records );
};