/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

import './style.scss';

// each cvss version has a different base url
const baseURLs = {
  '3.1': 'https://www.first.org/cvss/v3.1/specification-document#',
  '3.0': 'https://www.first.org/cvss/v3.0/specification-document#',
  '2.0': 'https://www.first.org/cvss/v2/guide#',
};

// linking to the first.org spec, this will jump users to the corresponding section on that page
const vectorLinkMap = {
  '3.1': {
    AV: '2-1-1-Attack-Vector-AV',
    AC: '2-1-2-Attack-Complexity-AC',
    PR: '2-1-3-Privileges-Required-PR',
    UI: '2-1-4-User-Interaction-UI',
    S: '2-2-Scope-S',

    C: '2-3-1-Confidentiality-C',
    I: '2-3-2-Integrity-I',
    A: '2-3-3-Availability-A',

    E: '3-1-Exploit-Code-Maturity-E',
    RL: '3-2-Remediation-Level-RL',
    RC: '3-3-Report-Confidence-RC',

    CR: '4-1-Security-Requirements-CR-IR-AR',
    IR: '4-1-Security-Requirements-CR-IR-AR',
    AR: '4-1-Security-Requirements-CR-IR-AR',

    MAV: '4-2-Modified-Base-Metrics',
    MAC: '4-2-Modified-Base-Metrics',
    MPR: '4-2-Modified-Base-Metrics',
    MUI: '4-2-Modified-Base-Metrics',
    MS: '4-2-Modified-Base-Metrics',
    MC: '4-2-Modified-Base-Metrics',
    MI: '4-2-Modified-Base-Metrics',
    MA: '4-2-Modified-Base-Metrics',
  },
  '3.0': {
    AV: '2-1-1-Attack-Vector-AV',
    AC: '2-1-2-Attack-Complexity-AC',
    PR: '2-1-3-Privileges-Required-PR',
    UI: '2-1-4-User-Interaction-UI',
    S: '2-2-Scope-S',

    C: '2-3-1-Confidentiality-Impact-C',
    I: '2-3-2-Integrity-Impact-I',
    A: '2-3-3-Availability-Impact-A',

    E: '3-1-Exploit-Code-Maturity-E',
    RL: '3-2-Remediation-Level-RL',
    RC: '3-3-Report-Confidence-RC',

    CR: '4-1-Security-Requirements-CR-IR-AR',
    IR: '4-1-Security-Requirements-CR-IR-AR',
    AR: '4-1-Security-Requirements-CR-IR-AR',

    MAV: '4-2-Modified-Base-Metrics',
    MAC: '4-2-Modified-Base-Metrics',
    MPR: '4-2-Modified-Base-Metrics',
    MUI: '4-2-Modified-Base-Metrics',
    MS: '4-2-Modified-Base-Metrics',
    MC: '4-2-Modified-Base-Metrics',
    MI: '4-2-Modified-Base-Metrics',
    MA: '4-2-Modified-Base-Metrics',
  },
  '2.0': {
    AV: '2-1-1-Access-Vector-AV',
    AC: '2-1-2-Access-Complexity-AC',
    Au: '2-1-3-Authentication-Au',
    C: '2-1-4-Confidentiality-Impact-C',
    I: '2-1-5-Integrity-Impact-I',
    A: '2-1-6-Availability-Impact-A',

    E: '2-2-1-Exploitability-E',
    RL: '2-2-2-Remediation-Level-RL',
    RC: '2-2-3-Report-Confidence-RC',

    CDP: '2-3-1-Collateral-Damage-Potential-CDP',
    TD: '2-3-2-Target-Distribution-TD',

    CR: '2-3-3-Security-Requirements-CR-IR-AR',
    IR: '2-3-3-Security-Requirements-CR-IR-AR',
    AR: '2-3-3-Security-Requirements-CR-IR-AR',
  },
};

// definition of the keys for each version
const metricKeyMap = {
  '2.0': {
    AV: 'Access Vector',
    AC: 'Access Complexity',
    Au: 'Authentication',
    C: 'Confidentiality Impact',
    I: 'Integrity Impact',
    A: 'Availability Impact',
    E: 'Exploitability',
    RL: 'Remediation Level',
    RC: 'Report Confidence',
    CDP: 'Collateral Damage Potential',
    TD: 'Target Distribution',
    CR: 'Confidentiality Requirement',
    IR: 'Integrity Requirement',
    AR: 'Availability Requirement',
  },
  '3.0': {
    AV: 'Attack Vector',
    AC: 'Attack Complexity',
    PR: 'Privileges Required',
    UI: 'User Interaction',
    S: 'Scope',
    C: 'Confidentiality Impact',
    I: 'Integrity Impact',
    A: 'Availability Impact',
    E: 'Exploit Code Maturity',
    RL: 'Remediation Level',
    RC: 'Report Confidence',
    CR: 'Confidentiality Requirement',
    IR: 'Integrity Requirement',
    AR: 'Availability Requirement',

    MAV: 'Modified Attack Vector',
    MAC: 'Modified Attack Complexity',
    MPR: 'Modified Privileges Required',
    MUI: 'Modified User Interaction',
    MS: 'Modified Scope',
    MC: 'Modified Confidentiality Impact',
    MI: 'Modified Integrity Impact',
    MA: 'Modified Availability Impact',
  },
  '3.1': {
    AV: 'Attack Vector',
    AC: 'Attack Complexity',
    PR: 'Privileges Required',
    UI: 'User Interaction',
    S: 'Scope',
    C: 'Confidentiality',
    I: 'Integrity',
    A: 'Availability',
    E: 'Exploit Code Maturity',
    RL: 'Remediation Level',
    RC: 'Report Confidence',
    CR: 'Confidentiality Requirement',
    IR: 'Integrity Requirement',
    AR: 'Availability Requirement',

    MAV: 'Modified Attack Vector',
    MAC: 'Modified Attack Complexity',
    MPR: 'Modified Privileges Required',
    MUI: 'Modified User Interaction',
    MS: 'Modified Scope',
    MC: 'Modified Confidentiality Impact',
    MI: 'Modified Integrity Impact',
    MA: 'Modified Availability Impact',
  },
};

// possible values for each metric, needed for tooltip
const metricValueMap = {
  '2.0': {
    AV: {
      L: 'Local',
      A: 'Adjacent Network',
      N: 'Network',
    },
    AC: {
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    Au: {
      M: 'Multiple',
      S: 'Single',
      N: 'None',
    },
    C: {
      N: 'None',
      P: 'Partial',
      C: 'Complete',
    },
    I: {
      N: 'None',
      P: 'Partial',
      C: 'Complete',
    },
    A: {
      N: 'None',
      P: 'Partial',
      C: 'Complete',
    },
    E: {
      U: 'Unproven',
      POC: 'Proof-of-Concept',
      F: 'Functional',
      H: 'High',
      ND: 'Not Defined',
    },
    RL: {
      OF: 'Official Fix',
      TF: 'Temporary Fix',
      W: 'Workaround',
      U: 'Unavailable',
      ND: 'Not Defined',
    },
    RC: {
      UC: 'Unconfirmed',
      UR: 'Uncorroborated',
      C: 'Confirmed',
      ND: 'Not Defined',
    },
    CDP: {
      N: 'None',
      L: 'Low',
      LM: 'Low-Medium',
      MH: 'Medium-High',
      H: 'High',
      ND: 'Not Defined',
    },
    TD: {
      N: 'None',
      L: 'Low',
      M: 'Medium',
      H: 'High',
      ND: 'Not Defined',
    },
    CR: {
      L: 'Low',
      M: 'Medium',
      H: 'High',
      ND: 'Not Defined',
    },
    IR: {
      L: 'Low',
      M: 'Medium',
      H: 'High',
      ND: 'Not Defined',
    },
    AR: {
      L: 'Low',
      M: 'Medium',
      H: 'High',
      ND: 'Not Defined',
    },
  },
  '3.0': {
    AV: {
      N: 'Network',
      A: 'Adjacent',
      L: 'Local',
      P: 'physical',
    },
    AC: {
      L: 'Low',
      H: 'High',
    },
    PR: {
      N: 'None',
      L: 'Low',
      H: 'High',
    },
    UI: {
      N: 'None',
      R: 'Required',
    },
    S: {
      U: 'Unchanged',
      C: 'Changed',
    },
    C: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    I: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    A: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    E: {
      X: 'Not Defined',
      H: 'High',
      F: 'Functional',
      P: 'Proof-of-Concept',
      U: 'Unproven',
    },
    RL: {
      X: 'Not Defined',
      U: 'Unavailable',
      W: 'Workaround',
      T: 'Temporary Fix',
      O: 'Official Fix',
    },
    RC: {
      X: 'Not Defined',
      C: 'Confirmed',
      R: 'Reasonable',
      U: 'Unknown',
    },
    CR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    IR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    AR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    MAV: {
      N: 'Network',
      A: 'Adjacent',
      L: 'Local',
      P: 'physical',
    },
    MAC: {
      L: 'Low',
      H: 'High',
    },
    MPR: {
      N: 'None',
      L: 'Low',
      H: 'High',
    },
    MUI: {
      N: 'None',
      R: 'Required',
    },
    MS: {
      U: 'Unchanged',
      C: 'Changed',
    },
    MC: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    MI: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    MA: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
  },
  '3.1': {
    AV: {
      N: 'Network',
      A: 'Adjacent',
      L: 'Local',
      P: 'physical',
    },
    AC: {
      L: 'Low',
      H: 'High',
    },
    PR: {
      N: 'None',
      L: 'Low',
      H: 'High',
    },
    UI: {
      N: 'None',
      R: 'Required',
    },
    S: {
      U: 'Unchanged',
      C: 'Changed',
    },
    C: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    I: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    A: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    E: {
      X: 'Not Defined',
      H: 'High',
      F: 'Functional',
      P: 'Proof-of-Concept',
      U: 'Unproven',
    },
    RL: {
      X: 'Not Defined',
      U: 'Unavailable',
      W: 'Workaround',
      T: 'Temporary Fix',
      O: 'Official Fix',
    },
    RC: {
      X: 'Not Defined',
      C: 'Confirmed',
      R: 'Reasonable',
      U: 'Unknown',
    },
    CR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    IR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    AR: {
      X: 'Not Defined',
      H: 'High',
      M: 'Medium',
      L: 'Low',
    },
    MAV: {
      N: 'Network',
      A: 'Adjacent',
      L: 'Local',
      P: 'physical',
    },
    MAC: {
      L: 'Low',
      H: 'High',
    },
    MPR: {
      N: 'None',
      L: 'Low',
      H: 'High',
    },
    MUI: {
      N: 'None',
      R: 'Required',
    },
    MS: {
      U: 'Unchanged',
      C: 'Changed',
    },
    MC: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    MI: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
    MA: {
      H: 'High',
      L: 'Low',
      N: 'None',
    },
  },
};

const scoreClass = score => {
  if ( score >= 9 ) {
    return 'critical';
  } else if ( score >= 7.5 ) {
    return 'high';
  } else if ( score >= 6 ) {
    return 'moderate';
  } else if ( score >= 3 ) {
    return 'low';
  }
  return 'minimal';

};

const CVSSBreakdown = ( { vulnerability } ) => {

  const [ version, setVersion ] = React.useState( null );
  const [ baseScore, setBaseScore ] = React.useState( null );
  const [ rawVectorString, setRawVectorString ] = React.useState( null );
  const [ vectorStringSections, setVectorStringSections ] = React.useState( [] );

  React.useEffect( () => {
    if ( isNotEmpty( vulnerability ) ) {

      if ( isNotEmpty( vulnerability.cvssv3 ) ) {
        setVersion( vulnerability.cvssv3.version );
        setBaseScore( vulnerability.cvssv3.baseScore );
        setRawVectorString( vulnerability.cvssv3.vectorString );
      } else if ( isNotEmpty( vulnerability.cvssv2 ) ) {
        setVersion( vulnerability.cvssv2.version );
        setBaseScore( vulnerability.cvssv2.baseScore );
        setRawVectorString( vulnerability.cvssv2.vectorString );
      } else {
        setVersion( null );
      }
    } else {
      setVersion( null );
    }
  }, [ vulnerability ] );

  React.useEffect( () => {
    if ( isNotEmpty( rawVectorString ) && isNotEmpty( version ) ) {
      const stringParts = rawVectorString.split( '/' );
      // the 2.0 version does not start with the cvss version
      // we won't need it, so need to remove it
      if ( version !== '2.0' ) {
        stringParts.shift();
      }

      const _vectorStringSections = [
        {
          label: `CVSS:${version}`,
          link: baseURLs[version],
        },
      ];

      stringParts.map( el => {
        const parts = el.split( ':' );
        // eslint-disable-next-line
        const key = parts[0];
        // eslint-disable-next-line
        const value = parts[1];

        _vectorStringSections.push( {
          label: el,
          link: `${baseURLs[version]}${vectorLinkMap[version][key]}`,
          metricKey: metricKeyMap[version][key],
          metricValue: metricValueMap[version][key][value],
        } );
      } );

      setVectorStringSections( _vectorStringSections );
    }
  }, [ version, rawVectorString ] );

  const hasSomeContent = isNotEmpty( baseScore )
    || isNotEmpty( vulnerability.public_notes )
    || isNotEmpty( vectorStringSections );

  return (
    <React.Fragment>
      {
        hasSomeContent &&
        <div className="CVSSBreakdown">
          {
            isNotEmpty( vectorStringSections ) &&
            <div className="vectorString">
              <div className="vectorStringWrapper">
                {
                  isNotEmpty( baseScore ) &&
                  <div className="scoreWrapper">
                    <span className="labelSpan">{  `CVSS ${version} Base Score:` }</span>
                    <span className={ `cvssScore risk--${scoreClass( baseScore )}`}>
                      { baseScore }
                    </span>
                  </div>

                }
                <div className="stringWrapper">
                  <span className="labelSpan">Vector String:</span>
                  <span className="stringContainer">
                    {
                      vectorStringSections.map( ( section, i ) => {
                        return <div
                          key={i}
                          className="vectorStringSection"
                        >
                          <a
                            href={section.link}
                            target="_blank"
                            rel="nofollow"
                          >
                            { `${section.label}${ i < vectorStringSections.length - 1 ? '/' : '' }` }
                          </a>
                          <div className="sectionInfo">
                            {
                              i === 0
                                ? <React.Fragment>
                                  <span>
                                    <label>CVSS Version:</label>
                                    <strong>{ version }</strong>
                                  </span>
                                  <a
                                    href={section.link}
                                    target="_blank"
                                    rel="nofollow"
                                  >
                                    <span>More</span>
                                    <InlineSVG type="link" version="primary" />
                                  </a>
                                </React.Fragment>
                                : <React.Fragment>
                                  <span>
                                    <label>{ section.metricKey }:</label>
                                    <strong>{ section.metricValue }</strong>
                                  </span>
                                  <a
                                    href={section.link}
                                    target="_blank"
                                    rel="nofollow"
                                  >
                                    <span>more</span>
                                    <InlineSVG type="link" version="primary" />
                                  </a>
                                </React.Fragment>
                            }
                          </div>
                        </div>;
                      } )
                    }
                  </span>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default CVSSBreakdown;
