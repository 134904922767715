/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../EmptyState';
import Loading from '../Loading';
import { isEmpty, isNotEmpty } from '../Utilities';

const EmptyLoading = ( { loading, loadingMessage='', payload, emptyMessage='' } ) => {
  return (
    <React.Fragment>
      {
        isEmpty( payload ) &&
        <React.Fragment>
          {
            loading
              ? <Loading text={ loadingMessage } />
              : <React.Fragment>
                {
                  isNotEmpty( emptyMessage ) &&
                  <EmptyState message={ emptyMessage } />
                }
              </React.Fragment>

          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EmptyLoading;