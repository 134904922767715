/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './CredentialItem.scss';

const CredentialItem = ( { credential, index } ) => {

  const protocolDisplayMap = {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'ssh-password': 'SSH Password',
    'ssh-key': 'SSH Private Key',
    'smb': 'SMB (deprecated)',
  };

  const credentialName = credential => {
    let name = '';
    if ( credential.domain ) {
      name = `${credential.domain}/${credential.username}`;
    } else {
      name = `${credential.username}`;
    }
    return name;
  };

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="orderContainer">
          <strong>
            Order
          </strong>
          <span>
            { index + 1 }
          </span>
        </div>
        <div className="column">
          <h2>{ credential.label || 'No Label' }</h2>
          <span>
            <strong>Type/Protocol:</strong> { protocolDisplayMap[credential.protocol] }
          </span>
          <span>
            <strong>User Account:</strong> { credentialName( credential ) }
          </span>
        </div>
      </section>
      <section>
        <strong className="sectionLabel">
          Used by scan groups
        </strong>
        <span className="sectionDetails" >
          { credential.scanGroupLabels.join( ', ' ) }
        </span>
      </section>
    </React.Fragment>
  );
};

export default CredentialItem;