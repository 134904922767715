/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Modal from '../../../shared/Modal';
import EmptyState from '../../../shared/EmptyState';

import {
  isNotEmpty,
} from '../../../shared/Utilities';
import { makeRequest } from '../../../../legacy/io';

const ModalBody = ( { targets } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( targets ) &&
        <React.Fragment>
          <div className="itemAttribute">
            <strong>{ `${targets?.inaccessible_ips?.length} Inaccessible IP Address(es)`}</strong>
            <p>
              These IP addresses responded to ping scans, but were not successfully scanned.
              This could occur for a variety of reasons, including firewall configuration,
              lack of appropriate credentials, or lack of support for the underlying technology.
            </p>
            {
              isNotEmpty( targets.inaccessible_ips )
                ? <React.Fragment>

                  <ul>
                    {
                      targets?.inaccessible_ips?.sort().map( ( ip, index ) => {
                        return  <li key={index}>
                          <a href={`#.=activity&page=scan_logs&item_count=100&item=${ip}&current_page=1`}>{ ip }</a>
                        </li>;
                      } )
                    }
                  </ul>
                </React.Fragment>
                : <EmptyState message="No Inaccessible IPs" />

            }
          </div>
          <div className="itemAttribute">
            <strong>{`${targets?.target_ranges?.length} Target Range(s)`}</strong>
            <p>
              These IP address ranges were calculated for this scan group after
              considering both the included and excluded IP address ranges.
            </p>
            <span>{ targets?.target_ranges?.join( ', ' ) }</span>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

const IPinfoModal = ( { record, show, setShow } ) => {

  const [ targets, setTargets ] = React.useState( {} );

  React.useEffect( () => {
    if ( show === true && isNotEmpty( record ) ) {
      makeRequest( 'COMPUTE', '/project/default/scan_group', { id: record.id } ).then( response => {
        if ( response && response.results ) {
          const _targets = response.results[record.id];
          if ( isNotEmpty( _targets ) ) {
            setTargets( _targets );
          } else {
            setTargets( {} );
          }
        } else {
          setTargets( {} );
        }
      } );
    } else {
      setTargets( {} );
    }
  }, [ show, record ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <React.Fragment>
          <Modal
            visible={show}
            setVisible={setShow}
            header={'Scan Status Summary'}
            elementClass="IPinfoModal"
            body={<ModalBody
              record={record}
              targets={targets}
            />}
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default IPinfoModal;
