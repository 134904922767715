/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, globalColors, isNotEmpty, itemIsArray, recordSorter } from '../Utilities';

import './Donut.scss';

const Donut = ( {
  data,
  total,
  strokeWidth='thick',
  radius='30',
  displayLabel=false,
  displayLabelOverride=false,
  subLabel=null,
  hoveredSeriesIdentifier=null,
  setHoveredSeriesIdentifier,
  selectedSeriesIdentifier=null,
  setSelectedSeriesIdentifier,
  onHoverCallback,
  onClickCallback,
  allowMultipleSelections=false,
} ) => {

  const [ viewBox, setViewBox ] = React.useState( '100' );
  const [ sortedData, setSortedData ] = React.useState( null );

  const alreadySelected = key => itemIsArray( selectedSeriesIdentifier )
    ? selectedSeriesIdentifier?.includes( key )
    : selectedSeriesIdentifier === key;

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHoverCallback ) ) {
        onHoverCallback( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHoverCallback ) ) {
        onHoverCallback( null );
      }
    }
  };

  const handleMouseClick = series => {
    let identifier;

    if ( isNotEmpty( setSelectedSeriesIdentifier ) ) {
      if ( alreadySelected( series?.key ) ) {
        if ( allowMultipleSelections ) {
          identifier = selectedSeriesIdentifier?.filter( i => i !== series.key );
        } else {
          identifier = null;
        }
      } else if ( allowMultipleSelections ) {
        identifier = [ series.key ];
        if ( isNotEmpty( selectedSeriesIdentifier ) ) {
          identifier = [ ...selectedSeriesIdentifier, series.key ];
        }
      } else {
        identifier = series.key;
      }

      setSelectedSeriesIdentifier( identifier );

      if ( isNotEmpty( onClickCallback ) ) {
        onClickCallback( identifier );
      }
    }
  };

  const isFaded = series => {
    if ( isNotEmpty( selectedSeriesIdentifier ) ) {
      if ( itemIsArray( selectedSeriesIdentifier ) ) {
        return !selectedSeriesIdentifier.includes( series.key );
      }
      return selectedSeriesIdentifier !== series.key;
    }
    return false;
  };

  React.useEffect( ( ) => {
    switch ( radius ) {
    case '30':
      if ( strokeWidth === 'thick' ) {
        setViewBox( isNotEmpty( setHoveredSeriesIdentifier ) ? '85': '80' );
      } else if ( strokeWidth === 'pie' ) {
        setViewBox( isNotEmpty( setHoveredSeriesIdentifier ) ? '120': '120' );
      } else {
        setViewBox( isNotEmpty( setHoveredSeriesIdentifier ) ? '75': '70' );
      }
      break;
    default:
      if ( strokeWidth === 'thick' ) {
        setViewBox( '80' );
      } else {
        setViewBox( '70' );
      }
    }

    if ( isNotEmpty( data ) ) {
      const _withShade = [];

      const _sorted = Object.values( data ).sort( ( a, b ) => recordSorter( 'order', true, b, a ) );

      let magnifiedReached = false;

      _sorted.map( s => {
        if ( !magnifiedReached && s.magnified ) {
          _withShade.push( { isMagnificationShade: true } );
          magnifiedReached = true;
        }
        _withShade.push( s );
      } );

      setSortedData( _withShade );
    }
  }, [ data, total, radius, strokeWidth ] );

  const getStrokeWidth = () => {
    if ( strokeWidth === 'thick' ) {
      return 20;
    } else if ( strokeWidth === 'pie' ) {
      return 60;
    }
    return 10;
  };

  return (
    <div className="svgDonutWrapper">
      {
        ( isNotEmpty( sortedData ) && isNotEmpty( total ) ) &&
        <svg viewBox={ `0 0 ${viewBox} ${viewBox}` } xmlns="http://www.w3.org/2000/svg" className="donutChart" >
          {/* background circle */}
          <circle
            cx="50%"
            cy="50%"
            r={ radius }
            strokeWidth={ getStrokeWidth() }
            stroke="#F7F7FA"
            fill="none"
          />
          {/* map through each data value and create a donut segment */}
          {
            total > 0 && sortedData.map( ( data, index ) => {
              // adjusting the percentage to fill a certain circumference and subtracting 1 to create a gap
              let arc = ( data.value * 2 ) - ( strokeWidth === 'pie' ? 0 : 0.5 );
              if ( arc < 0 ) {
                arc = 0;
              }

              const dashArrayGap = 200;
              const dashArrayOffset = 60;

              let prev = 0;
              // looping through all the previous circumferences and adding them all up to get the offset each
              sortedData.map( ( _data, _index ) => {
                if ( _index < index ) {
                  prev += _data.value;
                }
              } );

              return <circle
                // eslint-disable-next-line max-len
                className={ `${strokeWidth} donutSegment ${ isFaded( data ) ? 'isFaded' : '' } ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ isNotEmpty( setSelectedSeriesIdentifier ) ? 'isClickable' : '' } ${ hoveredSeriesIdentifier === data.key ? 'isHovered' : '' } ${ alreadySelected( data.key ) ? 'isSelected' : '' }` }
                key={ index }
                cx="50%"
                cy="50%"
                r={ radius }
                // eslint-disable-next-line max-len
                strokeWidth={ ( hoveredSeriesIdentifier === data.key || alreadySelected( data.key ) ) && strokeWidth !== 'pie' ? getStrokeWidth() + 5 : getStrokeWidth() }
                fill="none"
                stroke={ data.stroke }
                strokeDasharray={ `${ arc } ${ dashArrayGap - arc }` }
                strokeDashoffset={ `${ dashArrayOffset - ( prev * 2 ) }` }
                onMouseEnter={ () => handleMouseEnter( data ) }
                onMouseLeave={ handleMouseLeave }
                onClick={ () => handleMouseClick( data ) }

              />;
            } )
          }
          {
            displayLabel &&
            <React.Fragment>
              <text
                x="50%"
                y="50%"
                fontSize="12"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill={
                  displayLabelOverride
                    ? displayLabelOverride.fill
                    : Object.values( data )[0].stroke
                }
              >
                {
                  displayLabelOverride
                    ? displayLabelOverride.value
                    : formatNumber( Object.values( data )[0].rawValue )
                }
              </text>
            </React.Fragment>
          }
          {
            subLabel &&
            <React.Fragment>
              <text
                x="50%"
                y="65%"
                fontSize="5"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill={ globalColors['grey']}
              >
                { subLabel }
              </text>
            </React.Fragment>
          }
        </svg>
      }
    </div>
  );
};

export default Donut;