/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import CVSSChart from '../../../../../shared/Charts/CVSSChart';

import './CVSSBreakdown.scss';
import Legend from '../../../../../shared/Charts/Legend';
import Donut from '../../../../../shared/Charts/Donut';
import Categories from '../../../../../shared/Charts/Categories';

const CVSSBreakdown = ( { data, settings } ) => {

  const order = [
    'Critical',
    'High',
    'Moderate',
    'Low',
    'Minimal',
  ];

  const minMax = {
    critical: {
      maximum: 10,
      minimum: 9,
    },
    high: {
      maximum: 9,
      minimum: 7.5,
    },
    moderate: {
      maximum: 7.5,
      minimum: 6,
    },
    low: {
      maximum: 6,
      minimum: 3,
    },
    minimal: {
      maximum: 3,
      minimum: 0,
    },
  };

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = data => {
    if ( isNotEmpty( data?.results?.cvss ) ) {
      const _chartData = {};
      const _counts = {
        critical: data.results.cvss.critical,
        high: data.results.cvss.high,
        moderate: data.results.cvss.moderate,
        low: data.results.cvss.low,
        minimal: data.results.cvss.minimal,
      };

      const _total = data.results.cvss.critical
        + data.results.cvss.high
        + data.results.cvss.moderate
        + data.results.cvss.low
        + data.results.cvss.minimal;

      const zeroed = _total === 0;

      order.map( name => {

        let _value = _counts[name.toLowerCase()];

        if ( settings?.version === 'donut' ) {
          _value = zeroed ? zeroed : ( ( _counts[name.toLowerCase()] / _total ) * 100 );
        }
        _chartData[name.toLowerCase()] = {
          key: name.toLowerCase(),
          label: name,
          // eslint-disable-next-line max-len
          fill: settings?.version === 'horizontal' ? globalColors[`${name.toLowerCase()}--75`] : globalColors[name.toLowerCase()],
          // eslint-disable-next-line max-len
          stroke: settings?.version === 'horizontal' ? globalColors[`${name.toLowerCase()}--75`] : globalColors[name.toLowerCase()],
          total: _counts[name.toLowerCase()],
          value: _value,
          subLabel: `${ minMax[name.toLowerCase()].maximum} - ${ minMax[name.toLowerCase()].minimum}`,
        };
      } );
      setTotal( _total );
      setChartData( _chartData );
    }
  };

  React.useEffect( ( ) => {
    setupData( data );
  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && settings?.version === 'horizontal' ) &&
        <Categories
          data={ chartData }
          total={ total }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'barchart' ) &&
        <div className="chartWrapper">
          <CVSSChart
            cvssData={chartData}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </div>

      }
      {
        ( isNotEmpty( chartData ) && settings?.version === 'donut' && isNotEmpty( total ) ) &&
        <Donut
          data={chartData}
          total={total}
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
      {
        isNotEmpty( chartData ) &&
        <Legend
          horizontal={ settings?.version === 'horizontal' }
          legendData={ chartData }
          hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
          setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
        />
      }
    </React.Fragment>
  );
};

export default CVSSBreakdown;