/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, percentileToGrade } from '../../../../../shared/Utilities';
import EmptyLoading from '../../../../../shared/EmptyLoading';

import './RiskGrade.scss';
import { RiskContext } from '../../../../../Contexts/Risk';

const RiskGrade = ( { settings } ) => {

  const [ loading, setLoading ] = React.useState( false );

  const [ , , peerPercentile ] = React.useContext( RiskContext );

  const getFillClass = percentile => {
    if ( percentile <= 0.25 ) {
      return 'high';
    }
    if ( percentile <= 0.5 ) {
      return 'low';
    }
    if ( percentile <= 0.75 ) {
      return 'minimal';
    }
    return 'primaryBlue';
  };

  const setupData = async () => {
    setLoading( true );

    setLoading( false );
  };

  React.useEffect( () => {
    setupData();
  }, [ settings, peerPercentile ] );

  return (
    <React.Fragment>
      <EmptyLoading
        payload={ peerPercentile }
        loading={ loading }
        loadingMessage="Loading data"
        emptyMessage="No risk data"
      />
      {
        ( isNotEmpty( peerPercentile ) ) &&
        <div className={ `riskGradeWidgetWrapper risk--${getFillClass( peerPercentile )}` }>
          <div className="riskGradeContentWrapper">
            <div className="scoreCircle">
              <strong className="gradeWrapper">{ percentileToGrade( peerPercentile ) }</strong>
            </div>
            <span className="chartLabel">Risk Grade</span>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default RiskGrade;