/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import ExploitStatusBreakdown from '../../shared/Charts/ExploitStatusBreakdown';

const ExploitStatusBreakdownSection = ( { recordInstanceData } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( recordInstanceData ) &&
        <div className="recordDetails__InformationPanel__Body_Section exploitStautsBreakdown">
          <div className="bodySectionItem">
            <label>Exploit Status Breakdown</label>
            <ExploitStatusBreakdown instanceData={recordInstanceData} />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ExploitStatusBreakdownSection;