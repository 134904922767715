/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InstancesBreakdown from '../../shared/Charts/InstancesBreakdown';
import { isNotEmpty, riskToRating } from '../../shared/Utilities';

const VulnerabilityInstancesBreakdown = ( { record, recordType, recordInstanceData, riskType } ) => {

  const [ riskClass, setRiskClass ] = React.useState( 'default' );
  // eslint-disable-next-line
  const [ loading, setLoading ] = React.useState( false );
  const svgWrapperRef = React.useRef( null );

  React.useEffect( () => {
    if (
      isNotEmpty( record )
      && isNotEmpty( recordType )
      && isNotEmpty( recordInstanceData )
      && isNotEmpty( riskType )
    ) {
      if ( recordType === 'host' && record.has_host === false ) {
        setRiskClass( 'unknown' );
      } else {
        setRiskClass( riskToRating( record[riskType] ) );
      }
    }
  }, [ record, recordType, recordInstanceData ] );

  return (
    <React.Fragment>
      <InstancesBreakdown
        reportType={recordType}
        itemID={record.id}
        instanceData={ recordInstanceData?.tally }
        svgRef={ svgWrapperRef }
        riskRating={ riskClass }
        loading={ loading }
      />
    </React.Fragment>
  );
};

export default VulnerabilityInstancesBreakdown;