/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import SetupPage from '../../../shared/SetupComponents/SetupPage';

import {
  isNotDuplicate,
} from '../../../shared/Form/Validators';

import {
  isNotEmpty,
} from '../../../shared/Utilities';

import {
  recordData,
} from './data';
import RegistrationModal from './RegistrationModal';
import { getFieldValues } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';

const SubordinateScanners = () => {

  const EMPTY_FIELDS = [
    {
      type: 'text',
      label: 'Name',
      attribute: 'label',
      required: true,
      validators: [ isNotDuplicate ],
      duplicateTestArray: [],
      defaultValue: '',
    },
  ];

  const [ scanners, setScanners ] = React.useState( [] );
  const [ scannerToRegister, setScannerToRegister ] = React.useState( {} );

  const [ showModal, setShowModal ] = React.useState( false );
  const [ fields, setFields ] = React.useState( null );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // sets up and clears polling to get latest up to date status
  React.useEffect( () => {
    // eslint-disable-next-line
    let interval;
    clearInterval( interval );
    onRefresh();
    interval = setInterval( onRefresh, 10000 );
    return () => clearInterval( interval );
  }, [] );

  const onRefresh = () => {
    makeRequest( 'FIND', '/subordinate_scanner', {} ).then( subResponse => {

      if ( subResponse && subResponse['results'] ) {
        const names = [];
        const subScannerMap = {};
        subResponse.results.map( scanner => {
          names.push( scanner.label );
          subScannerMap[scanner.id] = [];
        } );

        EMPTY_FIELDS[0].duplicateTestArray = names;

        makeRequest( 'FIND', '/project/default/scan_group', {} ).then( sgResponse => {
          if ( sgResponse && sgResponse.results ) {
            const scanGroups = sgResponse.results;
            scanGroups.map( sg => {
              if ( isNotEmpty( sg.subordinate_scanner ) ) {
                subScannerMap[sg.subordinate_scanner].push( sg.label );
              } else {
                subScannerMap['00000000-0000-0000-0000-000000000000'].push( sg.label );
              }
            } );
            subResponse.results.map( scanner => {
              scanner.scanGroupLabels = subScannerMap[scanner.id];
            } );
          }
          setFields( EMPTY_FIELDS );
          setScanners( subResponse.results );
        } );
      } else {
        setFields( EMPTY_FIELDS );
        setScanners( [] );
      }
    } );
  };

  const showRegistrationModal = scanner => {
    setScannerToRegister( scanner );
    setShowModal( true );
  };

  const onSave = (
    scanner,
    isValid,
    fieldStates,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const includedValues = getFieldValues( fieldStates, 'subordinate_scanner' );

      const record = {
        label: includedValues.label,
      };

      if ( scanner && scanner.id ) {
        // we are updating, add the id
        record.id = scanner.id;
      }

      makeRequest( 'UPSERT', '/subordinate_scanner', { records: [ record ] } ).then( response => {
        if ( scanner && scanner.id ) {
          console.log( 'existing scanner, no need to register' );
        } else {
          showRegistrationModal( response.results[0] );
        }

        onRefresh();

        if ( response['errors'] ) {
          addFlashMessage( {
            body: response['errors'],
            type: 'alert',
          } );
        } else {
          addFlashMessage( {
            body: 'Successfully saved subordinate scanner',
            type: 'success',
          } );
        }
      } );
    }
  };

  return (
    <React.Fragment>
      <RegistrationModal
        showRegistrationModal={showRegistrationModal}
        scannerToRegister={scannerToRegister}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      {
        isNotEmpty( fields ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={scanners}
          setRecords={setScanners}
          recordType="subordinate_scanner"
          recordData={recordData}
          useForm
          fields={fields}
          showRegistrationModal={showRegistrationModal}
          alternateItemLayout
        />
      }
    </React.Fragment>
  );
};

export default SubordinateScanners;
