/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import {
  searchParamsForResultType,
  getResultsForType,
} from './SearchData';

import {
  debounce, isEmpty, isNotEmpty,
} from '../../../../Utilities';
import InlineSVG from '../../../../InlineSVG';
import InlineSelectedItem from './InlineSelectedItem';

const SearchBar = ( {
  field,
  onChange,
  formState,
  recordType,
  setResults,
  fieldRef,
  alreadySelectedItems,
  setLoading,
  setNoResults,
  setItems,
  setShouldShowResults,
} ) => {

  // fetches results from the server and sets them
  const fetchSearchResults = async ( type, query, alreadySelectedItems, additionalSearchParams, additional ) => {
    setShouldShowResults( true );

    const response = await getResultsForType(
      type,
      searchParamsForResultType( type, query, alreadySelectedItems, additionalSearchParams, additional ),
    );

    return response;
  };

  // debounces a fetch of search results, so it does not hammer the server
  const debounceSearchInput = debounce( query => {
    setLoading( true );
    setNoResults( false );
    if ( query === '' ) {
      setResults( [] );
      setLoading( false );
    }
    if ( query !== '' && recordType !== '' ) {
      fetchSearchResults(
        recordType,
        query,
        alreadySelectedItems,
        field.additionalSearchParams,
        field.additionalParams,
      ).then( response => {
        setResults( response );
        setLoading( false );
        if ( isEmpty( response ) ) {
          setNoResults( true );
          setLoading( false );
        }
      } );
    }
  }, 500 );

  // removes item from group
  const removeItem = item => {

    const _items = { ...alreadySelectedItems };
    delete _items[item.id];

    setItems( _items );

    if ( isNotEmpty( _items ) ) {
      onChange( field, Object.keys( _items ) );
    } else {
      onChange( field, [] );
    }
  };

  return (
    <div className={ `searchFieldWrapper ${field?.inlineVersion ? 'inlineVersion' : ''}` }>
      <InlineSVG type="search" elementClass="searchIcon" />
      <input
        disabled={field.disabled || formState.fieldStates[field.attribute]?.disabled}
        ref={fieldRef}
        type="search"
        onChange={ e => debounceSearchInput( e.target.value )}
        placeholder={ field.placeholder }
      />
      {
        ( isNotEmpty( alreadySelectedItems ) && field?.inlineVersion ) &&
        <ul className="selectedItemsInline">
          {
            Object.entries( alreadySelectedItems ).map( ( [ id, label ], index ) => {
              return <InlineSelectedItem label={label} id={id} key={index} callback={removeItem} />;
            } )
          }
        </ul>
      }
    </div>
  );
};

export default SearchBar;