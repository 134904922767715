/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import RecordList from './RecordList';
import FormModal from './FormModal';

import './style.scss';

import {
  focusForOnboarding, getGlobalSettings, isNotEmpty,
} from '../Utilities';

import SetupPageHeader from './SetupPageHeader';
import SetupPageVisualization from './SetupPageVisualization';
import IPConnectivityTest from '../../components/Scanning/IPConnectivityTest';
import IPinfoModal from '../../components/Scanning/ScanGroups/IPinfoModal';

const SetupPage = ( {
  onRefresh,
  onSave,
  records,
  onReorder,
  recordType,
  providers,
  credentials,
  recordData={},
  showRegistrationModal={},
  isDraggable=false,
  modalClass='',
  asCard=false,
  alternateItemLayout=false,
  users=null,
  osLabels=null,
  fields={fields},
  allowEnterSubmit,
  formChangeCallBack,
  activeIntegrations,
  externalUsers,
  assetTagOptions={},
  noCreate=false,
  externalLoading=false,
} ) => {

  const [ showModal, setShowModal ] = React.useState( false );
  const [ isValid, setIsValid ] = React.useState( true );
  const [ selectedRecord, setSelectedRecord ] = React.useState( {} );
  const [ notifications, setNotifications ] = React.useState( [] );
  // for some views, we need to keep track of any active integrations (jira, etc. )
  const [ globalSettings, setGlobalSettings ]= React.useState( {} );
  const [ loading, setLoading ] = React.useState( true );

  const [ showIPModal, setShowIPModal ] = React.useState( false );
  const [ showConnectionModal, setShowConnectionModal ] = React.useState( false );

  const loadItems = async () => {
    setLoading( true );
    await onRefresh();
    setLoading( false );
  };

  // this gets called when a child item needs to set a top-level notification on the page
  const notificationsCallback = _notifications => {
    if ( isNotEmpty( _notifications ) ) {
      setNotifications( _notifications );
    } else {
      setNotifications( [] );
    }
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( recordType ) && recordType === 'authentication_provider' ) {
      getGlobalSettings( 'global' ).then( response => {
        setGlobalSettings( response );
      } );
    }
  }, [ recordType ] );

  // when closing the modal, reload so all the items are present
  React.useEffect( () => {
    if ( showModal === false && recordType === 'remediation_plan' ) {
      loadItems();
    }
  }, [ showModal ] );

  // on page load, grab any existing records and refresh the list
  React.useEffect( () => {
    loadItems();
  }, [ providers, credentials ] );

  // whenever the modal closes, clear the selected record
  React.useEffect( () => {
    if ( showModal === false ) {
      setSelectedRecord( {} );
    }
  }, [ showModal ] );

  React.useEffect( () => {
    if ( recordData && recordData.onboarding ) {
      const elementToFocus = document.getElementById( 'newItemButton' );
      focusForOnboarding( elementToFocus );
    }
  }, [ recordData ] );

  return (
    <div className={`setupPageContainer ${recordType}`} >
      {
        recordType === 'scan_group' &&
        <React.Fragment>
          <IPConnectivityTest
            scanGroups={[ selectedRecord ]}
            ipAddresses={[]}
            show={showConnectionModal}
            setShow={setShowConnectionModal}
          />
          <IPinfoModal
            record={selectedRecord}
            show={showIPModal}
            setShow={setShowIPModal}
          />
        </React.Fragment>
      }
      {
        !noCreate &&
        <FormModal
          recordType={recordType}
          showModal={showModal}
          setShowModal={setShowModal}
          isValid={isValid}
          setIsValid={setIsValid}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          allowEnterSubmit={allowEnterSubmit}
          onSave={onSave}
          modalClass={modalClass}
          users={users}
          fields={fields}
          formChangeCallBack={formChangeCallBack}
          activeIntegrations={activeIntegrations}
          notificationsCallback={notificationsCallback}
          assetTagOptions={assetTagOptions}
          externalUsers={externalUsers}
          externalLoading={externalLoading}
          onRefresh={onRefresh}
          osLabels={osLabels}
        />
      }
      <SetupPageHeader
        setSelectedRecord={setSelectedRecord}
        setShowModal={setShowModal}
        records={records}
        recordData={recordData}
        onRefresh={onRefresh}
        recordType={recordType}
        noCreate={noCreate}
        notifications={notifications}
        globalSettings={globalSettings}
        setGlobalSettings={setGlobalSettings}
      />
      <SetupPageVisualization
        records={records}
        recordData={recordData}
        onRefresh={onRefresh}
        recordType={recordType}
      />

      <RecordList
        records={records}
        onReorder={onReorder}
        recordType={recordType}
        providers={providers}
        credentials={credentials}
        setSelectedRecord={setSelectedRecord}
        setShowModal={setShowModal}
        onRefresh={onRefresh}
        recordData={recordData}
        isDraggable={isDraggable}
        showRegistrationModal={showRegistrationModal}
        asCard={asCard}
        alternateItemLayout={alternateItemLayout}
        loading={loading}
        setLoading={setLoading}
        users={users}
        globalSettings={globalSettings}
        setGlobalSettings={setGlobalSettings}
        notificationsCallback={notificationsCallback}
        activeIntegrations={activeIntegrations}
        externalUsers={externalUsers}
        assetTagOptions={assetTagOptions}
        setShowIPModal={setShowIPModal}
        setShowConnectionModal={setShowConnectionModal}
      />
    </div>
  );
};

export default SetupPage;
