/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './style.scss';
const Accordion = ( {
  children,
} ) => {
  return (
    <React.Fragment>
      { children }
    </React.Fragment>
  );
};

export default Accordion;
