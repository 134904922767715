/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, globalColors, isNotEmpty } from '../../Utilities';

import './style.scss';
// import InlineSVG from '../../InlineSVG';
import Loading from '../../Loading';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp';
import Categories from '../Categories';
import Legend from '../Legend';
import {
  categoryLabelsAndDescription, parentCategoryKeys,
} from '../../../components/Reporting/Dashboards/Widgets/v2/VulnerabilityInstancesCategories';

const InstancesBreakdown = ( {
  reportType,
  itemID,
  riskRating,
  instanceData,
  svgRef,
  thirdPartyVersion=false,
  loading=false,
} ) => {

  const [ categoryData, setCategoryData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const [ selectedSeriesIdentifier, setSelectedSeriesIdentifier ] = React.useState( null );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  React.useEffect( ( ) => {

    const _categories = [];

    if (
      isNotEmpty( instanceData )
      && isNotEmpty( instanceData.results )
      && isNotEmpty( instanceData.results._total )
      && isNotEmpty( instanceData.results.category )
    ) {
      const { _total } = instanceData.results;

      // for vuln. reports we are going to take any non-zero category
      if ( reportType === 'vulnerability' ) {

        Object.entries( categoryLabelsAndDescription ).map( ( [ categoryKey, categoryData ] ) => {

          if ( !parentCategoryKeys.includes( categoryKey ) ) {
            const _categoryFromServerValue = instanceData.results.category[categoryKey];

            if ( isNotEmpty( _categoryFromServerValue ) && _categoryFromServerValue !== 0 ) {
              _categories.push(
                {
                  key: categoryKey,
                  label: <React.Fragment>{ categoryData.label } <HelpTrigger helpKey={categoryKey} /></React.Fragment>,
                  value: _categoryFromServerValue,
                  total: _categoryFromServerValue,
                  fill: categoryData.fill,
                },
              );
            }
          }
        } );
      // otherwise just summarize the 3 parent categories
      } else {
        _categories.push(
          {
            key: 'prioritized',
            label: <React.Fragment>Prioritized <HelpTrigger helpKey="prioritized" /></React.Fragment>,
            value: instanceData.results.category.prioritized,
            total: instanceData.results.category.prioritized,
            fill: globalColors['status--red--60'],
          },
        );
        _categories.push(
          {
            key: 'for_review',
            label: <React.Fragment>For Review <HelpTrigger helpKey="for_review" /></React.Fragment>,
            value: instanceData.results.category.for_review,
            total: instanceData.results.category.for_review,
            fill: globalColors['status--red--20'],
          },
        );
        _categories.push(
          {
            key: 'deprioritized',
            label: <React.Fragment>Deprioritized <HelpTrigger helpKey="deprioritized" /></React.Fragment>,
            value: instanceData.results.category.deprioritized,
            total: instanceData.results.category.deprioritized,
            fill: globalColors['status--green--50'],
          },
        );
      }

      setCategoryData( _categories );
      setTotal( _total );
    }
  }, [ instanceData, riskRating, reportType ] );

  const handleCategoryClick = category => {
    if (
      isNotEmpty( category )
      && isNotEmpty( reportType )
      && isNotEmpty( itemID )
    ) {
      const groupType = reportType === 'host' ? 'vulnerability' : 'host';

      // eslint-disable-next-line max-len
      window.open( `#.=risk_insight&report=instances&group_type=${groupType}&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1&accepted_risk=false&${reportType}_ids=%5B"${itemID}"%5D&category=${category}` );
    }
  };

  return (
    <React.Fragment>
      { loading && <Loading minimalVariant /> }
      {
        isNotEmpty( svgRef ) &&
        <div className="instancesBreakdownWrapper" ref={svgRef} >
          <h3>
            <label>
              {
                reportType === 'vulnerability'
                  ? 'Vulnerability Instances Breakdown'
                  : 'Vuln. Instances Breakdown'
              }
            </label>
            {/* eslint-disable-next-line max-len */}
            <strong><span>Total: </span><span className="totalValue" >{ formatNumber( total ) }</span></strong>
          </h3>
          {
            ( isNotEmpty( categoryData ) && isNotEmpty( total ) && total !== 0 )
              ? <Categories
                data={ categoryData }
                total={ total }
                selectedSeriesIdentifier={ selectedSeriesIdentifier }
                setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
                hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
                setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
                onClickCallback={ handleCategoryClick }
              />
              : <div className="emptyInstances" />
          }
          {
            !thirdPartyVersion &&
            <Legend
              legendData={ categoryData }
              horizontal
              canFilter={ false }
              selectedSeriesIdentifier={ selectedSeriesIdentifier }
              setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
              hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
              setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
              onClickCallback={ handleCategoryClick }
            />
          }
        </div>
      }

    </React.Fragment>
  );
};

export default InstancesBreakdown;