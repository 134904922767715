/** *************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './Buttons.scss';
import { isNotEmpty, paramsToFilters } from '../../Utilities';

const Buttons = ( {
  input,
  value,
  handleChange,
} ) => {

  const [ buttonValue, setButtonValue ] = React.useState( null );

  const handleButtonClick = ( e, value=null ) => {
    let _value;

    if ( isNotEmpty( e ) ) {
      e.preventDefault();
      e.stopPropagation();
      _value = e.target.valueAsNumber || e.target.value;
    }

    if ( isNotEmpty( value ) ) {
      _value = value;
    }

    setButtonValue( _value );

    if ( _value === 'all' ) {
      _value = '';
    }

    handleChange( input.attribute, _value );
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();

    if ( isNotEmpty( filterValues ) ) {
      const _value = filterValues[input.attribute];

      setButtonValue( isNotEmpty( _value ) ? _value : 'all' );

      handleChange( input.attribute, _value );
    }
  };

  // sets up the hashchange listener
  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input ] );

  return (
    <React.Fragment>
      <span className="labelWrapper">
        { input.label }
        {
          input.required &&
          <span className="required">*</span>
        }
        { input.helpItem }
      </span>
      {
        ( isNotEmpty( input ) && isNotEmpty( input.options ) ) &&
        <div className="buttonsWrapper">
          {
            Object.entries( input.options ).map( ( [ key, value ], index ) => {
              return <button
                className={ `buttonsInputButton ${key} ${ buttonValue === key ? 'selected' : ''}`}
                key={ index }
                onClick={ e => handleButtonClick( e, key ) }
              >
                { value.label }
              </button>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Buttons;