/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty, riskToRating } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';

import './RecordStatistic.scss';
import ExploitStatus from '../../shared/ExploitStatus';

const RecordStatistic = ( {
  record,
  statisticsKey,
  recordInstanceData,
  riskType,
  // recordType,
} ) => {

  const [ content, setContent ] = React.useState( null );

  const labelForStat = {
    /* eslint-disable camelcase */
    risk_reduction: 'Risk Reduction',
    vulnerability_instances: 'Vuln. Instances',
    patches: 'All Patches',
    unsuperseded_patches: 'Unsuperseded Patches',
    risk_rating: 'Risk Rating',
    sensitive_nodes: 'Sensitive Assets',
    vulnerabilities: 'Vulnerabilities',
    exploit_status: 'Exploit Status',
    hosts: 'Affected Hosts',
    active_hosts: 'Recently Accessed Hosts',
    domain_groups: 'Domain Groups',
    steps: 'Steps Required',
    num_vulnerabilities: 'Vulnerabilities Exploited',
    /* eslint-enable camelcase */
  };

  const iconForStat = {
    /* eslint-disable camelcase */
    risk_reduction: <React.Fragment></React.Fragment>,
    vulnerability_instances: <InlineSVG type="vulnerabilitiesAlt" />,
    patches: <InlineSVG type="patchesAlt" />,
    unsuperseded_patches: <InlineSVG type="patchesAlt" />,
    risk_rating: <React.Fragment></React.Fragment>,
    sensitive_nodes: <InlineSVG type="folderAlt" />,
    vulnerabilities: <InlineSVG type="vulnerabilitiesAlt" />,
    exploit_status: <React.Fragment></React.Fragment>,
    hosts: <InlineSVG type="hostsAlt" />,
    active_hosts: <InlineSVG type="hostsAlt" />,
    domain_groups: <InlineSVG type="domain_group" />,
    steps: <InlineSVG type="segmentsAlt" />,
    num_vulnerabilities: <InlineSVG type="vulnerability_record" />,
    /* eslint-enable camelcase */
  };

  const needsAlertIcon = () => statisticsKey === 'sensitive_nodes' && getAttributeForStat[statisticsKey]() !== '0';

  const getAttributeForStat = {
    /* eslint-disable camelcase */
    vulnerability_instances: () => formatNumber( recordInstanceData?.tally?.results?._total ),
    patches: () => formatNumber( record.num_patches ),
    unsuperseded_patches: () => formatNumber( record.num_unsuperseded_patches ),
    sensitive_nodes: () => formatNumber( record.num_sensitive_nodes ),
    vulnerabilities: () => formatNumber( record.num_vulnerabilities ),
    exploit_status: () => <ExploitStatus status={ record.exploit_status } />,
    active_hosts: () => formatNumber( record.active_hosts?.length ),
    domain_groups: () => formatNumber( record.domain_groups?.length ),
    hosts: () => formatNumber( record.num_hosts ),
    steps: () => formatNumber( record.edges?.length || 0 ),
    num_vulnerabilities: () => formatNumber( record.pathTopVulnerabilities?.length || 0 ),
    /* eslint-enable camelcase */
  };

  // pulling this out so that I don't have to repeat it down below
  const statContent = ( item, stat, riskType ) => {
    return (
      <React.Fragment>
        <span className="statisticLabel">
          { labelForStat[stat] }
        </span>
        {/* eslint-disable-next-line max-len */}
        <span className={ `statisticValue ${stat} ${riskToRating( item[riskType] )} ${needsAlertIcon() ? 'needsAlert' : ''}` }>
          { iconForStat[stat] }
          { getAttributeForStat[stat]() }
        </span>
      </React.Fragment>
    );
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) ) {
      setContent( statContent( record, statisticsKey, riskType ) );
    }
  }, [ record, statisticsKey, riskType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( statisticsKey ) ) &&
        <div className={ `recordStatisticWrapper ${statisticsKey}` }>
          { isNotEmpty( content ) && content }
        </div>
      }
    </React.Fragment>
  );
};

export default RecordStatistic;