/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../Utilities';

const XAxisLabels = ( { labels, variant='bar', elementClass='', withTic=false, above=false } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( labels ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${elementClass} axisContainer xAxis ticCountClass--${variant === 'area' ? labels.length - 1 : labels.length } ${variant}` }
        >
          {
            labels.map( ( label, i ) => {
              return <div
                // eslint-disable-next-line max-len
                className={ `${ above ? 'above' : '' } ${withTic ? 'withTic' : ''} axisLabel ${i === labels.length - 1 ? 'last' : ''}`}
                key={i}
              >
                { label }
              </div>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default XAxisLabels;