/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, formatNumber, isNotEmpty } from '../../../../../shared/Utilities';

import './RecordTotal.scss';
import InlineSVG from '../../../../../shared/InlineSVG';

const RecordTotal = ( { data, settings } ) => {

  const [ total, setTotal ] = React.useState( null );

  const setupData = data => {
    if (
      isNotEmpty( settings?.report_type )
      && isNotEmpty( data )
      && isNotEmpty( data.results )
      && isNotEmpty( data.results[settings?.report_type] )
    ) {
      setTotal( data.results[settings?.report_type].total );
    } else {
      setTotal( null );
    }
  };

  React.useEffect( () => {
    if ( data ) {
      setupData( data );
    }
  }, [ data, settings ] );


  return (
    <React.Fragment>
      {
        ( isNotEmpty( total ) && isNotEmpty( settings ) && isNotEmpty( settings.report_type ) ) &&
        <div className="bigNumberWrapper">
          <h3>{ capitalize( settings.report_type ) }</h3>
          <h2>
            <InlineSVG type={`${settings.report_type}Alt`} />
            { formatNumber( total ) }
          </h2>
          <span>
            {
              settings?.report_type === 'patches'
                ? 'need to be applied in your environment'
                : 'found in your environment'
            }
          </span>
        </div>
      }
    </React.Fragment>
  );
};

export default RecordTotal;