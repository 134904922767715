/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import InlineSVG from '../InlineSVG';
import { isNotEmpty } from '../Utilities';

import './style.scss';

const Dialog = ( {
  type='confirm',
  visible,
  setVisible,
  content=<React.Fragment></React.Fragment>,
  action=null,
  elementClass='',
  onCancel=() => {},
} ) => {

  const dialogRoot = document.getElementById( 'reactDialogPortal' );

  const handleConfirmation = () => {
    action();
    setVisible( false );
  };

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        type === 'confirm' &&
        <div
          className={ `${ visible ? 'visible' : '' } dialogMask` }
          onClick={ () => setVisible( false ) }
        />
      }
      <div className={ `${ visible ? 'visible' : '' } dialogContainer ${type} ${elementClass}` } >
        {
          isNotEmpty( content ) &&
          <div className="dialogContentWrapper">
            {
              type === 'confirm' &&
              <InlineSVG type="notificationAlert" />
            }
            {
              type === 'info' &&
              <InlineSVG type="notificationInfo" />
            }
            <div className="column">{ content }</div>
          </div>
        }
        <div className="dialogActions">
          {
            isNotEmpty( action )
              ? <React.Fragment>
                <button
                  onClick={
                    () => {
                      setVisible( false );
                      onCancel();
                    }
                  }
                  className="cancelButton"
                >
                  Cancel
                </button>
                <button onClick={ handleConfirmation } className="confirmButton">Okay</button>
              </React.Fragment>
              : <React.Fragment>
                {
                  type === 'confirm'
                    ? <button onClick={ () => setVisible( false ) } className="confirmButton">Okay</button>
                    : <button
                      onClick={
                        () => {
                          setVisible( false );
                          onCancel();
                        }
                      }
                      className="cancelButton"
                    >
                      Cancel
                    </button>
                }
              </React.Fragment>

          }
        </div>
      </div>
    </React.Fragment>,
    dialogRoot,
  );
};

export default Dialog;