/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatUnixDate } from '../../../../shared/Utilities';

import './CertificateItem.scss';

const CertificateItem = ( { certificate } ) => {
  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2>{ certificate.identity }</h2>
          <span>
            <strong>Type: </strong>
            { certificate.type } Certificate
          </span>
        </div>
      </section>
      <section>
        <strong className="sectionLabel">
          Purpose
        </strong>
        <span className="sectionDetails">
          {
            // eslint-disable-next-line max-len
            `${certificate.purpose === '*' ? 'all' : certificate.purpose} / ${certificate.subpurpose === '*' ? 'all' : certificate.subpurpose}`
          }
        </span>
      </section>
      <section>
        <strong className="sectionLabel">
          Added On
        </strong>
        <span className="sectionDetails">{ formatUnixDate( certificate.created ) }</span>
      </section>
    </React.Fragment>
  );
};

export default CertificateItem;