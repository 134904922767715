/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';

import './PolicyItem.scss';
import { buildPolicySentence, policyName } from './shared';

const PolicyItem = ( { policy, assetTagOptions, index } ) => {

  const [ policyWithSentence, setPolicyWithSentence ] = React.useState( null );

  React.useEffect( ( ) => {
    const sentence = buildPolicySentence( policy, assetTagOptions );
    setPolicyWithSentence( { ...policy, sentence } );

  }, [ policy, assetTagOptions ] );
  return (
    <React.Fragment>
      {
        isNotEmpty( policyWithSentence ) &&
        <React.Fragment>
          <section className="mainDetailsSection">
            <div className="orderContainer">
              <strong>
                Priority
              </strong>
              <span>
                { index + 1 }
              </span>
            </div>
            <div className="column">
              <h2>{ policyName( policy ) }</h2>
              <span className="policySentenceWrapper">
                {
                  policyWithSentence.sentence.map( ( s, i ) => {
                    return <React.Fragment key={i}>{ s }</React.Fragment>;
                  } )
                }
              </span>
            </div>
          </section>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PolicyItem;