/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './Toggle.scss';

const Toggle = ( {
  options,
  toggled,
  setToggled,
  elementClass='',
  toggleCallback=() => {},
} ) => {

  const handleToggle = key => {
    setToggled( key );
    toggleCallback();
  };

  return (
    <React.Fragment>
      <div className={ `toggleWrapper ${elementClass}`}>
        {
          Object.entries( options ).map( ( [ key, label ], index ) => {
            return <button
              className={ `toggleButton ${ toggled === key ? 'toggled' : '' }` }
              key={index}
              onClick={ () => handleToggle( key ) }>
              { label }
            </button>;
          } )
        }
      </div>
    </React.Fragment>
  );
};

export default Toggle;