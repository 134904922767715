/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyLoading from '../../../shared/EmptyLoading';
import DefaultVisual from './DefaultVisual';
import InstanceVisual from './InstanceVisual';

import './style.scss';

const InsightVisual = ( {
  collapsed,
  // setCollapsed,
  reportType,
  loading=false,
  visualData=null,
  records=null,
  hoverRecord=null,
  selectRecord=() => {},
  setHoverRecord=() => {},
  onRefresh=() => {},
  riskType='risk',
  visualMode='categories',
} ) => {
  return (
    <div className={ `riskInsightVisualWrapper ${collapsed ? 'collapsed' : ''} ${reportType}`}>
      {
        reportType === 'instance'
          ? <React.Fragment>
            <EmptyLoading
              loading={loading}
              loadingMessage="Loading..."
              payload={visualData}
              emptyMessage="No data available"
            />
            <InstanceVisual
              visualData={visualData}
              collapsed={collapsed}
              loading={loading}
              onRefresh={onRefresh}
              visualMode={visualMode}
            />
          </React.Fragment>
          : <React.Fragment>
            <EmptyLoading
              loading={loading}
              loadingMessage="Loading..."
              payload={records}
              emptyMessage="No data available"
            />
            <DefaultVisual
              records={records}
              reportType={reportType}
              collapsed={collapsed}
              selectRecord={selectRecord}
              hoverRecord={hoverRecord}
              setHoverRecord={setHoverRecord}
              riskType={riskType}
            />
          </React.Fragment>
      }
    </div>
  );
};

export default InsightVisual;