/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../Utilities';
import InlineSVG from '../../../../InlineSVG';

const InlineSelectedItem = ( { id, label, callback } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( label ) &&
        <li className="inlineSelectedItemWrapper" onClick={ () => callback( { id, label } ) }>
          <span>{ label }</span>
          <span className="removeButton" >
            <InlineSVG type="remove" />
          </span>
        </li>
      }
    </React.Fragment>
  );
};

export default InlineSelectedItem;