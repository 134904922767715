/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { capitalize, formatNumber, isNotEmpty } from '../Utilities';
import InlineSVG from '../InlineSVG';

const LegendOverflowModal = ( {
  legendData,
  showAllItems,
  setShowAllItems,
  label,
  isFaded,
  setHoveredSeriesIdentifier,
  hoveredSeriesIdentifier,
  alreadySelected,
  handleMouseClick,
  handleMouseEnter,
  handleMouseLeave,
  listItemBackground,
  setSelectedSeriesIdentifier,
  allowMultipleSelections,
  canFilter,
  selectedSeriesIdentifier,
} ) => {

  const portalRoot = document.getElementById( 'reactLegendOverflowPortal' );

  return ReactDOM.createPortal(
    <React.Fragment>
      {

        ( isNotEmpty( legendData ) && showAllItems ) &&
        <div className="chartLegendModal">
          {
            isNotEmpty( label ) &&
            <h4>{ label }</h4>
          }
          <button className="closeButton" onClick={ () => setShowAllItems( false ) }>
            <InlineSVG type="close" />
          </button>
          <ul>
            {
              Object.entries( legendData ).map( ( [ key, series ], index ) => {
                return <li
                  key={index}
                  // eslint-disable-next-line max-len
                  className={ `${ isFaded( series ) ? 'isFaded' : ''} ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ isNotEmpty( setSelectedSeriesIdentifier ) ? 'isClickable' : '' } ${ hoveredSeriesIdentifier === series.key ? 'isHovered' : '' } ${ alreadySelected( series.key ) ? 'isSelected' : '' } ${ series.total === 0 ? 'disabled' : '' }` }
                  onMouseEnter={ () => handleMouseEnter( series ) }
                  onMouseLeave={ handleMouseLeave }
                  onClick={ () => handleMouseClick( series ) }
                  style={
                    {
                      background: listItemBackground( series ),
                    }
                  }
                >
                  <div className="labelAndKeyWrapper">
                    <span className="legendKey" style={ { background: series.stroke ? series.stroke : series.fill} } />
                    <span className="legendLabel">
                      { series.label ? series.label : capitalize( key ) }
                    </span>
                    {
                      isNotEmpty( series.total ) &&
                      <strong className="legendCount">
                        ({ formatNumber( series.total ) })
                      </strong>
                    }
                  </div>
                  {
                    ( alreadySelected( series.key ) && !allowMultipleSelections && canFilter ) &&
                    <InlineSVG type="remove" elementClass="removeFilterIcon" />
                  }
                  {
                    ( isNotEmpty( selectedSeriesIdentifier ) && allowMultipleSelections ) &&
                    <React.Fragment>
                      {
                        alreadySelected( series.key )
                          ? <InlineSVG type="checkboxChecked" elementClass="removeFilterIcon" />
                          : <InlineSVG type="checkbox" elementClass="removeFilterIcon" />
                      }
                    </React.Fragment>
                  }
                </li>;
              } )
            }
          </ul>
        </div>
      }
    </React.Fragment>,
    portalRoot,
  );
};

export default LegendOverflowModal;