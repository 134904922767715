/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getDimensionsAndOffset, isEmpty, isNotEmpty, itemIsFunction, userDisplayName } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';
import { isBetaPage, isBetaRoute, isNewPage } from '../App/Routing';
import RatingBadge from '../../shared/RatingBadge';

import './MenuItemV2.scss';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { NavigationContext } from '../../Contexts/Navigation';
import { pagesRemovedInDemoMode } from '../App/DemoModeContent';

const SubNavigationContent = ( {
  pageIsCurrent,
  item,
  subNavigationItemIsCurrent,
  goToLink,
  collapsed,
  passedInSubNavigationItems,
} ) => {

  const [ subNavigationItems, setSubNavigationItems ] = React.useState( null );
  const [ style, setStyle ] = React.useState( { } );

  const NAV_ITEM_COUNT = 7;
  const COLLAPSED_ITEM_HEIGHT = 32;
  const ITEM_SPACER = 24;

  const LOWER_HEIGHT = 96;
  const LOGO_HEIGHT = 64;

  const calculateAvailableHeight = () => {
    const menuElement = document.getElementById( 'navigationMenu' );

    if ( isNotEmpty( menuElement ) ) {
      const menuDimensions = getDimensionsAndOffset( menuElement );

      if ( isNotEmpty( menuDimensions ) ) {
        const { height } = menuDimensions;

        // take the height of the menu
        //  - the logo
        //  - the lower
        //  - minus the height of all the items when collapsed
        //  = what is left for the menu items
        const availableHeight = height
          - LOGO_HEIGHT
          - LOWER_HEIGHT
          - ( NAV_ITEM_COUNT * ( COLLAPSED_ITEM_HEIGHT + ITEM_SPACER ) )
          - ( ITEM_SPACER * 1.5 );

        if ( isNotEmpty( availableHeight ) && !isNaN( availableHeight ) ) {
          setStyle( { maxHeight: availableHeight > 0 ? availableHeight : 0 } );
        }
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( passedInSubNavigationItems ) ) {
      setSubNavigationItems( passedInSubNavigationItems );
    } else if ( isNotEmpty( item ) && isNotEmpty( item.items ) ) {
      setSubNavigationItems( item.items );
    }

    calculateAvailableHeight();

    window.addEventListener( 'resize', calculateAvailableHeight );
    return () => window.removeEventListener( 'resize', calculateAvailableHeight );

  }, [ item, passedInSubNavigationItems ] );

  const handleActionClick = fn => {
    if ( isNotEmpty( fn ) && itemIsFunction( fn ) ) {
      fn();
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( subNavigationItems ) && !collapsed ) &&
        <ul
          style={ style }
          // eslint-disable-next-line max-len
          className={ `menuItemSubNavigation ${pageIsCurrent( item ) ? 'isCurrent' : ''} ${collapsed ? 'isCollapsed' : ''}`}
        >
          {
            Object.values( subNavigationItems ).map( ( subNavItem, snIndex ) => {
              // this item has nested navigation under it, this is the deepest it will get
              if ( isNotEmpty( subNavItem.items ) ) {
                return <React.Fragment key={snIndex} >
                  <div
                    // eslint-disable-next-line max-len
                    className={ `routeNavigationItemsWrapperV2 border--${subNavItem.borderClass || ''}` }
                  >
                    <li className="divider">
                      <label>{ subNavItem.label }</label>
                    </li>
                    {
                      Object.values( subNavItem.items ).map( ( subSubNavItem, ssnIndex ) => {
                        if ( !pagesRemovedInDemoMode.includes( subSubNavItem.slug ) ) {
                          return <li
                            className="routeNavigationItem indented"
                            key={ssnIndex}
                            onClick={ () => goToLink( subNavItem.link ) }
                          >
                            <React.Fragment>
                              {
                                isNotEmpty( subSubNavItem.action )
                                  ? <button
                                    className="routeNavActionButton allowMenuHover"
                                    onClick={ () => handleActionClick( subSubNavItem.action ) }
                                  >
                                    { subSubNavItem.label }
                                  </button>
                                  : <React.Fragment>
                                    {
                                      subNavigationItemIsCurrent( subSubNavItem )
                                        ? <div
                                          className="notLink"
                                        >
                                          <span >{ subSubNavItem.label }</span>
                                          {
                                            isNewPage( item.slug, subSubNavItem.slug ) &&
                                            <RatingBadge altVersion rating="new" />
                                          }
                                          {
                                            isBetaPage( item.slug, subSubNavItem.slug ) &&
                                            <RatingBadge altVersion rating="beta" />
                                          }
                                        </div>
                                        : <a href={ subSubNavItem.link } >
                                          <span >{ subSubNavItem.label }</span>
                                          {
                                            isNewPage( item.slug, subSubNavItem.slug ) &&
                                            <RatingBadge altVersion rating="new" />
                                          }
                                          {
                                            isBetaPage( item.slug, subSubNavItem.slug ) &&
                                            <RatingBadge altVersion rating="beta" />
                                          }
                                        </a>
                                    }
                                  </React.Fragment>
                              }
                            </React.Fragment>
                          </li>;
                        }

                      } )
                    }
                  </div>

                </React.Fragment>;
              }
              if ( !pagesRemovedInDemoMode.includes( subNavItem.slug ) ) {
                // this item is itself a page, make it a link
                return <li
                  className={ `routeNavigationItem allowMenuHover rootLevel border--${subNavItem.borderClass || ''}` }
                  key={snIndex}
                  onClick={ () => goToLink( subNavItem.link ) }
                >
                  {
                    isNotEmpty( subNavItem.action )
                      ? <button
                        className="routeNavActionButton allowMenuHover"
                        onClick={ () => handleActionClick( subNavItem.action ) }
                      >
                        { subNavItem.label }
                      </button>
                      : <React.Fragment>
                        {
                          subNavigationItemIsCurrent( subNavItem )
                            ? <div
                              className="notLink allowMenuHover"
                            >
                              <span >{ subNavItem.label }</span>
                              {
                                isNewPage( item.slug, subNavItem.slug ) &&
                                <RatingBadge altVersion rating="new" />
                              }
                              {
                                isBetaPage( item.slug, subNavItem.slug ) &&
                                <RatingBadge altVersion rating="beta" />
                              }
                            </div>
                            : <React.Fragment>
                              <a href={ subNavItem.link } >
                                <span >{ subNavItem.label }</span>
                                {
                                  isNewPage( item.slug, subNavItem.slug ) &&
                                  <RatingBadge altVersion rating="new" />
                                }
                                {
                                  isBetaPage( item.slug, subNavItem.slug ) &&
                                  <RatingBadge altVersion rating="beta" />
                                }
                              </a>
                            </React.Fragment>
                        }
                      </React.Fragment>
                  }
                </li>;
              }

            } )
          }
        </ul>
      }
    </React.Fragment>
  );
};


const MenuItemV2 = ( {
  item,
  currentRoute,
  currentPage,
} ) => {

  // all related to the user menu special actions
  const [ currentUser ] = React.useContext( CurrentUserContext );
  const [ itemLink, setItemLink ] = React.useState( null );

  const [
    ,
    ,
    ,
    ,
    expandedLeftNavMenus,
    setExpandedLeftNavMenus,
  ] = React.useContext( NavigationContext );

  // eslint-disable-next-line max-len

  // when the page changes, automatically open this section of the nav for the current page
  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( currentRoute ) && isNotEmpty( currentPage ) ) {
      if ( pageIsCurrent( item ) ) {

        const expanded = {};

        Object.keys( expandedLeftNavMenus ).map( ( key ) => {
          if ( key === item.slug ) {
            expanded[key] = true;
          } else {
            expanded[key] = false;
          }
        } );
        setExpandedLeftNavMenus( expanded );
      }
    }
  }, [ item, currentRoute, currentPage ] );

  const toggleSectionCollapse = () => {

    const expanded = {};

    Object.entries( expandedLeftNavMenus ).map( ( [ key, value ] ) => {
      if ( key === item.slug ) {
        expanded[key] = !value;
      } else {
        expanded[key] = false;
      }
    } );
    setExpandedLeftNavMenus( expanded );
  };

  // eslint-disable-next-line max-len
  const sectionIsCollapsed = () => expandedLeftNavMenus[item.slug] === false || isEmpty( expandedLeftNavMenus[item.slug] );

  const pageIsCurrent = item => {

    // early return for somehow empty routes
    if ( isEmpty( currentRoute ) && item.isCurrent === 'reports' ) {
      return true;
    }

    if ( isNotEmpty( currentRoute ) && currentRoute.includes( '_settings' ) ) {
      return currentRoute === `${item.slug}_settings`;
    }

    if ( Array.isArray( item.isCurrent ) ) {
      return item.isCurrent.includes( currentPage ) && currentRoute === item.slug;
    }
    return currentRoute === item.isCurrent && currentRoute === item.slug;
  };

  const goToLink = link => {
    if ( isNotEmpty( link ) ) {
      window.location.href = link;
    }
  };

  const subNavigationItemIsCurrent = subNavItem => {
    return subNavItem.isCurrent === currentPage && item.slug === currentRoute;
  };

  const setupUserAndOptions = async () => {
    setItemLink( `#.=setup&page=users&selected_record=${currentUser.id}` );
  };

  // once we have the current user and the wizard status, set up the actions, icon, and title for the user menu
  React.useEffect( () => {
    if (
      isNotEmpty( currentUser )
      && isNotEmpty( item )
      && item.slug === 'user_menu'
    ) {
      setupUserAndOptions();
    }
  }, [ item, currentUser ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( item ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `menutItemWrapperV2 ${pageIsCurrent( item ) ? 'isCurrent' : ''} ${sectionIsCollapsed() ? 'isCollapsed' : ''}`}
        >
          <div
            // eslint-disable-next-line max-len
            className={ `menuItemHeader ${pageIsCurrent( item ) ? 'isCurrent' : ''} ${sectionIsCollapsed() ? 'isCollapsed' : ''}`}
            onClick={ toggleSectionCollapse }
          >
            <span
              className="labelWrapper"
              onClick={ () => goToLink( isNotEmpty( itemLink ) ? itemLink : item.link ) }
            >
              {
                isNotEmpty( item.iconKey ) &&
                <InlineSVG type={item.iconKey} />
              }
              <h4>
                {
                  item.slug === 'user_menu'
                    ? userDisplayName( currentUser )
                    : item.label
                }
              </h4>
              { isBetaRoute( item.slug ) && <RatingBadge altVersion rating="beta" /> }
            </span>
            <button>
              <InlineSVG type="carretUp" />
            </button>
          </div>
          {
            ( !sectionIsCollapsed() && isNotEmpty( item.items ) ) &&
            <SubNavigationContent
              item={item}
              pageIsCurrent={pageIsCurrent}
              subNavigationItemIsCurrent={subNavigationItemIsCurrent}
              goToLink={goToLink}
              collapsed={sectionIsCollapsed()}
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default MenuItemV2;