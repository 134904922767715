/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { encodeURLHash, isNotEmpty, riskToRating } from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';

import './Tabs.scss';

const Tabs = ( {
  currentTab,
  setCurrentTab,
  record,
  recordType,
  // isCollapsed,
  riskType,
  currentScannerSignature,
} ) => {

  /* eslint-disable camelcase */

  const [ availableTabs, setAvailableTabs ] = React.useState( null );
  const [ riskClass, setRiskClass ] = React.useState( 'default' );

  const tabsForRecordType = {
    host: {
      risk_and_remediation: 'Risk and Remediation',
      deepsurface_scanning: 'DeepSurface Scanning',
      third_party_scanning: 'Third Party Status',
      vulnerability_evidence: 'Vulnerability Evidence',
    },
    patch: {
      risk_and_remediation: 'Risk and Remediation',
      third_party_scanning: 'Third Party Status',
      // vulnerability_evidence: 'Vulnerability Evidence',
    },
    vulnerability: {
      risk_and_remediation: 'Risk and Remediation',
      // risk_explanation: 'Risk Explanation',
      third_party_scanning: 'Third Party Status',
      vulnerability_evidence: 'Vulnerability Evidence',
    },
    user: {
      risk_and_remediation: 'Risk and Remediation',
    },
    path: {
      path_analysis: 'Path Analysis',
    },
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( riskType ) && isNotEmpty( recordType ) ) {

      if ( recordType === 'host' ) {
        if ( record.has_host ) {
          setRiskClass( riskToRating( record[riskType] ) );
        } else {
          setRiskClass( 'unknown' );
        }
      } else {
        let _riskType = riskType;
        if ( 'filtered_risk' in record ) {
          _riskType = 'filtered_risk';
        }
        setRiskClass( riskToRating( record[_riskType] ) );
      }

    }
  }, [ record, riskType, recordType ] );

  const iconForTab = tabKey => {
    if ( tabKey === 'third_party_scanning' || tabKey === 'deepsurface_scanning' ) {
      if ( isNotEmpty( recordType ) && recordType === 'host' ) {
        return <InlineSVG type="scanning_status" />;
      }
      return <InlineSVG type="scanning_nav" />;
    }
    if ( tabKey === 'risk_explanation' ) {
      return <InlineSVG type="dsRisk" />;
    }
    if ( tabKey === 'path_analysis' ) {
      return <InlineSVG type="path_record" />;
    }
    if ( tabKey === 'risk_and_remediation' ) {
      if ( isNotEmpty( recordType ) ) {
        return <InlineSVG type={ `${recordType}_record`} />;
      }
      return <InlineSVG type="dsRisk" />;
    }
    if ( tabKey === 'vulnerability_evidence' ) {
      return <InlineSVG type="evidence" />;
    }
    return <InlineSVG type="dsRisk" />;
  };

  const getTabClass = tabKey => {
    if (
      ( tabKey === 'risk_and_remediation' || tabKey === 'risk_explanation' || tabKey === 'path_analysis' )
      && isNotEmpty( riskClass )
    ) {
      return riskClass;
    }
    if ( tabKey === 'third_party_scanning' && isNotEmpty( currentScannerSignature ) ) {
      if ( isNotEmpty( currentScannerSignature.signature?.scanner_rating ) ) {
        if ( currentScannerSignature.signature.scanner_rating.includes( '/' ) ) {
          const [ outOf10 ] = currentScannerSignature.signature.scanner_rating;

          const parsed = parseInt( outOf10 );

          if ( !isNaN( parsed ) ) {
            if ( parsed <= 2 ) {
              return 'minimal';
            }
            if ( parsed <= 4 ) {
              return 'low';
            }
            if ( parsed <= 6 ) {
              return 'moderate';
            }
            if ( parsed <= 8 ) {
              return 'high';
            }
            return 'critical';
          }
        }
        return currentScannerSignature.signature.scanner_rating;
      }
      return 'default';
    }
    if ( tabKey === 'deepsurface_scanning' ) {

      if ( isNotEmpty( record ) && !record.has_host ) {
        return 'red';
      }
      if ( isNotEmpty( record ) && isNotEmpty( record.last_scanned ) ) {
        const now = new Date().getTime();
        const delta = now - ( record.last_scanned * 1_000 );
        const daysAgo = Math.floor( delta / ( 24 * 60 * 60 * 1_000 ) );

        if ( daysAgo <= 30 ) {
          if ( daysAgo <= 7 ) {
            if ( daysAgo <= 1 ) {
              return 'blue';
            }
            return 'green';
          }
          return 'yellow';
        }
        return 'orange';
      }
      return 'red';
    }
    return 'default';
  };

  const handleTabClick = tabKey => {
    if ( isNotEmpty( tabKey ) ) {
      encodeURLHash( { panel_tab: tabKey } );
      setCurrentTab( tabKey );
    }
  };

  /* eslint-enable camelcase */

  React.useEffect( () => {
    if ( isNotEmpty( recordType ) && isNotEmpty( tabsForRecordType[recordType] ) ) {
      setAvailableTabs( tabsForRecordType[recordType] );
    }
  }, [ recordType, currentScannerSignature ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( availableTabs ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `mainPanelTabsContainer tabCount--${Object.keys( availableTabs ).length} ${ isNotEmpty( currentTab ) ? `selectedTabIndex--${ Object.keys( availableTabs ).indexOf( currentTab )}`: 'noSelectedTab' }`}
        >
          {
            Object.entries( availableTabs ).map( ( [ tabKey, tabLabel ] ) => {
              return <div
                // eslint-disable-next-line max-len
                className={ `tabClass--${getTabClass( tabKey )} ${ currentTab === tabKey ? 'isCurrent' : ''} mainPanelTab ${tabKey}`} key={tabKey}
                onClick={ () => handleTabClick( tabKey ) }
              >
                <div className="iconLabelAndStatusWrapper">
                  <span>
                    { iconForTab( tabKey ) }
                    { tabLabel }
                  </span>
                </div>
              </div>;
            } )
          }
        </div>

      }
    </React.Fragment>
  );
};

export default Tabs;