/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../Utilities';

import './ItemPercentageBar.scss';

const ItemPercentageBar = ( { value, maxValue, fill=globalColors['grey--icon'], stroke } ) => {

  const [ barWidth, setBarWidth ] = React.useState( 0 );
  const [ border, setBorder ] = React.useState( `1px solid ${globalColors.grey}` );

  React.useEffect( ( ) => {
    if ( isNotEmpty( value ) && isNotEmpty( maxValue ) ) {
      const width = ( value / maxValue ) * 100;
      setBarWidth( width );
    } else {
      setBarWidth( 0.01 );
    }
  }, [ value, maxValue ] );

  React.useEffect( () => {
    if ( isNotEmpty( stroke ) ) {
      setBorder( `1px solid ${stroke}` );
    }
  }, [ stroke ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( barWidth ) &&
        <div className="itemPercentageBarWrapper">
          {
            isNotEmpty( barWidth ) &&
            <div className="itemPercentageBar"  style={ { width: `${barWidth}%`, background: fill, border } } />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default ItemPercentageBar;