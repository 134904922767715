import React from 'react';
import { formatUnixDate, getGlobalSettings, isNotEmpty } from '../../../shared/Utilities';

/* eslint-disable max-len, camelcase */

const formatSubordinate = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'unavailable' ) {
      content = <p>This subordinate scanner was offline when a scan job that requires it was launched. Scan aborted.</p>;
    } else {
      content = <p>This subordinate scanner was performing a scan when it went offline. Scan terminated prematurely.</p>;
    }
  }
  return ( { content, actions } );
};

const formatScanDomain = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( isNotEmpty( alert.details?.agent_host_name )  ) {
      content = <React.Fragment>
        <p>Unable to scan the <strong>{ alert.subject } </strong>Domain via the agent on host <span>{ alert.details?.agent_host_name } </span>. This could be due to Active Directory timing out on the machine-local LDAP connection.</p>
        <p>The error code was:</p>
        <pre>{ alert.details?.failure_type }</pre>
        <p>The exception was:</p>
        <pre>{ alert.details?.exception }</pre>
      </React.Fragment>;
    } else if ( alert.type === 'Stale Domain' ) {
      content = <p>Unable to find the <strong>{ alert.subject } </strong>Domain within the past <span>{ alert.details?.windows_domain_expiration_days } </span>days. Please ensure that a domain controller that has access to this domain can be scanned, either through a traditional credentialed scan or via an agent or user managed scan installed on the domain controller.</p>;
      actions = [
        <a href="#.=scanning&page=scan_groups">Configure credentialed scan</a>,
        <a href="#.=scanning&page=user_managed">Configure user managed scan</a>,
        <a href="#.=scanning&page=agents">Configure agent scan</a>,
      ];
    } else {
      content = <React.Fragment>
        <p>Unable to scan the <strong>{ alert.subject } </strong>Domain. This could be due to either a network connectivity limitation or a lack of appropriate credentials.</p>
        <p>The domain controllers in scope for this domain and scan group were:</p>
        <pre>{ alert.details?.controllers?.join( ', ' ) }</pre>
        <p>The error code was:</p>
        <pre>{ alert.details?.failure_type }</pre>
      </React.Fragment>;
    }
  }
  return ( { content, actions } );
};

const formatDomainDiscovery = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <p>Unable to find the <strong>{ alert.details['domain-name'] } </strong>Domain. The manually entered domain name <strong>{ alert.details['domain-name'] } </strong>could not be found in DNS A or DNS SRV records during the most recent scan. This could be caused by misconfiguration of the DeepSurface host's DNS servers, or due to an incorrectly entered fully-qualified domain (FQDN) for the specified Windows Domain. The configured domains may be edited in the scanning general setting section.</p>;
    actions = [
      <a href="#.=scanning&page=scanning_general_settings">Revisit domain configuration</a>,
    ];
  }
  return ( { content, actions } );
};

const formatEmail = alert => {
  let content;

  const standardContent = <p>Please fix it on the SMTP Settings page.</p>;
  const credsContent = <p>Please try re-entering your email login credentials on the SMTP Settings page.</p>;

  const actions = [
    <a href="#.=setup&page=smtp">Configure SMTP settings</a>,
  ];

  if ( isNotEmpty( alert ) ) {
    if ( alert.subject === 'port' ) {
      content = <React.Fragment>
        <p>{ alert.details?.message }</p>
        { standardContent }
      </React.Fragment>;
    } else if ( alert.subject === 'password' ) {
      content = <React.Fragment>
        <p>A password is missing for the username <strong>{ alert.details?.username } </strong> on host <strong>{ alert.details.hostname }</strong>.</p>
        { credsContent }
      </React.Fragment>;
    } else if ( alert.subject === 'connection-refused' ) {
      content = <React.Fragment>
        <p>Description:</p>
        <pre>{ alert.details?.description }</pre>
        <p>Exception:</p>
        <pre>{ alert.details?.exception }</pre>
        { standardContent }
      </React.Fragment>;
    } else if ( alert.subject === 'username' ) {
      content = <React.Fragment>
        <p>A username is missing for the host <strong>{ alert.details?.hostname }</strong>.</p>
        { credsContent }
      </React.Fragment>;
    } else if ( alert.subject === 'credential-uuid' ) {
      content = <React.Fragment>
        <p>The Credential UUID saved for the smtp email server credentials is invalid</p>
        { credsContent }
      </React.Fragment>;
    } else if ( isNotEmpty( alert.details?.failure_type ) ) {
      content = <React.Fragment>
        <p>Unknown Error:</p>
        <pre>{ alert.details?.failure_type }</pre>
        <p>Description:</p>
        <pre>{ alert.details?.description }</pre>
        <p>Exception:</p>
        <pre>{ alert.details?.exception }</pre>
        { standardContent }
      </React.Fragment>;
    } else if ( alert.subject === 'rejected-recipients' ) {
      content = <React.Fragment>
        <p>The configured SMTP server rejected some recipients.</p>
        <p>Details:</p>
        <pre>{ alert.details }</pre>
      </React.Fragment>;
    } else if ( alert.subject === 'no-sender-configured' ) {
      content = <React.Fragment>
        <p>No sender has been configured in the SMTP settings for sending emails.</p>
        { standardContent }
      </React.Fragment>;
    } else if ( alert.type === 'configuration' ) {
      content = <React.Fragment>
        <p>The following SMTP settings are not configured properly:</p>
        <pre>{ alert.subject }</pre>
        { standardContent }
      </React.Fragment>;
    }
  }
  return ( { content, actions } );
};

const formatScanHost = alert => {
  let content, actions;

  const subtypes = {
    'sudo-incorrect-password' : 'The password configured for use by sudo on the remote host appears to be incorrect. If this diagnosis is not correct, please temporarily change the elevation method for this host and reach out to our support team regarding this condition.',
    'su-incorrect-password' : 'The password configured for use by su on the remote host appears to be incorrect. If this diagnosis is not correct, please temporarily change the elevation method for this host and reach out to our support team regarding this condition.',
    'nonexistent-binsh' : 'The shell at /bin/sh appears to not exist on the remote host. Please either install Bourne Shell (sh/dash) at /bin/sh or reach out to our support team.',
    'sudo-nonexistent' : 'The elevation method configured for use by the host is sudo. However, the sudo binary does not appear to be installed on the remote host. Please either install sudo or change the elevation method for this host.',
    'su-nonexistent' : 'The su program does not appear to exist on the remote host. Please either install su or reach out to our support team regarding this condition.',
    'nonexistent' : 'One of the programs required by use for scanning appears to not be installed on the remote host. Please reach out to our support team as the product is unable to automatically triage this specific condition.',
    'sudo-not-allowed' : 'The configured user does not appear to have privileges to execute the command "su" using sudo. Please either configure sudo using the command "visudo" to allow the user to execute the command "su", choose a different user for scanning, or use an alternative elevation method.',
    'not-root' : 'For an unknown reason, DeepSurface was unable to elevate privileges to root. The elevation method appears to have worked, but the user ID (UID) was not 0 after elevating privileges. Please reach out to our support team as this condition is not due to your misconfiguration.',
    'exit-code' : 'The currently-configured elevation method returned a non-zero exit status code which indicates that the elevation was unsuccessful. Please reach out to our support team since DeepSurface is unable to automatically triage this condition.',
    // XXX: key doesn't follow convention here.  need a DB migration to fix it
    'Secrets cannot contain null bytes or newlines' : 'The elevation secret configured for use on the remote host appears to contain newlines which is unsupported on Unix-like operating systems. Please either reconfigure the elevation secret or reach out to our support team if you believe this condition was incorrectly triaged.',
  };

  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'elevate' ) {
      content = <React.Fragment>
        <p>{ subtypes[ alert.details?.details ] || `There was an unknown error that occurred while DeepSurface attempted to elevate privileges to root on the host ${JSON.stringify( alert.details?.details )}.`}</p>
        <p>To review the elevation configuration for this host, visit the credentials page.</p>
      </React.Fragment>;
      actions = [
        <a className="secondary" href="#.=scanning&page=credentials">Review credentials</a>,
        <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1` }>View scan log</a>,
      ];
    } else if ( alert.type === 'agent' ) {
      content = <React.Fragment>
        <p>Unable process agent message from IP address <strong>{ alert.details?.remote_ip || 'N/A' }</strong>.</p>
        <p>The error type returned was:</p>
        <pre>{ alert.details?.failure_type }</pre>
        <p>For more information please see the scan log for this host.</p>
      </React.Fragment>;
      actions = [
        <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1` }>View scan log</a>,
      ];
    } else if ( alert.type !== 'unknown' ) {
      content = <React.Fragment>
        <p>Unable to scan system at IP address <strong>{ alert.subject }</strong> using the protocol and credentials:</p>
        <pre>{ alert.details?.credential }</pre>
        <p>The error type returned was:</p>
        <pre>{ alert.details?.failure_type }</pre>
        <p>For more information please see the scan log for this host.</p>
      </React.Fragment>;
      actions = [
        <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1` }>View scan log</a>,
      ];
    }
  }
  return ( { content, actions } );
};

const formatAgentHost = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'bad-relay-token' ) {
      content = <React.Fragment>
        <p>Agent at IP address <strong>{ alert.details?.remote_ip } </strong>sent a bad relay token (<strong>{ alert.details?.bad_relay_token } </strong>). Was this agent registered to a previous DeepSurface appliance?</p>
        <p>The error type returned was:</p>
        <pre>{ alert.details?.failure_type }</pre>
      </React.Fragment>;
    } else if ( alert.type === 'non-existent-relay-token'  ) {
      content = <React.Fragment>
        <p>Agent at IP address <strong>{ alert.details?.remote_ip } </strong>sent a non existent relay token (<strong>{ alert.details?.non_existent_relay_token } </strong>). Have agent registration settings been changed since this host was registered?</p>
        <p>The error type returned was:</p>
        <pre>{ alert.details?.failure_type }</pre>
      </React.Fragment>;
    } else if ( alert.type === 'missing-host-id' ) {
      content = <React.Fragment>
        <p>Agent at IP address <strong>{ alert.details?.remote_ip } </strong>has an unknown host ID. Was this host deleted from DeepSurface without removing the agent?</p>
        <p>The error type returned was:</p>
        <pre>{ alert.details?.failure_type }</pre>
      </React.Fragment>;
    } else if ( alert.type === 'host-script-failed' ) {
      content = <React.Fragment>
        <p>A script failed while attempting to be run on a host.</p>
        <pre>
          { alert.details?.script_name } : { alert.details?.exception }
        </pre>
      </React.Fragment>;
    } else {
      content = <React.Fragment>
        <p>Unable to process agent message from IP address <strong>{ alert.details?.remote_ip } </strong>.</p>
        {
          isNotEmpty( alert.details ) &&
          <React.Fragment>
            <p>The error type returned was:</p>
            <pre>
              { isNotEmpty( alert.details?.failure_type ) && alert.details?.failure_type }
              { isNotEmpty( alert.details?.exception ) && alert.details?.exception }
            </pre>
          </React.Fragment>
        }
      </React.Fragment>;
    }
  }
  return ( { content, actions } );
};

const formatMSSQL = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'partial' ) {
      content = <React.Fragment>
        <p>DeepSurface was unable to scan one or more Microsoft SQL Server instances on <strong>{ alert.subject }</strong>. The specific errors encountered for each instance are included below:</p>
        <pre>{ Object.values( alert.details?.instance_failures ).join( '\n' )}</pre>
        <p>For more information, see the associated scan log.</p>
      </React.Fragment>;
      actions = [
        <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1` }>View scan log</a>,
      ];
    } else {
      content = <React.Fragment>
        <p>DeepSurface was unable to scan one or more Microsoft SQL Server instances on <strong>{ alert.subject }</strong>. The specific errors encountered for each instance are included below:</p>
        <pre>{ alert.details?.instance_failures?.join( '\n' )}</pre>
        <p>For more information, see the associated scan log.</p>
      </React.Fragment>;
      actions = [
        <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1` }>View scan log</a>,
      ];
    }
  }
  return ( { content, actions } );
};

const formatThirdPartyScan = alert => {
  let content, actions;

  const getRawInfo = () => {
    if ( isNotEmpty( alert.details?.raw_info ) && Object.keys( alert.details?.raw_info ).length > 0 ) {
      return <React.Fragment>
        <p>More Information:</p>
        <pre>{ JSON.stringify( alert.details?.raw_info ) }</pre>
      </React.Fragment>;
    }
    return <React.Fragment>
    </React.Fragment>;
  };

  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'unknown-host' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner reported scan results for a host, but DeepSurface could not match up its identifiers with any known system. Ensure DeepSurface is configured to scan this system (either via agents or credentialed scans) and then re-import the vulnerability scan results after successfully scanning the system with DeepSurface.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'connect-smb' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was unable to connect to the SMB service (port 445/TCP). The root cause is typically a lack of routing or a security control (such as a network firewall, Windows firewall, or network security group) preventing the third-party scanner from accessing this service.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'connect-ssh' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was unable to connect to the SSH service (port 22/TCP). The root cause is typically a lack of routing or a security control (such as a network firewall, Windows firewall, or network security group) preventing the third-party scanner from accessing this service.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'auth-smb' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was unable to authenticate to the SMB service. Ensure your third-party scanner has been configured with credentials that have sufficient rights to execute commands remotely.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'auth-ssh' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was unable to authenticate to the SSH service. Ensure your third-party scanner has been configured with credentials that have sufficient rights to execute commands remotely.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'authz-registry' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was able to authenticate to this host, but was unable to access the Windows registry service. To address this, first ensure the account has administrative privileges. Then either enable remote registry service on this host OR configure the scanner to automatically enable this service during the scan. If using a Tenable scanner, refer to <a href="https://community.tenable.com/s/article/How-to-enable-the-Start-the-Remote-Registry-service-during-the-scan-option-in-a-scan-policy" target="_blank" rel="noopener noreferrer">this article </a> for more information.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'authz-adminshares' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was able to authenticate to this host, but was unable to access administrative shares service, which prevents it from retrieving output from remote commands. To address this, first ensure the account has administrative privileges. Then either enable administrative shares on this host OR configure the scanner to automatically enable this during the scan. If using a Tenable scanner, refer to <a href="https://www.tenable.com/blog/making-it-easier-to-perform-credentialed-scanning-auditing" target="_blank" rel="noopener noreferrer">this article </a> for more information.</p>
        { getRawInfo() }
      </React.Fragment>;
    } else if ( alert.type === 'ssh-elevate' ) {
      content = <React.Fragment>
        <p>Your third-party vulnerability scanner was able to authenticate to this host via SSH, but was unable to elevate privileges to gather complete information. To address this, configure your vulnerability scanner with secondary credentials that can be used to gain increased privileges. If using a Tenable scanner, refer to <a href="https://docs.tenable.com/tenablesc/Content/PrivilegeEscalation.htm" target="_blank" rel="noopener noreferrer">this article </a> for more information. </p>
        { getRawInfo() }
      </React.Fragment>;
    }
  }
  return ( { content, actions } );
};

const formatLicense = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'aws-metering-failed' ) {
      content = <React.Fragment>
        <p>The metering validation routine returned the error:</p>
        <pre>{ alert.details.message }</pre>
        <p>"When DeepSurface runs from a metered AWS Marketplace image, the instance must be provided an IAM Role with permission to access the metering service. For more information, see: <a rel="noopener noreferrer" target="_blank" href="https://docs.aws.amazon.com/marketplace/latest/buyerguide/buyer-ami-metering-enabled-products.html">this guide</a> for more information.</p>
      </React.Fragment>;
    }
  }
  return ( { content, actions } );
};

const formatSetupWizard = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'unconfigured-agent-endpoints' ) {
      content = <React.Fragment>
        <p>You have not yet configured your agent, please do so in the agents page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=agents">Configure agents</a>,
      ];
    } else if ( alert.type === 'agent-data-missing' ) {
      content = <React.Fragment>
        <p>Agents have not returned any host data yet. If you have not yet configured your agent please do so agents page. Otherwise, you can run the "Process Scan Queue" task on the tasks page.</p>
      </React.Fragment>;

      actions = [
        <a className="secondary" href="#.=scanning&page=agents">Configure agents</a>,
        <a href="#.=activity&page=tasks">Process scan queue</a>,
      ];
    } else if ( alert.type === 'no-connectivity-probe-history' ) {
      content = <React.Fragment>
        <p>The network connectivity probe task has been run yet. Please visit the tasks and run your first probe.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=activity&page=tasks">Run probe</a>,
      ];
    } else if ( alert.type === 'no-connectivity-probe-settings' ) {
      content = <React.Fragment>
        <p>No network connectivity probe task schedule has been configured yet. Please configure the connectivity probe on the network connectivity page so that DeepSurface can keep up to date.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=connectivity_settings">Configure network connectivity</a>,
      ];
    } else if ( alert.type === 'no-hosts' ) {
      content = <React.Fragment>
        <p>There are no hosts in the model. You should configure scan groups on the scan groups page if you have not yet, then run an credentialed scan from the tasks page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=scan_groups">Configure scan group(s)</a>,
        <a href="#.=activity&page=tasks">Run credentialed scan</a>,
      ];
    } else if ( alert.type === 'no-authenticated-scans' ) {
      content = <React.Fragment>
        <p>No scan jobs have been run yet. You should configure scan groups on the scan groups page if you have not yet, then run an credentialed scan from the tasks page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=scan_groups">Configure scan group(s)</a>,
        <a href="#.=activity&page=tasks">Run credentialed scan</a>,
      ];
    } else if ( alert.type === 'no-scheduled-scans' ) {
      content = <React.Fragment>
        <p>There are no authenticated scans configured. You should configure at least one scan group to be scanned on a schedule on the scan groups page to ensure the model stays up to date.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=scan_groups">Schedule scan group(s)</a>,
      ];
    } else if ( alert.type === 'unconfigured-credentials' ) {
      content = <React.Fragment>
        <p>No credentials have been configured, but credentials are required to perform an authenticated scan. Please configure credentials on the credentials page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=credentials">Configure credential(s)</a>,
      ];
    } else if ( alert.type === 'unconfigured-scan-groups' ) {
      content = <React.Fragment>
        <p>No scan groups are configured. You must configure at least one scan group before running an authentiated scan. Please configure at least one scan group on the scan groups page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=scan_groups">Schedule scan group(s)</a>,
      ];
    } else if ( alert.type === 'unconfigured-windows-domain' ) {
      content = <React.Fragment>
        <p>To bootstrap domain scanning, DeepSurface needs to know the fully-qualified domain names of Windows domains that you would like to include in authenticated scans. Please configure at least one Windows domain on the scanning settings page.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=scanning_general_settings">Configure Windows domain(s)</a>,
      ];
    } else if ( alert.type === 'unconfigured-third-party' ) {
      content = <React.Fragment>
        <p>DeepSurface needs to import third-party vulnerability scanner information in order to build a useful model, but there are not currently any sources of this information configured!  Please visit the vulnerability scanners page to configure a new source of vulnerability data.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=scanning&page=vulnerability_scanners">Configure vulnerability scanner(s)</a>,
      ];
    }
  }
  return ( { content, actions } );
};

const formatAnalysis = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'no-assets' ) {
      content = <React.Fragment>
        <p>No sensitive assets are defined in the model, which prevents DeepSurface from estimating the risk of a breach. Please visit the Sensitive Asset Policies section of the application and create a policy that will automatically tag sensitive nodes with an impact score based on rules that you set. Alternatively, you can manually assign an impact score to particular assets in the manual sensitive asset interface.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=setup&page=sensitive_assets_policies">Create sensitive asset policy</a>,
      ];
    } else if ( alert.type === 'stale-model' ) {
      content = <React.Fragment>
        <p>A scan has run recently, but no analysis job has finished yet. Ensure an analysis job is running in the tasks page of the application.</p>
      </React.Fragment>;

      actions = [
        <a href="#.=activity&page=tasks">Check background job</a>,
      ];
    }
  }
  return ( { content, actions } );
};

const formatHealthCheck = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'disk-space-critical' ) {
      content = <p>The DeepSurface main console server is critically low on disk space on the <strong>{ alert.subject }</strong> partition. Please expand this partition (perhaps doubling it in size) in order to ensure continued safe operation of the software. Don't hesitate to contact <a href="email:product-support@deepsurface.com">Deepsurface support</a> for more information</p>;
    } else if ( alert.type === 'disk-space-low' ) {
      content = <p>The DeepSurface main console server is low on disk space on the <strong>{ alert.subject }</strong> partition. Please expand this partition (perhaps increasing the size by 50%) in order to ensure continued safe operation of the software. Don't hesitate to contact <a href="email:product-support@deepsurface.com">Deepsurface support</a> for more information</p>;
    }
  }
  return ( { content, actions } );
};

const formatScanWindowsClients = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>DeepSurface was unable to perform Windows desktop client application scans on <strong>{ alert.subject }</strong>.</p>
      <p>The error code was:</p>
      <pre>{ alert.details.failure_type }</pre>
      <p>For more information see the associated scan log.</p>
    </React.Fragment>;

    actions = [
      <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1`}>View scan log</a>,
    ];
  }
  return ( { content, actions } );
};

const formatMYSQLD = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>DeepSurface was unable to scan a MySQL database server instance on <strong>{ alert.subject }</strong>.</p>
      <p>The error code was:</p>
      <pre>{ alert.details.failure_type }</pre>
      <p>For more information see the associated scan log.</p>
    </React.Fragment>;

    actions = [
      <a href={ `#.=activity&item_count=100&page=scan_logs&item=${alert.subject}&current_page=1`}>View scan log</a>,
    ];
  }
  return ( { content, actions } );
};

const formatThirdPartyAPI = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>DeepSurface attempted to download the latest vulnerability scan results using the <strong>{ alert.details?.label || alert.subject }</strong> configuration, but encountered the error:</p>
      <pre>{ alert.details?.exception }</pre>
    </React.Fragment>;
  }
  return ( { content, actions } );
};

const formatThirdPartyHost = async ( alert ) => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    if ( alert.type === 'rejected-due-to-age' ) {
      const response = await getGlobalSettings( 'project' );

      let hostName = 'N/A';

      if ( isNotEmpty( alert.details?.raw_info ) ) {
        if ( isNotEmpty( alert.details.raw_info.host_names ) ) {
          [ hostName ] = alert.details.raw_info.host_names;
        } else if ( isNotEmpty( alert.details.raw_info.ip_addresses ) ) {
          [ hostName ] = alert.details.raw_info.ip_addresses;
        } else if ( isNotEmpty( alert.details.raw_info.third_party_identifiers ) ) {
          [ hostName ] = alert.details.raw_info.third_party_identifiers;
        } else if ( isNotEmpty( alert.details.subject ) ) {
          hostName = alert.details.subject;
        }
      }
      content = <React.Fragment>
        <p>
          { hostName } was rejected due to its most recent result being too old
        </p>
        <ul>
          <li>
            <div className="infoSection">
              <label>
                Scanned date:
              </label>
              <span>
                { formatUnixDate( alert.details?.raw_info?.last_scanned ) || 'N/A' }
              </span>
            </div>
          </li>
          <li>
            <div className="infoSection">
              <label>
                Oldest acceptable date:
              </label>
              <span>
                {
                  isNotEmpty( response?.settings?.third_party_ignore_age )
                    ? formatUnixDate( ( new Date().getTime() / 1_000 ) -  response?.settings?.third_party_ignore_age )
                    : 'N/A'
                }
              </span>
            </div>
          </li>
        </ul>
        <p>This can be remedied in various ways:</p>
        <ul>
          <li>If the host is retired, delete it from your vulnerability scanner results.</li>
          <li>If the host is running, ensure that your vulnerability scans are able to sucessfully scan your machines on a regular basis.</li>
          <li>If the host data is from an acceptable time range, you can adjust the range that DeepSurface uses <a href="#.=scanning&page=scanning_general_settings">here</a>.</li>
        </ul>
      </React.Fragment>;
      actions = [
        <a href="#.=scanning&page=scanning_general_settings">Adjust scan age settings</a>,
      ];
      return ( { content, actions } );
    }
  }
};

const formatAuthenticationProvider = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {

    const hostErrors = () => {
      if ( isNotEmpty( alert.details?.server_errors ) ) {
        return Object.entries( alert.details.server_errors ).map( ( [ host, error ] ) => <span><strong>{ host }: </strong> { error }</span> ).join( '\n' );
      }
    };

    content = <React.Fragment>
      <p>When a user attempted to log in to the DeepSurface console, the at least one server configured with that user's authentication provider could not be reached.</p>
      {
        alert.details?.one_server_reached
          ? <p>This did not prevent the user from logging in at that time because another server could be reached.</p>
          : <p><strong>This prevented the user from logging in at that time because no servers could be reached!</strong></p>
      }
      <p>Specific details about these failures is included below:</p>
      <pre>{ hostErrors() }</pre>
      <p>In cases where the server error is listed as "timeout", this means the server could not be reached on the network because the server was down or there are network configuration problems. In cases where the server error is indicated to be an "untrusted_certificate", the authentication server's certificate could not be verified as being legitimate. This can be resolved by deleting any cached TOFU certificate (if TOFU is enabled), or by installing the CA certificate for the authority that was used to sign the server certificate. Both of these changes can be made on the certificates page of the application.</p>
    </React.Fragment>;

    actions = [
      <a href="#.=setup&page=certificates">Configure certificate(s)</a>,
    ];
  }
  return ( { content, actions } );
};

const formatSyncLicense = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>DeepSurface attempted to download the latest license information from the DeepSurface Feed servers, but was unable to. This license sync process helps ensure your product installation has all the correct functionality enabled. It is also required in order to download the most up-to-date vulnerability, patch, and rule information. Please ensure this system has network access to contact https://updates.deepsurface.com/.</p>
      <p>The specific error encountered was:</p>
      <pre>{ alert.details?.exception }</pre>
    </React.Fragment>;
  }
  return ( { content, actions } );
};

const formatLicenseExpired = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <p>{ alert.details?.message }</p>;
  }
  return ( { content, actions } );
};

const formatLicenseExceeded = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <p>{ alert.details?.message }</p>;
  }
  return ( { content, actions } );
};

const formatSyncFeed = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>DeepSurface attempted to download the latest vulnerability, patch, and proprietary rule information from DeepSurface Feed servers, but was unable to. Please ensure this system has network access to contact https://updates.deepsurface.com/.</p>
      <p>The specific error encountered was:</p>
      <pre>{ alert.details?.exception }</pre>
    </React.Fragment>;
  }
  return ( { content, actions } );
};

const formatSystemClockSync = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <React.Fragment>
      <p>The DeepSurface console server time is off from the DeepSurface feed update server time by more than <strong>{ alert.details?.max_seconds_allowed } seconds </strong>, at <strong>{ alert.details?.seconds_off } seconds </strong>. Letting this issue persist can cause unexpected behavior and break functionality if the DeepSurface console server time is not fixed. After correcting the server time, please run a Rule Engine Data Feed job again to remove this warning.</p>
    </React.Fragment>;
  }
  return ( { content, actions } );
};

const formatCloud = alert => {
  let content, actions;
  if ( isNotEmpty( alert ) ) {
    content = <p>{ alert.details?.exception }</p>;

    actions = [
      <a href="#.=Scanning&page=cloud_scanning">Configure cloud scanning</a>,
    ];
  }
  return ( { content, actions } );
};

export const needsAwait = [
  'third-party-host',
];

export const descriptionFormatters = {
  'scan-subordinate': formatSubordinate,
  'scan-domain': formatScanDomain,
  'domain-discovery': formatDomainDiscovery,
  'email': formatEmail,
  'scan-host': formatScanHost,
  'agent-host': formatAgentHost,
  'scan-mssql': formatMSSQL,
  'scan-windows_clients': formatScanWindowsClients,
  'scan-mysqld': formatMYSQLD,
  'third-party-api': formatThirdPartyAPI,
  'third-party-vuln': formatThirdPartyScan,
  'third-party-host': formatThirdPartyHost,
  'authentication-provider': formatAuthenticationProvider,
  'sync-license': formatSyncLicense,
  'license-expired': formatLicenseExpired,
  'license-exceeded': formatLicenseExceeded,
  'license': formatLicense,
  'sync-feed': formatSyncFeed,
  'setup-wizard': formatSetupWizard,
  'system-clock-sync': formatSystemClockSync,
  'analysis': formatAnalysis,
  'cloud-aws': formatCloud,
  'health-check': formatHealthCheck,
};