/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, formatNumber, globalColors, isNotEmpty } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './RiskBreakdown.scss';

const RiskBreakdown = ( {
  settings,
  // contentClass,
  data,
  // currentEditItem,
  // printing,
} ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ total, setTotal ] = React.useState( null );

  const setupData = async ( data ) => {
    let tallyForReportType;
    if ( isNotEmpty( data?.results ) ) {
      tallyForReportType = data.results[settings.report_type];
    }

    if ( isNotEmpty( tallyForReportType ) ) {

      const _total = tallyForReportType.total;

      setTotal( _total );

      const zeroed = _total === 0;

      const _chartData = {
        critical: {
          key: 'critical',
          label: 'Critical',
          stroke: globalColors['critical'],
          value: zeroed ? zeroed : ( ( tallyForReportType.critical / _total ) * 100 ),
          total: tallyForReportType.critical,
        },
        high: {
          key: 'high',
          label: 'High',
          stroke: globalColors['high'],
          value: zeroed ? zeroed : ( ( tallyForReportType.high / _total ) * 100 ),
          total: tallyForReportType.high,
        },
        moderate: {
          key: 'moderate',
          label: 'Moderate',
          stroke: globalColors['moderate'],
          value: zeroed ? zeroed : ( ( tallyForReportType.moderate / _total ) * 100 ),
          total: tallyForReportType.moderate,
        },
        low: {
          key: 'low',
          label: 'Low',
          stroke: globalColors['low'],
          value: zeroed ? zeroed : ( ( tallyForReportType.low / _total ) * 100 ),
          total: tallyForReportType.low,
        },
        minimal: {
          key: 'minimal',
          label: 'Minimal',
          stroke: globalColors['minimal'],
          value: zeroed ? zeroed : ( ( tallyForReportType.minimal / _total ) * 100 ),
          total: tallyForReportType.minimal,
        },
      };
      setChartData( _chartData );
    }
  };

  // when the item and data comes in, grab the correct record type and finesse the data to get ready for the donut
  React.useEffect( () => {
    if ( isNotEmpty( settings ) && isNotEmpty( settings.report_type ) && isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( chartData ) &&
        <React.Fragment>
          <Donut
            strokeWidth="thin"
            data={chartData}
            total={total}
            displayLabel
            displayLabelOverride= { { value: formatNumber( total ), fill: globalColors.darkBlue } }
            subLabel={ capitalize( settings?.report_type ) }
          />
          <Legend legendData={chartData} />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default RiskBreakdown;