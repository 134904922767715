/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  globalColors,
  hexToRGB,
  isNotEmpty,
  itemIsArray,
} from '../../../shared/Utilities';

import './TagItem.scss';
import { getRecords } from '../../../shared/RecordCache';

const TagItem = ( { tag, users } ) => {

  const [ owner, setOwner ] = React.useState( null );
  const [ includedTagString, setIncludedTagString ] = React.useState( null );
  const [ excludedTagString, setExcludedTagString ] = React.useState( null );

  const [ r, setR ] = React.useState( null );
  const [ g, setG ] = React.useState( null );
  const [ b, setB ] = React.useState( null );

  React.useEffect( () => {
    let rgb = {};
    if ( isNotEmpty( tag.color ) ) {
      rgb = hexToRGB( tag.color );
    } else {
      rgb = hexToRGB( globalColors.grey );
    }

    if ( isNotEmpty( rgb ) ) {
      setR( rgb.r );
      setG( rgb.g );
      setB( rgb.b );
    }
  }, [ tag ] );

  const buildTagStrings = async ( tag ) => {

    const hostIDs = [ ...tag.included_host_ids, ...tag.excluded_host_ids ];
    let hosts;

    if ( isNotEmpty( hostIDs ) ) {
      // eslint-disable-next-line camelcase
      hosts = await getRecords( 'scope', { id_list: hostIDs } );
    }
    const _included = [];
    const _excluded = [];
    if ( isNotEmpty( tag.included_ranges ) ) {
      _included.push(
        <span className="included">
          IP Range(s) <code>{ tag.included_ranges.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.included_host_patterns ) ) {
      _included.push(
        <span className="included">
          Host Wildcard(s) <code>{ tag.included_host_patterns.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.included_host_ids && itemIsArray( tag.included_host_ids ) ) ) {

      const labels = [];

      tag.included_host_ids.map( id => {
        const host = hosts.find( h => h.id === id );

        if ( isNotEmpty( host ) && isNotEmpty( host.label ) ) {
          labels.push( host.label );
        }
      } );
      _included.push(
        <span className="included">
          {/* eslint-disable-next-line max-len */}
          Specific Host(s) <code>{ labels.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.included_product_names ) ) {
      _included.push(
        <span className="included">
          {/* eslint-disable-next-line max-len */}
          Operating System(s) <code>{ tag.included_product_names.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_ranges ) ) {
      _excluded.push(
        <span className="excluded">
          IP Range(s) <code>{ tag.excluded_ranges.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_host_patterns ) ) {
      _excluded.push(
        <span className="excluded">
          Host Wildcard(s) <code>{ tag.excluded_host_patterns.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_host_ids ) && itemIsArray( tag.excluded_host_ids ) ) {
      const labels = [];

      tag.excluded_host_ids.map( id => {
        const host = hosts.find( h => h.id === id );

        if ( isNotEmpty( host ) && isNotEmpty( host.label ) ) {
          labels.push( host.label );
        }
      } );

      _excluded.push(
        <span className="excluded">
          {/* eslint-disable-next-line max-len */}
          Specific Host(s) <code>{ labels.join( ', ' ) }</code>
        </span>,
      );
    }
    if ( isNotEmpty( tag.excluded_product_names ) ) {
      _excluded.push(
        <span className="excluded">
          {/* eslint-disable-next-line max-len */}
          Operating System(s) <code>{ tag.excluded_product_names.join( ', ' ) }</code>
        </span>,
      );
    }
    setIncludedTagString( _included );
    setExcludedTagString( _excluded );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( tag ) && isNotEmpty( tag.remediation_manager ) && isNotEmpty( users ) ) {
      buildTagStrings( tag );
      if ( isNotEmpty( users[tag.remediation_manager] ) ) {
        setOwner( users[tag.remediation_manager] );
      }
    }
  }, [ tag, users ] );

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2 style={
            {
              border: `1px solid ${isNotEmpty( tag.color ) ? tag.color : globalColors['grey']}`,
              background: isNotEmpty( tag.color ) ? `rgba(${r},${g},${b}, 0.2)` : globalColors['grey--divider'],
              color: isNotEmpty( tag.color ) ? tag.color : globalColors['grey'],
            }
          }
          >
            { tag.label }</h2>
          <span>
            <label>Owner: </label>
            <span>{ isNotEmpty( owner ) ? owner : 'N/A' }</span>
          </span>
        </div>
        <section className="includedFilters">
          <label className="sectionLabel">
            Included
          </label>
          {
            isNotEmpty( includedTagString )
              ? <div className="tagStringWrapper">
                {
                  includedTagString.map( ( s, i ) => {
                    return <React.Fragment key={i} >{ s }</React.Fragment>;
                  } )
                }
              </div>
              : <span>N/A</span>
          }
        </section>
        <section className="excludedFilters">
          <label className="sectionLabel">
            Excluded
          </label>
          {
            isNotEmpty( excludedTagString )
              ? <div className="tagStringWrapper">
                {
                  excludedTagString.map( ( s, i ) => {
                    return <React.Fragment key={i} >{ s }</React.Fragment>;
                  } )
                }
              </div>
              : <span className="emptyString">N/A</span>
          }
        </section>
      </section>
    </React.Fragment>
  );
};

export default TagItem;