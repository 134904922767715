/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { isNotEmpty } from '../../../Utilities';
import { getFieldValues } from '../../Shared';

// not really a field, just a way to pass a block of content into a field for description...
// or help... really whatever
const ContentBlock = ( {
  field,
  fields,
  originalValue,
  formState,
  recordType,
} ) => {

  React.useEffect( ( ) => {
    if (
      isNotEmpty( field )
      && isNotEmpty( field.init )
      && isNotEmpty( formState )
      && isNotEmpty( formState.fieldStates )
    ) {
      const values = getFieldValues( formState.fieldStates, recordType );
      field.init( field, null, fields, values );
    }
  }, [ field, formState ] );

  return (
    <React.Fragment>
      { field.value || originalValue }
    </React.Fragment>

  );
};

export default ContentBlock;
