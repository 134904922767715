/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import RecordFilters from './RecordFilters';
import AutoProvisionSelect from '../../components/Setup/Authentication/AuthenticationProviders/AutoProvisionSelect';

import {
  capitalize,
  decodeURLHash,
  isNotEmpty,
  removeFromURLHash,
} from '../Utilities';
import InlineSVG from '../InlineSVG';
import Modal from '../Modal';
import Notification from '../Notification';
import PageHeader from '../PageHeader';
import PageCreateButton from '../PageCreateButton';
import PageAlerts from '../PageAlerts';

const SetupPageHeader = ( {
  recordData,
  onRefresh,
  records,
  recordType,
  setSelectedRecord,
  setShowModal,
  noCreate=false,
  notifications,
  globalSettings,
  setGlobalSettings,
} ) => {

  const [ allNotifications, setAllNotifications ] = React.useState( null );
  const [ showNotificationsModal, setShowNotificationsModal ] = React.useState( false );

  React.useEffect( ( ) => {
    const _allNotifications = [];

    if ( isNotEmpty( recordData?.notifications ) ) {
      recordData.notifications.map( n => _allNotifications.push( n ) );
    }
    if ( isNotEmpty( notifications ) ) {
      notifications.map( n => _allNotifications.push( n ) );
    }
    setAllNotifications( _allNotifications );
  }, [ recordData, notifications ] );

  // when the page loads, check the hash, if the 'new' param is present, open up the modal
  React.useEffect( ( ) => {
    const hash = decodeURLHash();
    if ( hash.new ) {
      setRecordAndShow();
      removeFromURLHash( 'new' );
    }
  }, [] );

  const setRecordAndShow = record => {
    if ( isNotEmpty( record ) ) {
      setSelectedRecord( record );
    } else {
      setSelectedRecord( {} );
    }
    setShowModal( true );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( allNotifications ) &&
        <Modal
          visible={ showNotificationsModal }
          setVisible={ setShowNotificationsModal }
          elementClass="setupNotificationsModal"
          header={ `${capitalize( recordType )} Alert(s)` }
          body={
            <React.Fragment>
              {
                allNotifications.map( ( n, i ) => {
                  return <Notification
                    key={i}
                    options={ {
                      type: 'alert',
                      message: n.message,
                    } }
                  />;
                } )
              }
            </React.Fragment>
          }
        />
      }
      {
        !noCreate &&
        <PageCreateButton>
          <button
            className="newItemButton"
            id="newItemButton"
            onClick={ () => setRecordAndShow() }
          >
            <InlineSVG type="new" version="light" />
            <span>{ capitalize( recordType ) }</span>
          </button>
        </PageCreateButton>
      }
      {
        isNotEmpty( allNotifications ) &&
        <PageAlerts>
          <button className="showNotificationsButton" onClick={ () => setShowNotificationsModal( true ) }>
            <InlineSVG type="notifications_nav" />
            <span className="notificationsCount">{ allNotifications.length }</span>
          </button>
        </PageAlerts>
      }
      {
        recordData.filters &&
        <PageHeader elementClass={ `setupPageHeader_${recordType}` }>
          <RecordFilters
            recordData={recordData}
            onRefresh={onRefresh}
            records={records}
          />
        </PageHeader>
      }
      {
        recordType === 'authentication_provider' &&
        <PageHeader elementClass={ `setupPageHeader_${recordType}` }>
          <AutoProvisionSelect
            records={records}
            globalSettings={globalSettings}
            setGlobalSettings={setGlobalSettings}
          />
        </PageHeader>
      }
    </React.Fragment>
  );
};

export default SetupPageHeader;
