/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 *                                                             *
 * Utilities to make the standard DOM API suck less            *
 ***************************************************************/
// eslint-disable-next-line max-len
/* eslint-disable max-len, camelcase, space-in-parens, indent, brace-style, eqeqeq, semi, quotes, curly, prefer-destructuring, comma-spacing, no-else-return, spaced-comment, comma-dangle, no-extra-semi, array-bracket-spacing, no-nested-ternary */
'use strict';

import { formatUnixTime } from '../react/shared/Utilities';
import {
  makeRequest,
} from './io';
import {
  readableBytes,
} from './util';

/**
 * Prepends Element or text nodes to another DOM element depending on the type
 *
 * Example:
 * $get('element-id').prepend(
 *     'hello',
 *     $e('span').append(' '),
 *     'world'
 * )
 */
function $_prepend(...children)
{
    children.map(child => {
        if(!this.firstChild) {
            this.append(child);
        } else if (child == null || child == undefined) {
            console.error('$_prepend: ', child, ' passed to .append(...)')
        } else if(typeof(child)=="string" || typeof(child)=="number") {
            this.insertBefore(document.createTextNode(child), this.firstChild);
        } else {
            this.insertBefore(child, this.firstChild);
        }
    });

    return this;
}

/**
 * Appends Element or text nodes to another DOM element depending on the type
 *
 * Example:
 * $get('element-id').append(
 *     'hello',
 *     $e('span').append(' '),
 *     'world'
 * )
 */
function $_append(...children)
{
    children.map(child => {
        if(typeof(child)=="object")
            this.appendChild(child);
        else if (child == null || child == undefined)
            console.error('$_append: ', child, ' passed to .append(...)')
        else
            this.appendChild(document.createTextNode(child));
    });

    return this;
}

/**
 * Fetches an element by its `id` and sets $_prepend and $_append functions.
 * Returns `undefined` if the element was not found
 *
 * Example:
 * var el = $get('element-id');
 * console.assert(el.append);
 * console.assert(el.prepend);
 */
export function $get(id)
{
    var ret_val = document.getElementById(id);
    if(!ret_val)
        return ret_val;
    ret_val.append = $_append.bind(ret_val);
    ret_val.prepend = $_prepend.bind(ret_val);
    return ret_val;
}

/**
 * Creates an Element object and sets $_prepend/$_append. The only required arg
 * is `tag` (a string), but `attributes` (object) and namespace (string) may
 * also be set.
 *
 * var el1 = $e('div').append('hello world!');
 * var el2 = $e('div', {'id':'message'}).append('hello world #2!');
 * var svg = $e('svg', {}, 'http://www.w3.org/2000/svg');
 */
export function $e(tag, attributes, namespace)
{
    var ret_val;
    if(namespace)
        ret_val = document.createElementNS(namespace, tag);
    else
        ret_val = document.createElement(tag);

    if(!ret_val)
        return ret_val;

    if(attributes)
    {
        for(var attr in attributes)
        {
            ret_val.setAttribute(attr, attributes[attr]);
        }
    }

    ret_val.append = $_append.bind(ret_val);
    ret_val.prepend = $_prepend.bind(ret_val);

    return ret_val;
}

/**
 * Deletes all children elements inside of an Element.
 *
 * clear_tag($get('list'));
 */
export function clear_tag(id_or_element, reset_scroll)
{
    if(!id_or_element)
        return;

    var e = id_or_element;
    if (typeof id_or_element === 'string')
        e = $get(id_or_element);

    while(e.childNodes.length > 0)
        e.removeChild(e.childNodes[0]);

    if(reset_scroll && e.scrollTop)
        e.scrollTop = 0;
}

// TODO: docs
export function parse_url_hash(url, param_delimiter /* '&' */, kv_delimiter /* '=' */)
{
    if(!url)
        url = document.location.href;
    if(!param_delimiter)
        param_delimiter = '&';
    if(!kv_delimiter)
        kv_delimiter = '=';

    var hash = '';
    if(url.search('#') > -1)
        hash = url.split('#',2)[1]

    var params = hash.split(param_delimiter);
    var ret_val = {};

    for (var i=0; i < params.length; i++)
    {
        var kv = params[i].split(kv_delimiter, 2); /* XXX: truncates if there are kv_delimiters in the value */
        ret_val[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
    }

    return ret_val;
}

// TODO: docs
export function serialize_url_hash(params, param_delimiter /* '&' */, kv_delimiter /* '=' */)
{
    if(!param_delimiter)
        var param_delimiter = '&';
    if(!kv_delimiter)
        var kv_delimiter = '=';

    function _encode(s)
    {
        s = new String(s);
        if(s)
            return (s.replace('%', '%25')
                    .replace(param_delimiter, '%'+param_delimiter.charCodeAt(0).toString(16))
                    .replace(kv_delimiter, '%'+kv_delimiter.charCodeAt(0).toString(16)));
        else
            return '';
    }

    var plist = [];
    for(var key in params)
    {
        if(key && !(key === ''))
            plist.push(_encode(key)+'='+_encode(params[key]));
    }

    return plist.join(param_delimiter);
}

// TODO: docs
export function set_url_hash(params, param_delimiter /* '&' */, kv_delimiter /* '=' */)
{
    window.location.hash = serialize_url_hash(params, param_delimiter, kv_delimiter);
}

// TODO: docs
export function update_url_hash(updates)
{
    var old_params = parse_url_hash();
    var new_params = Object.assign(old_params,updates);
    set_url_hash(new_params);
}

// TODO: docs
export function format_tz_offset(tz_offset)
{
    var tz_offset = ''+tz_offset/(60*60);
    if(tz_offset?.startsWith('-'))
        return tz_offset;
    else
        return '+'+tz_offset;
}

/////////////////////////// File Manager ///////////////////////////////
var FILE_MANAGER_COLUMNS = {
    // sort name => label
    'name': 'Filename',
    'modified' : 'Last Modified',
    'size': 'Size'
}

export function init_file_manager(root, initial_path) {
    clear_tag(root);

    // build form

    var buttons_root = $e('div', {'class':'file-manager-buttons-root'});
    var files_table_root = $e('div', {'class':'file-manager-files-table-root'});
    root.append(buttons_root, files_table_root);

    var button_upload = $e('button', {'class':'file-manager-button'}).append('Upload Files');
    var button_extract = $e('button', {'class':'file-manager-button'}).append('Extract (.zip)');
    var button_create_directory = $e('button', {'class':'file-manager-button'}).append('Create Directory');
    var button_delete = $e('button', {'class':'file-manager-button'}).append('Delete');
    var button_refresh = $e('button', {'class':'file-manager-button'}).append('Refresh');
    buttons_root.append(button_upload, button_extract, button_create_directory, button_delete, button_refresh);

    button_upload.onclick = () => {
        var file_chooser = $e('input', {'type':'file', 'accept':'*', 'style':'display: none;', 'multiple':'multiple'});
        file_chooser.onchange = (e) => {
            var total_files = e.target.files.length;
            var current_files = 0;

            for (var i = 0; i < e.target.files.length; i++) {
                var file = e.target.files[i];

                // cannot accept anything over 60 mb
                if (file.size > 60000000) {
                    alert(`${file.name} size of ${file.size / 1000000 } MB is too large, files must be less than 60 MB in size. Consider using a zipped version instead.`)
                } else {
                    var reader = new FileReader();

                    reader.onload = function(e) {
                        var filename = e.target.filename;
                        var contents = Array.from(new Uint8Array(e.target.result));
                        makeRequest('FWRITE', '/filemanager', { 'path': root.$path + '/' + filename, 'data': contents, 'chroot': root.$chroot }, (results) => {
                            if ((results['errors'] && results['errors'].length) || !results) {
                                alert('Failed to upload file ' + filename + ': ' + results['errors'].join('. '));
                            } else {
                                // refresh
                                change_file_manager_path(root, root.$path);
                            }
                        });
                    }
                    reader.filename = file.name;
                    reader.readAsArrayBuffer(file);
                };
            };
        };
        file_chooser.click();

        return false;
    }

    button_extract.onclick = () => {
        var selected_files = _file_manager_get_selected(root, false);
        for (var i = 0; i < selected_files.length; i++) {
            var file = selected_files[i];

            makeRequest('EXTRACT', '/filemanager', { 'path':root.$path+'/'+file, 'to_path':root.$path, 'chroot': root.$chroot }, (results) => {
                if (results['errors'] && results['errors'].length) {
                    alert('Failed to extract ' + file + ' file: ' + results['errors'].join('. '));
                }

                // refresh
                change_file_manager_path(root, root.$path);
            });
        }
        return false;
    }

    button_create_directory.onclick = () => {
        var dirname = prompt('Enter the new directory\'s name:');
        makeRequest('MKDIR', '/filemanager', { 'path':root.$path+'/'+dirname, 'chroot': root.$chroot }, (results) => {
            if (results['errors'] && results['errors'].length) {
                alert('Failed to create directory: ' + results['errors'].join('. '));
            }

            // refresh
            change_file_manager_path(root, root.$path);
        });
        return false;
    }

    button_delete.onclick = () => {
        var selected_files = _file_manager_get_selected(root, false);
        if (selected_files && confirm('Are you sure you want to delete the selected files/directories?\n\n* ' + selected_files.join('\n* ') + '\n\nNOTE: There is no way to undo this action.')) {
            makeRequest('DELETE', '/filemanager', { 'paths':_file_manager_get_selected(root, true), 'chroot': root.$chroot }, (results) => {
                if (results['errors'] && results['errors'].length) {
                    alert('Failed to delete files/directories: ' + results['errors'].join('. '));
                }

                change_file_manager_path(root, root.$path);
            });
        }
        return false;
    }

    button_refresh.onclick = () => {
        change_file_manager_path(root, root.$path);
        return false;
    }

    var files_header = $e('div', {'class':'file-manager-table-header'});
    files_table_root.append(files_header);
    root.$_files_header = files_header;

    var files_table = $e('div', {'class':'file-manager-files-table'});
    files_table_root.append(files_table);

    var files = $e('div', {'class':'file-manager-files-files'});
    root.$_files_div = files;
    var columns = [
        $e('div', {'class':'file-manager-column'}) // checkbox
    ];
    for (const [key, label] of Object.entries(FILE_MANAGER_COLUMNS)) {
        var link = $e('a', {'href':'javascript:void(0);'}).append(label);
        link.onclick = (function(key) {
            if (root.$sort_key === key) {
                root.$sort_desc = !root.$sort_desc;
            } else {
                root.$sort_key = key;
            }

            _rerender_file_manager_files(root);
        }).bind(null, key);
        columns.push($e('div', {'class':'file-manager-column file-manager-link'}).append(link));
    }
    files_table.append($e('div', {'class':'file-manager-row file-manager-table-header'}).append(...columns), files);

    if (!initial_path) {
        console.error('Please supply an initial path to load.');
        return false;
    }

    if (!initial_path.endsWith('/')) {
        initial_path += '/';
    }

    root.$chroot = initial_path;
    root.$path = initial_path;
    root.$files = [];
    root.$sort_key = 'name';
    root.$sort_desc = true;

    change_file_manager_path(root, initial_path);
    return root;
}

function change_file_manager_path(root, path) {
    if (!path.endsWith('/')) {
        path += '/';
    }

    makeRequest('LIST', '/filemanager', { 'path': path, 'chroot': root.$chroot }, (results) => {
        if (results['errors']) {
            alert('Failed to change paths: ' + results['errors'].join('. '));
        } else {
            root.$_files_header.innerText = results['root'];
            root.$path = results['root'];
            root.$files = results['files'];
            _rerender_file_manager_files(root);
        }
    });
}

function _file_manager_get_selected(root, prefix_paths) {
    return Array.from(root.querySelectorAll('input[type=checkbox]:checked'))
        .map((el) => (prefix_paths ? root.$path + '/' : '') + el.name);
}

function _file_manager_set_selected(root, files) {
    Array.from(root.querySelectorAll('input[type=checkbox]')).forEach(((el) => {
        el.checked = files.includes(el.name);
    }));
}

function __sort_file_manager(key, files, sort_desc) {
    var numb = sort_desc ? 1 : -1;
    return files.sort((a, b) => {
        // always sort .. first
        if (a['name'] === '..') {
            return -1;
        } else if (b['name'] === '..') {
            return 1;
        }

        // sort by key for remaining
        return (a[key] > b[key]) ? numb : ((b[key] > a[key]) ? -numb : 0);
    });
}

function _rerender_file_manager_files(root) {
    root.$files = __sort_file_manager(root.$sort_key, root.$files, root.$sort_desc);
    var previously_selected = _file_manager_get_selected(root, false);
    clear_tag(root.$_files_div);

    if (root.$files.length) {
        root.$files.forEach((file) => {
            var row = $e('div', {'class': 'file-manager-row file-manager-files'});
            var unique_id = 'checkbox-' + Math.floor(Math.random() * 1000000000); // XXX: use a uuidv4 or another solution

            var checkbox = $e('input', {'type': 'checkbox', 'id': unique_id, 'name': file['name']});
            var checkbox_wrapper = $e('div', {'class':'file-manager-column', 'style': (
                    // hide the checkbox for .. only
                    file['name'] === '..' ? 'visibility: hidden' : ''
            )}).append(
                checkbox
            );

            // only used for directories
            var enter_dir = () => {
                change_file_manager_path(root, root.$path + '/' + file['name']);
                return false;
            };

            /*
            // XXX: figure out why this fires twice. this should allow users to
            //   just click on the rows and it auto enters the directories /
            //   clicks the checkboxes.
            row.onclick = (e) => {
                e.preventDefault();
                checkbox.click();
                debugger;
                return false;
            }

            if (file['type'] === 'directory') {
                row.ondblclick = enter_dir;
            }
            */

            // create a row
            root.$_files_div.append(row.append(
                checkbox_wrapper,

                // create each column in the row
                ...Array.from(Object.keys(FILE_MANAGER_COLUMNS)).map((column) => {
                    if (column === 'name') {
                        if (file['type'] === 'file') {
                            return $e('label', {'class':'file-manager-column', 'for':unique_id}).append(file[column]);
                        } else {
                            var link = $e('a', {'class':'file-manager-column file-manager-link', 'href':'javascript:void(0);'});
                            link.append(file[column]);
                            link.onclick = enter_dir;
                            return link;
                        }
                    } else if (column === 'size') {
                        return $e('div', {'class':'file-manager-column'}).append(''+(file[column] == null ? '' : readableBytes(file[column])));
                    } else if (column === 'modified') {
                        return $e('div', {'class':'file-manager-column'}).append(''+(file[column] == null ? '' : formatUnixTime(file[column], false)));
                    }
                })
            ));
        });
    } else {
        root.$_files_div.append($e('center').append('No files in this directory yet'))
    }

    _file_manager_set_selected(root, previously_selected);
}