/** *************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import ActivityItem from './ActivityItem';

const ActivityList = ( {
  items,
  viewItem,
  toggleItemVisibility,
  itemType,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( items ) &&
        <ul className="activityList">
          {
            items.map( ( item, index ) => {
              return <ActivityItem
                key={ index }
                item={ item }
                viewItem={ viewItem }
                toggleItemVisibility={ toggleItemVisibility }
                itemType={ itemType }
              />;
            } )
          }
        </ul>
      }
    </React.Fragment>
  );
};

export default ActivityList;