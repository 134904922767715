/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty } from '../Utilities';
import './Categories.scss';

const Categories = ( {
  data,
  total,
  onHover,
  hoveredSeriesIdentifier,
  setHoveredSeriesIdentifier,
  selectedSeriesIdentifier,
  setSelectedSeriesIdentifier,
  onClickCallback,
  allowMultipleSelections=false,
} ) => {
  const containerHeight = 6;
  const containerWidth = 100;
  const barGap = 0.75;

  const [ categories, setCategories ] = React.useState( null );

  React.useEffect( () => {
    const _categories = [];

    if ( isNotEmpty( total ) && isNotEmpty( data ) ) {
      const ratio = ( containerWidth - ( ( Object.values( data ).length - 1 ) * barGap ) ) / total;

      Object.values( data ).map( ( category, index ) => {
        const { key, label, fill, value } = category;

        const prevWidth = Object.values( data ).reduce( ( accum, current, _index ) => {
          if ( _index < index ) {
            return accum + current.value;
          }
          return accum;
        }, 0 );

        _categories.push(
          {
            key,
            label,
            value,
            x: ( prevWidth * ratio ) + ( index * barGap ),
            fill,
            width: ( value * ratio ),
          },
        );
      } );
    }
    setCategories( _categories );
  }, [ data, total ] );

  const handleMouseClick = series => {
    let identifier;

    if ( allowMultipleSelections ) {
      identifier = [ series.key ];
      if ( isNotEmpty( selectedSeriesIdentifier ) ) {
        identifier = [ ...selectedSeriesIdentifier, series.key ];
      }
    } else {
      identifier = series.key;
    }

    setSelectedSeriesIdentifier( identifier );

    if ( isNotEmpty( onClickCallback ) ) {
      onClickCallback( identifier );
    }
  };

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {

      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHover ) ) {
        onHover( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHover ) ) {
        onHover( null );
      }
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( categories ) &&
        <svg
          viewBox={ `0 0 ${containerWidth} ${containerHeight}` }
          xmlns="http://www.w3.org/2000/svg"
          className="categoriesBreakdownChart"
          preserveAspectRatio="none"
        >
          {
            categories.map( ( cat, i ) => {
              return <rect
                key={i}
                x={( cat.x < 0 || isEmpty( cat.x ) || isNaN( cat.x ) ) ? 0 : cat.x}
                y={0}
                width={( cat.width < 0 || isEmpty( cat.width ) || isNaN( cat.width ) ) ? 0 : cat.width }
                height={containerHeight}
                rx={1}
                fill={ cat.fill }
                fillOpacity={ hoveredSeriesIdentifier === cat.key ? 0.7 : 1 }
                // eslint-disable-next-line max-len
                className={ `${ isNotEmpty( setSelectedSeriesIdentifier ) ? 'isClickable' : '' } ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === cat.key ? 'isHovered' : '' } categoryRect ${cat.key}`}
                onMouseEnter={ () => handleMouseEnter( cat ) }
                onMouseLeave={ handleMouseLeave }
                onClick={ () => handleMouseClick( cat ) }
              />;
            } )
          }
        </svg>
      }
    </React.Fragment>
  );
};

export default Categories;