/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Filter from './Filter';
import Accordion from '../Accordion';

import {
  isEmpty, isNotEmpty, paramsToFilters,
} from '../Utilities';

const InputGroup = ( {
  name,
  inputs,
  values,
  horizontal,
  disabled,
} ) => {

  const [ collapsed, setCollapsed ] = React.useState( true );

  React.useEffect( () => {
    if ( name === 'all' ) {
      setCollapsed( false );
    } else {

      // going to collapse the group by default unless something within this group has a value,
      // then show it
      const inputAttrs = inputs.map( i => i.attribute );

      const groupedValues = [];

      const _values = paramsToFilters();

      Object.entries( _values ).map( ( [ attr, data ] ) => {
        if ( inputAttrs.includes( attr ) ) {
          groupedValues.push( data );
        }
      } );
      // eslint-disable-next-line max-len
      const isCollapsed = inputs.some( i => i.grouped && i.grouped.collapsed ) && groupedValues.every( v => isEmpty( v ) );
      setCollapsed( isCollapsed );
    }

  }, [ inputs, name, values ] );

  return (
    <React.Fragment>
      {
        name === 'all'
          ? <React.Fragment>
            {
              isNotEmpty( inputs ) && inputs.map( ( input, index ) => {
                return  <Filter
                  value={values[input.attribute] || ''}
                  inputs={inputs}
                  values={values}
                  key={index}
                  input={input}
                  horizontal={horizontal}
                  disabled={disabled}
                />;
              } )
            }
          </React.Fragment>
          : <Accordion>
            <div className={`${collapsed ? 'collapsed' : ''} accordionWrapper`}>
              <div
                className="accordionHeader"
              >
                <button
                  onClick={ () => setCollapsed( !collapsed ) }
                >
                  { collapsed ? 'View' : 'Hide' }
                </button>
                <h3>{ name }</h3>
              </div>
              <div className="accordionBody">
                {
                  isNotEmpty( inputs ) && inputs.map( ( input, index ) => {
                    return  <Filter
                      value={values[input.attribute] || ''}
                      inputs={inputs}
                      values={values}
                      key={index}
                      input={input}
                      horizontal={horizontal}
                      disabled={disabled}
                    />;
                  } )
                }
              </div>
            </div>
          </Accordion>
      }

    </React.Fragment>
  );
};

export default InputGroup;