/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpTrigger } from '../../components/HelpDocumentation/ContextualHelp';
import InlineSVG from '../InlineSVG';

import './style.scss';
import { isNotEmpty } from '../Utilities';


const ExploitStatus = ( {
  status,
  fullVersion=true,
  alignment='vertical',
} ) => {

  const exploitStatus = {
    'weaponized': 'Weaponized',
    'poc': 'PoC Published',
    'published_details': 'Details Published',
    'private': 'Private',
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( status ) &&
        <span className={ `exploitStatusWrapper ${alignment}` } >
          {
            alignment === 'horizontal' &&
            <strong>Exploit Status: </strong>
          }
          <span
            className={ `exploitStatusIcons ${status}`}
          >
            <InlineSVG type="attack" />
            <InlineSVG type="attack" />
            <InlineSVG type="attack" />
          </span>
          {
            fullVersion &&
            <span className="statusTitle">
              { alignment !== 'horizontal' && exploitStatus[status] }
              <HelpTrigger helpKey="exploit_status" />
            </span>
          }
        </span>
      }
    </React.Fragment>

  );
};

export default ExploitStatus;