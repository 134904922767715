/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import { isNotEmpty } from '../shared/Utilities';

export const ModelContext = React.createContext();

export const ModelProvider = ( { children } ) => {

  const [ globalModel, setGlobalModel ] = React.useState( {} );
  // const [ globalProjectSettings, setGlobalProjectSettings ] = React.useState( {} );

  let isMounted = true;

  const refreshModel = async () => {
    const response = await makeRequest( 'FETCH', '/model', { project: 'default', model: 'base' } );
    if ( isMounted && response.results?.risk !== undefined ) {
      setGlobalModel( response.results );
    }
  };

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  const RefreshModelData = async ( types=[] ) => {
    if ( isNotEmpty( types ) ) {
      if ( types.includes( 'model' ) ) {
        await refreshModel();
      }
    } else {
      await refreshData();
    }
  };

  const refreshData = async () => {
    await refreshModel();
  };

  const data = [
    globalModel,
    RefreshModelData,
  ];

  return (
    <ModelContext.Provider value={ data }>
      { children }
    </ModelContext.Provider>
  );
};