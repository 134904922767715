/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';

const PatchHeader = ( { patch, options, handleClose, collapsed, setCollapsed, context } ) => {

  const getExploreLink = ( record, recordType ) => {
    // eslint-disable-next-line max-len
    return `#.=explore&page=explore_model&type=scope&explore_${ recordType === 'host' ? 'scope' : recordType }=%5B"${record.id}"%5D`;
  };

  const handleExploreClick = () => {
    if ( isNotEmpty( patch ) ) {
      window.open( getExploreLink( patch, 'patch' ) );
    }
  };

  const riskClass = () => {
    if ( isNotEmpty( patch ) ) {
      if ( 'risk' in patch ) {
        return riskToRating( patch.risk );
      }
      if ( 'direct_risk' in patch ) {
        return riskToRating( patch.direct_risk );
      }
      if ( 'filtered_risk' in patch ) {
        return riskToRating( patch.filtered_risk );
      }
      return 'unknown';
    }
    return 'unknown';
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( patch ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <InlineSVG type="draggable" elementClass="draggableIcon" />
              }
              <div className={ `recordIconWrapper ${ riskClass() }` } >
                <InlineSVG type="patch_record" />
              </div>
              <span className="label">
                { reportTypeDisplayName( patch, 'patch' ) }
              </span>
            </h2>
            <div className="right">
              {
                ( isNotEmpty( options ) && 'include_risk' in options )
                  ? <React.Fragment>
                    {
                      options.include_risk === true &&
                      <RatingBadge rating={ riskClass() } />
                    }
                  </React.Fragment>
                  : <RatingBadge rating={ riskClass() } />
              }
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button
                  className="recordCardCloseButton roundGlyphButton light"
                  onClick={ handleClose }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              }
            </div>
          </div>
          <div className={ `recordCardHeaderRow__dividerBar ${ riskClass() }` } />
          <div className="recordCardHeaderRow">
            <div className="left"></div>
            <div className="right">
              {
                context !== 'explore' &&
                <button
                  className="roundGlyphButton light"
                  // eslint-disable-next-line max-len
                  onClick={ handleExploreClick }
                >
                  <InlineSVG type="explore_nav" />
                </button>
              }
              {
                context === 'explore' &&
                <React.Fragment>
                  {
                    options?.isCollapsible &&
                    <button
                      className="roundGlyphButton light"
                      // eslint-disable-next-line max-len
                      onClick={ () => setCollapsed( !collapsed ) }
                    >
                      {
                        collapsed
                          ? <InlineSVG type="expand" />
                          : <InlineSVG type="collapse" />
                      }
                    </button>
                  }
                </React.Fragment>
              }
              {
                context !== 'record_details' &&
                <button
                  className="roundGlyphButton light"
                  // eslint-disable-next-line max-len
                  onClick={ () => window.open( `#.=risk_insight&report=patches&risk_type=risk&item_count=100&superseded=unsuperseded&order_by=filtered_risk&order_direction=DESC&accepted_risk=any&item=${patch?.id}&current_page=1&panel_tab=risk_and_remediation` ) }
                >
                  <InlineSVG type="newTabLink" />
                </button>
              }
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PatchHeader;