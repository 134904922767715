/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './ScanningAgeBreakdown.scss';
import { isNotEmpty, globalColors, formatNumber } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

const ScanningAgeBreakdown = ( { data, settings } ) => {

  const scanningAgeOrder = {
    /* eslint-disable camelcase */
    last_24_hours: { label: '24 Hours', fill: globalColors['status--green'] },
    last_7_days: { label: '7 Days', fill: globalColors['status--green--50'] },
    last_30_days: { label: '30 Days', fill: globalColors['grey--divider'] },
    greater_than_30_days: { label: '>30 Days', fill: globalColors['status--red--40'] },
    never_scanned: { label: 'Never Scanned', fill: globalColors['status--red'] },
    /* eslint-enable camelcase */
  };

  const [ ageTotal, setAgeTotal ] = React.useState( 1 );
  const [ ageData, setAgeData ] = React.useState( null );

  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );

  const setupData = ( tallyData ) => {
    const _ageData = {};

    setAgeTotal( tallyData?.category?.all );

    const zeroed = tallyData?.category?.all === 0;

    Object.entries( scanningAgeOrder ).map( ( [ key, data ] ) => {
      _ageData[key] = {
        stroke: data.fill,
        value: zeroed ? zeroed : ( ( tallyData.category[key] / ( tallyData?.category?.all || 1 ) ) * 100 ),
        total: tallyData.category[key],
        key,
      };
    } );

    setAgeData( _ageData );
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data );
    }
  }, [ data, settings ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( ageTotal ) && isNotEmpty( ageData ) ) &&
        <React.Fragment>
          {
            settings?.scanning_type === 'credentialed'
              ? <p><strong>{ formatNumber( ageTotal ) }</strong> hosts scanned via credentialed scans</p>
              : <p><strong>{ formatNumber( ageTotal ) }</strong> hosts scanned via user managed/agent scans</p>
          }
          <Donut
            data={ageData}
            total={ageTotal}
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ ageData }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScanningAgeBreakdown;