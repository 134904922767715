/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import { isNotEmpty, paramsToFilters } from '../../Utilities';

const Hidden = ( {
  input,
  value,
  handleChange,
} ) => {

  const inputRef = React.useRef( null );

  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input, inputRef ] );

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute];
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      inputRef.current.value = newValue || '';
    }
  };

  const onChange = e => {
    const value = e.target.valueAsNumber || e.target.value;
    handleChange( input.attribute, value );
  };

  React.useEffect( () => {
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      inputRef.current.value = value;
    }
  }, [ value, inputRef ] );

  return (
    <React.Fragment>
      <input
        ref={inputRef}
        type={input.type}
        onChange={ onChange }
      />
    </React.Fragment>
  );
};

export default Hidden;
