/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

import './HorizontalBar.scss';

const HorizontalBar = ( {
  data,
  onHover=null,
  onClick=null,
  maxOverride=null,
  hoveredSeriesIdentifier,
  setHoveredSeriesIdentifier,
  svgAspectRatio=null,
  gradient=false,
  reverseGradient=false,
} ) => {

  const [ max, setMax ] = React.useState( 1 );

  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) ) {

      let _max = 1;

      if ( maxOverride ) {
        _max = maxOverride;
      } else {
        Object.values( data ).map( ( barData ) => {
          if ( barData.value > _max ) {
            _max = barData.value;
          }
        } );
      }
      setMax( _max );
    }
  }, [ data, svgAspectRatio ] );

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {

      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHover ) ) {
        onHover( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHover ) ) {
        onHover( null );
      }
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( data ) && isNotEmpty( max ) ) &&
        <div className="horizontalBarChartWrapper">
          {
            Object.values( data ).map( ( barData, index ) => {

              const itemCount = Object.values( data ).length;

              let opacity = 1;

              let opacityMultiplier = 0.05;

              if ( itemCount >= 25 ) {
                opacityMultiplier = 0.025;
              }

              if ( itemCount > 40 ) {
                opacityMultiplier = 0.0125;
              }

              if ( gradient ) {
                if ( reverseGradient ) {
                  opacity = 1 - ( ( itemCount - index ) * opacityMultiplier );
                } else {
                  opacity = 1 - ( index * opacityMultiplier );
                }
              }

              return <div
                key={index}
                className={ `barPlusLabelWrapper ${ isNotEmpty( barData.label ) ? 'withLabel' : ''}` }
              >
                <div
                  // eslint-disable-next-line max-len
                  className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } fullBarWrapper ${ isNotEmpty( onClick ) ? 'clickable' : '' }` }
                  onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
                  onMouseEnter={ () => handleMouseEnter( barData ) }
                  onMouseLeave={ handleMouseLeave }
                >
                  <div
                    // eslint-disable-next-line max-len
                    className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barContainer`}
                    onMouseEnter={ () => handleMouseEnter( barData ) }
                    onMouseLeave={ handleMouseLeave }
                  />
                  <div
                    id={
                      barData?.original?.id
                        ? `barSegmentID_${barData?.original?.id}`
                        : `randomSegment_${Math.random()}`
                    }
                    style={ {
                      background: barData.fill,
                      height: `${ ( barData.value / max ) * 100 }%`,
                      minHeight: '0.5em',
                      opacity,
                    } }
                    // eslint-disable-next-line max-len
                    className={ `${ barData.outline ? 'outline' : '' } ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === barData.key ? 'isHovered' : '' } barSegment`}
                    onMouseEnter={ () => handleMouseEnter( barData ) }
                    onMouseLeave={ handleMouseLeave }
                  />
                </div>
                {
                  isNotEmpty( barData.label ) &&
                  <label>{ barData.label }</label>
                }
              </div>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default HorizontalBar;