/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../../../shared/EmptyState';
import Loading from '../../../shared/Loading';
import { decodeURLHash, isNotEmpty } from '../../../shared/Utilities';
import ExploreItem from './ExploreItem';

const ExploreList = ( {
  items,
  selected,
  setSelected,
  exploreItems,
  setExploreItems,
  displayExploreItemNameFor,
  loading,
  toggleSelection,
} ) => {

  const [ itemType, setItemType ] = React.useState( 'scope' );

  React.useEffect( () => {
    setItemType( decodeURLHash()['type'] );
  }, [ items ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( items )
          ? <ul>
            {
              items.map( ( item, index ) => {
                return  <ExploreItem
                  key={index}
                  item={item}
                  type={itemType}
                  selected={selected}
                  setSelected={setSelected}
                  displayExploreItemNameFor={displayExploreItemNameFor}
                  items={items}
                  exploreItems={exploreItems}
                  setExploreItems={setExploreItems}
                  toggleSelection={toggleSelection}
                />;
              } )
            }
          </ul>
          : <div className="loadingWrapper">
            {
              loading
                ? <div className="loadingWrapper">
                  <Loading />
                </div>
                // eslint-disable-next-line max-len
                : <EmptyState message={`No ${itemType === 'vulnerability' ? 'vulnerabilities' : `${itemType}s`} matched your filters`} />
            }
          </div>

      }
    </React.Fragment>
  );
};

export default ExploreList;