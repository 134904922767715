// returns the defaults for advanced filter,
// the current values are compared to these and if they do not match
// a little pill is shown to the user so that they know what filters are applied
// NOTE: only filters that matter are ones outside of the alwaysVisible group
// because the user will be able to see those

import React from 'react';

import { makeRequest } from '../../../legacy/io';
import {
  formatUnixDate,
  isNotEmpty,
  shortenedVulnerabilityScannerNameMap,
  vulnerabilityScannerInstanceName,
  vulnerabilityScannerLogo,
} from '../Utilities';
import { getRecord, getRecords } from '../RecordCache';

/* eslint-disable camelcase */
export const defaultFilterValues = {
  instance: {
    asset_tag_ids: [],
    host_ids: [],
    patch_ids: [],
    vulnerability_ids: [],
    signature_ids: [],
    host_globs: '',
    patch_globs: '',
    vulnerability_globs: '',
    exploit_status: 'null',
    accepted_risk: 'false',
  },
  host: {
    sensitive_assets: 'any',
    asset_tag_ids: [],
    accepted_risk: 'false',
    risk_reduction: [ 'gt_map', '' ],

  },
  vulnerability: {
    asset_tag_ids: [],
    patchable: 'any',
    published_before: '',
    published_after: '',
    exploit_status: 'null',
    accepted_risk: 'false',
    age: [ 'gt_map', '' ],
    risk_reduction: [ 'gt_map', '' ],
    cvss_base_score: [ 'gt_map', '' ],
  },
  patch: {
    asset_tag_ids: [],
    superseded: 'any',
    published_before: '',
    published_after: '',
    accepted_risk: 'false',
    age: [ 'gt_map', '' ],
    risk_reduction: [ 'gt_map', '' ],
    num_hosts: [ 'gt_map', '' ],
    risk_type: 'risk',
  },
  path: {},
  user: {},
  scans: {
    asset_tag_ids: [],
    host_ids: [],
    patch_ids: [],
    vulnerability_ids: [],
    signature_ids: [],
    host_globs: '',
    patch_globs: '',
    vulnerability_globs: '',
    exploit_statuses: [ 'private' ],
  },
};

export const ignoredFieldsForAppliedFilters = [
  'order_by',
  'order_direction',
  'include_risk',
  'item_count',
  'group_type',
  'instances_visual_mode',
];

export const acceptedRiskFilterOptions = {
  false: 'Hide accepted risk items',
  true: 'Show accepted risk items only',
  any: 'Show all items',
};

export const supersededFilterOptions = {
  fully: 'Fully superseded',
  unsuperseded: 'Not superseded',
  any: 'Any',
  partial: 'Partially superseded',
};

export const separateValues = [
  'exploit_status',
  'exploit_statuses',

  // asset tags needs to be done in the applied filter componenet, so that it can utilize the react context
  'asset_tag_ids',
  'third_party_setting_ids',

  // 'filters' in the tag editor (v3)
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const tagFilterAttributes = [
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const includedTagFilterAttributes = [
  'included_ranges',
  'included_host_patterns',
  'included_host_ids',
  'included_product_names',
];

// 'filters' in the tag editor (v3) used to display values and a few other things
export const excludedTagFilterAttributes = [
  'excluded_ranges',
  'excluded_host_patterns',
  'excluded_host_ids',
  'excluded_product_names',
];

export const needsRecordLabel = [
  'host_ids',
  'patch_ids',
  'vulnerability_ids',
];

export const omitLabels = [
  ...needsRecordLabel,
  'asset_tag_ids',
  'third_party_setting_ids',
];

export const deprioritizedCategoryClasses = [
  'deprioritized',
  'overridden',
  'not_exploitable',
  'dos_only',
];
export const forReviewCategoryClasses = [
  'for_review',
  'missing_host',
  'unrecognized',
  'cannot_model',
  'insufficient_information',
  'missing_capability',
];
export const prioritizedCategoryClasses = [
  'prioritized',
  'carries_risk',
  'unreachable',
];

const getThirdPartySettings = async( settings ) => {
  if ( isNotEmpty( settings ) ) {
    const thirdPartyResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
    } );

    const credentialResponse = await makeRequest( 'SEARCH', '/project/default/credential', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'protocol', 'domain', 'username', 'eval_order', 'options', 'label' ],
      // eslint-disable-next-line camelcase
      order_by: [ [ 'eval_order', 'ASC' ] ],
    } );

    if ( isNotEmpty( thirdPartyResponse ) && isNotEmpty( thirdPartyResponse.results ) ) {
      const appliedSettings = [];

      thirdPartyResponse.results.map( s => {
        if ( settings.includes( s.id ) ) {
          const cred = credentialResponse.results.find( c => c.id === s.settings?.credential_id );
          const setting = {
            icon: vulnerabilityScannerLogo( s.tool ),
            record: s,
          };

          if ( isNotEmpty( cred ) ) {
            const combined = { ...s, settings: { ...s.settings, ...cred } };
            // eslint-disable-next-line max-len
            setting.label = <div className="vulnScannerInstanceNameWrapper">
              <strong>{shortenedVulnerabilityScannerNameMap[s.tool]}</strong>
              <span>({vulnerabilityScannerInstanceName( combined )})</span>
            </div>;
          } else {
            setting.label = <div className="vulnScannerInstanceNameWrapper">
              <strong>{shortenedVulnerabilityScannerNameMap[s.tool]}</strong>
              <span>(Manual Upload)</span>
            </div>;
          }
          appliedSettings.push( setting );
        }
      } );
      return appliedSettings;
    }
    return [];
  }
  return [];
};

const typeMap = type => {
  if ( type === 'scanner_signature' ) {
    return 'signature';
  }
  // if ( type === 'host' ) {
  //   return 'scope';
  // }
  return type;
};

export const getRecordForType = async( type, id ) => {
  let record;
  if ( type === 'host' ) {
    record = await getRecord( typeMap( type ), id, {}, true );
  } else {
    record = await getRecord( typeMap( type ),  id,  {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'scanner', 'signature', 'identifier', 'vendor', 'label', 'title' ],
    } );
  }
  return record;
};

export const appliedFilterLabelForAttribute = ( attribute, field ) => {
  if ( isNotEmpty( attribute ) && isNotEmpty( field ) ) {
    if ( attribute === 'age_start' ) {
      return 'Newer than';
    }
    if ( attribute === 'age_end' ) {
      return 'Older than';
    }
    if ( isNotEmpty( field ) && isNotEmpty( field.label ) ) {
      return field.label;
    }
    return 'N/A';
  }
  if ( isNotEmpty( field ) && isNotEmpty( field.label ) ) {
    return field.label;
  }
  return 'N/A';
};

export const appliedFiltersTransformMap = {
  published_before: { formatter: date => formatUnixDate( date ) },
  published_after: { formatter: date => formatUnixDate( date ) },
  // eslint-disable-next-line max-len
  exploit_status: { formatter: statuses => isNotEmpty( statuses ) ? statuses : [] },
  // eslint-disable-next-line max-len
  exploit_statuses: { formatter: statuses => isNotEmpty( statuses ) ? statuses : [] },
  host_ids: { isPromise: true, formatter: id => getRecordForType( 'scope', id ) },
  patch_ids: { isPromise: true, formatter: id => getRecordForType( 'patch', id ) },
  vulnerability_ids: { isPromise: true, formatter: id => getRecordForType( 'vulnerability', id ) },
  signature_ids: { isPromise: true, formatter: id => getRecordForType( 'scanner_signature', id ) },
  // eslint-disable-next-line max-len
  asset_tag_ids: { formatter: ids => isNotEmpty( ids ) ? ids : [] },
  third_party_setting_ids: { isPromise: true, formatter: settings => getThirdPartySettings( settings ) },
  accepted_risk: { formatter: value => acceptedRiskFilterOptions[value] },
  superseded: { formatter: value => supersededFilterOptions[value] },

  age: { formatter: value => `${ value[0] === 'gt_map' ? 'Older than' : 'Newer than' } ${value[1]} day(s)` },

  risk_reduction: { formatter: value => `${ value[0] === 'gt_map' ? '>' : '<' } ${value[1]}%` },
  num_hosts: { formatter: value => `${ value[0] === 'gt_map' ? '>' : '<' } ${value[1]} hosts` },
  cvss_base_score: { formatter: value => `${ value[0] === 'gt_map' ? '>' : '<' } ${value[1]}` },

  // 'filters' in the tag editor (v3) that also use the applied filter buttons
  included_ranges: { formatter: ips => isNotEmpty( ips ) ? ips : [] },
  excluded_ranges: { formatter: ips => isNotEmpty( ips ) ? ips : [] },

  included_host_patterns: { formatter: patterns => isNotEmpty( patterns ) ? patterns : [] },
  excluded_host_patterns: { formatter: ids => isNotEmpty( ids ) ? ids : [] },

  included_host_ids: { isPromise: true, formatter: id_list => getRecords( 'scope', { id_list } ) },
  excluded_host_ids: { isPromise: true, formatter: id_list => getRecords( 'scope', { id_list } ) },

  included_product_names: { formatter: versions => isNotEmpty( versions ) ? versions : [] },
  excluded_product_names: { formatter: versions => isNotEmpty( versions ) ? versions : [] },

  risk_type: { formatter: type => type === 'direct_risk' ? 'Direct' : 'Cumulative' },
};
/* eslint-enable camelcase */