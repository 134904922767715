/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { decodeURLHash, isEmpty, isNotEmpty } from '../../shared/Utilities';
import RatingBadge from '../../shared/RatingBadge';

import './PageSelector.scss';
import InlineSVG from '../../shared/InlineSVG';
import { NavigationContext } from '../../Contexts/Navigation';
import { isBetaPage, isNewPage } from '../App/Routing';
import RouteSubNavigation from './RouteSubNavigation';

const PageSelectorMenu = ( {
  currentRouteItem,
  shouldShowMenu,
  pageIsCurrent,
  onSelectCallback,
} ) => {

  const root = document.getElementById( 'pageSelectorPortal' );

  return ReactDOM.createPortal(
    <RouteSubNavigation
      item={ currentRouteItem }
      currentRoute={ decodeURLHash()['.'] }
      currentPage={ decodeURLHash().report ? decodeURLHash().report : decodeURLHash().page }
      shouldShowMenu={ shouldShowMenu }
      pageIsCurrent={ pageIsCurrent }
      renderContext="pageSelector"
      onSelectCallback={ onSelectCallback }
    />,
    root,
  );
};

const PageSelector = ( ) => {

  // const [ subNavigationItems, setSubNavigationItems ] = React.useState( null );
  const [ selectedPage, setSelectedPage ] = React.useState( null );
  const [ showSelector, setShowSelector ] = React.useState( false );
  const [ currentRouteItem, setCurrentRouteItem ] = React.useState( null );
  const [ , , routing ] = React.useContext( NavigationContext );

  const shouldShowMenu = () => showSelector;

  const refreshPageSelector = ( ) => {
    const hash = decodeURLHash();

    const currentRoute = hash['.'];
    const currentPage = isNotEmpty( hash.report ) ? hash.report : hash.page;

    let _selectedPage;

    if ( isNotEmpty( currentRoute ) && isNotEmpty( currentPage ) && isNotEmpty( routing ) ) {
      const _currentRouteItem = routing[currentRoute];

      if ( isNotEmpty( _currentRouteItem ) && isNotEmpty( _currentRouteItem.items ) ) {
        setCurrentRouteItem( _currentRouteItem );


        if ( isNotEmpty( currentPage ) ) {

          if ( isNotEmpty( _currentRouteItem ) && isNotEmpty( _currentRouteItem.items ) ) {
            for ( const section of Object.values( _currentRouteItem.items ) ) {
              if ( currentPage === section.slug  && isNotEmpty( section.component ) ) {
                _selectedPage = section;
                break;
              // seeing if it matches a sub-page of a route section ( ie Reports > Standard > Hosts )
              } else if ( isNotEmpty( section.items ) ) {
                for ( const page of Object.values( section.items ) ) {
                  if ( currentPage === 'custom' ) {
                    _selectedPage = section.items[hash.item];
                    break;
                  } else if ( currentPage === page.slug ) {
                    _selectedPage = page;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    setSelectedPage( _selectedPage );
  };

  React.useEffect( () => {
    refreshPageSelector();
    window.addEventListener( 'hashchange', refreshPageSelector );
    return () => window.removeEventListener( 'hashchange', refreshPageSelector );
  }, [ routing ] );

  const pageIsCurrent = item => {

    const hash = decodeURLHash();

    const currentRoute = hash['.'];
    const currentPage = isNotEmpty( hash.report ) ? hash.report : hash.page;

    // early return for somehow empty routs
    if ( isEmpty( currentRoute ) && item.isCurrent === 'reports' ) {
      return true;
    }

    if ( Array.isArray( item.isCurrent ) ) {
      return item.isCurrent.includes( currentPage ) && currentRoute === item.slug;
    }
    return currentRoute === item.isCurrent && currentRoute === item.slug;
  };

  const onSelectCallback = () => setShowSelector( false );

  return (
    <React.Fragment>
      {
        showSelector &&
        <div className="pageSelectorShade" onClick={ () => setShowSelector( false ) } />
      }
      {
        ( isNotEmpty( currentRouteItem ) && isNotEmpty( selectedPage ) ) &&
        <div className="pageSelectorWrapper">
          <div
            id="pageSelectorTrigger"
            className={ `pageSelectorTrigger ${ showSelector ? 'open' : '' }` }
            onClick={ () => setShowSelector( !showSelector ) }
          >
            <span className="labelWrapper">
              <InlineSVG type="menu" elementClass="menuIcon" />
              {
                isNotEmpty( selectedPage.parentLabel ) &&
                <span className="parentLabel">{ selectedPage.parentLabel }: </span>
              }
              { selectedPage.label }
              {
                isNewPage( decodeURLHash()['.'], selectedPage.slug ) &&
                <RatingBadge rating="new" />
              }
              {
                isBetaPage( decodeURLHash()['.'], selectedPage.slug ) &&
                <RatingBadge rating="beta" />
              }
            </span>

            <InlineSVG type="carretDown" elementClass="carretIcon" />
          </div>
          {
            showSelector &&
            <PageSelectorMenu
              currentRouteItem={ currentRouteItem }
              shouldShowMenu={ shouldShowMenu }
              pageIsCurrent={ pageIsCurrent }
              onSelectCallback={ onSelectCallback }
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default PageSelector;