/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

export const percentileColor = percentile => {
  if ( percentile >= 0.75 ) {
    return 'primaryBlue';
  }
  if ( percentile >= 0.5 ) {
    return 'green';
  }
  if ( percentile >= 0.25 ) {
    return 'yellow';
  }
  return 'red';
};

export const globalRiskColor = ( risk, target ) => {
  const ratio = risk / ( target || 1 );
  if ( ratio < 1 ) {
    return 'minimal';
  } else if ( ratio < 1.5 ) {
    return 'low';
  } else if ( ratio < 1.75 ) {
    return 'moderate';
  } else if ( ratio < 2 ) {
    return 'high';
  }
  return 'critical';
};

export const chartGradients = {
  'darkBlue': <linearGradient id="gradient--darkBlue" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#334D6E" />
    <stop offset="20%" stopColor="#334D6E" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#334D6E" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#334D6E" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#334D6E" stopOpacity="00" />
  </linearGradient>,
  'primary': <linearGradient id="gradient--primary" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#1ab3ec" />
    <stop offset="20%" stopColor="#1ab3ec" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#1ab3ec" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#1ab3ec" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#1ab3ec" stopOpacity="00" />
  </linearGradient>,
  'grey': <linearGradient id="gradient--grey" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#90A0B7" />
    <stop offset="20%" stopColor="#90A0B7" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#90A0B7" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#90A0B7" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#90A0B7" stopOpacity="00" />
  </linearGradient>,
  'grey--icon': <linearGradient id="gradient--grey--icon" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#C2CFE0" />
    <stop offset="20%" stopColor="#C2CFE0" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#C2CFE0" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#C2CFE0" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#C2CFE0" stopOpacity="00" />
  </linearGradient>,
  'status--red': <linearGradient id="gradient--status--red" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#F7685B" />
    <stop offset="20%" stopColor="#F7685B" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#F7685B" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#F7685B" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#F7685B" stopOpacity="00" />
  </linearGradient>,
  'status--orange': <linearGradient id="gradient--status--orange" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#F2994A" />
    <stop offset="20%" stopColor="#F2994A" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#F2994A" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#F2994A" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#F2994A" stopOpacity="00" />
  </linearGradient>,
  'status--yellow': <linearGradient id="gradient--status--yellow" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#F9CD49" />
    <stop offset="20%" stopColor="#F9CD49" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#F9CD49" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#F9CD49" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#F9CD49" stopOpacity="00" />
  </linearGradient>,
  'status--green': <linearGradient id="gradient--status--green" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#6FCF97" />
    <stop offset="20%" stopColor="#6FCF97" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#6FCF97" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#6FCF97" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#6FCF97" stopOpacity="00" />
  </linearGradient>,
  'status--blue': <linearGradient id="gradient--status--blue" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#2D9CDB" />
    <stop offset="20%" stopColor="#2D9CDB" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#2D9CDB" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#2D9CDB" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#2D9CDB" stopOpacity="00" />
  </linearGradient>,
  'status--red--75': <linearGradient id="gradient--status--red--75" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f9867c" />
    <stop offset="20%" stopColor="#f9867c" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f9867c" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f9867c" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f9867c" stopOpacity="00" />
  </linearGradient>,
  'status--green--75': <linearGradient id="gradient--status--green--75" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#93dbb1" />
    <stop offset="20%" stopColor="#93dbb1" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#93dbb1" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#93dbb1" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#93dbb1" stopOpacity="00" />
  </linearGradient>,

  'critical': <linearGradient id="gradient--critical" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#c33937" />
    <stop offset="20%" stopColor="#c33937" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#c33937" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#c33937" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#c33937" stopOpacity="00" />
  </linearGradient>,
  'high': <linearGradient id="gradient--high" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ea5e50" />
    <stop offset="20%" stopColor="#ea5e50" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ea5e50" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ea5e50" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ea5e50" stopOpacity="00" />
  </linearGradient>,
  'moderate': <linearGradient id="gradient--moderate" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ff961a" />
    <stop offset="20%" stopColor="#ff961a" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ff961a" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ff961a" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ff961a" stopOpacity="00" />
  </linearGradient>,
  'low': <linearGradient id="gradient--low" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ffdf0d" />
    <stop offset="20%" stopColor="#ffdf0d" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ffdf0d" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ffdf0d" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ffdf0d" stopOpacity="00" />
  </linearGradient>,
  'minimal': <linearGradient id="gradient--minimal" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#43d988" />
    <stop offset="20%" stopColor="#43d988" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#43d988" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#43d988" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#43d988" stopOpacity="00" />
  </linearGradient>,

  'tag--343434': <linearGradient id="gradient--tag--#343434" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#343434" />
    <stop offset="20%" stopColor="#343434" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#343434" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#343434" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#343434" stopOpacity="00" />
  </linearGradient>,
  'tag--f94144': <linearGradient id="gradient--tag--#f94144" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f94144" />
    <stop offset="20%" stopColor="#f94144" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f94144" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f94144" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f94144" stopOpacity="00" />
  </linearGradient>,
  'tag--f3722c': <linearGradient id="gradient--tag--#f3722c" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f3722c" />
    <stop offset="20%" stopColor="#f3722c" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f3722c" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f3722c" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f3722c" stopOpacity="00" />
  </linearGradient>,
  'tag--f8961e': <linearGradient id="gradient--tag--#f8961e" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f8961e" />
    <stop offset="20%" stopColor="#f8961e" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f8961e" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f8961e" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f8961e" stopOpacity="00" />
  </linearGradient>,
  'tag--f9844a': <linearGradient id="gradient--tag--#f9844a" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f9844a" />
    <stop offset="20%" stopColor="#f9844a" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f9844a" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f9844a" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f9844a" stopOpacity="00" />
  </linearGradient>,
  'tag--f9c74f': <linearGradient id="gradient--tag--#f9c74f" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#f9c74f" />
    <stop offset="20%" stopColor="#f9c74f" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#f9c74f" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#f9c74f" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#f9c74f" stopOpacity="00" />
  </linearGradient>,
  'tag--90be6d': <linearGradient id="gradient--tag--#90be6d" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#90be6d" />
    <stop offset="20%" stopColor="#90be6d" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#90be6d" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#90be6d" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#90be6d" stopOpacity="00" />
  </linearGradient>,
  'tag--43aa8b': <linearGradient id="gradient--tag--#43aa8b" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#43aa8b" />
    <stop offset="20%" stopColor="#43aa8b" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#43aa8b" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#43aa8b" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#43aa8b" stopOpacity="00" />
  </linearGradient>,
  'tag--4d908e': <linearGradient id="gradient--tag--#4d908e" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#4d908e" />
    <stop offset="20%" stopColor="#4d908e" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#4d908e" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#4d908e" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#4d908e" stopOpacity="00" />
  </linearGradient>,
  'tag--577590': <linearGradient id="gradient--tag--#577590" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#577590" />
    <stop offset="20%" stopColor="#577590" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#577590" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#577590" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#577590" stopOpacity="00" />
  </linearGradient>,
  'tag--277da1': <linearGradient id="gradient--tag--#277da1" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#277da1" />
    <stop offset="20%" stopColor="#277da1" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#277da1" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#277da1" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#277da1" stopOpacity="00" />
  </linearGradient>,
  'tag--8a8a8a': <linearGradient id="gradient--tag--#8a8a8a" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#8a8a8a" />
    <stop offset="20%" stopColor="#8a8a8a" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#8a8a8a" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#8a8a8a" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#8a8a8a" stopOpacity="00" />
  </linearGradient>,
  'tag--ffdd00': <linearGradient id="gradient--tag--#ffdd00" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ffdd00" />
    <stop offset="20%" stopColor="#ffdd00" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ffdd00" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ffdd00" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ffdd00" stopOpacity="00" />
  </linearGradient>,
  'tag--ffbe0b': <linearGradient id="gradient--tag--#ffbe0b" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ffbe0b" />
    <stop offset="20%" stopColor="#ffbe0b" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ffbe0b" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ffbe0b" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ffbe0b" stopOpacity="00" />
  </linearGradient>,
  'tag--fb5607': <linearGradient id="gradient--tag--#fb5607" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#fb5607" />
    <stop offset="20%" stopColor="#fb5607" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#fb5607" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#fb5607" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#fb5607" stopOpacity="00" />
  </linearGradient>,
  'tag--ff006e': <linearGradient id="gradient--tag--#ff006e" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#ff006e" />
    <stop offset="20%" stopColor="#ff006e" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#ff006e" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#ff006e" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#ff006e" stopOpacity="00" />
  </linearGradient>,
  'tag--3a86ff': <linearGradient id="gradient--tag--#3a86ff" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#3a86ff" />
    <stop offset="20%" stopColor="#3a86ff" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#3a86ff" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#3a86ff" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#3a86ff" stopOpacity="00" />
  </linearGradient>,
  'tag--4cc9f0': <linearGradient id="gradient--tag--#4cc9f0" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#4cc9f0" />
    <stop offset="20%" stopColor="#4cc9f0" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#4cc9f0" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#4cc9f0" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#4cc9f0" stopOpacity="00" />
  </linearGradient>,
  'tag--3f37c9': <linearGradient id="gradient--tag--#3f37c9" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#3f37c9" />
    <stop offset="20%" stopColor="#3f37c9" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#3f37c9" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#3f37c9" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#3f37c9" stopOpacity="00" />
  </linearGradient>,
  'tag--560bad': <linearGradient id="gradient--tag--#560bad" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#560bad" />
    <stop offset="20%" stopColor="#560bad" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#560bad" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#560bad" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#560bad" stopOpacity="00" />
  </linearGradient>,
  'tag--8338ec': <linearGradient id="gradient--tag--#8338ec" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#8338ec" />
    <stop offset="20%" stopColor="#8338ec" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#8338ec" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#8338ec" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#8338ec" stopOpacity="00" />
  </linearGradient>,
  'tag--90A0B7': <linearGradient id="gradient--tag--#90A0B7" gradientTransform="rotate(90)" >
    <stop offset="0%" stopColor="#90A0B7" />
    <stop offset="20%" stopColor="#90A0B7" stopOpacity="0.65" />
    <stop offset="50%" stopColor="#90A0B7" stopOpacity="0.4" />
    <stop offset="80%" stopColor="#90A0B7" stopOpacity="0.15" />
    <stop offset="100%" stopColor="#90A0B7" stopOpacity="00" />
  </linearGradient>,
};

export const isCurrent = ( point, currentPoint ) => point?.id === currentPoint?.id;

export const shouldShowTicLabel = ( point, currentPoint, data ) => {
  if ( isCurrent( point, currentPoint ) ) {
    return true;
  }

  if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) ) {
    const allPoints = Object.values( data.transformed );

    const pointsLength = allPoints.length;

    const thisIndex = allPoints.indexOf( point );

    // always show first
    if ( thisIndex === 0 ) {
      return true;
    }
    // // always show 20%
    // if ( thisIndex === Math.floor( pointsLength * 0.2 ) ) {
    //   return true;
    // }
    // // always show 40%
    // if ( thisIndex === Math.floor( pointsLength * 0.4 ) ) {
    //   return true;
    // }
    // // always show 60%
    // if ( thisIndex === Math.floor( pointsLength * 0.6 ) ) {
    //   return true;
    // }
    // // always show 80%
    // if ( thisIndex === Math.floor( pointsLength * 0.8 ) ) {
    //   return true;
    // }
    // always show last
    if ( thisIndex === pointsLength - 1 ) {
      return true;
    }
    return false;
  }
  return false;
};




