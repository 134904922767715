/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import CopyToClipBoard from '../../../CopyToClipBoard';
import Notification from '../../../Notification';

import './ApiCredentials.scss';

import {
  isEmpty,
  isNotEmpty,
  generateAPICredentials,
} from '../../../Utilities';

const ApiCredentials = ( {
  field,
  formState,
  onChange,
  existingRecord,
} ) => {

  const [ apiValue, setApiValue ] = React.useState( { key: '', secret: '' } );

  React.useEffect( () => {
    // update the apiValue with the existing record values
    if ( isNotEmpty( existingRecord ) ) {
      setApiValue( { key: existingRecord.api_key || '', secret: '' } );
    // clear, this is a new record
    } else {
      setApiValue( { key: '', secret: '' } );
    }
  }, [ existingRecord ] );


  const options = {
    header: 'Attention',
    type: 'info',
    message:  <React.Fragment>
      <p>
        Be sure to copy your API Secret now. After saving this form, it will no longer be visible.
      </p>
      <p>If you ever lose your API Secret, a new key/secret pair will need to be generated.</p>
    </React.Fragment>,
  };

  const generate = () => {
    const newValue =  generateAPICredentials( isEmpty( existingRecord.api_key ) );
    if ( isNotEmpty( newValue ) ) {
      setApiValue( newValue );
      onChange( field, newValue );
    }
  };

  return (
    <React.Fragment>
      <div className="apiCredWrapper">
        {
          isNotEmpty( apiValue.key )
            ? <div className="cred">
              <span>API Key</span>
              <pre>
                { apiValue.key }
                <CopyToClipBoard text={apiValue.key}/>
              </pre>
            </div>
            : <div className="cred">
              <span>API Key</span>
              <pre>
                -- Not Yet Generated --
              </pre>
            </div>
        }
        <div className="cred">
          <span>API Secret</span>
          {
            isNotEmpty( apiValue.secret )
              ? <React.Fragment>
                <pre>
                  { apiValue.secret }
                  <CopyToClipBoard text={apiValue.secret}/>
                </pre>
                <Notification options={options} />
              </React.Fragment>
              : <pre>
                -- Not Shown --
              </pre>
          }
        </div>
        <button
          disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
          className="generateButton"
          onClick={generate}
        >
          { field.generateOptions.buttonText }
        </button>
      </div>

    </React.Fragment>
  );
};

export default ApiCredentials;
