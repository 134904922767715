/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { TagsContext } from '../../../../../Contexts/Tags';
import { isNotEmpty, tagColors } from '../../../../../shared/Utilities';
import Donut from '../../../../../shared/Charts/Donut';
import Legend from '../../../../../shared/Charts/Legend';

import './TagBreakdown.scss';

const TagBreakdown = ( { data, settings } ) => {

  const [ tagTotal, setTagTotal ] = React.useState( 1 );
  const [ tagData, setTagData ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );
  const [ hoveredSeriesIdentifier, setHoveredSeriesIdentifier ] = React.useState( null );
  const [ selectedSeriesIdentifier, setSelectedSeriesIdentifier ] = React.useState( null );

  const setupData = ( data, settings ) => {
    if ( isNotEmpty( data ) && isNotEmpty( data.results ) && isNotEmpty( data.results.asset_tag ) ) {
      const availableColors = [ ...tagColors.three ];
      availableColors.shift();
      const _tagData = {};
      let total = 0;
      // if we are limiting to specific tags
      if ( isNotEmpty( settings ) && isNotEmpty( settings.asset_tag_ids ) ) {
        settings.asset_tag_ids.map( id => {
          const count = data.results.asset_tag[id];

          if ( isNotEmpty( count ) ) {
            total += count;
          }
        } );
      } else {
        total = Object.values( data.results.asset_tag ).reduce( ( accum, thisCount ) => ( accum + thisCount ), 0 );
      }

      Object.entries( data.results.asset_tag ).map( ( [ tagID, count ], index ) => {
        if ( isNotEmpty( tags ) && isNotEmpty( tags[tagID] ) ) {
          const tag = tags[tagID];

          const _tag = {
            label: tag.label,
            stroke: tag.color || availableColors[index % 8],
            value: ( count / total ) * 100,
            originalCount: count,
            total: count,
            key: tagID,
            original: { id: tagID },
          };

          if (
            isNotEmpty( settings )
            && isNotEmpty( settings.asset_tag_ids )
          ) {
            if ( settings.asset_tag_ids.includes( tagID ) ) {
              _tagData[tagID] = _tag;
            }
          } else {
            _tagData[tagID] = _tag;
          }
        }
      } );
      setTagTotal( total );
      setTagData( _tagData );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      setupData( data, settings );
    }
  }, [ data, tags, settings ] );

  const handleTagClickRedirect = tagKey => {
    // eslint-disable-next-line max-len
    let baseURLString = '#.=risk_insight&report=instances&group_type=host&order_by=filtered_risk&order_direction=DESC&item_count=100&current_page=1&accepted_risk=false&include_risk=true&instances_visual_mode=categories';

    if ( isNotEmpty( tagKey ) ) {
      baseURLString += `&asset_tag_ids=%5B"${tagKey}"%5D`;
    }
    window.open( baseURLString );
  };

  const onChartClick = tagKey => {
    if ( isNotEmpty( tagKey ) ) {
      handleTagClickRedirect( tagKey );
    }
  };

  const onLegendClick = legendKey => {
    if ( isNotEmpty( legendKey ) ) {
      handleTagClickRedirect( legendKey );
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( tagTotal ) && isNotEmpty( tagData ) ) &&
        <React.Fragment>
          <Donut
            data={tagData}
            total={tagTotal}
            onClickCallback={ onChartClick }
            selectedSeriesIdentifier={ selectedSeriesIdentifier }
            setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
          <Legend
            legendData={ tagData }
            onClickCallback={ onLegendClick }
            selectedSeriesIdentifier={ selectedSeriesIdentifier }
            setSelectedSeriesIdentifier={ setSelectedSeriesIdentifier }
            hoveredSeriesIdentifier={ hoveredSeriesIdentifier }
            setHoveredSeriesIdentifier={ setHoveredSeriesIdentifier }
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default TagBreakdown;