/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import './NotFound.scss';

const NotFound = ( { message } ) => {
  return (
    <div className="notFoundWrapper">
      <h1>404</h1>
      {
        message
          ? message
          : <React.Fragment>
            <p>We're sorry, the page you requested does not exist</p>
            <a href="#.=reporting&page=reporting_dashboard">Return to Dashboard</a>
          </React.Fragment>
      }
    </div>
  );
};

export default NotFound;