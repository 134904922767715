/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';

export const PrintingContext = React.createContext();

export const PrintingProvider = ( { children } ) => {

  const [ printingOrientation, setPrintingOrientation ] = React.useState( 'portrait' );

  const data = [
    printingOrientation,
    setPrintingOrientation,
  ];

  return (
    <PrintingContext.Provider value={ data }>
      { children }
    </PrintingContext.Provider>
  );
};
