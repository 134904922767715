/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { defaultTagColor, hexToRGB, isEmpty, isNotEmpty, itemIsObject } from '../../../Utilities';
import Dropdown from '../../../Dropdown';

import './ColorSelect.scss';

const ColorSelect = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {
  // used as an intermediary to store selections
  const [ menuItems, setMenuItems ] = React.useState( [] );
  const [ trigger, setTrigger ] = React.useState( null );
  const [ optionValuesMap, setOptionValuesMap ] = React.useState( {} );

  // called onChange to be a go-between to handle value correctly
  const handleSelection = ( e, _value, _displayValue ) => {

    let value;
    // there is no event, the value was passed in directly
    if ( isEmpty( e ) ) {
      value = _value;
    } else {
      value = e.target.valueAsNumber || e.target.value;
    }

    onChange( field, value );

    // since this is not actually a select, need to handle the value manually
    if ( isNotEmpty( _displayValue ) && isNotEmpty( optionValuesMap ) ) {
      const optionsKey = ( isEmpty( _displayValue ) || _displayValue === 'null' ) ? defaultTagColor : _displayValue;
      let triggerValue = optionValuesMap[optionsKey];

      if ( isEmpty( triggerValue ) ) {
        triggerValue = defaultTagColor;
      }

      const { r, g, b } = hexToRGB( triggerValue );

      setTrigger(
        <span
          className="triggerColorBlock"
          style={
            {
              border: `1px solid ${triggerValue}`,
              background: `rgba(${r},${g},${b}, 0.15)`,
              color: triggerValue,
            }
          }
        >
          Label
        </span>,
      );
    }
  };

  // only do this once, set the valuesMap for the display name of the dropdown type
  React.useEffect( ( ) => {
    const valuesMap = { [defaultTagColor]: defaultTagColor };

    if ( isNotEmpty( field ) && isNotEmpty( field.options ) && itemIsObject( field.options ) ) {

      Object.values( field.options ).map( hexCodes => {
        hexCodes.map( hex => {
          valuesMap[hex] = hex;
        } );
      } );
      setOptionValuesMap( valuesMap );
    }
  }, [ field ] );

  React.useEffect( ( ) => {
    const items = [];

    if ( isNotEmpty( field ) && isNotEmpty( field.options ) && itemIsObject( field.options ) ) {
      Object.values( field.options ).map( ( groupColors, index ) => {
        groupColors.map( hex => {

          const { r, g, b } = hexToRGB( hex );

          const item = <strong
            key={index}
            className="colorBlock"
            onClick={ () => handleSelection( null, hex, hex ) }
            style={
              {
                border: `1px solid ${hex}`,
                background: `rgba(${r},${g},${b}, 0.15)`,
                color: hex,
              }
            }
          >
            Label
          </strong>;

          items.push( item );
        } );
      } );

      setMenuItems( items );
    }
  }, [ field, optionValuesMap, formState ] );

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
    // since this is not actually a select, need to handle the value manually
    if ( isNotEmpty( optionValuesMap ) ) {
      const optionsKey = ( isEmpty( originalValue ) || originalValue === 'null' ) ? defaultTagColor : originalValue;
      let triggerValue = optionValuesMap[optionsKey];

      if ( isEmpty( triggerValue ) ) {
        triggerValue = defaultTagColor;
      }

      const { r, g, b } = hexToRGB( triggerValue );

      setTrigger(
        <span
          className="triggerColorBlock"
          style={
            {
              border: `1px solid ${triggerValue}`,
              background: `rgba(${r},${g},${b}, 0.15)`,
              color: triggerValue,
            }
          }
        >
          Label
        </span>,
      );
    }
  }, [ originalValue, fieldRef, existingRecord, optionValuesMap ] );

  return (
    <React.Fragment>
      {/* uses the dropdown component instead for more versatility, styled to look like a regular select, mostly  */}
      {
        ( isNotEmpty( field ) && isNotEmpty( menuItems ) && isNotEmpty( trigger ) ) &&
        <Dropdown
          asSelect
          trigger={ trigger }
          menuItems={ menuItems }
          scrollOffsetContainerID="reactSetupModal_body"
          menuElementClass="colorSelectMenu"
        />
      }
    </React.Fragment>
  );
};

export default ColorSelect;