/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, paramsToFilters } from '../../Utilities';

import './Inequality.scss';

const Inequality = ( {
  input,
  value,
  handleChange,
} ) => {
  const [ isFocused, setIsFocused ] = React.useState( false );

  const inequalityRef = React.useRef( null );
  const unitRef = React.useRef( null );

  const [ inequalityValue, setInequalityValue ] = React.useState( 'gt_map' );
  const [ unitValue, setUnitValue ] = React.useState( '' );

  React.useEffect( () => {
    if ( isNotEmpty( value ) ) {
      if ( isNotEmpty( value.inequality ) ) {
        setInequalityValue( value.inequality );
      }
      if ( isNotEmpty( value.value ) ) {
        setUnitValue( value.value );
      }
    }
  }, [ value ] );

  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input ] );

  const handleHashChange = () => {
    const filterValues = paramsToFilters();

    if ( isNotEmpty( filterValues[input.attribute] ) ) {
      const [ inequality, unit ] = filterValues[input.attribute];
      setInequalityValue( inequality );
      setUnitValue( unit );

      inequalityRef.current.value = inequality;
      unitRef.current.value = unit;
    }
  };

  const onInequalityChange = value => {
    setInequalityValue( value );
    handleChange( input.attribute, [ value, unitValue ] );
  };

  const onUnitChange = value => {
    setUnitValue( value );
    handleChange( input.attribute, [ inequalityValue, value ] );
  };

  return (
    <React.Fragment>
      <label className={`${isFocused ? 'focus' : ''}`}>
        <span className="labelWrapper">
          { input.label }
          {
            input.required &&
            <span className="required">*</span>
          }
          { input.helpItem }
        </span>
        <div className="inputsWrapper">
          <div className="selectFieldWrapper" >
            <select
              disabled={ input.disabled }
              onChange={ e => onInequalityChange( e.target.value ) }
              ref={inequalityRef}
            >
              <option value="gt_map">{ input.valuesMap ? input.valuesMap.gt_map : 'More than'}</option>
              <option value="lt_map">{ input.valuesMap ? input.valuesMap.lt_map : 'Less than'}</option>
            </select>
          </div>
          <input
            ref={unitRef}
            type="number"
            onFocus={ () => setIsFocused( true ) }
            onBlur={ () => setIsFocused( false ) }
            placeholder={ input.placeholder ? input.placeholder : ''}
            disabled={input.disabled }
            onChange={ e => onUnitChange( e.target.valueAsNumber ) }
            { ...input.htmlProps }
          />
          {
            isNotEmpty( input.subLabel ) &&
            <span className="subLabel">{ input.subLabel }</span>
          }
        </div>
      </label>
    </React.Fragment>
  );
};

export default Inequality;