/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../shared/Utilities';

import './PathAnalysis.scss';
import InlineSVG from '../../../shared/InlineSVG';
import Loading from '../../../shared/Loading';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';
import { getNodeIcon, nodeIconMap } from '../../RecordDetails/shared';
import PathsGraph from '../Sections/PathsGraph';
import { nodeTypeMap } from '../RecordCard/shared';

const PathAnalysis = ( {
  path,
  relatedPaths,

  // path recordType vars
  pathSensitiveAsset,
  loading,
  pathFormattedEdges,
  pathTopVulnerabilities,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,
} ) => {
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const getEdgeRating = edge => {
    if ( edge.nofix ) {
      return 'nofix';
    }
    if ( edge.riskPercentile < 0.05 ) {
      return 'primary';
    }
    if ( edge.riskPercentile < 0.25 ) {
      return 'minimal';
    }
    if ( edge.riskPercentile < 0.75 ) {
      return 'low';
    }
    if ( edge.riskPercentile < 0.95 ) {
      return 'moderate';
    }
    return 'high';
  };

  return (
    <div className="recordDetails__MainPanel__Body_PathAnalysis path" >
      { loading && <Loading /> }
      {
        isNotEmpty( relatedPaths ) &&
        <div className="pathDetailVisual">
          <PathsGraph
            relatedPaths={relatedPaths}
            item={path}
            reportType="path"

            // recordCard variables
            recordCardRecord={recordCardRecord}
            setRecordCardRecord={setRecordCardRecord}
            recordCardType={recordCardType}
            setRecordCardType={setRecordCardType}
            showRecordCard={showRecordCard}
            setShowRecordCard={setShowRecordCard}
          />
          {
            isNotEmpty( relatedPaths?.results ) &&
            <button
              className="fullScreenVisualToggleButton"
              onClick={ () => showFullScreenVisual(
                <PathsGraph
                  relatedPaths={relatedPaths}
                  item={path}
                  reportType="path"

                  // recordCard variables
                  recordCardRecord={recordCardRecord}
                  setRecordCardRecord={setRecordCardRecord}
                  recordCardType={recordCardType}
                  setRecordCardType={setRecordCardType}
                  showRecordCard={showRecordCard}
                  setShowRecordCard={setShowRecordCard}
                />,
                'riskInsightDetails section pathsGraph',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }
        </div>
      }
      {
        isNotEmpty( pathFormattedEdges ) &&
        <div className="pathAnalysisContainer">
          <h3 className="mainPanel__SectionHeader">
            Path Analysis and Description
          </h3>
          {
            isNotEmpty( pathSensitiveAsset ) &&
            <p className="description">
              {/* eslint-disable-next-line max-len */}
              An attacker can reach the sensitive asset <strong>{ pathSensitiveAsset.node?.label }</strong> on { pathSensitiveAsset.scope?.label } in <strong>{ formatNumber( pathFormattedEdges.length ) } steps</strong> by exploiting <strong>{ formatNumber( pathTopVulnerabilities.length ) } { pathTopVulnerabilities.length === 1 ? 'vulnerability' : 'vulnerabilities' }</strong>.
            </p>
          }
          <ul className="edgeDescriptionList">
            {/* add in the final description for the sensitive asset */}
            <li className="edgeDescriptionItem adversaryNode">
              <div className="timelineColumn">
                <div className="nodeIconWrapper adversary">
                  <svg
                    width={ 24 }
                    height={ 24 }
                    viewBox="0 0 32 32"
                    fill="none"
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="recordTypeHeaderIcon"
                  >
                    { nodeIconMap.adversary }
                  </svg>
                </div>
              </div>
              <div className="contentColumn">
                <h2>
                  <strong>Adversary</strong>
                </h2>
              </div>
            </li>
            {
              pathFormattedEdges.map( ( edge, index ) => {
                return <li
                  key={ index }
                  // eslint-disable-next-line max-len
                  className={ `${index === pathFormattedEdges.length - 1 ? 'lastItem' : '' } edgeDescriptionItem risk-${ getEdgeRating( edge ) }`}
                >
                  <div className="timelineColumn">
                    <div className="nodeIconWrapper">
                      <svg
                        width={ 24 }
                        height={ 24 }
                        viewBox="0 0 32 32"
                        fill="none"
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="recordTypeHeaderIcon"
                      >
                        { getNodeIcon( edge.toNode ) }
                      </svg>
                    </div>
                  </div>
                  <div className="contentColumn">
                    <h2>
                      <strong>{ edge.toNode?.label } ({ nodeTypeMap[edge.toNode?.type] })</strong>
                      <span>- {edge.toScope?.label}</span>
                    </h2>
                    {
                      edge.vulnerabilities.length > 1
                        ? <p>
                          {/* eslint-disable-next-line max-len */}
                          An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> by exploiting any one of <strong>{ formatNumber( edge.vulnerabilities.length ) } vulnerabilities</strong>, the highest risk vulnerability being <a
                            // eslint-disable-next-line max-len
                            href={ `#.=risk_insight&report=vulnerabilities&item_count=100&patchable=any&order_by=filtered_risk&order_direction=DESC&accepted_risk=false&item=${edge.vulnerabilities[0].id}&current_page=1` }
                            target="_blank"
                            rel="noreferrer noopener"
                            // eslint-disable-next-line max-len
                            className={ `inlineLink newTabLink risk-${riskToRating( edge.vulnerabilities[0].risk ) }`}
                          >
                            { reportTypeDisplayName( edge.vulnerabilities[0], 'vulnerability' ) }
                            <InlineSVG type="newTabLink" />
                          </a>
                        </p>
                        : <p>
                          {
                            edge.nofix
                              ? <span>
                                {
                                  isNotEmpty( edge.vulnerabilities ) &&
                                  <span>
                                    {/* eslint-disable-next-line max-len */}
                                    An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> because <strong className="vulnDescription">{ edge.vulnerabilities[0].description }</strong>
                                  </span>
                                }
                              </span>
                              : <span>
                                {
                                  isNotEmpty( edge.vulnerabilities ) &&
                                  <React.Fragment>
                                    {/* eslint-disable-next-line max-len */}
                                    An attacker is able to gain access to <strong>{ edge.toNode?.label }</strong> by exploiting <a
                                      // eslint-disable-next-line max-len
                                      href={ `#.=risk_insight&report=vulnerabilities&item_count=100&patchable=any&order_by=filtered_risk&order_direction=DESC&accepted_risk=false&item=${edge.vulnerabilities[0].id}&current_page=1` }
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      // eslint-disable-next-line max-len
                                      className={ `inlineLink newTabLink risk-${riskToRating( edge.vulnerabilities[0].risk ) }`}
                                    >
                                      { reportTypeDisplayName( edge.vulnerabilities[0], 'vulnerability' ) }
                                      <InlineSVG type="newTabLink" />
                                    </a>
                                  </React.Fragment>
                                }

                              </span>
                          }
                        </p>
                    }
                  </div>
                </li>;
              } )
            }

          </ul>
        </div>
      }
    </div>
  );
};

export default PathAnalysis;