/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import SearchBar from './SearchBar';
import SearchResults from './SearchResults';

import {
  isNotEmpty,
  triggerHashRefresh,
  removeFromURLHash,
  paramsToFilters,
  encodeURLHash,
  recordTypeDisplayName,
} from '../../../Utilities';

import './style.scss';
import InlineSVG from '../../../InlineSVG';
import { typeMap } from './SearchData';
import { getRecord } from '../../../RecordCache';

export const getRecordForType = async( type, id ) => {
  let record;
  if ( type === 'host' ) {
    record = await getRecord( typeMap( type ), id, {}, true );
  } else {
    record = await getRecord( typeMap( type ),  id,  {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'scanner', 'signature', 'identifier', 'vendor', 'label', 'title' ],
    } );
  }

  return record;
};

const SearchWithResults = ( {
  input,
  handleChange,
  withinFilterDrawer,
} ) => {

  const [ results, setResults ] = React.useState( [] );
  const [ label, setLabel ] = React.useState( '' );
  const [ loading, setLoading ] = React.useState( false );
  const [ noResults, setNoResults ] = React.useState( false );
  const [ shouldShowResults, setShouldShowResults ] = React.useState( false );
  const searchBarInput = React.useRef( null );

  const clear = ( shouldRefresh=true ) => {
    removeFromURLHash( input.attribute );
    removeFromURLHash( `${input.attribute}_callback_ids` );
    setLabel( '' );
    setShouldShowResults( false );
    if ( shouldRefresh ) {
      triggerHashRefresh();
      handleChange( input.attribute, '' );
    }
  };

  // needs to retreive label, etc. for specific record types
  const fillInput = async ( val=null, shouldRefresh=true ) => {
    if ( isNotEmpty( val ) ) {
      // eslint-disable-next-line
      const primaryRecordID = val[0];
      const record = await getRecordForType( input.recordType, primaryRecordID );
      const _label = recordTypeDisplayName( record, input.recordType );

      if ( isNotEmpty( _label ) ) {
        setLabel( _label );
      }

      if ( input.selectCallback ) {
        const callBackIDs = await input.selectCallback( record );

        if ( isNotEmpty( callBackIDs ) ) {
          encodeURLHash( { [ `${input.attribute}_callback_ids` ]: JSON.stringify( callBackIDs ) } );
        } else {
          removeFromURLHash( input.attribute );
        }
      } else {
        encodeURLHash( { [input.attribute]: JSON.stringify( val ) } );
      }
      handleChange( input.attribute, val );
      if ( !withinFilterDrawer && shouldRefresh ) {
        triggerHashRefresh();
      }
    }
    setShouldShowResults( false );
  };

  // called when a user selects a result
  const selectResult = ( e, result ) => {
    e.stopPropagation();
    e.preventDefault();
    setResults( [] );
    fillInput( [ result.id ] );
    setShouldShowResults( false );
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    if ( isNotEmpty( filterValues[input.attribute] ) ) {
      fillInput( filterValues[input.attribute], false );
    } else {
      clear( false );
    }
  };

  // see if the url already has a value that needs filling
  React.useEffect( () => {
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ input ] );

  // onload handle anything in the url
  React.useEffect( ( ) => {
    handleHashChange();
  }, [ ] );

  return (
    <label>
      {
        !input.clearOnSelect &&
        <React.Fragment>
          <span className="labelWrapper">
            { input.label }
            { input.helpItem }
          </span>
        </React.Fragment>
      }
      <div className={
        `searchWithResultsWrapper ${isNotEmpty( results ) ? 'hasResults' : ''}`
      }>
        {
          isNotEmpty( label )
            ? <div className="selectedSearchResult">
              { label }
              <button
                onClick={ clear }
              >
                <InlineSVG type="remove" />
              </button>
            </div>
            : <SearchBar
              recordType={input.recordType}
              setResults={setResults}
              searchBarInput={searchBarInput}
              input={input}
              setNoResults={setNoResults}
              setLoading={setLoading}
              setShouldShowResults={setShouldShowResults}
            />
        }
        <SearchResults
          recordType={input.recordType}
          results={results}
          setResults={setResults}
          input={input}
          searchBarInput={searchBarInput}
          selectResult={selectResult}
          noResults={noResults}
          loading={loading}
          shouldShowResults={shouldShowResults}
          setShouldShowResults={setShouldShowResults}
        />
      </div>
    </label>
  );
};

export default SearchWithResults;
