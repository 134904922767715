/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

import './style.scss';

const RatingBadge = ( { item, itemType, rating, altVersion=false, alternateText=null, elementClass='' } ) => {

  const [ badgeContent, setBadgeContent ] = React.useState( 'N/A' );
  const [ badgeClass, setBadgeClass ] = React.useState( '' );

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( itemType ) ) {
      if ( isNotEmpty( alternateText ) ) {
        setBadgeClass( alternateText );
        setBadgeContent( alternateText );
      } else if ( itemType === 'host' && !item.has_host ) {
        setBadgeClass( 'unknown' );
        setBadgeContent( 'unknown' );
      } else if ( isNotEmpty( rating ) ) {
        setBadgeClass( rating );
        setBadgeContent( rating );
      }
    } else if ( isNotEmpty( alternateText ) ) {
      if ( isNotEmpty( rating ) ) {
        setBadgeClass( rating );
      } else {
        setBadgeClass( alternateText );
      }
      setBadgeContent( alternateText );
    } else if ( isNotEmpty( rating ) ) {
      setBadgeClass( rating );
      setBadgeContent( rating );
    }
  }, [ item, itemType, alternateText, rating ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( rating ) &&
        <div className={ `ratingBadge ${badgeClass} ${altVersion ? 'altVersion' : ''} ${elementClass}`}>
          { badgeContent }
        </div>
      }
    </React.Fragment>
  );
};

export default RatingBadge;