/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  HelpTrigger,
} from '../../../HelpDocumentation/ContextualHelp/index.js';

/* eslint-disable camelcase */
export const data = {
  filterInputs: [
    {
      type: 'debouncedText',
      label: 'Asset Name',
      attribute: 'keywords',
      placeholder: 'Filter assets by keyword...',
    },
    {
      type: 'searchResults',
      label: 'Limit to specific host',
      attribute: 'id_list',
      placeholder: 'Find host by name...',
      recordType: 'host',
    },
    {
      type: 'select',
      label: 'Limit to asset type',
      attribute: 'type',
      value: 'any',
      helpItem: <HelpTrigger helpKey="type" />,
      options: {
        any: 'Any',
        database: 'Database',
        // eslint-disable-next-line camelcase
        shared_folder_permission: 'Shared Folder',
      },
    },
    {
      type: 'select',
      label: 'Impact',
      attribute: 'combined_impact',
      value: 'either_impact',
      options: {
        'has_impact': 'Impact Assigned',
        'no_impact': 'No Impact Assigned',
        'either_impact': 'Any Impact Assigned',
      },
    },
    {
      type: 'hidden',
      label: 'Number of results',
      attribute: 'item_count',
      value: 100,
      // options: {
      //   25: '25',
      //   50: '50',
      //   75: '75',
      //   100: '100',
      // },
    },
    {
      type: 'hidden',
      value: 1,
      attribute: 'current_page',
    },
    {
      type: 'hidden',
      attribute: 'sort_by',
      value: 'impact',
    },
    {
      type: 'hidden',
      attribute: 'sort_direction',
      value: 'DESC',
    },
    {
      type: 'hidden',
      attribute: 'current_page',
      value: 1,
    },
    // {
    //   type: 'select',
    //   label: 'Tag',
    //   attribute: 'asset_tag_ids',
    //   value: '',
    //   allowBlank: true,
    // },
  ],
  // until endpoints get built
  stubbedSearchResults: {
    results: [
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: 'MSSQL Excludes default',
        impact: 100,
        asset_tag_id: 'a12f9585-98fa-4080-9b5c-7e79d1007816',
        filter: {
          asset_type: 'mssql',
          exclude_builtin: true,
        },
      },
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: 'Shared Folder Write',
        impact: 300,
        asset_tag_id: null,
        filter: {
          asset_type: 'sharedfolder',
          exclude_builtin: true,
          permission: 'write',
        },
      },
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: 'Shared Folder Read All',
        impact: 700,
        asset_tag_id: 'd318b5b0-7658-40d9-b065-b30548056af6',
        filter: {
          asset_type: 'sharedfolder',
          exclude_builtin: false,
          permission: 'read',
        },
      },
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: 'Local User',
        impact: 10,
        asset_tag_id: null,
        filter: {
          asset_type: 'user',
        },
      },
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: 'Domain User with keyword',
        impact: 200,
        asset_tag_id: null,
        filter: {
          asset_type: 'domain_user',
          keywords: 'blah',
        },
      },
      {
        id: uuidv4(),
        created: 1666225146.683407,
        label: null,
        impact: 10,
        asset_tag_id: null,
        filter: {
          asset_type: 'domain_group',
          keywords: 'A super long one, blah lbha blasdfasdf asdf',
        },
      },
    ],
  },
};
/* eslint-enable camelcase */
