/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import SetupPage from '../../../shared/SetupComponents/SetupPage';

import { v4 as uuidv4 } from 'uuid';

import {
  moreThan1,
} from '../../../shared/Form/Validators';

import {
  recordData,
} from './cliqueData';

import {
  isNotEmpty,
} from '../../../shared/Utilities';
import { getFieldValues } from '../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../legacy/io';

const Cliques = ( {
  cliques,
  setCliques,
  subnets,
} ) => {
  const project = 'default';

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );
  const [ fields, setFields ] = React.useState( [] );

  React.useEffect( () => {
    const _subnets = {};

    if ( isNotEmpty( subnets ) && subnets.included && subnets.excluded ) {
      subnets.included.map( subnet => {
        _subnets[subnet.id] = subnet.net;
      } );
      subnets.excluded.map( subnet => {
        _subnets[subnet.id] = subnet.net;
      } );
    }

    const EMPTY_FIELDS = [
      {
        type: 'text',
        label: 'Name',
        attribute: 'label',
        required: true,
        defaultValue: '',
      },
      {
        type: 'selectList',
        label: 'Clique Members',
        attribute: 'members',
        allowMultiple: true,
        defaultValue: [],
        required: true,
        validators: [ moreThan1 ],
      },
    ];

    const membersField = EMPTY_FIELDS.find( f => f.attribute === 'members' );
    membersField.options = _subnets;

    recordData.subnets = _subnets;

    setFields( EMPTY_FIELDS );

  }, [ subnets ] );

  const onRefresh = () => {
    // get latest cliques
    makeRequest( 'FIND', `/project/${project}/connectivity_clique`, {} ).then( response => {
      if ( response && response.results ) {
        setCliques( response.results );
      } else {
        setCliques( [] );
      }
    } );
  };

  const onSave = (
    selectedClique,
    isValid,
    fieldStates,
  ) => {
    if ( isValid && isNotEmpty( fieldStates ) ) {

      const values = getFieldValues( fieldStates, 'connectivity_clique' );
      const record = {
        label: values.label,
        members: values.members,
      };

      let existing = cliques;

      existing.map( c => {
        delete c.created;
        delete c.modified;
      } );

      if ( selectedClique ) {
        record.id = selectedClique.id || uuidv4();
        existing = cliques.filter( c => c.id !== selectedClique.id );
      }

      const allCliques = [ ...existing, record ];
      makeRequest( 'REPLACE', `/project/${project}/connectivity_clique`, { 'records': allCliques } ).then( () => {

        addFlashMessage( {
          body: 'Successfully saved cliques',
          type: 'success',
        } );
        onRefresh();
      } );
    }
  };

  return (
    <section className="cliquesSection">
      <div className="sectionHeader">
        <h2>Connectivity Cliques</h2>
      </div>
      {
        ( isNotEmpty( subnets ) && isNotEmpty( fields ) ) &&
        <SetupPage
          onRefresh={onRefresh}
          onSave={onSave}
          records={cliques}
          setRecords={setCliques}
          recordType="connectivity_clique"
          recordData={recordData}
          useForm
          fields={fields}
          alternateItemLayout
        />
      }
    </section>
  );
};

export default Cliques;
