/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import { isHttpOrHttps, isWindowsDomain, isListOfPorts } from '../../../shared/Form/Validators';
import { isNotEmpty } from '../../../shared/Utilities';

export const PAM_OPTIONS = {
  'none': 'None',
  'laps': 'Microsoft LAPS',
  'thycotic-secret-server': 'Delinea (Thycotic) Secret Server',
  'cyberark-vault': 'CyberArk Vault',
};

export const CRED_OPTIONS = {
  'smb-wmi': 'SMB/WMI',
  'winrm-psrp': 'WinRM (Powershell Remoting)',
  'ssh-password': 'SSH Password',
  'ssh-key': 'SSH Private Key',
  'smb': 'SMB (deprecated)',
};

export const WINDOWS_CREDS = {
  'smb-wmi': 'SMB/WMI',
  'winrm-psrp': 'WinRM (Powershell Remoting)',
  'smb': 'SMB (deprecated)',
};

const ELEVATE_OPTIONS = {
  'null': 'None',
  'sudo': 'sudo',
};

const SSH_PASSWORD_SECRET_OPTIONS = {
  'no_secret': 'No password is required to use sudo',
  'same_secret': 'Use the login password for sudo',
  'new_secret': 'Use the following new password for sudo',
};

const SSH_KEY_SECRET_OPTIONS = {
  'no_secret': 'No password is required to use sudo',
  'new_secret': 'Use the following new password for sudo',
};

export const CONDITIONAL_CRED_OPTIONS = {
  'none' : {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'ssh-password': 'SSH Password',
    'ssh-key': 'SSH Private Key',
    'smb': 'SMB (deprecated)',
  },
  'laps': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'smb': 'SMB (deprecated)',
  },
  'thycotic-secret-server': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'ssh-password': 'SSH Password',
    'smb': 'SMB (deprecated)',
  },
  'cyberark-vault': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'ssh-password': 'SSH Password',
    'ssh-key': 'SSH Private Key',
    'smb': 'SMB (deprecated)',
  },
};

export const CONDITIONAL_WINDOWS_CRED_OPTIONS = {
  'none' : {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'smb': 'SMB (deprecated)',
  },
  'laps': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'smb': 'SMB (deprecated)',
  },
  'thycotic-secret-server': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'smb': 'SMB (deprecated)',
  },
  'cyberark-vault': {
    'smb-wmi': 'SMB/WMI',
    'winrm-psrp': 'WinRM (Powershell Remoting)',
    'smb': 'SMB (deprecated)',
  },
};

// elevate method fields for ssh-key type
const SSH_KEY_ELEVATE_FIELDS = [
  {
    type: 'select',
    label: 'Elevation Method',
    attribute: 'elevate_method',
    options: ELEVATE_OPTIONS,
    defaultValue: 'null',
  },
  {
    includeIf: [
      { attribute: 'elevate_method', value: 'sudo' },
    ],
    type: 'radioGroup',
    label: 'Secret Source',
    attribute: 'elevate_source',
    options: SSH_KEY_SECRET_OPTIONS,
    required: true,
    defaultValue: 'no_secret',
  },
  {
    includeIf: [
      { attribute: 'elevate_source', value: 'new_secret' },
    ],
    type: 'text',
    label: 'Secret',
    attribute: 'elevate_secret',
    placeholder: '(You may leave this blank if it was previously configured.)',
    required: true,
    defaultValue: '',
  },
];

// elevate method fields for ssh-password type
const SSH_PASSWORD_ELEVATE_FIELDS = [
  {
    type: 'select',
    label: 'Elevation Method',
    attribute: 'elevate_method',
    options: ELEVATE_OPTIONS,
    defaultValue: 'null',
  },
  {
    includeIf: [
      { attribute: 'elevate_method', value: 'sudo' },
    ],
    type: 'radioGroup',
    label: 'Secret Source',
    attribute: 'elevate_source',
    options: SSH_PASSWORD_SECRET_OPTIONS,
    required: true,
    defaultValue: 'no_secret',
  },
  {
    includeIf: [
      { attribute: 'elevate_source', value: 'new_secret' },
      { attribute: 'elevate_method', value: 'sudo' },
    ],
    type: 'text',
    label: 'Secret',
    attribute: 'elevate_secret',
    placeholder: '(You may leave this blank if it was previously configured.)',
    required: true,
    defaultValue: '',
  },
];

// ms laps fields
const LAPS_FIELDS = [
  {
    type: 'text',
    label: 'LDAP Username',
    attribute: 'username',
    help: <HelpTrigger helpKey="laps_username" />,
    required: true,
    defaultValue: '',
  },
  {
    type: 'password',
    removeGenerate: true,
    label: 'LDAP Password',
    help: <HelpTrigger helpKey="laps_password" />,
    attribute: 'secret',
    required: true,
    defaultValue: '',
  },
  {
    type: 'text',
    label: 'Domain',
    attribute: 'domain',
    defaultValue: '',
    required: true,
  },
];

// thycotic secret server fields
const DELINEA_FIELDS = [
  {
    type: 'text',
    label: 'Secret Server URL',
    attribute: 'url',
    required: true,
    help: <HelpTrigger helpKey="url" />,
    defaultValue: '',
    validators: [ isHttpOrHttps ],
  },
  {
    type: 'text',
    label: 'API Domain',
    attribute: 'domain',
    defaultValue: '',
  },
  {
    type: 'text',
    label: 'API Username',
    attribute: 'username',
    help: <HelpTrigger helpKey="delinea_api_username" />,
    required: true,
    defaultValue: '',
  },
  {
    type: 'password',
    removeGenerate: true,
    label: 'API Password',
    help: <HelpTrigger helpKey="delinea_api_password" />,
    attribute: 'secret',
    required: true,
    defaultValue: '',
  },
  {
    type: 'text',
    label: 'Secret Name',
    attribute: 'secret_name',
    required: true,
    help: <HelpTrigger helpKey="secret_name" />,
    defaultValue: '',
  },
  {
    type: 'checkbox',
    label: 'Allow Trust-on-first-use (TOFU) ',
    value: true,
    attribute: 'tofu',
    help: <HelpTrigger helpKey="tofu" />,
    defaultValue: true,
  },
];

// cyberark fields
const CYBERARK_FIELDS = [
  {
    type: 'text',
    label: 'Vault URL',
    attribute: 'url',
    required: true,
    help: <HelpTrigger helpKey="cyberark_url" />,
    defaultValue: '',
    validators: [ isHttpOrHttps ],
  },
  {
    type: 'text',
    label: 'Safe',
    attribute: 'safe_name',
    help: <HelpTrigger helpKey="cyberark_safe_name" />,
    defaultValue: '',
    required: true,
  },
  {
    type: 'text',
    label: 'API Username',
    attribute: 'username',
    help: <HelpTrigger helpKey="cyberark_api_username" />,
    required: true,
    defaultValue: '',
  },
  {
    type: 'password',
    removeGenerate: true,
    label: 'API Password',
    help: <HelpTrigger helpKey="cyberark_api_password" />,
    attribute: 'secret',
    required: true,
    defaultValue: '',
  },
  {
    type: 'textarea',
    label: 'TLS Client Certificate',
    placeholder: '-----BEGIN CERTIFICATE-----\n...\n...\n...\n-----END CERTIFICATE-----',
    attribute: 'pam_client_cert',
    help: <HelpTrigger helpKey="pam_client_cert" />,
    defaultValue: '',
  },
  {
    requireIf: { attribute: 'pam_client_cert', check: isNotEmpty },
    type: 'textarea',
    placeholder: '-----BEGIN KEY-----\n...\n...\n...\n-----END KEY-----',
    label: 'TLS Client Key',
    attribute: 'pam_client_key',
    help: <HelpTrigger helpKey="pam_client_key" />,
    defaultValue: '',
  },
  {
    type: 'checkbox',
    label: 'Require IP Match',
    attribute: 'require_ip_match',
    help: <HelpTrigger helpKey="cyberark_require_ip_match" />,
    defaultValue: false,
  },
  {
    type: 'checkbox',
    label: 'Allow Trust-on-first-use (TOFU)',
    attribute: 'tofu',
    help: <HelpTrigger helpKey="tofu" />,
    defaultValue: true,
  },
];

// the common fields that all credential forms share when no PAM is selected
const WITH_NO_PAM_FIELDS = {
  winType: [
    {
      type: 'text',
      label: 'Username',
      attribute: 'username',
      help: <HelpTrigger helpKey="username" />,
      required: true,
      defaultValue: '',
    },
    {
      type: 'password',
      removeGenerate: true,
      label: 'Password',
      help: <HelpTrigger helpKey="password" />,
      attribute: 'secret',
      required: true,
      defaultValue: '',
    },
    {
      type: 'text',
      label: 'Domain',
      attribute: 'domain',
      defaultValue: '',
    },
  ],
  'ssh-password': [
    {
      type: 'text',
      label: 'Username',
      attribute: 'username',
      help: <HelpTrigger helpKey="username" />,
      required: true,
      defaultValue: '',
    },
    {
      type: 'password',
      removeGenerate: true,
      label: 'Password',
      help: <HelpTrigger helpKey="password" />,
      attribute: 'secret',
      required: true,
      defaultValue: '',
    },
    {
      type: 'textarea',
      label: 'Ports',
      attribute: 'ports',
      needsSplit: true,
      required: false,
      defaultValue: [ 22 ],
      validators: [ isListOfPorts ],
      help: <HelpTrigger helpKey="ports" />,
    },
    ...SSH_PASSWORD_ELEVATE_FIELDS,
  ],
  'ssh-key': [
    {
      type: 'text',
      label: 'Username',
      attribute: 'username',
      help: <HelpTrigger helpKey="username" />,
      required: true,
      defaultValue: '',
    },
    {
      type: 'textarea',
      label: 'Ports',
      attribute: 'ports',
      needsSplit: true,
      required: false,
      defaultValue: [ 22 ],
      validators: [ isListOfPorts ],
      help: <HelpTrigger helpKey="ports" />,
    },
    {
      type: 'textarea',
      label: 'Private Key',
      placeholder: '-----BEGIN RSA PRIVATE KEY-----\n...\n...\n...\n-----END RSA PRIVATE KEY-----',
      attribute: 'secret',
      required: true,
      defaultValue: '',
    },
    {
      type: 'text',
      label: 'Key Passphrase',
      attribute: 'ssh-key-passphrase',
      placeholder: '(optional: leave blank if key is not encrypted)',
      defaultValue: '',
    },
    ...SSH_KEY_ELEVATE_FIELDS,
  ],

};

const WITH_LAPS_FIELDS = {
  'winrm-psrp': [
    {
      type: 'text',
      label: 'Local User',
      attribute: 'local_user',
      help: <HelpTrigger helpKey="laps_local_user" />,
      defaultValue: 'Administrator',
    },
  ],
  'smb-wmi': [
    {
      type: 'text',
      label: 'Local User',
      attribute: 'local_user',
      help: <HelpTrigger helpKey="laps_local_user" />,
      defaultValue: 'Administrator',
    },
  ],
  'smb': [
    {
      type: 'text',
      label: 'Local User',
      attribute: 'local_user',
      help: <HelpTrigger helpKey="laps_local_user" />,
      defaultValue: 'Administrator',
    },
  ],
};

// the common fields that all credential forms share when Delinea is selected
const WITH_DELINEA_FIELDS = {
  'winrm-psrp': [
    {
      type: 'text',
      label: 'Override Credential Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="target_domain" />,
      defaultValue: '',
    },
    {
      type: 'text',
      label: 'Override Credential Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="target_username" />,
      defaultValue: '',
    },
  ],
  'smb-wmi': [
    {
      type: 'text',
      label: 'Override Credential Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="target_domain" />,
      defaultValue: '',
    },
    {
      type: 'text',
      label: 'Override Credential Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="target_username" />,
      defaultValue: '',
    },
  ],
  'smb': [
    {
      type: 'text',
      label: 'Override Credential Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="target_domain" />,
      defaultValue: '',
    },
    {
      type: 'text',
      label: 'Override Credential Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="target_username" />,
      defaultValue: '',
    },
  ],
  'ssh-password': [
    {
      type: 'textarea',
      label: 'Ports',
      attribute: 'ports',
      needsSplit: true,
      required: false,
      defaultValue: [ 22 ],
      help: <HelpTrigger helpKey="ports" />,
    },
    {
      type: 'text',
      label: 'Override Credential Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="target_username" />,
      defaultValue: '',
    },
    ...SSH_PASSWORD_ELEVATE_FIELDS,
  ],
};

// the common fields that all credential forms share when CyberArk is selected
const WITH_CYBERARK_FIELDS = {
  'winrm-psrp': [
    {
      type: 'text',
      label: 'Host Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="cyberark_host_username" />,
      defaultValue: '',
      required: true,
    },
    {
      type: 'text',
      label: 'Host Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="cyberark_target_domain" />,
      defaultValue: '',
      validators: [ isWindowsDomain ],
    },
  ],
  'smb-wmi': [
    {
      type: 'text',
      label: 'Host Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="cyberark_host_username" />,
      defaultValue: '',
      required: true,
    },
    {
      type: 'text',
      label: 'Host Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="cyberark_target_domain" />,
      defaultValue: '',
      validators: [ isWindowsDomain ],
    },
  ],
  'smb': [
    {
      type: 'text',
      label: 'Host Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="cyberark_host_username" />,
      defaultValue: '',
      required: true,
    },
    {
      type: 'text',
      label: 'Host Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="cyberark_target_domain" />,
      defaultValue: '',
      validators: [ isWindowsDomain ],
    },
  ],
  'ssh-password': [
    {
      type: 'text',
      label: 'Host Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="cyberark_host_username" />,
      defaultValue: '',
      required: true,
    },
    {
      type: 'text',
      label: 'Host Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="cyberark_target_domain" />,
      defaultValue: '',
      validators: [ isWindowsDomain ],
    },
    {
      type: 'textarea',
      label: 'Ports',
      attribute: 'ports',
      needsSplit: true,
      required: false,
      defaultValue: [ 22 ],
      help: <HelpTrigger helpKey="ports" />,
    },
    ...SSH_PASSWORD_ELEVATE_FIELDS,
  ],
  'ssh-key': [
    {
      type: 'text',
      label: 'Host Username',
      attribute: 'target_username',
      help: <HelpTrigger helpKey="cyberark_host_username" />,
      defaultValue: '',
      required: true,
    },
    {
      type: 'text',
      label: 'Host Domain',
      attribute: 'target_domain',
      help: <HelpTrigger helpKey="cyberark_target_domain" />,
      defaultValue: '',
      validators: [ isWindowsDomain ],
    },
    {
      type: 'textarea',
      label: 'Ports',
      attribute: 'ports',
      needsSplit: true,
      required: false,
      defaultValue: [ 22 ],
      help: <HelpTrigger helpKey="ports" />,
    },
    ...SSH_KEY_ELEVATE_FIELDS,
  ],
};

export const recordData = {
  onboarding: true,
  display: {},
  // step 1) these fields figure out what form to show
  typeSelectionFields: [
    {
      type: 'text',
      label: 'Custom Label',
      attribute: 'label',
      defaultValue: '',
    },
    // Step 1, choose the pam type (if any)
    {
      type: 'select',
      options: PAM_OPTIONS,
      label: 'Pam Type (optional)',
      attribute: 'pam_type',
      help: <HelpTrigger helpKey="pam_type" />,
      defaultValue: 'none',
      disableOnEdit: true,
      allowInDemoMode: true,
    },
    // Choose the protocol Type
    {
      type: 'select',
      label: 'Credential Type / Protocol',
      attribute: 'protocol',
      defaultValue: 'smb-wmi',
      conditionalOptions: { attribute: 'pam_type', options: CONDITIONAL_CRED_OPTIONS },
      options: CRED_OPTIONS,
      disableOnEdit: true,
      allowInDemoMode: true,
    },
  ],
  // step 2) these are the main fields, slightly different version of the form based on which combinations were chosen
  // in step 1. There is a lot of repitition, but it is necessary to make sure each form is correct and specific enough
  // for each specific option chosen
  credentialFields: {
    // all possible forms where no PAM is used
    none: {
      'smb-wmi': WITH_NO_PAM_FIELDS.winType,
      'winrm-psrp': WITH_NO_PAM_FIELDS.winType,
      'ssh-password': WITH_NO_PAM_FIELDS['ssh-password'],
      'ssh-key': WITH_NO_PAM_FIELDS['ssh-key'],
      'smb': WITH_NO_PAM_FIELDS.winType,
    },
    // all possible forms where LAPS PAM is used
    laps: {
      'smb-wmi': {
        pam: {
          header: 'PAM Settings',
          fields: LAPS_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_LAPS_FIELDS['smb-wmi'],
        },
      },
      'winrm-psrp': {
        pam: {
          header: 'PAM Settings',
          fields: LAPS_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_LAPS_FIELDS['winrm-psrp'],
        },
      },
      'smb': {
        pam: {
          header: 'PAM Settings',
          fields: LAPS_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_LAPS_FIELDS['smb'],
        },
      },
    },
    // all possible forms where DELINEA PAM is used
    'thycotic-secret-server': {
      'smb-wmi': {
        pam: {
          header: 'PAM Settings',
          fields: DELINEA_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_DELINEA_FIELDS['smb-wmi'],
        },
      },
      'winrm-psrp':  {
        pam: {
          header: 'PAM Settings',
          fields: DELINEA_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_DELINEA_FIELDS['winrm-psrp'],
        },
      },
      'ssh-password': {
        pam: {
          header: 'PAM Settings',
          fields: DELINEA_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_DELINEA_FIELDS['ssh-password'],
        },
      },
      'smb':  {
        pam: {
          header: 'PAM Settings',
          fields: DELINEA_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_DELINEA_FIELDS['smb'],
        },
      },
    },
    // all possible forms where CYBERARK PAM is used
    'cyberark-vault': {
      'smb-wmi': {
        pam: {
          header: 'PAM Settings',
          fields: CYBERARK_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_CYBERARK_FIELDS['smb-wmi'],
        },
      },
      'winrm-psrp':  {
        pam: {
          header: 'PAM Settings',
          fields: CYBERARK_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_CYBERARK_FIELDS['winrm-psrp'],
        },
      },
      'ssh-password': {
        pam: {
          header: 'PAM Settings',
          fields: CYBERARK_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_CYBERARK_FIELDS['ssh-password'],
        },
      },
      'ssh-key':  {
        pam: {
          header: 'PAM Settings',
          fields: CYBERARK_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_CYBERARK_FIELDS['ssh-key'],
        },
      },
      'smb':  {
        pam: {
          header: 'PAM Settings',
          fields: CYBERARK_FIELDS,
        },
        common: {
          header: 'Protocol',
          fields: WITH_CYBERARK_FIELDS['smb'],
        },
      },
    },
  },
};
