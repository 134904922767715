/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getRecord, getRecords } from '../../../../shared/RecordCache';
import {
  formatNumber,
  isEmpty,
  isNotEmpty,
  reportTypeDisplayName,
} from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import Modal, { getModalPosition } from '../../../../shared/Modal';
import EmptyLoading from '../../../../shared/EmptyLoading';

import './style.scss';
import RecordList from '../../../../shared/RecordList';

const ModalBody = ( { item, rating, patches, loading } ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( item ) &&
        <div className={ `patchRollupHeader risk-${rating}` }>
          <InlineSVG type="patchRollupAlt" />
          <strong>{ reportTypeDisplayName( item, 'patch' ) }:</strong>
          <span>roll up status</span>
        </div>
      }
      {
        isNotEmpty( patches ) &&
        <React.Fragment>
          <div className="detailSectionDescription">
            This patch supersedes or "rolls up" the
            following <strong>{ formatNumber( patches.length ) }</strong> patche(s).
          </div>
          <RecordList
            records={patches}
            orderBy="risk"
            reportType="patch"
          />
        </React.Fragment>
      }
      <EmptyLoading
        payload={patches}
        loading={loading}
        emptyMessage="This patch does not rollup any other patches"
      />
    </React.Fragment>
  );
};

const PatchRollup = ( { item, riskType='risk', itemRating, itemRisk } ) => {

  const [ showModal, setShowModal ] = React.useState( false );
  const [ supersededPatches, setSupersededPatches ] = React.useState( null );
  const [ style, setStyle ] = React.useState( {} );
  const [ loading, setLoading ] = React.useState( false );
  const [ highestRisk, setHighestRisk ] = React.useState( 0 );

  const getRollupInformation = async( item, riskType ) => {
    setLoading( true );
    // eslint-disable-next-line camelcase, max-len
    const risk_type = riskType === 'filtered_risk' ? 'risk' : riskType;
    const _record = await getRecord(
      'patch',
      item.id,
      // eslint-disable-next-line camelcase, max-len
      { risk_type },
      true,
    );
    if ( isNotEmpty( _record ) &&  isNotEmpty( _record.supersedes ) ) {
      let supersedes = await getRecords( 'patch', {
        // eslint-disable-next-line camelcase
        id_list: _record.supersedes,
        rownums: [ 0, _record.supersedes.length + 1 ],
        // eslint-disable-next-line camelcase
        extra_columns: [ 'vendor', 'identifier', 'description', 'patch_analysis.risk' ],
        // eslint-disable-next-line camelcase
        order_by: [ [ 'patch_analysis.risk', 'DESC' ] ],
      } );

      if ( isNotEmpty( supersedes ) ) {

        const _highestRisk = Math.max( ...supersedes.map( p => p.risk ) );
        setHighestRisk( _highestRisk );
        // eslint-disable-next-line max-len
        supersedes = supersedes.sort( ( a, b ) => ( b.direct_risk ? b.direct_risk : b.risk ) - ( a.direct_risk ? a.direct_risk : a.risk ) );
      }

      setSupersededPatches( supersedes );
    }
    setLoading( false );
  };

  const showAndPositionModal = clickEvent => {
    const position = getModalPosition( clickEvent, 544 );

    if ( isNotEmpty( position ) && isNotEmpty( position.left ) && isNotEmpty( position.top ) ) {
      setStyle( { left: position.left, top: position.top } );
    }
    setShowModal( true );
  };

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( riskType ) && isEmpty( supersededPatches ) && showModal ) {
      getRollupInformation( item, riskType );
    }
  }, [ item, riskType, showModal, supersededPatches ] );

  return (
    <React.Fragment>
      <Modal
        elementClass="patchRollupModal"
        visible={showModal}
        setVisible={setShowModal}
        modalStyle={ style }
        body={ <ModalBody
          loading={loading}
          item={item}
          risk={itemRisk}
          rating={itemRating}
          patches={supersededPatches}
          highestRisk={highestRisk}
        /> }
      />
      <button className={ `showRollupButton risk-${itemRating}` } onClick={ showAndPositionModal } >
        <InlineSVG type="patchRollupAlt" />
      </button>
    </React.Fragment>
  );
};

export default PatchRollup;