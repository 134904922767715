/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { TasksProvider } from '../../../Contexts/Tasks';

import TaskList from './TaskList';
import './style.scss';
import ConfigurationAlert, { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import { isNotEmpty } from '../../../shared/Utilities';

const Tasks = ( ) => {
  const [ hasDiskAlert, setHasDiskAlert ] = React.useState( false );

  React.useEffect( () => {
    getConfigurationAlertsFor( 'health-check' ).then( response => {
      if ( isNotEmpty( response ) ) {
        setHasDiskAlert( true );
      }
    } );
  }, [] );

  return (
    <div className="setupPageContainer tasksPage">
      <TasksProvider>
        {
          hasDiskAlert &&
          <ConfigurationAlert
            sourceType="health-check"
          />
        }
        <TaskList />
      </TasksProvider>
    </div>
  );
};

export default Tasks;