/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './RadioGroup.scss';

import {
  isNotEmpty,
} from '../../../Utilities';

const RadioGroup = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  const [ checkedVal, setCheckedVal ] = React.useState( '' );

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( originalValue )
    ) {
      setCheckedVal( originalValue );
    }
  }, [ existingRecord, originalValue, fieldRef ] );

  const handleCheck = ( field, val ) => {
    setCheckedVal( val );
    onChange( field, val );
  };

  return (
    <React.Fragment>
      {
        ( field.options && isNotEmpty( field.options ) ) &&
        Object.keys( field.options ).map( ( opt, index ) => {
          return  <label
            className="radioOptionWrapper"
            key={index}
          >
            {/* eslint-disable-next-line max-len */}
            <div className={ `radioFieldWrapper ${originalValue === opt ? 'original' : ''} ${checkedVal === opt ? 'checked' : ''} ${( field.disabled || formState.fieldStates[field.attribute].disabled ) ? 'disabled' : ''}` }>
              <input
                name={field.attribute}
                disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
                type="radio"
                onChange={ () => handleCheck( field, opt ) }
                { ...field.htmlProps }
              />
            </div>
            <span className="labelWrapper">{ field.options[opt] }</span>
          </label>;
        } )
      }
    </React.Fragment>
  );
};

export default RadioGroup;
