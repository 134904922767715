/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';

import { isEmpty, isNotEmpty, useLocalStorage } from '../shared/Utilities';

export const RemediationContext = React.createContext();

export const RemediationProvider = ( { children } ) => {

  const [ workingDraftPlan, setWorkingDraftPlan ] = useLocalStorage( 'workingDraftPlan', '' );

  const [ allRemediationDrafts, setAllRemediationDrafts ] = useLocalStorage( 'allRemediationDrafts', '' );

  const refreshAllDrafts = () => {
    makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ).then( response => {
      const allDrafts = [];

      if ( response && isNotEmpty( response.results ) ) {
        response.results.map( plan => {
          if ( plan.status === 'draft' ) {
            allDrafts.push( plan );
          }
        } );
      }

      if ( isNotEmpty( allDrafts ) ) {
        setAllRemediationDrafts( allDrafts );
        if ( isEmpty( workingDraftPlan ) ) {
          setWorkingDraftPlan( allDrafts[0] );
        }
      } else {
        setAllRemediationDrafts( null );
        setWorkingDraftPlan( null );
      }
    } );
  };

  React.useEffect( ( ) => {
    refreshAllDrafts();
  }, [] );

  const data = [
    workingDraftPlan,
    setWorkingDraftPlan,
    allRemediationDrafts,
    setAllRemediationDrafts,
    refreshAllDrafts,
  ];

  return (
    <RemediationContext.Provider value={ data }>
      { children }
    </RemediationContext.Provider>
  );
};
