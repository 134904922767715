/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import CopyToClipBoard from '../../../CopyToClipBoard';

import './ACSURL.scss';

import {
  isNotEmpty,
} from '../../../Utilities';

const ACSURL = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( formState ) && isNotEmpty( formState.fieldStates ) ) &&
        <div className="URLWrapper">
          <pre>https://</pre>
          <input
            ref={fieldRef}
            placeholder={ field.placeholder ? field.placeholder : ''}
            disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
            type="text"
            onChange={ e => onChange( field, e.target.value ) }
            { ...field.htmlProps }
          />
          <pre>
            {/* eslint-disable-next-line max-len */}
            { `/sso/saml2/${formState.fieldStates.saml2_id.updatedValue || formState.fieldStates.saml2_id.originalValue}` }
          </pre>
          <CopyToClipBoard
            // eslint-disable-next-line max-len
            text={`https://${formState.fieldStates.saml2_acs_host_port.updatedValue || formState.fieldStates.saml2_acs_host_port.originalValue}/sso/saml2/${formState.fieldStates.saml2_id.updatedValue || formState.fieldStates.saml2_id.originalValue}`}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default ACSURL;
