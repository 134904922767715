/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
'use strict';
import "regenerator-runtime/runtime.js";

export async function sha256(data) {
  return Array.from(new Uint8Array(await crypto.subtle.digest('SHA-256', new TextEncoder().encode(data)))).map(b => b.toString(16).padStart(2, '0')).join('');
};

export async function getEULA() {
  const eula = await make_request('GET', '/eula.txt');
  return { eula, eula_hash: await sha256(eula) };
};

export async function load_global_settings() {
  const response = await make_request('INDEX', '/global_setting', {});
  if(response['results']) {
    return Object.assign({}, response['results']);
  } else {
    console.log("ERROR: Failed to load global settings");
  };

  return {};
};

/* t in seconds
 * include_seconds causes seconds precision to be added to the return value
 * tzoffset in seconds.  negative for the western hemisphere, positive for the eastern
 * tzname is an arbitrary label of the time zone to be included in the return value
 */
export function formatUnixTime(t, include_seconds, tzoffset, tzname) {
    if((tzoffset == null) || (tzoffset == undefined))
        tzoffset = 0 - ((new Date()).getTimezoneOffset()*60);

    var a = new Date((t + tzoffset) * 1000);
    var year = a.getUTCFullYear();
    var month = "0" + (a.getUTCMonth()+1);
    var day = "0" + a.getUTCDate();
    var hour = "0" + a.getUTCHours();
    var min = "0" + a.getUTCMinutes();
    var sec = "0" + a.getUTCSeconds();

    var ret_val = year + '-' + month.substr(month.length-2) + '-' + day.substr(day.length-2) + ' ' + hour.substr(hour.length-2) + ':' + min.substr(min.length-2);
    if(include_seconds)
        ret_val += ':' + sec.substr(sec.length-2);

    if(tzname)
        ret_val += ' ('+tzname+')';

    return ret_val;
}

const MAX_RETRY = 8
const RETRY_DELAY_MS = 200

export const makeRequestRetry = async ( ...args ) => {
  let retryLeft = MAX_RETRY
  while ( retryLeft > 0 ) {
    try {
      return await make_request( ...args )
    } catch ( err ) {
      await sleep( RETRY_DELAY_MS )
    } finally {
      retryLeft -= 1
    }
  }
  throw new Error(`Too many retries`)
}

const sleep = delay => {
  return new Promise( resolve => setTimeout( resolve, delay ) )
}

/**
 * Makes an HTTP request with Authorization information automatically set if
 * the user is logged in. Useful for interacting with the API. It automatically
 * attempts to parse/decode JSON.
 *
 * Parameters:
 * method (string): the HTTP request method, e.g. 'UPDATE'
 * path (string): url path to make request to
 * params (object): a key => value dictionary object mapping URL parameters
 * callback (function): a callback, e.g. function(results) { ... }
 *
 * Example:
 * make_request('FETCH', '/api/v1/example', { 'query':'test' }, function(results) {
 *     console.log('Results:', results['results'])
 * });
 *
 */
// XXX: an async version of this function would be really nice.
export function make_request(method, path, params, callback) {

  let myHeaders = {'Content-Type':'application/json; charset=utf-8'};
  const sid = window.localStorage.getItem('sid');

  if (sid) {
    myHeaders['Authorization'] = `kanchil ${sid}`;
  };

  let myInit = { method: method, headers: new Headers(myHeaders)};

  if (params) {
    myInit.body = JSON.stringify(params);
  };

  if (callback) {
    return (
            fetch(path, myInit)
              .then( response => {
                // if it encounters a 503, retry until it hits 0 attempts
                if (response.status === 503 || response.status === 500 ) {
                  // if it is a 503, send a better message to the FE
                  const errorObject = { cause: response.statusText };
                  throw new Error(response.status, errorObject );
                } else {
                  return process_json_response(response);
                };
              })
              .then(callback)
              .catch( function(error) {
                console.log(error);
              })
          );
  };


  return (
    fetch(path, myInit)
      .then( response => {
        // if it encounters a 503, retry until it hits 0 attempts
        if (response.status === 503 || response.status === 500 ) {
          // if it is a 503, send a better message to the FE
          const errorObject = { cause: response.statusText };
          throw new Error(response.status, errorObject );
        } else {
          return process_json_response(response);
        };
      })
      .catch(function(error) {
        return ( error );
      })
    );
};

async function process_json_response(response) {
  return await response.text().then(function(body) {
    // if(response.status == 401) {
    //   // window.location.href = `/login.html${window.location.hash}`;
    //   throw new Error("Session timed out");
    // }

    const xrs = response.headers.get('X-Refresh-Session');

    if (xrs) {
      const new_session = JSON.parse(xrs);
      window.localStorage.setItem('sid', new_session['sid']);
      window.localStorage.setItem('sid_expiration', new String(new_session['expiration']));
    }

    const contentType = response.headers.get("content-type");
    if(contentType && contentType.includes("application/json")) {
      try {
            return JSON.parse(body);
          } catch(e) {
            throw new TypeError("JSON response couldn't be parsed: " + body);
          }
    } else {
      return body;
    }
  });
};

window.ds = {};
window.ds.make_request = make_request;
