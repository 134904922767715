/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  globalColors,
  hexToRGB,
  isNotEmpty,
} from '../../../shared/Utilities';

const TagItem = ( { tag } ) => {

  const [ r, setR ] = React.useState( null );
  const [ g, setG ] = React.useState( null );
  const [ b, setB ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( tag ) ) {
      let rgb = {};
      if ( isNotEmpty( tag.color ) ) {
        rgb = hexToRGB( tag.color );
      } else {
        rgb = hexToRGB( globalColors.grey );
      }

      if ( isNotEmpty( rgb ) ) {
        setR( rgb.r );
        setG( rgb.g );
        setB( rgb.b );
      }
    }
  }, [ tag ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( tag ) &&
        <li
          className="assetTagListItem"
          style={
            {
              border: `1px solid ${isNotEmpty( tag.color ) ? tag.color : globalColors['grey']}`,
              background: isNotEmpty( tag.color ) ? `rgba(${r},${g},${b}, 0.15)` : globalColors['grey--divider'],
              color: isNotEmpty( tag.color ) ? tag.color : globalColors['grey'],
            }
          }
        >
          { !tag.truncated && tag.label }
        </li>
      }
    </React.Fragment>
  );
};

export default TagItem;