/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatUnixTime, isNotEmpty, recordSorter } from '../../../shared/Utilities';
import { makeRequest } from '../../../../legacy/io';
import EmptyLoading from '../../../shared/EmptyLoading';

import './ScanLogViewer.scss';

const ScanLogViewer = ( { logIdentifier, withinModal=false } ) => {

  const [ formattedLogContent, setFormattedLogContent ] = React.useState( null );
  const [ logDate, setLogDate ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( true );

  React.useEffect( () => {

  }, [ logIdentifier ] );


  const getLogContent = async ( identifier ) => {

    setLoading( true );
    const fetchedLog = await makeRequest( 'FIND', '/project/default/scan_log', {
      // eslint-disable-next-line camelcase
      field_map: {
        identifier,
      },
    } );

    if ( isNotEmpty( fetchedLog ) && isNotEmpty( fetchedLog.results ) ) {
      setFormattedLogContent( formatLogOutput( fetchedLog.results ) );
      setLoading( false );
    } else {
      setFormattedLogContent( null );
      setLogDate( null );
      setLoading( false );
    }
  };

  const formatLogOutput = logRows => {
    const output = [];

    logRows = logRows.sort( ( a, b ) => recordSorter( 'created', true, a, b ) );

    setLogDate( formatUnixTime( logRows[0].created ) );

    logRows.map( ( log, index ) => {

      // need to cleanup and split the message into parts for formatting and syntax highlighting
      const formattedMessage = log.message.replace( '\n', '\n        ' );
      const split = formattedMessage?.split( '\u2022' ) || [];
      const [ logType, remainingLog ] = split;
      const splitIP = remainingLog?.split( '\u00BB' ) || [];
      const [ ip, message ] = splitIP;

      output.push(
        <p key={index} className={ `${logType} logRow` }>
          <span className="lineInfo">
            <span className="timestamp">{ formatUnixTime( log.created, true ) }</span>
            <span className="ipAddress">{ `(${ip || 'N/A'})` }</span>
          </span>
          <span className="lineContent">
            <span className="content">
              <strong>{ `${logType || 'DEBUG' }  \u00BB` }</strong>
              { message || ' --' }
            </span>
          </span>
        </p>,
      );
    } );

    return output;
  };

  React.useEffect( () => {
    if ( isNotEmpty( logIdentifier ) ) {
      getLogContent( logIdentifier );
    } else {
      setFormattedLogContent( null );
      setLogDate( null );
    }
  }, [ logIdentifier ] );

  return (
    <React.Fragment>
      <div className="logOutputWrapper">
        <div className={ `upper ${withinModal ? 'withinModal' : ''}`}>
          <span>
            <strong>Created:</strong> { logDate || 'N/A' }
          </span>
          <span>
            <strong>Identifier:</strong> { logIdentifier || 'N/A' }
          </span>
        </div>
        {
          isNotEmpty( formattedLogContent ) &&
          <div className={ `logContentWrapper ${withinModal ? 'withinModal' : ''}`} >
            { formattedLogContent }
          </div>
        }
        <EmptyLoading
          loading={loading}
          emptyMessage="This scan log is empty"
          payload={formattedLogContent}
        />
      </div>
    </React.Fragment>
  );
};

export default ScanLogViewer;