/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import './style.scss';

const EmptyState = ( { message='No records found' } ) => {
  return (
    <div className="emptyState">
      { message }
    </div>
  );
};

export default EmptyState;
