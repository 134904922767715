/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import './RadioGroup.scss';

import {
  isNotEmpty, paramsToFilters,
} from '../../Utilities';

const RadioGroup = ( {
  input,
  value,
  handleChange,
} ) => {

  const [ selectValue, setSelectValue ] = React.useState( null );

  const handleSelection = value => {
    setSelectValue( value );
    handleChange( input.attribute, value );
  };

  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input ] );

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute];
    setSelectValue( newValue || '' );
  };

  return (
    <label>
      <span className="labelWrapper">
        { input.label }
        { input.helpItem }
      </span>
      {
        ( input.options && isNotEmpty( input.options ) ) &&
        Object.keys( input.options ).map( ( opt, index ) => {
          return  <label
            className="radioOptionWrapper"
            key={index}
          >
            {/* eslint-disable-next-line max-len */}
            <div className={ `radioFieldWrapper ${selectValue === opt ? 'checked' : ''} ${input.disabled ? 'disabled' : ''}` }>
              <input
                name={input.attribute}
                disabled={input.disabled}
                type="radio"
                onChange={ () => handleSelection( opt ) }
                checked={ selectValue === opt }
                { ...input.htmlProps }
              />
            </div>
            <span className="labelWrapper">{ input.options[opt] }</span>
          </label>;
        } )
      }
    </label>
  );
};

export default RadioGroup;
