/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import Modal from '../../../shared/Modal';
import Notification from '../../../shared/Notification';
import EmptyState from '../../../shared/EmptyState';
import Loading from '../../../shared/Loading';

import Form from '../../../shared/Form';

import {
  getFieldValues,
} from '../../../shared/Form/Shared.js';

import {
  HelpTrigger,
} from '../../../components/HelpDocumentation/ContextualHelp/index.js';

import './style.scss';

import {
  isEmpty,
  isNotEmpty,
  itemIsObject,
  sleep,
} from '../../../shared/Utilities';

import { makeRequest } from '../../../../legacy/io';

const ModalBody = ( {
  fields,
  setUpdatedForm,
  setIsValid,
  ipConnectivityTestResults,
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( fields ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          recordType="ip_connectivity"
          setIsValid={setIsValid}
        />
      }
      <div className="ipConnectivityTestResultsContainer">
        {
          isEmpty( ipConnectivityTestResults )
            ? <EmptyState message="Enter IP and Scan Group information and run 'Test'" />
            : <React.Fragment>
              { ipConnectivityTestResults }
            </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
};

const ModalAction = ( {
  isValid,
  testIPConnectivity,
  isRunning,
} ) => {

  return (
    <button
      disabled={ !isValid || isRunning }
      className={'submitButton'}
      onClick={ testIPConnectivity }
    >
      Test
    </button>
  );
};

const IPConnectivityTest = ( {
  ipAddresses,
  scanGroups,
  show,
  setShow,
} ) => {

  const [ fields, setFields ] = React.useState( [] );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( false );
  const [ ipConnectivityTestResults, setIPConnectivityTestResults ] = React.useState( null );

  const EMPTY_FIELDS = [
    {
      type: 'checkbox',
      label: 'Enter IP Address Manually',
      disabled: isEmpty( ipAddresses ),
      defaultValue: !!isEmpty( ipAddresses ),
      attribute: 'use_manual',
      help: <HelpTrigger helpKey="enter_ip_manually" />,
    },
    {
      type: 'select',
      attribute: 'ip_address_select',
      label: 'IP Address',
      required: true,
      includeIf: {
        attribute: 'use_manual',
        value: false,
      },
      help: <HelpTrigger helpKey="ip_address" />,
    },
    {
      type: 'text',
      attribute: 'ip_address_manual',
      label: 'IP Address',
      defaultValue: '',
      required: true,
      includeIf: {
        attribute: 'use_manual',
        value: true,
      },
      help: <HelpTrigger helpKey="ip_address" />,
    },
    {
      type: 'select',
      attribute: 'scan_group',
      label: 'Select Scan Group',
      required: true,
      help: <HelpTrigger helpKey="scan_groups" />,
    },
  ];

  // 1) set up the form and options for this particular host, must be an Empty
  //  watch, otherwise it will re-render on every context change, such as clicking
  //  on the help icon
  // setting the scan group and ip field and options asyncronously
  React.useEffect( () => {
    const _fields = [ ...EMPTY_FIELDS ];

    if ( isNotEmpty( scanGroups ) && show ) {
      const scanGroupOptions = {};

      scanGroups.map( sg => {
        if ( isNotEmpty( sg ) ) {
          scanGroupOptions[sg.id] = sg.label;
        }

      } );

      const sgField = _fields.find( f => f.attribute === 'scan_group' );
      if ( isNotEmpty( sgField ) && isNotEmpty( scanGroupOptions ) && itemIsObject( scanGroupOptions ) ) {
        // eslint-disable-next-line
        sgField.defaultValue = Object.keys( scanGroupOptions )[0];
        sgField.options = scanGroupOptions;
        sgField.disabled = Object.keys( scanGroupOptions ).length === 1;
      }

      const IPOptions = {};

      if ( isNotEmpty( ipAddresses ) ) {
        ipAddresses.map( ip => {
          IPOptions[ip] = ip;
        } );
      }

      const ipField = _fields.find( f => f.attribute === 'ip_address_select' );
      if ( isNotEmpty( ipField ) && isNotEmpty( IPOptions ) && itemIsObject( IPOptions ) ) {
        // eslint-disable-next-line
        ipField.defaultValue = Object.keys( IPOptions )[0];
        ipField.options = IPOptions;
        ipField.disabled = Object.keys( IPOptions ).length === 1;
      }
    }
    setFields( _fields );
  }, [ show ] );

  const testIPConnectivity = async() => {

    if ( isNotEmpty( updatedForm ) ) {
      const values = getFieldValues( updatedForm?.fieldStates, 'ip_connectivity' );

      const _scanGroupId = values.scan_group;
      const _ipAddress = values.use_manual === true
        ? values?.ip_address_manual?.trim()
        : values?.ip_address_select;

      let notificationBody;

      let logMessages = [];

      setIPConnectivityTestResults(
        <Loading text={ `Testing connectivity to host ${_ipAddress}...` }/>,
      );

      const testReponse = await makeRequest( 'TEST', '/project/default/scan_group', {
        id: _scanGroupId,
        // eslint-disable-next-line camelcase
        ip_address: _ipAddress,
      } );

      if ( testReponse.errors ) {
        notificationBody = <ul>
          {
            testReponse?.errors?.map( ( error, index ) => {
              return  <li key={index}>
                { error }
              </li>;
            } )
          }
        </ul>;

        setIPConnectivityTestResults(
          <Notification
            options={ {
              type: 'alert',
              message: notificationBody,
              header: 'Connectivity Test Failed',
            } }
          />,
        );
      } else {
        // eslint-disable-next-line camelcase
        const job_id = testReponse.results;

        const start = ( new Date() ).getTime();

        let statusResponse;
        // eslint-disable-next-line
        while( true ) {
          const seconds = ( ( new Date() ).getTime() - start ) / 1000.0;

          if( seconds > 300 ) {
            notificationBody = <span>
              Connectivity test unexpectedly timed out!
            </span>;
            setIPConnectivityTestResults(
              <Notification options={ { type: 'alert', message: notificationBody } } />,
            );

            break;
          }

          // eslint-disable-next-line camelcase
          statusResponse = await makeRequest( 'STATUS', '/project/default/scan_group', { job_id: job_id } );

          if( statusResponse?.errors ) {

            notificationBody = <ul>
              {
                statusResponse?.errors?.map( ( error, index ) => {
                  return  <li key={index}>
                    { error }
                  </li>;
                } )
              }
            </ul>;

            setIPConnectivityTestResults(
              <Notification options={ { type: 'alert', message: notificationBody } } />,
            );
          }

          if ( statusResponse?.results === null )   {
            await sleep( 1571 );
            continue;
          }

          logMessages = statusResponse?.results?.log;

          if ( statusResponse?.results?.connectable ) {

            notificationBody = <span>Successfully connected to host.</span>;

            setIPConnectivityTestResults(
              <React.Fragment>
                <Notification options={{ type: 'success', message: notificationBody}} />
                {
                  logMessages.map( ( msg, i ) => {
                    return <span className={`logLevel-${msg.level}`} key={i}>
                      { msg.formatted || '' }
                    </span>;
                  } )
                }
              </React.Fragment>,
            );
          } else {

            notificationBody = <span>
              Unable to connect to host. Is the host up?
              Are you sure credentials are configured correctly?
            </span>;

            setIPConnectivityTestResults(
              <React.Fragment>
                <Notification options={{ type: 'alert', message: notificationBody}} />
                {
                  logMessages?.map( ( msg, i ) => {
                    return <span className={`logLevel-${msg.level}`} key={i}>
                      { msg.formatted || '' }
                    </span>;
                  } )
                }
              </React.Fragment>,
            );
          }
          break;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        visible={show}
        setVisible={setShow}
        header="Test Scan Configuration"
        elementClass="ipConnectivityModal"
        body={<ModalBody
          fields={fields}
          setUpdatedForm={setUpdatedForm}
          setIsValid={setIsValid}
          ipConnectivityTestResults={ipConnectivityTestResults}
        />
        }
        action={<ModalAction
          isValid={isValid}
          testIPConnectivity={testIPConnectivity}
        />}
      />
    </React.Fragment>

  );
};

export default IPConnectivityTest;
