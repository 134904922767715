/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';

import RemediationWorkflowMenu from '../RemediationWorkflow/RemediationWorkflowMenu.js';
import AcceptedRiskMenu from '../AcceptedRisk/AcceptedRiskMenu.js';
import { isNotEmpty } from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG/index.js';

const defaultCollapsedState = { host: true, patch: true, vulnerability: true };

const Menu = ( {
  menuRef,
  show,
  setShow,
  menuPosition,
  setMenuPosition,
  collapsed,
  setCollapsed,
  menuContext,
  setMenuContext,
  items,
  itemsKey,
  callback,
  setTotalCount,
  planOptions,
} ) => {

  const root = document.getElementById( 'addToMenuPortal' );

  const resetMenu = () => {
    setMenuContext( null );
    setShow( false );
    setCollapsed( defaultCollapsedState );
  };

  // sometimes we want to skip the chooser directly and go straight to just one of the workflows, this checks for that
  // situation. Currently this is only implemented in the remediation cart in the topbar, but it is conceivable that
  // we might want it somewhere else in the application at some future point -DMC 2023-01-09
  React.useEffect( ( ) => {
    if ( isNotEmpty( planOptions ) && planOptions.length === 1 ) {
      if ( show ) {
        setMenuContext( planOptions[0] );
      } else {
        setMenuContext( null );
      }
    }
  }, [ planOptions, show ] );

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        isNotEmpty( menuContext )
          ? <React.Fragment>
            {
              menuContext === 'remediation' &&
              <RemediationWorkflowMenu
                menuRef={ menuRef}
                show= { show }
                setShow={ setShow }
                menuPosition={ menuPosition }
                setMenuPosition={ setMenuPosition }
                collapsed={ collapsed }
                setCollapsed={ setCollapsed }
                items={ items }
                itemsKey={ itemsKey }
                callback={ callback }
                resetMenu={ resetMenu }
                setTotalCount={setTotalCount}
              />
            }
            {
              menuContext === 'acceptedRisk' &&
              <AcceptedRiskMenu
                collapsed={ collapsed }
                setCollapsed={ setCollapsed }
                menuRef={ menuRef}
                show= { show }
                setShow={ setShow }
                menuPosition={ menuPosition }
                setMenuPosition={ setMenuPosition }
                tasks={ items }
                tasksKey={ itemsKey }
                callback={ callback }
                resetMenu={ resetMenu }
              />
            }
          </React.Fragment>
          : <React.Fragment>
            { show && <div className="contextChooser addToPlanShade" onClick={ resetMenu } /> }
            <div
              ref={ menuRef }
              className={ `contextChooser ${show ? 'visible' : ''} addToPlanMenu`}
              style={ menuPosition }
            >
              <h3>
                Which plan type?
              </h3>
              <div className="contextOptions" >
                <button
                  onClick={ () => setMenuContext( 'remediation' ) }
                  className="addToMenuButton remediation"
                >
                  <InlineSVG type="remediation" />
                  Remediation
                </button>
                <button
                  onClick={ () => setMenuContext( 'acceptedRisk' ) }
                  className="addToMenuButton acceptedRisk"
                >
                  <InlineSVG type="acceptedRisk" />
                  Accepted Risk
                </button>
              </div>
            </div>
          </React.Fragment>
      }
    </React.Fragment>,
    root,
  );
};

export default Menu;


