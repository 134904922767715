/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../shared/Utilities';
import RecordStatistic from './RecordStatistic';
import ReductionAndRating from '../../shared/RiskExplanation/ReductionAndRating';

const Statistics = ( {
  record,
  recordType,
  riskType='filtered_risk',
  recordInstanceData,
  includeRisk=false,
  riskClass,
} ) => {

  const [ adjustedRiskType, setAdjustedRiskType ] = React.useState( null );

  const statisticsForRecordType = {
    host: [
      'vulnerabilities',
      'patches',
      'sensitive_nodes',
      'unsuperseded_patches',
    ],
    patch: [
      'hosts',
      'vulnerabilities',
    ],
    vulnerability: [
      'hosts',
      'patches',
      'unsuperseded_patches',
      'exploit_status',
    ],
    user: [
      'active_hosts',
      'domain_groups',
      // 'sensitive_assets',
    ],
    path: [
      'steps',
      'num_vulnerabilities',
    ],
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( riskType ) ) {
      if ( 'filtered_risk' in record ) {
        setAdjustedRiskType( 'filtered_risk' );
      } else {
        setAdjustedRiskType( riskType );
      }
    } else {
      setAdjustedRiskType( 'filtered_risk' );
    }
  }, [ record, riskType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( statisticsForRecordType[recordType] ) ) &&
        <div className={ `recordDetails__InformationPanel__Body_Section statistics ${recordType}` }>
          {
            statisticsForRecordType[recordType].map( ( statisticsKey, index ) => {
              return <RecordStatistic
                key={index}
                record={record}
                statisticsKey={statisticsKey}
                recordInstanceData={recordInstanceData}
                riskType={adjustedRiskType}
                recordType={recordType}
              />;
            } )
          }
          {
            includeRisk &&
            <ReductionAndRating
              record={record}
              recordType={recordType}
              riskClass={riskClass}
              riskType={adjustedRiskType}
              includeExplanationLink
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Statistics;