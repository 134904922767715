/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';

import InlineSVG from '../InlineSVG';
import { copyToClipBoard } from '../Utilities';

import './style.scss';

const CopyToClipBoard = ( {
  text,
  flash=true,
} ) => {
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const copy = text => {
    copyToClipBoard( text );
    if ( flash ) {
      addFlashMessage( {
        body: 'copied to clipboard!',
        type: 'success',
      } );
    }
  };
  return (
    <React.Fragment>
      <button
        className="copyToClipBoardButton"
        onClick={ () => copy( text ) }
      >
        <InlineSVG type="copyAlt" version="primary" />
      </button>
    </React.Fragment>
  );
};

export default CopyToClipBoard;
