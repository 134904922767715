/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { isNotEmpty } from '../../../Utilities';

const Hidden = ( {
  field,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  // set the value on init
  React.useEffect( () => {
    if (
      isNotEmpty( fieldRef )
      && isNotEmpty( fieldRef.current )
    ) {
      fieldRef.current.value = originalValue || '';
    }
  }, [ originalValue, fieldRef, existingRecord ] );

  return (
    <React.Fragment>
      <input
        ref={fieldRef}
        type={field.type}
        onChange={ e => onChange( field, e.target.valueAsNumber || e.target.value ) }
      />
    </React.Fragment>
  );
};

export default Hidden;
