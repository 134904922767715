/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import { makeRequest } from '../../legacy/io';
import { getGlobalSettings, isNotEmpty } from '../shared/Utilities';

export const RiskContext = React.createContext();

export const RiskProvider = ( { children } ) => {

  let isMounted = true;

  const [ targetRisk, setTargetRisk ] = React.useState( 1 );
  const [ globalRisk, setGlobalRisk ] = React.useState( 1 );
  const [ peerPercentile, setPeerPercentile ] = React.useState( 0.9 );

  const refreshRisk = async ( ) => {
    await refreshGlobalRisk();
    await refreshTarget();
  };

  const refreshGlobalRisk = async () => {
    const response = await makeRequest( 'FETCH', '/model', { project: 'default', model: 'base' } );
    if ( isMounted && response.results?.risk !== undefined ) {
      setGlobalRisk( response.results.risk );
      window.localStorage.setItem( 'globalRisk', response.results.risk );
    }
  };

  const refreshTarget = async () => {
    const projectResponse = await getGlobalSettings( 'project' );

    if ( isNotEmpty( projectResponse ) && isNotEmpty( projectResponse.settings ) ) {
      setTargetRisk( projectResponse.settings.risk_target );
      window.localStorage.setItem( 'targetRisk', projectResponse.settings.risk_target );
    }
  };

  const refreshPeerPercentile = async () => {
    const response = await makeRequest(
      'FETCH',
      '/analysis/industry_percentile_history',
      { project: 'default', model: 'base' },
    );

    if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
      const _currentPercentile = response.results[ response.results.length - 1 ];
      setPeerPercentile( _currentPercentile?.percentile );
    } else {
      setPeerPercentile( 0 );
    }
  };

  React.useEffect( () => {
    isMounted = true;
    refreshRisk();
    refreshPeerPercentile();
    return () => {
      isMounted = false;
    };
  }, [] );

  const data = [
    globalRisk,
    targetRisk,
    peerPercentile,
    refreshRisk,
    refreshPeerPercentile,
  ];

  return (
    <RiskContext.Provider value={ data }>
      { children }
    </RiskContext.Provider>
  );
};
