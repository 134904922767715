import { decodeURLHash, isNotEmpty } from '../../../shared/Utilities';

export const reportTypes = {
  hosts: 'host',
  patches: 'patch',
  vulnerabilities: 'vulnerability',
  instances: 'instance',
  users: 'user',
  // eslint-disable-next-line camelcase
  vulnerability_data: 'vulnerability_data',
};

export const defaultRemdiationItems = {
  host: {},
  patch: {},
  vulnerability: {},
};

export const remediationItemsKey = type => {
  if ( type === 'instance' || type === 'vulnerability_data' ) {
    const hash = decodeURLHash();
    let groupType = hash.group_type;

    if ( groupType === 'patch_cumulative' ) {
      groupType = 'patch';
    }

    if ( isNotEmpty( groupType ) ) {
      return groupType;
    }
    return 'host';
  }
  return type;
};

export const planOptionsForReportType = {
  host: [ 'remediation', 'acceptedRisk' ],
  patch: [ 'remediation' ],
  vulnerability: [ 'remediation', 'acceptedRisk' ],
  user: [ ],
};