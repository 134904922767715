/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  debounce,
  formatUnixDate,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  tagColors,
} from '../../../../../shared/Utilities';
import YAxisLabels from '../../../../../shared/Charts/AxisLabels/YAxisLabels';
import Legend from '../../../../../shared/Charts/Legend';

import './Overtime.scss';

import MultiArea from '../../../../../shared/Charts/MultiArea';
import YAxisLines from '../../../../../shared/Charts/AxisLines/YAxisLines';
import XAxisLabels from '../../../../../shared/Charts/AxisLabels/XAxisLabels';
import XAxisLines from '../../../../../shared/Charts/AxisLines/XAxisLines';
import EmptyState from '../../../../../shared/EmptyState';
import { TagsContext } from '../../../../../Contexts/Tags';

const OverTimeCompliance = ( {
  item,
  settings,
  data,
  adjustSVGAspectRatio,
  svgAspectRatio,
  svgContainerRef,
} ) => {

  const [ chartData, setChartData ] = React.useState( null );
  const [ seriesLegendData, setSeriesLegendData ] = React.useState( null );
  const [ xAxisLabels, setXAxisLabels ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 400 );
  const svgContainerWidth = 1_000;

  const [ yMax, setYMax ] = React.useState( null );

  // once we know what version we are dealing with, we need to figure out what data to look and format the data
  // according to how it needs to be displayed
  React.useEffect( () => {
    if (
      isNotEmpty( settings )
      && 'report_type' in settings
      && isNotEmpty( tags )
      && isNotEmpty( data )
    ) {

      let attribute = '';
      if ( settings.report_type === 'hosts' ) {
        attribute = 'num_hosts';
      }
      if ( settings.report_type === 'patches' ) {
        attribute = 'num_patches';
      }
      if ( settings.report_type === 'vulnerabilities' ) {
        attribute = 'num_vulnerabilities';
      }
      if ( settings.report_type === 'vulnerability_instances' ) {
        attribute = 'num_instances';
      }
      if ( settings.report_type === 'risk' ) {
        attribute = 'risk';
      }

      // for full over time version (area), the data will be a flat array of points, they will be a flat
      // list of intries that need to be grouped by timestamp
      if ( itemIsArray( data ) ) {

        const availableColors = [ ...tagColors.three ];
        availableColors.shift();

        const groupedByTimeStamp = { };
        const transformed = {};

        // adds all the points, even the tags that are not included in the chart
        data.map( point => {
          if ( isEmpty( groupedByTimeStamp[point.created] ) ) {
            groupedByTimeStamp[point.created] = {
              points: [ { ...point, isIncluded: settings?.asset_tag_ids?.includes( point.asset_tag_id )  } ],
            };
          } else {
            groupedByTimeStamp[point.created].points.push(
              {
                ...point,
                isIncluded: settings?.asset_tag_ids?.includes( point.asset_tag_id ),
              },
            );
          }
        } );

        if ( isNotEmpty( groupedByTimeStamp ) ) {

          const timeStampKeys = Object.keys( groupedByTimeStamp );

          const [ firstTimestamp ] = timeStampKeys;
          const lastTimeStamp = timeStampKeys[timeStampKeys.length - 1];

          if ( isNotEmpty( firstTimestamp ) && isNotEmpty( lastTimeStamp ) ) {
            const xlabels = [];

            const timestampDelta = parseInt( lastTimeStamp ) - parseInt( firstTimestamp );

            xlabels.push( formatUnixDate( parseInt( firstTimestamp ) ) );
            xlabels.push( formatUnixDate( Math.floor( parseInt( firstTimestamp ) + ( timestampDelta * ( 2 / 6 ) ) ) ) );
            xlabels.push( formatUnixDate( Math.floor( parseInt( firstTimestamp ) + ( timestampDelta * ( 3 / 6 ) ) ) ) );
            xlabels.push( formatUnixDate( Math.floor( parseInt( firstTimestamp ) + ( timestampDelta * ( 4 / 6 ) ) ) ) );
            xlabels.push( formatUnixDate( Math.floor( parseInt( firstTimestamp ) + ( timestampDelta * ( 5 / 6 ) ) ) ) );
            xlabels.push( formatUnixDate( parseInt( lastTimeStamp ) ) );

            setXAxisLabels( xlabels );
          }

          Object.values( groupedByTimeStamp ).map( timestampGroup => {
            // instead of going with the overall max amount of hosts, just going with the largets subset by tag
            const max = Math.max( ...timestampGroup.points?.map( p => {
              if ( settings?.asset_tag_ids?.includes( p.asset_tag_id ) ) {
                return p[attribute];
              }
              return 0;
            } ) );
            // const max = timestampGroup.points?.reduce( ( accum, point ) => accum + point[attribute], 0 );
            timestampGroup.max = max;
          } );

          const _yMax = Math.max( ...Object.values( groupedByTimeStamp ).map( p => p.max ) );

          const _seriesLegendData = {};

          Object.entries( groupedByTimeStamp ).map( ( [ timestamp, group ], index ) => {

            const series = {};

            const pointTotal = group?.points?.reduce( ( accum, p ) => accum + p[attribute], 0 );

            group.points.map( ( point, _index )  => {
              const tag = tags[point.asset_tag_id];

              if ( settings?.asset_tag_ids?.includes( point.asset_tag_id ) ) {
                if ( isEmpty( _seriesLegendData[point.asset_tag_id] ) ) {
                  _seriesLegendData[point.asset_tag_id] = {
                    label: tag.label,
                    stroke: tag.color || availableColors[_index % 8],
                    fill: tag.color || availableColors[_index % 8],
                  };
                }
                series[point.asset_tag_id] = {
                  value: point[attribute],
                  fill: tag.color || availableColors[_index % 8],
                  isTagFill: true,
                  isIncluded: settings.asset_tag_ids.includes( point.asset_tag_id ),
                };
              }

            } );

            const _transformedPoint = {
              series,
              timestamp,
              original: group,
              max: _yMax,
              pointTotal,
              originalIndex: index,
              totalPoints: Object.entries( groupedByTimeStamp ).length,
              id: `${timestamp}_${group.max}_${attribute}`,
              isTag: true,
            };
            transformed[timestamp] = _transformedPoint;
          } );

          const _chartData = {
            max: _yMax,
            original: data,
            transformed,
            yAxis: 'value',
            xAxis: 'timestamp',
            isTag: true,
          };
          setYMax( _yMax );
          setSeriesLegendData( _seriesLegendData );
          setChartData( _chartData );
        }
      }
    }
  }, [ settings, data, tags ] );

  React.useEffect( () => {
    if ( isNotEmpty( svgAspectRatio ) && svgAspectRatio !== 0 ) {
      setSVGContainerHeight( svgContainerWidth / svgAspectRatio );
    } else {
      setSVGContainerHeight( 400 );
    }
  }, [ svgAspectRatio ] );

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) ) {
      adjustSVGAspectRatio();
      window.addEventListener( 'resize', debounce( () => {
        adjustSVGAspectRatio();
      }, 100 ) );
      return () => window.removeEventListener( 'resize', debounce );
    }
  }, [ svgContainerRef, settings, chartData, yMax ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( chartData ) && isNotEmpty( yMax ) ) &&
        <React.Fragment>
          {
            Object.keys( chartData.transformed ).length > 1
              ? <div
                id={ `historyOverTimeSVGWrapper-${item.i}` }
                className={ `overtimeMultiAreaWrapper ${ settings?.include_legend ? 'withLegend' : '' }`}
                ref={svgContainerRef}
              >
                <YAxisLabels yMax={ yMax } percentageTics={ settings?.count_version_tag === 'percent' }/>
                <YAxisLines
                  lineCount={ settings?.count_version === 'percent' ? 4 : 3 }
                  elementClass={ settings.include_legend ? '' : 'fullWidth' }
                />
                {
                  isNotEmpty( xAxisLabels ) &&
                  <XAxisLines lineCount={xAxisLabels.length - 1} variant="area" />
                }
                {
                  ( isNotEmpty( svgContainerHeight ) && isNotEmpty( svgContainerWidth ) ) &&
                  <svg
                    viewBox={ `0 0 ${svgContainerWidth} ${svgContainerHeight}` }
                    xmlns="http://www.w3.org/2000/svg"
                    className="multiAreaPlusBarWrapper"
                    id="areaPlusBarWrapper"
                    preserveAspectRatio="none"
                  >
                    <MultiArea
                      data={chartData}
                      containerHeight={svgContainerHeight}
                      containerWidth={svgContainerWidth}
                      version={
                        (
                          settings?.count_version_tag === 'percent'
                          || settings?.count_version === 'percent'
                        )
                          ? 'stacked'
                          : 'overlapping'
                      }
                    />
                  </svg>
                }
                {
                  ( isNotEmpty( seriesLegendData ) && settings.include_legend ) &&
                  <Legend legendData={ seriesLegendData } />
                }
              </div>
              : <EmptyState message="Insufficient data" />
          }
        </React.Fragment>
      }
      {
        isNotEmpty( xAxisLabels ) &&
        <XAxisLabels labels={xAxisLabels} variant="area" elementClass={ settings.include_legend ? '' : 'fullWidth' } />
      }
    </React.Fragment>
  );
};

export default OverTimeCompliance;