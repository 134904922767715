/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';

export const FullScreenVisualContext = React.createContext();

export const FullScreenVisualProvider = ( { children } ) => {

  const [ visualContent, setVisualContent ] = React.useState( null );
  const [ showVisual, setShowVisual ] = React.useState( false );
  const [ fullScreenClass, setFullScreenClass ] = React.useState( '' );
  const [ printingFullScreen, setPrintingFullScreen ] = React.useState( false );

  const showFullScreenVisual = ( content, elementClass='' ) => {
    setVisualContent( content );
    setFullScreenClass( elementClass );
    setShowVisual( true );
  };

  const exitFullScreenVisual = () => {
    setVisualContent( null );
    setFullScreenClass( '' );
    setShowVisual( false );
  };

  const data = [
    visualContent,
    setVisualContent,
    showVisual,
    setShowVisual,
    showFullScreenVisual,
    exitFullScreenVisual,
    fullScreenClass,
    printingFullScreen,
    setPrintingFullScreen,
  ];

  return (
    <FullScreenVisualContext.Provider value={ data }>
      { children }
    </FullScreenVisualContext.Provider>
  );
};