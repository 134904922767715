/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
// eslint-disable-next-line max-len
/* eslint-disable block-spacing, max-len, block-scoped-var, camelcase, space-in-parens, indent, brace-style, eqeqeq, semi, quotes, curly, prefer-destructuring, comma-spacing, no-else-return, spaced-comment, comma-dangle, no-extra-semi, array-bracket-spacing, no-nested-ternary */
'use strict';
import {
  global_risk,
  model_meta,
  fetch_scopes,
  fetch_nodes,
  makeRequest,
} from './io';

import {
  $e,
  $get,
} from './dom';

// XXX: Not cryptographically secure, but probably doesn't matter
export function generate_uuid()
{
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

export async function terminate_session() {
  var sid = window.localStorage.getItem('sid');
  if(sid) {
    await makeRequest('LOGOUT', '/profile', {});
    window.localStorage.removeItem('sid');
    window.location.href = '/login.html';
  }

}

export async function scope_labels(scope_id)
{
    var scopes = await fetch_scopes([scope_id]);
    if(!scopes[scope_id])
        return ret_val;

    var ret_val = [];
    var scope = scopes[scope_id];
    if(scope['ancestor_labels'])
        var ret_val = [...scope['ancestor_labels']];

    ret_val.push(scope['label']);
    return ret_val;
}

export async function edge_label(edge)
{
    var ri = $get('report-item');
    var enodes = await fetch_nodes([edge['from_node'], edge['to_node']]);
    var fn = enodes[edge['from_node']];
    var tn = enodes[edge['to_node']];
    var fnsl = await scope_labels(fn['scope_id']);
    var tnsl = await scope_labels(tn['scope_id']);

    var sep = ' / ';
    var from = fn['label'];
    if(fnsl.length > 0)
        from = fnsl.join(sep) + sep + from;

    var to = tn['label'];
    if(tnsl.length > 0)
        to = tnsl.join(sep) + sep + to;

    return [from, ' \u2192 ', to];
}

const node_icon = node => {
  const icon_images = {
    'adversary':'img/icons/graph/adversary.svg',
    'info':'img/icons/graph/info.svg',
    'drag': 'img/icons/dark/draggable.svg',
    'database':'img/icons/graph/database.svg',
    //'user':'img/user.svg',
    //'domain_user':'img/user.svg',
    //'group':'img/group.svg',
  };

  return icon_images[node.type] || null;
};

async function fetch_node_ancestors(nodes)
{
    var base_ids = Object.values(nodes).map((n) => n['scope_id']);
    var ret_val = await fetch_scopes(base_ids);
    var unique_ancestors = {};

    for(var scope of Object.values(ret_val))
    {
        if(scope['ancestors'])
        {
            for(var aid of scope['ancestors'])
                unique_ancestors[aid] = null;
        }
    }

    Object.assign(ret_val, await fetch_scopes(['00000000-0000-0000-0000-000000000000'].concat(Object.keys(unique_ancestors))));

    console.log('fetch_node_ancestors:', ret_val);
    return ret_val;
}

export var network;
export function setNetwork(f) {
  network = f;
};


export const format_risk_reduction = risk => {

  let displayValue = 'N/A';

  let globalRisk;

  if (
    model_meta
    && model_meta.project
    && model_meta.risk
  ) {
    globalRisk = model_meta.risk;
  } else if (window.localStorage.getItem('globalRisk')) {
    globalRisk = window.localStorage.getItem('globalRisk');
  };

  // early return if global is somehow 0
  if (globalRisk === 0 || !globalRisk) { return displayValue };

  // early return if risk is 0
  if (risk === 0) { return '0%' };

  const risk_ratio = risk / globalRisk;
  const percent = risk_ratio * 100;

  if (percent < 0.005) {
    displayValue = '< 0.01%';
  } else {
    if (percent < 10.0) {
      displayValue = `${percent.toFixed(2)}%`;
    } else {
      displayValue = `${percent.toFixed(2)}%`;
    };
  };
  return displayValue;
};

export function global_target_risk_to_label(target_risk)
{
    let denominator;

    if (
        model_meta
        && model_meta.project
        && model_meta.project.settings
        && model_meta.project.settings.risk_target
    ) {
        denominator = model_meta.project.settings.risk_target;
    } else if (window.localStorage.getItem('targetRisk')) {
        denominator = parseFloat(window.localStorage.getItem('targetRisk'));
    } else {
        denominator = 1;
    };

    var global_target_ratio = global_risk / denominator;

    var ret_val = 'critical';
    if (global_target_ratio < 0.5)
        ret_val = 'minimal';
    else if (global_target_ratio < 1.0)
        ret_val = 'low';
    else if (global_target_ratio < 1.25)
        ret_val = 'moderate';
    else if (global_target_ratio < 1.50)
        ret_val = 'high';

    return ret_val;
}

export function risk_to_rating (risk)
{
    let denominator;

    if (
        model_meta
        && model_meta.project
        && model_meta.project.settings
        && model_meta.project.settings.risk_target
    ) {
        denominator = model_meta.project.settings.risk_target;
    } else if (window.localStorage.getItem('targetRisk')) {
        denominator = parseFloat(window.localStorage.getItem('targetRisk'));
    } else {
        denominator = 1;
    };

    var target_ratio = risk / denominator;

    var ret_val = 'critical';
    if (target_ratio < 0.04)
        ret_val = 'minimal';
    else if (target_ratio < 0.08)
        ret_val = 'low';
    else if (target_ratio < 0.16)
        ret_val = 'moderate';
    else if (target_ratio < 0.32)
        ret_val = 'high';

    return ret_val;
}

function edge_color(edge)
{
    if(!edge || edge['nofix'] || !('risk_percentile' in edge))
        return '#90A0B7';

    if (edge.risk_percentile < 0.05)
        return '#00AAE9';
    if (edge.risk_percentile < 0.25)
        return '#2ED47A';
    if (edge.risk_percentile < 0.75)
        return '#FFB800';
    if (edge.risk_percentile < 0.95)
        return '#FF8A00';
    return '#EF3535';
}

export async function fetch_conditional_risks(group_type, filter_record_type, filter_record_ids, rownums, asset_tag_ids=null) {

  let filters = {};

  if ( filter_record_type === 'user') {
        filters['host_ids'] = filter_record_ids;
    } else {
        filters[filter_record_type + '_ids'] = filter_record_ids;
  }

  if (asset_tag_ids) {
    filters.asset_tag_ids = asset_tag_ids;
  }
  const response = await makeRequest('GROUP', '/analysis/instance', {
                                      project: 'default',
                                      model: 'base',
                                      group_type: group_type,
                                      filters: filters,
                                      order_by: [['filtered_risk', 'DESC']],
                                      rownums: rownums,
                                    });
  let risks = {};

  response.results.map( result => {
    risks[result.id] = result.filtered_risk;
  });

  return risks;
};

export async function full_node_label(scope_id, node_label) {
  var context = await scope_labels(scope_id);
  if (context) {
    context.push(node_label);
    return context.join(' / ');
  };
};

export async function path_description(path, all_breadcrumbs)
{
    var node_labels = path['node_labels'];
    var last = node_labels[node_labels.length-1];
    var title = await full_node_label(last[0],last[1]);

    var plabel = [];
    if(all_breadcrumbs)
    {
        for(var i=0; i<node_labels.length; i++)
        {
            plabel.push(await full_node_label(node_labels[i][0],node_labels[i][1]));
        }
    }
    else
    {
        if(node_labels.length > 2)
        {
            plabel.push(await full_node_label(node_labels[1][0],node_labels[1][1]));
        }
        if (node_labels.length > 3)
        {
            var mid = Math.ceil((2+node_labels.length)/2.0);
            plabel.push(await full_node_label(node_labels[mid][0],node_labels[mid][1]));
        }
        plabel.reverse();
    }

    return {'title':title, 'breadcrumbs':plabel}
}

export function risk_percentile_to_class(percentile)
{
    var risk_class = 'report-item-risk-critical';
    if (percentile < 0.05)
        risk_class = 'report-item-risk-minimal';
    else if (percentile < 0.25)
        risk_class = 'report-item-risk-low';
    else if (percentile < 0.75)
        risk_class = 'report-item-risk-moderate';
    else if (percentile < 0.95)
        risk_class = 'report-item-risk-high';

    return risk_class;
}

export function format_urls(parent, urls, p_class)
{
    var urls = urls.split('\n');
    for(var i=0; i<urls.length; i++)
    {
        if(urls[i].startsWith('http'))
        {   // XXX: not allowing ftp:// which may not be a bad thing... anything else we might allow?
            var a = $e('a', {'href':urls[i], 'target':'_blank', 'rel':'noopener noreferrer'}).append(urls[i]);
            parent.append($e('p', {'class':p_class}).append(a));
        }
    }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function cleanup_filename(fname)
{
    fname = fname.replace(/ /g, '-');
    return fname.replace(/[^A-Z0-9_.-]/gi, '_');
}

export function export_csv(data, base_name)
{
    //console.log(data, base_name);

    function _escape_cell(cell_data)
    { return (''+cell_data).replace(/"/g, '""'); }

    var content = '';
    data.forEach(function (row) {
        var first = true;
        row.forEach(function (cell) {
            if(first)
                content += '"'+_escape_cell(cell)+'"';
            else
                content += ',"'+_escape_cell(cell)+'"';
            first = false;
        });
        content += '\n';
    });
    //console.log(content);

    var filename = cleanup_filename(base_name) + ".csv";
    export_file(content, filename, 'text/csv');
    /*
    var pom = document.createElement('a');
    var url = window.URL.createObjectURL(bb);
    pom.href = url;
    pom.download = filename;

    // Firefox (& Safari?) requires the anchor tag be on the page somewhere before the click.
    pom.style = 'display: none;';
    document.body.appendChild(pom);

    // Safari apparently needs this...
    // perhaps because the above appendChild is running somewhat async?  Not sure why...
    setTimeout(function () {
        pom.click();
        document.body.removeChild(pom);
        window.URL.revokeObjectURL(url);
    }, 10);
    */
}

export function export_file(data, filename, content_type)
{
    var bb = new Blob([data], {type: content_type});
    var pom = document.createElement('a');
    var url = window.URL.createObjectURL(bb);
    pom.href = url;
    pom.download = filename;

    // Firefox (& Safari?) requires the anchor tag be on the page somewhere before the click.
    pom.style = 'display: none;';
    document.body.appendChild(pom);

    // Safari apparently needs this...
    // perhaps because the above appendChild is running somewhat async?  Not sure why...
    setTimeout(function () {
        pom.click();
        document.body.removeChild(pom);
        window.URL.revokeObjectURL(url);
    }, 10);
}

export function record_field_sorter(field, reverse, a, b)
{
    if(reverse)
    {
        if(a[field] > b[field])
            return -1;
        if(a[field] < b[field])
            return 1;
    }
    else
    {
        if(a[field] < b[field])
            return -1;
        if(a[field] > b[field])
            return 1;
    }

    // Makes sorting order stable even if fields are equal
    if(a['id'] > b['id'])
        return -1;
    if(a['id'] < b['id'])
        return 1;
    return 0;
}

export var report_item_sorter = record_field_sorter.bind(null, 'risk', true);

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} num The number of bytes.
 *
 * https://ourcodeworld.com/articles/read/713/converting-bytes-to-human-readable-values-kb-mb-gb-tb-pb-eb-zb-yb-with-javascript
 */
export function readableBytes(bytes) {
    if (bytes <= 0) {
        return '0 B';
    }

    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return (bytes / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + sizes[i];
}