/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { formatNumber, isNotEmpty } from '../../../shared/Utilities';
import { getNodeIcon, nodeTypeLabelMap } from '../shared';

import './SensitiveAssetItem.scss';

const SensitiveAssetItem = ( { asset, asListItem=false } ) => {

  const content = <React.Fragment>
    <div className="left">
      <div className="nodeIconWrapper">
        <svg
          width={ 24 }
          height={ 24 }
          viewBox="0 0 32 32"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          className="recordTypeHeaderIcon"
        >
          { getNodeIcon( asset.node ) }
        </svg>
      </div>
      <div className="labelAndType">
        <span>
          <strong>{ `${asset.node?.label }` }</strong> - { asset.scope?.label }
        </span>
        <span className="type" >{ nodeTypeLabelMap[asset.node?.type] }</span>
      </div>
    </div>
    <div className="right">
      <label>Impact</label>
      <strong>{ formatNumber( asset.node?.combined_impact || asset.node?.impact || 0 ) }</strong>
    </div>
  </React.Fragment>;
  return (
    <React.Fragment>
      {
        (
          isNotEmpty( asset )
          && isNotEmpty( asset.node )
          && isNotEmpty( asset.scope )
        ) &&
        <React.Fragment>
          {
            asListItem
              ? <li className="sensitiveAssetItemWrapper asListItem">
                { content }
              </li>
              : <div className="sensitiveAssetItemWrapper standAlone">
                { content }
              </div>
          }
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default SensitiveAssetItem;