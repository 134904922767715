/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import { convertValueForDuration } from '../../Shared';

import './Duration.scss';

import {
  isEmpty,
  isNotEmpty,
  itemIsObject,
} from '../../../Utilities';

const Duration = ( {
  field,
  originalValue,
  formState,
  onChange,
  shouldDisable=false,
  existingRecord,
} ) => {

  const [ unitValue, setUnitValue ] = React.useState( field.expectedUnit );
  const [ durationValue, setDurationValue ] = React.useState( '' );
  const [ unitOptions, setUnitOptions ] = React.useState( {} );

  const isDisabled = field.disabled || formState?.fieldStates[field.attribute].disabled || shouldDisable;

  const unitMap = {
    s: 'Seconds',
    m: 'Minutes',
    h: 'Hours',
    d: 'Days',
  };

  // 1) when the input comes in, convert the value to whatever makes the most sense
  // also setting up the select to only allow the units the input allows.
  React.useEffect( () => {

    const d = 60 * 60 * 24;
    const h = 60 * 60;
    const m = 60;
    const s = 1;

    let _newDuration, _newUnit;

    if ( isNotEmpty( field ) ) {

      let _value = originalValue;

      if ( itemIsObject( originalValue ) ) {
        _value = originalValue.overrideValue || originalValue.globalDefault;
      }

      // first convert the unit to seconds for consistency
      if ( field.expectedUnit === 'd' ) {
        _value = _value * d;
      }
      if ( field.expectedUnit === 'h' ) {
        _value = _value * h;
      }
      if ( field.expectedUnit === 'm' ) {
        _value = _value * m;
      }
      if ( field.expectedUnit === 's' ) {
        _value = _value * s;
      }

      // then find the most reasonable unit and duration
      if (
        _value === 0
        || _value === ''
        || Number.isNaN( _value )
        || isEmpty( _value )
      ) {
        _newDuration = 0;
        _newUnit = field.expectedUnit || 's';
      } else if ( _value % d === 0 ) {
        _newDuration = _value / d;
        _newUnit = 'd';
      } else if ( _value % h === 0 ) {
        _newDuration = _value / h;
        _newUnit = 'h';
      } else if ( _value % m === 0 ) {
        _newDuration = _value / m;
        _newUnit = 'm';
      } else if ( _value % s === 0 ){
        _newDuration = _value / s;
        _newUnit = 's';
      } else {
        _newDuration = originalValue;
        _newUnit = field.expectedUnit;
      }

      if ( field.allowedUnits ) {
        const _options = {};
        field.allowedUnits.map( u => {
          _options[u] = unitMap[u];
        } );
        setUnitOptions( _options );
      } else {
        setUnitOptions( unitMap );
      }

    } else {
      _newDuration = 0;
      _newUnit = 's';
    }

    setDurationValue( parseInt( _newDuration ) || 0 );
    setUnitValue( _newUnit || 's' );

  }, [ existingRecord, originalValue, shouldDisable ] );

  React.useEffect( () => {
    let _durationValue = durationValue;
    if (
      durationValue === 0
      || durationValue === ''
      || Number.isNaN( durationValue )
      || isEmpty( durationValue )
    ) {
      _durationValue = 0;
    }

    const _value = convertValueForDuration( _durationValue, unitValue, field );

    if ( isNotEmpty( field ) && isNotEmpty( unitValue ) ) {
      field.selectedUnit = unitValue;
    }

    onChange( field, _value );
  }, [ durationValue, unitValue ] );

  return (
    <React.Fragment>
      <input
        placeholder={ field.placeholder ? field.placeholder : ''}
        disabled={isDisabled}
        type="number"
        onChange={ e => setDurationValue( e.target.valueAsNumber || e.target.value ) }
        value={ durationValue }
      />
      <div className={ 'selectFieldWrapper' }>
        <select
          disabled={isDisabled}
          onChange={ e => setUnitValue( e.target.value ) }
          value={ unitValue }
        >
          <option disabled value="">select...</option>
          {
            isNotEmpty( unitOptions ) &&
            <React.Fragment>
              {
                Object.keys( unitOptions ).map( ( opt, index ) => {
                  return  <option
                    key={index}
                    value={opt}
                  >
                    { unitOptions[opt]}
                  </option>;
                } )
              }
            </React.Fragment>
          }
        </select>
      </div>
    </React.Fragment>
  );
};

export default Duration;
