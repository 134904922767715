/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';

const Details = ( { record } ) => {

  const [ altNames, setAltNames ] = React.useState( null );
  const [ networks, setNetworks ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( record ) ) {
      const _altNames = [];
      const _networks = [];

      if ( isNotEmpty( record.host_names ) ) {
        record.host_names.map( n => {
          if ( n !== record.local_name ) {
            _altNames.push( n );
          }
        } );
      }

      if ( isNotEmpty( record.networks ) ) {
        record.networks.map( n => {
          if ( isNotEmpty( n.net ) ) {
            _networks.push( n.net );
          }
        } );
      }

      setAltNames( _altNames );
      setNetworks( _networks );
    }
  }, [ record ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <div className="recordDetails__InformationPanel__Body_Section details host">
          <div className="bodySectionItem">
            <label>IP Address(es)</label>
            <span>{ isNotEmpty( record.ip_addresses ) ? record.ip_addresses.join( ', ' ) : '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>Also Known As</label>
            <span>
              {
                isNotEmpty( altNames )
                  ? altNames.join( ', ' )
                  : '--'
              }
            </span>
          </div>
          <div className="bodySectionItem">
            <label>Windows Domain</label>
            <span>{ record.extra?.windows_domain || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>Operating System</label>
            <span>{ record.product_name || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>OS Release</label>
            <span>{ record.host_os_release || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>OS Build - Revision </label>
            <span>{ record.host_os_build || '--' } - { record.host_os_revision || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>OS Architecture</label>
            <span>{ record.host_architecture || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>OS Type</label>
            <span>{ record.host_os_type || '--' }</span>
          </div>
          <div className="bodySectionItem">
            <label>Directly Connected Networks</label>
            <span>
              {
                isNotEmpty( networks )
                  ? networks.join( ', ' )
                  : '--'
              }
            </span>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Details;