/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, paramsToFilters } from '../../Utilities';

const Number = ( {
  input,
  value,
  handleChange,
} ) => {

  const [ isFocused, setIsFocused ] = React.useState( false );

  const inputRef = React.useRef( null );

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute] || '';

    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      inputRef.current.value = newValue || '';
    }
  };

  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input, inputRef ] );

  const onChange = e => {
    const value = e.target.valueAsNumber || e.target.value;
    handleChange( input.attribute, value );
  };

  return (
    <React.Fragment>
      <label className={`${isFocused ? 'focus' : ''}`}>
        <span className="labelWrapper">
          { input.label }
          {
            input.required &&
            <span className="required">*</span>
          }
          { input.helpItem }
        </span>
        <input
          ref={inputRef}
          type={input.type}
          onFocus={ () => setIsFocused( true ) }
          onBlur={ () => setIsFocused( false ) }
          placeholder={ input.placeholder ? input.placeholder : ''}
          disabled={input.disabled }
          onChange={ onChange }
          { ...input.htmlProps }
        />
      </label>
    </React.Fragment>
  );
};

export default Number;