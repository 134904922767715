/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './style.scss';

const SetupForm = ( {
  children,
  elementClass='',
} ) => {

  return (
    <div className={`setupFormContainer ${elementClass}`}>
      { children }
    </div>
  );
};

export default SetupForm;
