/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';
import {
  riskToRating,
  isNotEmpty,
  pluralizeType,
  itemIsObject,
  itemIsArray,
} from '../../../shared/Utilities';
import { RiskContext } from '../../../Contexts/Risk';
import RiskReduction from '../../../shared/RiskReduction';
// import RatingBadge from '../../../shared/RatingBadge';

import './ExploreItem.scss';
import RiskPercentageBar from '../../../shared/RiskPercentageBar';
import { edgeRiskClass } from '../../RecordDetails/shared';

const ExploreItem = ( {
  item,
  type,
  exploreItems,
  isSelected=false,
  displayExploreItemNameFor,
  toggleSelection,
  items,
  setSelectedPathID=null,
  selectedPathID=null,
  setExternalHoverIDs=() => {},
  standalone=false,
  noRisk=false,
} ) => {

  const [ formatted, setFormatted ] = React.useState( {} );
  const [ , targetRisk ] = React.useContext( RiskContext );
  const [ highestRisk, setHighestRisk ] = React.useState( 0 );

  const [ hoverIDs, setHoverIDs ] = React.useState( null );

  const percentageAllowed = [];

  const handleItemClick = ( item, type ) => {
    if ( isNotEmpty( toggleSelection ) ) {
      toggleSelection( item, type );
    }
    if ( type === 'path' ) {
      setExternalHoverIDs( hoverIDs );
    }
    if ( isNotEmpty( setSelectedPathID ) ) {
      setSelectedPathID( item.id );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( item ) && isNotEmpty( type ) ) {

      if ( type === 'path' ) {
        let _ids = [];

        if ( isNotEmpty( item.edges ) ) {
          _ids = [ ..._ids, ...item.edges ];
        }
        if ( isNotEmpty( item.nodes ) ) {
          _ids = [ ..._ids, ...item.nodes ];
        }

        setHoverIDs( _ids );
      }

      displayExploreItemNameFor( item, type, { showPathTo: true, showCount: true } ).then( response => {
        setFormatted( {
          label: response,
          id: item.id,
          risk: item.risk,
          rating: type === 'edge' ? edgeRiskClass( item ) : riskToRating( item.risk, targetRisk ),
        } );
      } );
    }

    if ( isNotEmpty( items ) ) {
      let allItems = [];
      if ( itemIsObject( items ) ) {
        Object.values( items ).map( itemsOfType => {
          allItems = [ ...allItems, ...itemsOfType ];
        } );
      } else if ( itemIsArray( items ) ) {
        allItems = items;
      }
      const highest = Math.max( ...allItems.map( i => i.risk ) );
      setHighestRisk( highest );
    }
  }, [ item, type, items, isSelected ] );

  // eslint-disable-next-line max-len
  const hasBeenSelected = ( item, type ) => !standalone && exploreItems[type] && exploreItems[type].map( i => i.id ).includes( item.id );

  return (
    <React.Fragment>
      {
        isNotEmpty( formatted ) &&
        <li
          // eslint-disable-next-line max-len
          className={ `${ selectedPathID === item.id ? 'isSelectedPath' : '' } ${standalone ? 'standalone' : ''} ${type} risk-${formatted.rating} ${hasBeenSelected( item, type ) ? 'selected' : ''} exploreModelexploreItem ${isSelected ? 'alreadySelected' : ''}`}
          // eslint-disable-next-line max-len
          onMouseEnter={ () => type === 'path' ? () => {} : setExternalHoverIDs( type === 'path' ? hoverIDs : [ item.id ] ) }
          onMouseLeave={ () => type === 'path' ? () => {} : setExternalHoverIDs( [] ) }
          onClick={ () => handleItemClick( item, type ) }
        >
          {
            ( isSelected || hasBeenSelected( item, type ) ) &&
            <InlineSVG elementClass="itemTypeIcon" type={`${pluralizeType( type )}Alt`} />
          }
          <span
            className={ `name ${type}` }
            // style={ { whiteSpace: needsWrap.includes( type ) ? 'unset' : 'nowrap' } }
          >
            { formatted.label }
          </span>
          {
            (
              !isSelected
              && !hasBeenSelected( item, type )
              && isNotEmpty( highestRisk )
              && !standalone
              && percentageAllowed.includes( type )
            ) &&
            <RiskPercentageBar
              itemRating={formatted.rating}
              itemRisk={formatted.risk}
              totalRisk={highestRisk}
              minimalVariant
            />
          }
          {
            !noRisk &&
            <RiskReduction item={item} riskType="risk" type={type} />
          }
          {
            !standalone &&
            <React.Fragment>
              {
                ( isSelected || hasBeenSelected( item, type ) )
                  ? <InlineSVG type="remove" elementClass="addRemoveIcon" />
                  : <InlineSVG type="add" elementClass="addRemoveIcon" />
              }
            </React.Fragment>
          }
        </li>
      }
    </React.Fragment>
  );
};

export default ExploreItem;