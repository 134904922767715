/** *************************************************************
* Copyright (C) 2016-2023 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { capitalize, isNotEmpty } from '../../shared/Utilities';
import InlineSVG from '../../shared/InlineSVG';

import './ActivityItem.scss';

const ActivityItem = ( {
  item,
  viewItem,
  toggleItemVisibility,
  itemType,
} ) => {

  const typeLabelMap = {
    configurationAlert: 'Configuration Alert',
    scanLog: 'Scan Log',
  };

  const additionalSections = {
    configurationAlert: 'category',
  };

  const iconForItemType = ( itemType ) => {
    if ( itemType === 'configurationAlert' ) {
      return <InlineSVG type="configurationAlert" />;
    }
    if ( itemType === 'scanLog' ) {
      return <InlineSVG type="scanning_nav" />;
    }
    return <InlineSVG type="configurationAlert" />;
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( item ) &&
        <li className={ `activityItem ${itemType} ${ itemType === 'configurationAlert' ? item.priority : ''}`} >
          <div className="contentWrapper">
            <div className="itemIconWrapper">
              { iconForItemType( itemType ) }
            </div>
            <div className="main">
              <h2 onClick={ () => viewItem( item ) }>
                <strong>{ item.label }</strong>
                {
                  isNotEmpty( item.subLabel ) &&
                  <span>({item.subLabel})</span>
                }
              </h2>
              <span className="itemtype">{ typeLabelMap[itemType] }</span>
            </div>
            {
              ( isNotEmpty( itemType ) && isNotEmpty( additionalSections[itemType] ) ) &&
              <div className="additional">
                <label>{ capitalize( additionalSections[itemType] ) }</label>
                <span>{ item[additionalSections[itemType]] }</span>
              </div>
            }
            {
              isNotEmpty( item?.timestamp ) &&
              <div className="timestamp">
                { item.timestamp }
              </div>
            }
          </div>
          <div className="actionsWrapper">
            {
              isNotEmpty( toggleItemVisibility ) &&
              <button className="toggleVisibilityButton" onClick={ () => toggleItemVisibility( item ) }>
                <InlineSVG type="notVisible" />
              </button>
            }
            {
              isNotEmpty( viewItem ) &&
              <button className="viewItemButton" onClick={ () => viewItem( item ) }>
                <InlineSVG type="carretRight" />
              </button>
            }
          </div>
        </li>
      }
    </React.Fragment>
  );
};

export default ActivityItem;