import { makeRequest } from '../../../../legacy/io';
import { formatUnixDate, isEmpty, isNotEmpty } from '../../../shared/Utilities';

export const typeOptions = [
  'scope',
  'patch',
  'vulnerability',
];

export const nameForPlan = plan => {
  if ( isNotEmpty( plan ) && isNotEmpty( plan.label ) ) {
    return plan.label;
  }
  return `Plan from ${formatUnixDate( plan.created )}`
  ;
};

export const percentComplete = ( total, current ) => {
  if ( isEmpty( total ) || current < 0 || current === null || current === undefined ) {
    return 0;
  }

  const delta = total - current;

  const ratio = delta / total;

  // the current number is more than the start, not sure what to return, just returning 0 for now
  if ( delta < 0 ) {
    return 0;
  }

  return Math.round( ratio * 100 );

};

export const completionClass = ( total, current ) => {
  if ( isEmpty( total ) || current < 0 || current === null || current === undefined ) {
    return 'error';
  }
  const delta = total - current;

  // the current number is more than the start, bad bad
  if ( delta < 0 ) {
    return 'critical';
  }
  const ratio = delta / total;

  if ( ratio > 0.75 ) {
    return 'minimal';
  }
  if ( ratio > 0.5 ) {
    return 'low';
  }
  if ( ratio > 0.25 ) {
    return 'moderate';
  }
  return 'high';
};

export const fillForCompletion = percent => {
  if ( percent <= 10 ) {
    return '#BC2220';
  }
  if ( percent <= 25 ) {
    return '#EF3535';
  }
  if ( percent <= 50 ) {
    return '#FF8A00';
  }
  if ( percent <= 75 ) {
    return '#FFB800';
  }
  return '#2ED47A';
};

export const closeRemediationPlan = async ( plan ) => {
  // eslint-disable-next-line max-len, camelcase
  const _planRequest = await makeRequest( 'UPSERT', '/project/default/model/base/remediation_plan', { records: [ { ...plan, status: 'closed' } ] } );
  return _planRequest;
};

export const typeForTask = task => task?.type?.split( '_' )[0] || '';

// when the user clicks to export the report via the overview pain,
// copied from risk insight, only currently supports xlsx though
export const exportReport = ( plan, callback=() => {} ) => {

  let ownerID = plan?.owner;

  if ( ownerID === null || ownerID === undefined || ownerID === '' || ownerID === 'null' ) {
    ownerID = null;
  }

  const _report = {
    owner: ownerID,
    label: nameForPlan( plan ),
    format: 'xlsx',
    type: 'remediation',
    filters: {
      // eslint-disable-next-line camelcase
      plan_id: plan.id,
    },
  };

  makeRequest( 'ADD', '/model/base/exported_report', { record: _report } ).then( response => {
    if ( response && response.results ) {
      makeRequest( 'START', '/model/base/exported_report', { id: response.results.id } ).then( () => {
        callback( response );
      } );
    }
  } );
};
