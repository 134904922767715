/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

import EmptyState from '../../../../shared/EmptyState';
import { getFieldValues } from '../../../../shared/Form/Shared';
import InlineSVG from '../../../../shared/InlineSVG';
import Loading from '../../../../shared/Loading';
import {
  isNotEmpty,
  isEqual,
  riskToRating,
} from '../../../../shared/Utilities';
import Form from '../../../../shared/Form';

import {
  vulnerabilityAttributes,
  escalationAttributes,
  selectInputs,
} from './Shared.js';
import RiskPercentageBar from '../../../../shared/RiskPercentageBar';
import RiskReduction from '../../../../shared/RiskReduction';

const ExistingTab = ( {
  loadingEscalations,
  changes,
  setChanges,
  selectedEscalation,
  setSelectedEscalation,
  setVulnNotIDs,
  vulnNotIDs,
  availableEscalations,
  escalations,
  updatedEscalation,
  setUpdatedEscalation,
  escalationFields,
  selectEscalationForEdit,
} ) => {

  const [ sortedEscalations, setSortedEscalations ] = React.useState( [] );
  const [ highestRisk, setHighestRisk ] = React.useState( null );

  // when the data comes in, sort by vuln risk and find the highest vuln risk
  React.useEffect( () => {
    if ( isNotEmpty( availableEscalations ) ) {
      // eslint-disable-next-line max-len
      const _sorted = Object.values( availableEscalations ).sort( ( a, b ) => b.vulnerabilityData?.risk - a.vulnerabilityData?.risk );
      if ( isNotEmpty( _sorted ) ) {

        const _custom = _sorted.filter( r => r.vulnerabilityData?.identifier?.startsWith( 'CUSTOM' ) );
        const _regular = _sorted.filter( r => !r.vulnerabilityData?.identifier?.startsWith( 'CUSTOM' ) );

        const _all = [ ..._custom, ..._regular ];
        const _highest = Math.max( ..._all.map( r => r.vulnerabilityData?.risk ) );

        setSortedEscalations( _all );
        setHighestRisk( _highest );
      }
    }
  }, [ availableEscalations ] );

  // eslint-disable-next-line no-unused-vars
  let isMounted = true;

  const [ vulnEditDisabled, setVulnEditDisabled ] = React.useState( true );

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  // is called whenever a user edits an escalation, need to keep track of the changes.
  React.useEffect( () => {
    if (
      isNotEmpty( escalationFields )
      && isNotEmpty( selectedEscalation )
      && isNotEmpty( updatedEscalation )
      && isNotEmpty( updatedEscalation.fieldStates )
    ) {
      const eKey = selectedEscalation.escalationData.id;

      const vulnChanges = {};
      const escalationChanges = {};

      const originalVulnerabilityValues = {};
      const newVulnerabilityValues = {};

      const originalEscalationValues = {};
      const newEscalationValues = {};
      const values = getFieldValues( updatedEscalation?.fieldStates, 'escalation' );
      let _field;
      Object.entries( values ).map( ( [ attribute, val ] ) => {
        if ( vulnerabilityAttributes.includes( attribute ) ) {

          _field = escalationFields.vulnerability.fields.find( f => f.attribute === attribute );

          if ( isNotEmpty( _field ) ) {
            newVulnerabilityValues[attribute] = val;

            // need to look for the original value in the additions
            if ( isNotEmpty( changes.added[eKey] ) ) {
              originalVulnerabilityValues[attribute] = changes.added[eKey].vulnerabilityData[attribute];
            // else, grab the original values from the main list
            } else {
              originalVulnerabilityValues[attribute] = escalations[eKey].vulnerabilityData[attribute];
            }

            // a field was changed, keeping track of it for later
            // eslint-disable-next-line max-len
            if ( isNotEmpty( originalVulnerabilityValues[attribute] ) && newVulnerabilityValues[attribute] !== originalVulnerabilityValues[attribute] ) {

              let _original = originalVulnerabilityValues[attribute];
              if ( selectInputs.includes( attribute ) ) {
                _original = _field.options[originalVulnerabilityValues[attribute]];
              }

              _field.updated = true;
              _field.originalValue = _original;

              vulnChanges[attribute] = {
                originalValue: originalVulnerabilityValues[attribute],
                updatedValue: newVulnerabilityValues[attribute],
              };
            } else {
              _field.updated = false;
              _field.originalValue = null;
            }
          }
        }
        if ( escalationAttributes.includes( attribute ) ) {

          const possibleFields = [
            ...escalationFields.exploitLeft.fields,
            ...escalationFields.exploitRight.fields,
          ];

          _field = possibleFields.find( f => f.attribute === attribute );

          newEscalationValues[attribute] = val;

          // need to look for the original value in the additions
          if ( isNotEmpty( changes.added[eKey] ) ) {
            originalEscalationValues[attribute] = changes.added[eKey].escalationData[attribute];
          // else, grab the original values from the main list
          } else {
            originalEscalationValues[attribute] = escalations[eKey].escalationData[attribute];
          }

          // a field was changed, keeping track of it for later
          // eslint-disable-next-line max-len
          if ( isNotEmpty( originalEscalationValues[attribute] ) && newEscalationValues[attribute] !== originalEscalationValues[attribute] ) {

            let _original = originalEscalationValues[attribute];
            if ( selectInputs.includes( attribute ) ) {
              _original = _field.options[originalEscalationValues[attribute]];
            }

            _field.updated = true;
            _field.originalValue = _original;

            escalationChanges[attribute] = {
              originalValue: originalEscalationValues[attribute],
              updatedValue: newEscalationValues[attribute],
            };
          } else {
            _field.updated = false;
            _field.originalValue = null;
          }
        }
      } );
      // eslint-disable-next-line max-len
      const isUpdated = ( !isEqual( originalVulnerabilityValues, newVulnerabilityValues ) || !isEqual( originalEscalationValues, newEscalationValues ) );

      const combinedValues = {
        ...selectedEscalation,
        updated: isUpdated,
        escalationData: {
          ...selectedEscalation.escalationData,
          ...newEscalationValues,
          changes: escalationChanges,
        },
        vulnerabilityData: {
          ...selectedEscalation.vulnerabilityData,
          ...newVulnerabilityValues,
          changes: vulnChanges,
        },
      };

      setChanges( {
        ...changes,
        updated: {
          ...changes.updated,
          [eKey]: combinedValues,
        },
      } );
    }
  }, [ updatedEscalation, selectedEscalation, escalationFields ] );

  // selected/deselecting an escalation in tab 1
  const toggleSelection = escalation => {
    setVulnEditDisabled( true );
    if ( isNotEmpty( selectedEscalation ) ) {
      if ( selectedEscalation.escalationData.id === escalation.escalationData.id ) {
        setSelectedEscalation( null );
      } else {
        selectEscalationForEdit( escalation );
      }
    } else {
      selectEscalationForEdit( escalation );
    }
  };

  // remove an escalation/vuln from tab 1
  const removeVulnerability = ( e, item ) => {
    e.preventDefault();
    e.stopPropagation();
    setChanges(
      {
        ...changes,
        deleted: {
          ...changes.deleted,
          [item.escalationData.id]: item,
        },
      },
    );

    // remove this from the ids that are not available in step 2, aka, make it available again
    let _ids = Array.from( vulnNotIDs );
    _ids = _ids.filter( i => i !== item.vulnerabilityData.id );
    setVulnNotIDs( _ids );
  };

  return (
    <React.Fragment>
      <h2>Edit Segment: Existing Vulnerabilities</h2>
      <div className="stepDirections">
        Choose a vulnerability associated with this segment to edit any of the attributes or
        information <strong>NOTE</strong> Editing any of the vulnerability specific fields will
        effect all instances of this vulnerability
      </div>
      <div className="existingVulnerabilitiesColumn">
        {
          ( isNotEmpty( sortedEscalations ) && isNotEmpty( highestRisk ) )
            ? <React.Fragment>
              <label>
                Associated
                Vulnerabilities <strong>({ sortedEscalations.length })</strong>
              </label>
              <ul>
                {
                  sortedEscalations.map( ( escalation, index ) => {
                    return  <li
                      key={index}
                      onClick={ () => toggleSelection( escalation ) }
                      // eslint-disable-next-line max-len
                      className={ `${escalation.updated ? 'updated' : ''} ${escalation.isAdded ? 'added' : ''} escalationItem ${ ( isNotEmpty( selectedEscalation ) && selectedEscalation.escalationData.id === escalation.escalationData?.id ) ? 'selected' : ''}` }
                    >
                      <span className="name">
                        { escalation.vulnerabilityData ? escalation.vulnerabilityData.identifier : 'N/A' }
                      </span>
                      <RiskPercentageBar
                        totalRisk={highestRisk}
                        itemRisk={ escalation.vulnerabilityData?.risk || 0 }
                        itemRating={ riskToRating( escalation.vulnerabilityData?.risk || 0 ) }
                        minimalVariant
                      />
                      <RiskReduction item={ escalation.vulnerabilityData } riskType="risk" />
                      <button
                        onClick={ e => removeVulnerability( e, escalation ) }
                      >
                        <InlineSVG type="delete" />
                      </button>
                    </li>;
                  } )
                }
              </ul>
            </React.Fragment>
            : <React.Fragment>
              {
                loadingEscalations
                  ? <Loading text="Loading..." />
                  : <EmptyState message="No Associated Vulnerabilities" />
              }
            </React.Fragment>
        }
      </div>
      <div className={ `${vulnEditDisabled ? 'editDisabled' : 'editEnabled'} formColumnWrapper` }>
        {
          ( isNotEmpty( selectedEscalation ) && isNotEmpty( escalationFields ) )
            ? <React.Fragment>
              <div className="vulnerabilityEditBanner" >
                {
                  !vulnEditDisabled &&
                  <h2>
                    <InlineSVG type="warning" version="light" />
                    <strong>Warning</strong> Editing
                    vulnerability fields will apply across all instances of this vulnerability
                  </h2>
                }
                <button
                  onClick={ () => setVulnEditDisabled( !vulnEditDisabled )}
                >
                  { vulnEditDisabled ? 'Edit' : 'Done' }
                </button>
              </div>
              <Form
                fields={escalationFields}
                // setIsValid={setIsValid}
                onChangeCallback={setUpdatedEscalation}
                existingRecord={selectedEscalation}
                recordType="escalation"
              />
            </React.Fragment>

            : <EmptyState message="Select a vulnerability to edit" />
        }
      </div>
    </React.Fragment>
  );
};

export default ExistingTab;