/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import './Error.scss';

const ErrorFallback = ( { error } ) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className="errorFallbackWrapper" >
      <h1>Something went wrong</h1>
      <pre>{ error.message }</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
};

export default ErrorFallback;