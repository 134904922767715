/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';
import { makeRequest } from '../../../../legacy/io';

import InlineSVG from '../../../shared/InlineSVG';

import {
  isEmpty,
  isNotEmpty,
} from '../../../shared/Utilities';

const Subnets = ( {
  subnets,
  setSubnets,
} ) => {

  const [ selectedIncluded, setSelectedIncluded ] = React.useState( [] );
  const [ selectedExcluded, setSelectedExcluded ] = React.useState( [] );

  React.useEffect( () => {

    const records = [];

    if ( subnets.included && subnets.excluded ) {
      subnets.included.map( subnet => {
        // eslint-disable-next-line camelcase
        records.push( { id: subnet.id, probe_connectivity: true } );
      } );

      subnets.excluded.map( subnet => {
        // eslint-disable-next-line camelcase
        records.push( { id: subnet.id, probe_connectivity: false } );
      } );

      makeRequest( 'UPDATE', '/project/default/subnet', { records: records } ).then( () => {
        setSelectedExcluded( [] );
        setSelectedIncluded( [] );
      } );
    }

  }, [ subnets ] );

  const handleSubnetChange = ( e, source ) => {
    const value = Array.from( e.target.selectedOptions, option => option.value );

    if ( source === 'included' ) {
      setSelectedIncluded( value );
      setSelectedExcluded( [] );
    } else {
      setSelectedIncluded( [] );
      setSelectedExcluded( value );
    }
  };

  const moveSubnetsTo = destination => {
    let tmpSrc, tmpDest, newSrc, newDest;

    if ( destination === 'excluded' ) {
      tmpSrc = Array.from( subnets.included );
      tmpDest = Array.from( subnets.excluded );

      newSrc = [];
      newDest = Array.from( tmpDest );

      tmpSrc.map( subnet => {
        if ( selectedIncluded.includes( subnet.id ) ) {
          newDest.push( subnet );
        } else {
          newSrc.push( subnet );
        }
      } );

      setSubnets( {
        included: newSrc,
        excluded: newDest,
      } );

    } else {
      tmpSrc = Array.from( subnets.excluded );
      tmpDest = Array.from( subnets.included );

      newSrc = [];
      newDest = Array.from( tmpDest );

      tmpSrc.map( subnet => {
        if ( selectedExcluded.includes( subnet.id ) ) {
          newDest.push( subnet );
        } else {
          newSrc.push( subnet );
        }
      } );

      setSubnets( {
        included: newDest,
        excluded: newSrc,
      } );
    }

  };

  return (
    <section className="networkConnectivitySection subnetSection">
      <div className="sectionHeader">Probe Connectivity</div>
      {
        isNotEmpty( subnets ) &&
        <React.Fragment>
          {
            subnets.included &&
            <div className="subnetListWrapper">
              <h2>Included in probes</h2>
              <select
                value={selectedIncluded}
                onChange={ e => handleSubnetChange( e, 'included' ) }
                multiple
              >
                {
                  subnets.included.map( ( subnet, index ) => {
                    return  <option
                      key={index}
                      value={subnet.id}
                    >
                      {subnet.net}
                    </option>;
                  } )
                }
              </select>
            </div>
          }
          {
            subnets.excluded &&
            <div className="subnetListWrapper">
              <h2>Excluded from probes</h2>
              <select
                value={selectedExcluded}
                onChange={ e => handleSubnetChange( e, 'excluded' ) }
                multiple
              >
                {
                  subnets.excluded.map( ( subnet, index ) => {
                    return  <option
                      key={index}
                      value={subnet.id}
                    >
                      {subnet.net}
                    </option>;
                  } )
                }
              </select>
            </div>

          }
          <div className="buttonContainer">
            <button
              disabled={ isEmpty( selectedExcluded ) && isEmpty( selectedIncluded ) }
              onClick={ () => moveSubnetsTo( isNotEmpty( selectedExcluded ) ? 'included' : 'excluded' )}
            >
              {
                isNotEmpty( selectedExcluded ) &&
                <InlineSVG type="carretLeft" elementClass="includeSelected" version="light" />
              }
              {
                isNotEmpty( selectedExcluded )
                  ? <span>Include </span>
                  : <span>Exclude </span>
              }
              selected
              {
                isNotEmpty( selectedIncluded ) &&
                <InlineSVG type="carretRight" elementClass="excludeSelected" version="light" />
              }
            </button>
          </div>
        </React.Fragment>
      }
    </section>
  );
};

export default Subnets;
