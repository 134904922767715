/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../InlineSVG';

import './style.scss';

const StreamDownload = ( {
  url,
  type='application/pdf',
  filename='filename',
} ) => {

  const hiddenDownloadLinkRef = React.useRef( null );

  const begin = () => {

    // not using the standard make_request so that I had more control over the
    // response, content type, and flow of data
    const myHeaders = { 'Content-Type': 'application/json; charset=utf-8' };

    const sid = window.localStorage.getItem( 'sid' );

    if ( sid ) {
      myHeaders['Authorization'] = `kanchil ${sid}`;
    }

    const myInit = { method: 'FETCH', headers: new Headers( myHeaders ) };

    fetch( url, myInit )
      .then( response => response.body )
      .then( rb => {
        const reader = rb.getReader();

        return new ReadableStream( {
          start( controller ) {
            function push() {
              reader.read().then( ( {done, value} ) => {
                if ( done ) {
                  controller.close();
                  return;
                }
                controller.enqueue( value );
                push();
              } );
            }

            push();
          },
        } );
      } )
    // creates a new response from the stream with the correct type
      .then( stream => new Response( stream, { headers: { 'Content-Type': type } } ) )
    // creates a blob from the response, and ultimately a url
      .then( response => response.blob() )
      .then( blob => URL.createObjectURL( blob ) )
    // sets all appropriate attributes and then simulates the click on the hidden el
      .then( url => {
        const hiddenLinkEl = hiddenDownloadLinkRef.current;

        hiddenLinkEl.setAttribute( 'href', url );

        hiddenLinkEl.setAttribute( 'download', filename );

        hiddenLinkEl.click();

      } )
      .catch( console.error );
  };

  return (
    <React.Fragment>
      <button
        className="taskRunnerButton download"
        onClick={ begin }
      >
        <InlineSVG type="downloadAlt" version="risk--green" />
        Download
      </button>
      <a
        download
        ref={hiddenDownloadLinkRef}
        className="hiddenLink"
      ></a>
    </React.Fragment>
  );
};

export default StreamDownload;
