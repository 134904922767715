/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  debounce,
  isEmpty,
} from '../../../Utilities';
import InlineSVG from '../../../InlineSVG';
import { searchParamsForResultType } from './SearchData';
import { makeRequest, search_model_records } from '../../../../../legacy/io';
import { getRecords } from '../../../RecordCache';

const SearchBar = ( {
  input,
  recordType,
  setResults,
  searchBarInput,
  setLoading,
  setNoResults,
  setShouldShowResults,
} ) => {

  const getResultsForType = async( type, params ) => {
    setShouldShowResults( true );
    let results;

    if ( type === 'scope' ) {
      results = ( await search_model_records( 'scope', params ) );
    }

    if ( type === 'host' ) {
      results = ( await getRecords( 'HOST', params, true ) );
    }

    if ( type === 'scanner_signature' ) {
      // eslint-disable-next-line
      results = ( await makeRequest( 'SEARCH', '/model/signature', params ) ).results;
    }

    if ( type === 'patch' || type === 'vulnerability' ) {
      results = await getRecords( type, params );
    }
    return results;
  };

  // fetches results from the server and sets them
  const fetchSearchResults = async ( type, query ) => {
    const response = await getResultsForType( type, searchParamsForResultType( type, query ) );
    return response;
  };

  // debounces a fetch of search results, so it does not hammer the server
  const debounceSearchInput = debounce( query => {
    setLoading( true );
    setNoResults( false );
    if ( query === '' ) {
      setResults( [] );
      setLoading( false );
    }
    if ( query !== '' && recordType !== '' ) {
      fetchSearchResults( recordType, query ).then( response => {
        setResults( response );
        setLoading( false );
        if ( isEmpty( response ) ) {
          setNoResults( true );
          setLoading( false );
        }
      } );
    }
  }, 500 );

  return (
    <div className="searchFieldWrapper">
      <InlineSVG type="search" />
      <input
        ref={searchBarInput}
        type="search"
        onChange={ e => debounceSearchInput( e.target.value ) }
        placeholder={ input.placeholder }
      />
    </div>
  );
};

export default SearchBar;
