/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import { edgeRiskClass } from '../../shared';

const EdgeHeader = ( { edge, options, handleClose, collapsed, setCollapsed } ) => {

  const [ fromNodeLabel, setFromNodeLabel ] = React.useState( null );
  const [ toNodeLabel, setToNodeLabel ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {
      if ( isNotEmpty( edge.includedEdges ) ) {
        const [ firstEdge ] = edge.includedEdges;

        const lastEdge = edge.includedEdges[ edge.includedEdges.length - 1 ];

        setFromNodeLabel( firstEdge?.fromNodeFull?.label );
        setToNodeLabel( lastEdge?.toNodeFull?.label );
      } else if ( isNotEmpty( edge.fromNode ) && isNotEmpty( edge.toNode ) ) {
        setFromNodeLabel( edge.fromNode?.label );
        setToNodeLabel( edge.toNode?.label );
      } else {
        setFromNodeLabel( 'N/A' );
        setToNodeLabel( 'N/A' );
      }
    }
  }, [ edge ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( edge ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <InlineSVG type="draggable" elementClass="draggableIcon" />
              }
              <div className={ `recordIconWrapper ${ edgeRiskClass( edge ) }` } >
                <InlineSVG type="segmentsAlt" elementClass="recordTypeHeaderIcon" />
              </div>
              <span className="label">
                <strong>{ fromNodeLabel }</strong>
                <span>to</span>
                <strong>{ toNodeLabel }</strong>
              </span>
            </h2>
            <div className="right">
              <RatingBadge rating={ edgeRiskClass( edge ) } />
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button
                  className="recordCardCloseButton roundGlyphButton light"
                  onClick={ handleClose }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              }
            </div>
          </div>
          <div className={ `recordCardHeaderRow__dividerBar ${ edgeRiskClass( edge ) }` } />
          <div className="recordCardHeaderRow">
            <div className="left"></div>
            {
              ( isNotEmpty( options ) && options.isCollapsible ) &&
              <button
                onClick={ () => setCollapsed( !collapsed ) }
                className="panelCollapseButton roundGlyphButton light"
              >
                {
                  collapsed
                    ? <InlineSVG type="expand" />
                    : <InlineSVG type="collapse" />
                }
              </button>
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default EdgeHeader;