/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../Utilities';
import { chartGradients } from './shared';

import './Area.scss';
import EmptyState from '../EmptyState';

const Area = ( {
  data,
  // elementClass='',
  fill='darkBlue',
  stroke='darkBlue',
  // horizontalLinesCount=3,
  // yOffset=0,
  // withoutWrapper=false,
  // withoutAxes=false,
  thick=false,
  // height=10,
  containerHeight=10,
  containerWidth=100,
  fullHeight=false,
  noXAxis=false,
  // adjustYMin=false,
} ) => {

  // eslint-disable-next-line no-nested-ternary
  const _containerHeight = noXAxis
    ? fullHeight ? containerHeight * 1 : containerHeight * 0.6
    : fullHeight ? containerHeight * 0.85 : containerHeight * 0.4;

  // const containerWidth = 100;
  const svgHeight = _containerHeight - 10;
  const svgWidth = containerWidth - 10;

  const xGutter = ( containerWidth - svgWidth ) / 2;
  const yGutter = ( _containerHeight - svgHeight ) / 2;

  const pathCurveRatio = 3; // lower number is a greater curve

  const [ points, setPoints ] = React.useState( null );
  const [ pointsString, setPointsString ] = React.useState( '' );
  const [ pathString, setPathString ] = React.useState( '' );

  // calculates everything when the data changes
  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) && isNotEmpty( data.max ) && isNotEmpty( data.yAxis ) ) {
      const _points = [];
      let _pointsString = [];
      let _pathString = [];

      Object.values( data.transformed ).map( ( point ) => {
        if ( point[data.yAxis] > data.max ) {
          data.max = point[data.yAxis];
        }
      } );
      const _widthRatio = svgWidth / ( Object.values( data.transformed ).length - 1 );
      const _heightRatio = svgHeight / data.max;

      Object.values( data.transformed ).map( ( point, index ) => {
        let x;
        // const data.max = max <= 0 ? 1 : max;
        const y = ( svgHeight - ( _heightRatio * point[data.yAxis] ) ) + yGutter;
        // points are not necessarily spaced out evenly, need to find the distance from the left based on
        // the xAxis series, if no xAxis is passed in... need to assume even spacing
        if ( isNotEmpty( data.xAxis ) ) {
          const firstPointValue = Object.values( data.transformed )[0][data.xAxis];
          // eslint-disable-next-line max-len
          const lastPointValue = Object.values( data.transformed )[ Object.values( data.transformed ).length - 1][data.xAxis];
          const delta = lastPointValue - firstPointValue;
          const newWidthRatio = svgWidth / delta;
          x = ( newWidthRatio * ( point[data.xAxis] - firstPointValue ) ) + xGutter;

        // evenly spaced out
        } else {
          x = ( _widthRatio * index ) + xGutter;
        }
        const height = svgHeight;
        _points.push( { height, x, y, id: point.id, original: point } );
      } );

      _points.map( ( p, index ) => {

        let px1 = 0;
        let py1 = 0;
        let px2 = 0;
        let py2 = 0;

        if ( isNotEmpty( p.x ) && !isNaN( p.x ) ) {
          px1 = p.x;
        }
        if ( isNotEmpty( p.y ) && !isNaN( p.y ) ) {
          py1 = p.y;
        }

        let pathValue = `${px1},${py1}`;
        let xDelta = 1;
        const prevPoint = _points[ index - 1 ];

        if ( isNotEmpty( prevPoint ) ) {

          if ( isNotEmpty( prevPoint.x ) && !isNaN( prevPoint.x ) ) {
            px2 = prevPoint.x;
          }
          if ( isNotEmpty( prevPoint.y ) && !isNaN( prevPoint.y ) ) {
            py2 = prevPoint.y;
          }

          xDelta = px1 - px2;
        }

        // first point, no curve
        if ( index === 0 ) {
          pathValue = `M ${xGutter},${py1}`;
        // one of the points in the middle, not the last point
        } else if ( index !== _points.length - 1 ) {
          // eslint-disable-next-line max-len
          pathValue = `C ${px2 + ( xDelta * ( 1 / pathCurveRatio ) )},${py2} ${px1 - ( xDelta * ( 1 / pathCurveRatio ) )},${py1} ${px1},${py1}`;
        // the last point
        } else {
          // eslint-disable-next-line max-len
          pathValue = `C ${px2 + ( xDelta * ( 1 / pathCurveRatio ) )},${py2} ${px1},${py1} ${px1},${py1}`;
        }

        _pathString.push( pathValue );
        _pointsString.push( `${px1},${py1}` );
      } );

      _pathString = _pathString.join( ' ' );
      _pointsString = _pointsString.join( ' ' );

      setPoints( _points );
      setPointsString( _pointsString );
      setPathString( _pathString );
    }
  }, [ data, containerHeight, containerWidth ] );

  const svgContent = () => {
    return (
      <g className="svgAreaContentGroup">
        {/* gradient fill */}
        <path
          d={ `${pathString} L ${svgWidth + xGutter},${svgHeight - yGutter} L ${xGutter},${svgHeight - yGutter} z` }
          fill={ `url(#gradient--${fill})` }
          fillOpacity={0.25}
          stroke="none"
          className="areaChartFill"
        />
        {/* actual visible path line */}
        <path
          d={ pathString }
          fill="none"
          stroke={ globalColors[stroke] }
          strokeWidth={ thick ? 2.5 : 1.25 }
          strokeOpacity={ data.isTag ? '0.8' : '1' }
        />
      </g>
    );
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( points ) && isNotEmpty( pointsString ) && points.length > 1 )
          ? <React.Fragment>
            <defs>
              {
                Object.values( chartGradients ).map( ( grad, index ) => {
                  return <React.Fragment key={index}>
                    { grad }
                  </React.Fragment>;
                } )
              }
            </defs>
            { svgContent() }
          </React.Fragment>
          : <EmptyState message="Insufficient data" />
      }
    </React.Fragment>
  );
};

export default Area;