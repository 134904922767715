/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Bar from '../../../shared/Charts/Bar';

import {
  cvssScoreToRating,
  decodeURLHash,
  formatNumber,
  formatRiskReduction,
  getDimensionsAndOffset,
  globalColors,
  isNotEmpty,
  reportTypeDisplayName,
  riskColorMap,
  riskToRating,
} from '../../../shared/Utilities';
import Legend from '../../../shared/Charts/Legend';
import { FullScreenVisualContext } from '../../../Contexts/FullScreenVisual';
import InlineSVG from '../../../shared/InlineSVG';
import { keyToAttrMap, multipleOrderByOptions } from '../../Reporting/Dashboards/shared';
import YAxisLabels from '../../../shared/Charts/AxisLabels/YAxisLabels';

const exploitStatusFillMap = {
  private: globalColors['grey--divider'],
  // eslint-disable-next-line camelcase
  published_details: globalColors['low'],
  poc: globalColors['moderate'],
  weaponized: globalColors['high'],
};

const exploitStatusValueTranslationMap = {
  private: 25,
  // eslint-disable-next-line camelcase
  published_details: 50,
  poc: 75,
  weaponized: 100,
};

const exploitStatusLabelTranslationMap = {
  25: 'Private',
  50: 'Details Published',
  75: 'PoC Published',
  100: 'Weaponized',
};

const DefaultVisual = ( {
  records,
  collapsed,
  reportType,
  selectRecord,
  // hoverRecord,
  riskType,
} ) => {
  const [ barChartData, setBarChartData ] = React.useState( {} );
  const [ , , showVisual, , showFullScreenVisual, , , ] = React.useContext( FullScreenVisualContext );

  const [ yMax, setYMax ] = React.useState( null );
  const [ svgContainerWidth, setSVGContainerWidth ] = React.useState( 1 );
  const [ svgContainerHeight, setSVGContainerHeight ] = React.useState( 1 );
  const [ legendData, setLegendData ] = React.useState( null );
  const [ shouldOrderByTiers, setShouldOrderByTiers ] = React.useState( false );
  const [ primaryOrderBy, setPrimaryOrderBy ] = React.useState( null );

  const [ hoveredSeries, setHoveredSeries ] = React.useState( null );

  const svgContainerRef = React.useRef( null );

  let windowWidth = window.innerWidth;

  // fires on all resize events, throttles to changes greater than 100px
  const adjustSVGAspectRatio = ( onPageLoad=false ) => {

    const adjustmentThreshold = 100;

    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( svgContainerRef.current ) ) {

      if ( ( Math.abs( window.innerWidth - windowWidth ) > adjustmentThreshold ) || onPageLoad ) {
        const dimensions = getDimensionsAndOffset( svgContainerRef.current );
        setSVGContainerWidth( dimensions.width );
        setSVGContainerHeight( dimensions.height );

        windowWidth = window.innerWidth;
      }

    }
  };

  const getTierKey = ( value, orderBy, max, record ) => {
    if ( orderBy === 'risk' || orderBy === 'filtered_risk' || orderBy === 'direct_risk' ) {
      if ( reportType === 'patch' && orderBy === 'filtered_risk' ) {
        return riskToRating( record[riskType] );
      }
      if ( reportType === 'user' ) {
        return riskToRating( record.risk );
      }
      if ( reportType === 'host' && isNotEmpty( record ) && !record.has_host ) {
        return 'unknown';
      }
      return riskToRating( value );
    }
    if ( orderBy === 'cvss_base_score' ) {
      return cvssScoreToRating( value );
    }
    if ( orderBy === 'exploit_status' ) {
      return value;
    }
    const valuePercentile = value / max;
    let tierKey = 20;
    if ( valuePercentile >= 0.8 ) {
      tierKey = 100;
    } else if ( valuePercentile >= 0.6 ) {
      tierKey = 80;
    } else if ( valuePercentile >= 0.4 ) {
      tierKey = 60;
    } else if ( valuePercentile >= 0.2 ) {
      tierKey = 40;
    }
    return tierKey;
  };

  const getFillForAttribute = ( value, orderBy, max, record ) => {
    if ( isNotEmpty( orderBy ) && isNotEmpty( max ) ) {

      // use the risk criticality scale
      if ( orderBy === 'risk' || orderBy === 'filtered_risk' || orderBy === 'direct_risk' ) {
        if ( reportType === 'host' && isNotEmpty( record ) && !record.has_host ) {
          return globalColors['grey--icon'];
        }
        if ( reportType === 'patch' && orderBy === 'filtered_risk' ) {
          const rating = riskToRating( record[riskType] );
          return riskColorMap[rating];
        }
        if ( reportType === 'user' && orderBy === 'filtered_risk' ) {
          const rating = riskToRating( record.risk );
          return riskColorMap[rating];
        }
        const rating = riskToRating( value );
        return riskColorMap[rating];
      }
      // use the risk criticality scale
      if ( orderBy === 'cvss_base_score' ) {
        const rating = cvssScoreToRating( value );
        return riskColorMap[rating];
      }
      // use the risk criticality scale
      if ( orderBy === 'exploit_status' ) {
        return exploitStatusFillMap[value];
      }
      if ( orderBy === 'num_vulnerabilities' ) {
        return globalColors[`status--blue--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_hosts' ) {
        return globalColors[`status--yellow--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_patches' ) {
        return globalColors[`status--green--${getTierKey( value, orderBy, max )}`];
      }
      if ( orderBy === 'num_unsuperseded_patches' ) {
        return globalColors[`status--green--${getTierKey( value, orderBy, max )}`];
      }
    }
    // default
    return globalColors[`darkBlue--${getTierKey( value, orderBy, max )}`];
  };

  const getValueForAttribute = ( value, orderBy, record ) => {
    if ( orderBy === 'filtered_risk' && reportType === 'patch' ) {
      return record[riskType];
    }
    if ( orderBy === 'filtered_risk' && reportType === 'user' ) {
      return record.risk;
    }
    if ( orderBy === 'exploit_status' ) {
      return exploitStatusValueTranslationMap[value];
    }
    return value;
  };

  // sets up resize aspect ratio event listener
  React.useEffect( ( ) => {
    if ( isNotEmpty( svgContainerRef ) && isNotEmpty( records ) ) {
      windowWidth = window.innerWidth;

      setTimeout( () => {
        adjustSVGAspectRatio( true );
      }, 100 );

      window.addEventListener( 'resize', adjustSVGAspectRatio );
      return () => window.removeEventListener( 'resize', adjustSVGAspectRatio );
    }
  }, [ svgContainerRef, records ] );

  // formats all of the data so that it conforms to what the bar chart and legend expect
  React.useEffect( () => {
    if ( isNotEmpty( records ) && isNotEmpty( reportType ) ) {
      const _barData = {};
      let _legendData = {};

      // eslint-disable-next-line camelcase
      let order_by = riskType;
      // if we are ordering by something other than risk, need to adjust the data accordingly
      const params = decodeURLHash();

      if ( isNotEmpty( params ) ) {
        ( { order_by } = params );

        // for the purposes of the display of the barchart, we only need to know
        // the primary order by, ignore any possible others
        if ( isNotEmpty( order_by ) ) {
          if ( multipleOrderByOptions.includes( order_by ) ) {
            // eslint-disable-next-line camelcase
            const [ primaryKey ] = order_by.split( '_' );
            // eslint-disable-next-line camelcase
            order_by = keyToAttrMap[primaryKey];
          }
        }
      }

      setPrimaryOrderBy( order_by );

      const _yMax = Math.max( ...records.map( r => getValueForAttribute( r[order_by], order_by, r ) ) );

      // eslint-disable-next-line camelcase
      if ( order_by === 'risk' || order_by === 'filtered_risk' || order_by === 'direct_risk' ) {
        setShouldOrderByTiers( false );
        _legendData = {
          critical: { key: 'critical', label: 'Critical', total: 0, fill: globalColors.critical },
          high: { key: 'high', label: 'High', total: 0, fill: globalColors.high },
          moderate: { key: 'moderate', label: 'Moderate', total: 0, fill: globalColors.moderate },
          low: { key: 'low', label: 'Low', total: 0, fill: globalColors.low },
          minimal: { key: 'minimal', label: 'Minimal', total: 0, fill: globalColors.minimal },
        };
      // eslint-disable-next-line camelcase
      } else if ( order_by === 'cvss_base_score' ) {
        setShouldOrderByTiers( false );
        _legendData = {
          critical: { key: 'critical', label: 'Critical: 9 - 10', total: 0, fill: globalColors.critical },
          high: { key: 'high', label: 'High: 9 - 7.5', total: 0, fill: globalColors.high },
          moderate: { key: 'moderate', label: 'Moderate: 7.5 - 6', total: 0, fill: globalColors.moderate },
          low: { key: 'low', label: 'Low: 6 - 3', total: 0, fill: globalColors.low },
          minimal: { key: 'minimal', label: 'Minimal: 3 - 0', total: 0, fill: globalColors.minimal },
        };
      // eslint-disable-next-line camelcase
      } else if ( order_by === 'exploit_status' ) {
        setShouldOrderByTiers( false );
        _legendData = {
          weaponized: { key: 'weaponized', label: 'Weaponized', total: 0, fill: globalColors.high },
          poc: { key: 'poc', label: 'PoC Published', total: 0, fill: globalColors.moderate },
          // eslint-disable-next-line camelcase
          published_details: { key: 'published_details', label: 'Details Published', total: 0, fill: globalColors.low },
          private: { key: 'private', label: 'Private', total: 0, fill: globalColors['grey--divider'] },
        };
      // percentage tiers
      } else {
        setShouldOrderByTiers( true );
        _legendData = {
          100: { key: 100, label: '>80%', total: 0, fill: getFillForAttribute( 81, order_by, 100 ) },
          80: { key: 80, label: '60% - 80%', total: 0, fill: getFillForAttribute( 61, order_by, 100 ) },
          60: { key: 60, label: '40% - 60%', total: 0, fill: getFillForAttribute( 41, order_by, 100 ) },
          40: { key: 40, label: '20% - 40%', total: 0, fill: getFillForAttribute( 21, order_by, 100 ) },
          20: { key: 20, label: '<20%', total: 0, fill: getFillForAttribute( 11, order_by, 100 ) },
        };
      }

      if (
        reportType === 'host'
        // eslint-disable-next-line camelcase
        && ( order_by === 'risk' || order_by === 'filtered_risk' || order_by === 'direct_risk' )
      ) {
        _legendData.unknown = { key: 'unknown', label: 'Unknown', total: 0, fill: globalColors['grey--icon'] };
      }

      // need to go through the data and format to what the barchart needs,
      // for the most part, just grab the values of what we are ordering by, but the
      // legend and the coloring need to be handled differently depending on what the order_by is,
      // the colors will align with the associated color for that record type in the dashboard widget, ie:

      // hosts => status--yellow
      // patches => status--green
      // vulns => status--blue

      // cvss => risk colors
      // exploit status => exploit status pallete

      // vuln instances => darkBlue
      // risk => status--red

      records.map( record => {
        if ( isNotEmpty( record ) ) {
          const key = getTierKey( record[order_by], order_by, _yMax, record );
          const fill = getFillForAttribute( record[order_by], order_by, _yMax, record );

          _barData[record.id] = {
            original: record,
            label: reportTypeDisplayName( record, reportType ),
            value: getValueForAttribute( record[order_by], order_by, record ),
            fill,
            stroke: fill,
            key,
          };
          _legendData[key].total += 1;
        }
      } );

      setLegendData( _legendData );
      setBarChartData( _barData );
      setYMax( _yMax );
    } else {
      setBarChartData( null );
    }
  }, [ records, reportType ] );

  const onClick = element => {
    if ( isNotEmpty( element ) ) {
      // eslint-disable-next-line
      const _id = element.id.split( '_' )[1];
      selectRecord( _id );
    }
  };

  const ticFormatter = tic => {
    if ( primaryOrderBy === 'risk' || primaryOrderBy === 'filtered_risk' || primaryOrderBy === 'direct_risk' ) {
      return formatRiskReduction( tic );
    } else if ( primaryOrderBy === 'cvss_base_score' ) {
      return tic.toFixed( 1 );
    } else if ( primaryOrderBy === 'exploit_status' ) {
      return exploitStatusLabelTranslationMap[tic];
    }
    return formatNumber( Math.floor( tic ) );
  };

  const getTicCount = () => {
    if ( primaryOrderBy === 'cvss_base_score' || primaryOrderBy === 'exploit_status' ) {
      return 4;
    }
    return 3;
  };

  const getYAxisMax = () => {
    if ( primaryOrderBy === 'cvss_base_score' ) {
      return 10;
    }
    if ( primaryOrderBy === 'exploit_status' ) {
      return 100;
    }
    return yMax;
  };


  return (
    <React.Fragment>
      {
        isNotEmpty( barChartData ) &&
        <div className="dashboardWidgetWrapper noWrapper">
          <div className="widgetContent" ref={svgContainerRef} >
            <YAxisLabels yMax={ getYAxisMax() } ticsCount={ getTicCount() } ticFormatter={ ticFormatter } />
            <Bar
              data={barChartData}
              containerHeight={ svgContainerHeight }
              containerWidth={ svgContainerWidth }
              onClick={ onClick }
              maxOverride={ getYAxisMax() }
              insightVersion
              hoveredSeriesIdentifier={ hoveredSeries }
              // setHoveredSeriesIdentifier={ setHoveredSeries }
            />
            <Legend
              legendData={legendData}
              hoveredSeriesIdentifier={ hoveredSeries }
              setHoveredSeriesIdentifier={ setHoveredSeries}
              orderByTiers={ shouldOrderByTiers }
            />

          </div>
          {
            !collapsed &&
            <button
              className="roundGlyphButton light showFullScreenButton"
              onClick={ () => showFullScreenVisual(
                <div className="widgetContent" ref={svgContainerRef} >
                  <YAxisLabels yMax={ getYAxisMax() } ticsCount={ getTicCount() } ticFormatter={ ticFormatter } />
                  <Bar
                    data={barChartData}
                    containerHeight={ svgContainerHeight }
                    containerWidth={ svgContainerWidth }
                    onClick={ onClick }
                    maxOverride={ getYAxisMax() }
                    insightVersion
                    hoveredSeriesIdentifier={ hoveredSeries }
                    // setHoveredSeriesIdentifier={ setHoveredSeries }
                  />
                  <Legend
                    legendData={legendData}
                    hoveredSeriesIdentifier={ hoveredSeries }
                    setHoveredSeriesIdentifier={ setHoveredSeries}
                    orderByTiers={ shouldOrderByTiers }
                  />
                </div>,
                'riskInsightIndex',
              ) }
            >
              { showVisual ? <InlineSVG type="exitFullscreen" /> : <InlineSVG type="fullscreen" /> }
            </button>
          }
        </div>
      }
    </React.Fragment>
  );
};

export default DefaultVisual;