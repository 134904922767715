/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../../shared/InlineSVG';
import { isNotEmpty } from '../../../../shared/Utilities';

import './AuthenticationProvider.scss';
import RatingBadge from '../../../../shared/RatingBadge';

const authProviderDisplayMap = {
  'internal': 'DS Password Store',
  'ldap-ntlm': 'LDAP with NTLM',
  'saml2': 'SAML 2.0',
};

const AuthenticationProviderItem = ( { provider, globalSettings } ) => {

  const [ selected, setSelected ] = React.useState( false );

  React.useEffect( ( ) => {
    if ( isNotEmpty( provider ) && isNotEmpty( globalSettings ) ) {
      setSelected( globalSettings.autoprovision_auth_provider === provider.id );
    } else {
      setSelected( false );
    }
  }, [ provider, globalSettings ] );

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2>
            {
              selected &&
              <InlineSVG type="checkmarkCircle" version="status--green" />
            }
            { provider.label }
            {
              selected &&
              <RatingBadge rating="success" alternateText="Auto-Provisioning" elementClass="autoprovisioning" />
            }
          </h2>
          <span>
            <strong>Type: </strong>
            { authProviderDisplayMap[provider.type] }
          </span>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AuthenticationProviderItem;