/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';

import './NotScannedEmptyState.scss';

const NotScannedEmptyState = ( ) => {
  return (
    <div className="notScannedEmptyState">
      <InlineSVG type="notScanned" />
      {/* eslint-disable-next-line max-len */}
      <p>This host has not yet been scanned by DeepSurface. Please consider scanning via a <a href="#.=scanning&page=user_managed">user managed</a>, <a href="#.=scanning&page=agents">agent</a>, or <a href="#.=scanning&page=scan_groups">credentialed</a> scan.</p>
    </div>
  );
};

export default NotScannedEmptyState;