/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';

const ScanGroupItem = ( { scanGroup } ) => {

  const displaySchedule = sg => {
    const scheduleDetails = [];

    if ( sg.schedule_settings?.recurring?.length > 0 ) {
      let remainder = 0;
      let toIterate = [];
      if ( sg.schedule_settings.recurring.length >= 3 ) {
        toIterate = sg.schedule_settings.recurring.slice( 0, 3 );
        remainder = sg.schedule_settings.recurring.length - 3;
      } else {
        toIterate = sg.schedule_settings.recurring;
      }
      // only enumerate the first 3 items
      toIterate.map( sched => {
        if ( sched.type === 'monthly' ) {
          scheduleDetails.push(
            <React.Fragment>
              <strong>Monthly: </strong>
              <span>{ `${sched.week} ${sched.dow} @ ${sched.time}` }</span>
            </React.Fragment>,
          );
        }
        if ( sched.type === 'weekly' ) {
          scheduleDetails.push(
            <React.Fragment>
              <strong>Weekly: </strong>
              <span>{ `${sched.dow} @ ${sched.time}` }</span>
            </React.Fragment>,
          );
        }
        if ( sched.type === 'daily' ) {
          scheduleDetails.push(
            <React.Fragment>
              <strong>Daily: </strong>
              <span>{ `@ ${sched.time}` }</span>
            </React.Fragment>,
          );
        }
      } );
      if ( remainder > 0 ) {
        scheduleDetails.push( `+${remainder} more...` );
      }
    }
    return scheduleDetails;
  };

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2>{ scanGroup.label || 'No Label' }</h2>
          <span className="scheduleWrapper">
            <strong>Schedule: </strong>
            {
              isNotEmpty( displaySchedule( scanGroup ) )
                ? <ul>
                  {
                    displaySchedule( scanGroup ).map( ( text, index ) => {
                      return  <li
                        className={`${index >= 3 ? 'remainder' : ''}`}
                        key={index}
                      >
                        { text }
                      </li>;
                    } )
                  }
                </ul>
                : 'Manual'
            }
          </span>
        </div>
      </section>
      <section>
        <strong className="sectionLabel">
          { `Target Ranges (${scanGroup.included_ranges?.length})` }
        </strong>
        <span className="sectionDetails" >
          { scanGroup.included_ranges?.join( ', ' ) }
        </span>
      </section>
    </React.Fragment>
  );
};

export default ScanGroupItem;