/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { HelpTrigger } from '../../../components/HelpDocumentation/ContextualHelp/index.js';
import Modal from '../../../shared/Modal';
import { isEmpty, isNotEmpty } from '../../../shared/Utilities';
import Form from '../../../shared/Form';
import { selectExploreItems } from './Shared';
import { getFieldValues } from '../../../shared/Form/Shared';
import Loading from '../../../shared/Loading';
import { FlashMessageQueueContext } from '../../../Contexts/FlashMessageQueue';
import { addRecords, updateRecords } from '../../../shared/RecordCache.js';

const DEFAULT_VALUES = {
  label: '',
  parent: '',
  type: 'host',
  flags: {},
};

const EMPTY_FIELDS = [
  {
    type: 'text',
    attribute: 'label',
    label: 'Label',
    required: true,
    defaultValue: '',
    help: <HelpTrigger helpKey="scope_label" />,
  },
  {
    type: 'searchResults',
    attribute: 'parent',
    label: 'Parent Scope',
    placeholder: 'Find scope by name...',
    recordType: 'scope',
    required: true,
    defaultValue: '',
    help: <HelpTrigger helpKey="parent_scope" />,
  },
  {
    type: 'select',
    attribute: 'type',
    label: 'Type',
    required: true,
    defaultValue: 'host',
    options: {
      host: 'Host',
      application: 'Application',
      // eslint-disable-next-line camelcase
      windows_domain: 'Windows Domain',
      subnet: 'Subnet',
    },
    help: <HelpTrigger helpKey="scope_type" />,
  },
  {
    type: 'hidden',
    attribute: 'flags',
    defaultValue: {},
  },
];

const ModalBody = ( {
  fields,
  setUpdatedForm,
  selectedItem,
  setIsValid,
  isLoading,
} ) => {
  return (
    <React.Fragment>
      {
        ( isNotEmpty( fields ) && isNotEmpty( selectedItem ) ) &&
        <Form
          fields={fields}
          onChangeCallback={setUpdatedForm}
          setIsValid={setIsValid}
          recordType="explore_scope"
          existingRecord={selectedItem}
        />
      }
      { isLoading && <Loading text="Saving..." /> }
    </React.Fragment>
  );
};

const ModalAction = ( {
  isValid,
  updatedForm,
  setShow,
  selectedItem,
  fetchAndRedrawGraph,
  setIsLoading,
} ) => {
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const save = async () => {
    setIsLoading( true );
    if ( isNotEmpty( updatedForm ) ) {

      const values = getFieldValues( updatedForm?.fieldStates, 'explore_scope' );

      let scopeResponse;
      const _scopeValues = {};

      // need to create a scope
      if ( isNotEmpty( selectedItem ) && isEmpty( selectedItem.id ) ) {

        Object.entries( values ).map( ( [ attr, val ] ) => {
          _scopeValues[attr] = val || '';
        } );

        // DMC - 2022-08-04 backend regression was introduced that does not allow flags to be null, this prevents that
        if ( isNotEmpty( selectedItem ) ) {
          _scopeValues.flags = selectedItem.flags || {};
        }

        scopeResponse = await addRecords( 'scope', [ { ..._scopeValues, id: uuidv4() } ] );

        if ( scopeResponse.errors ) {
          scopeResponse.errors.map( e => {
            addFlashMessage( {
              type: 'alert',
              body: e,
            } );
          } );
        } else {

          // need to create an any node inside the just created scope
          // in order for it to show on the graph
          const nodeResponse = await addRecords( 'node', [
            {
              // eslint-disable-next-line camelcase
              scope_id: scopeResponse[0].id,
              label: 'any',
              type: 'user',
              flags: {},
              impact: 0,
              id: uuidv4(),
            },
          ] );

          if ( nodeResponse.errors ) {
            nodeResponse.errors.map( e => {
              addFlashMessage( {
                type: 'alert',
                body: e,
              } );
            } );
          } else {
            selectExploreItems( 'scope', scopeResponse );
            selectExploreItems( 'node', nodeResponse );
            fetchAndRedrawGraph( true );
            addFlashMessage( {
              type: 'success',
              body: 'Successfully created scope',
            } );
            setShow( false );
          }
          setIsLoading( false );
        }
      // need to update a scope
      } else {

        Object.entries( values ).map( ( [ attr, val ] ) => {
          if ( val !== selectedItem[attr] ) {
            _scopeValues[attr] = val || '';
          }
        } );

        if ( isNotEmpty( _scopeValues ) ) {

          // DMC - 2022-08-04 backend regression was introduced that does not allow flags to be null, this prevents that
          if ( isNotEmpty( selectedItem ) ) {
            _scopeValues.flags = selectedItem.flags || {};
          }

          scopeResponse = await updateRecords( 'scope', [ { ..._scopeValues, id: selectedItem.id } ] );
          if ( scopeResponse.errors ) {
            scopeResponse.errors.map( e => {
              addFlashMessage( {
                type: 'alert',
                body: e,
              } );
            } );
          } else {
            selectExploreItems( 'scope', scopeResponse );
            fetchAndRedrawGraph( true );
            addFlashMessage( {
              type: 'success',
              body: 'Successfully updated scope',
            } );
            setShow( false );
          }
        } else {
          setShow( false );
        }
      }
    }
    setIsLoading( false );
  };
  return (
    <React.Fragment>
      <button
        disabled={!isValid}
        className={ `${isValid ? '' : 'disabled'}`}
        onClick={ save }
      >
        Save scope
      </button>
    </React.Fragment>
  );
};

const EditScope = ( { selectedItem, selectedItemType, show, setShow, fetchAndRedrawGraph } ) => {

  const [ fields, setFields ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( false );
  const [ updatedForm, setUpdatedForm ] = React.useState( null );
  const [ isLoading, setIsLoading ] = React.useState( false );

  const setupForm = item => {

    let _fields = Array.from( EMPTY_FIELDS );

    if ( isNotEmpty( item ) ) {

      // we are editing an existing scope
      if ( item.id ) {
        _fields.map( f => {
          if ( f.attribute === 'parent' ) {
            f.disabled = true;
          }
          f.defaultValue = item[f.attribute] || '';
        } );
      } else {
        _fields = Array.from( EMPTY_FIELDS );

        _fields.map( f => {
          if ( f.attribute === 'parent' ) {
            f.disabled = false;
          }
          // need to reset the defaults in order to clear out previous values
          f.defaultValue = DEFAULT_VALUES[f.attribute] || '';
        } );

        const scopeField = _fields.find( f => f.attribute === 'parent' );
        scopeField.defaultValue = item.parent;
      }

      setFields( _fields );
    } else {
      setFields( [] );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( selectedItem ) && show && selectedItemType === 'scope' ) {
      setShow( true );

      if ( isNotEmpty( selectedItem ) ) {
        setupForm( selectedItem );
      }
    } else {
      setFields( null );
    }
  }, [ selectedItem, show, selectedItemType ] );

  return (
    <React.Fragment>
      <Modal
        elementClass="exploreModal"
        visible={show}
        setVisible={setShow}
        header={`${ isNotEmpty( selectedItem ) && isNotEmpty( selectedItem.id ) ? 'Edit' : 'Add' } Scope`}
        size="small"
        body={ <ModalBody
          selectedItem={selectedItem}
          fields={fields}
          setUpdatedForm={setUpdatedForm}
          setIsValid={setIsValid}
          isLoading={isLoading}
        />}
        action={  <ModalAction
          isValid={isValid}
          setShow={setShow}
          updatedForm={updatedForm}
          selectedItem={selectedItem}
          fetchAndRedrawGraph={fetchAndRedrawGraph}
          setIsLoading={setIsLoading}
        />}
      />
    </React.Fragment>
  );
};

export default EditScope;