/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import InlineSVG from '../../../../shared/InlineSVG';
import UserDetails from '../../../../shared/UserDetails';
import { isNotEmpty, userDisplayName } from '../../../../shared/Utilities';
import { hasAccess } from '../../../App/AccessControl';

const UserItem = ( { user, index, externalUsers, activeIntegrations } ) => {

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const integrationIconMap = {
    jira: 'jiraLogo',
  };

  const [ externalUser, setExternalUser ] = React.useState( null );
  const [ integration, setIntegration ] = React.useState( null );

  React.useEffect( ( ) => {
    if ( isNotEmpty( user ) && isNotEmpty( activeIntegrations ) && isNotEmpty( externalUsers ) ) {

      const flattenedIntegrations = {};

      Object.values( activeIntegrations ).map( intArray => {
        intArray.map( int => {
          flattenedIntegrations[int.id] = int;
        } );
      } );


      const _externalUser = Object.values( externalUsers ).find( u => u.web_user_id === user.id );
      setExternalUser( _externalUser );

      if ( isNotEmpty( _externalUser ) && isNotEmpty( flattenedIntegrations ) ) {
        setIntegration( flattenedIntegrations[_externalUser.third_party_setting_id] );
      }
    }
  }, [ user, activeIntegrations, externalUsers ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( licenseInfo ) &&
        <React.Fragment>
          <UserDetails user={user} randomizeKey={index} includeDetails includeEmail />
          <section>
            <strong className="sectionLabel">
              Linked External User
              <HelpTrigger helpKey="external_user" />
            </strong>
            {
              hasAccess( licenseInfo, null, 'feature', 'remediationWorkflow' )
                ? <React.Fragment>
                  {
                    ( isNotEmpty( integration ) && isNotEmpty( externalUser ) )
                      ? <span className="sectionDetails">
                        <InlineSVG type={integrationIconMap[integration.tool]} version="special" />
                        { userDisplayName( externalUser, false, integration.tool ) }
                      </span>
                      : <span className="sectionDetails">N/A</span>
                  }
                </React.Fragment>
                : <span>Not Available</span>
            }
          </section>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default UserItem;