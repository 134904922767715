/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';

import { isNotEmpty } from '../Utilities';
import FlashMessage from './FlashMessage';

import './style.scss';

const FlashMessageQueue = ( ) => {

  const [ , , currentFlashMessages ] = React.useContext( FlashMessageQueueContext );

  const queueRoot = document.getElementById( 'reactFlashMessageQueuePortal' );

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        isNotEmpty( currentFlashMessages ) &&
        <ul className="flashMessageQueueWrapper">
          {
            [ ...currentFlashMessages.keys() ].map( messageKey => {

              const flashMessage = currentFlashMessages.get( messageKey );

              return <FlashMessage flashMessage={flashMessage} messageKey={messageKey} key={messageKey} />;
            } )
          }
        </ul>
      }
    </React.Fragment>,
    queueRoot,
  );
};

export default FlashMessageQueue;