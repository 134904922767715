/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';

import Loading from '../../../../shared/Loading';
import Modal from '../../../../shared/Modal';

import {
  EMPTY_FIELDS,
  ESCALATION_DEFAULTS,
} from './Shared.js';

import {
  isEmpty,
  isNotEmpty,
} from '../../../../shared/Utilities';

import './style.scss';
import ExistingTab from './ExistingTab';
import NewTab from './NewTab';
import { selectExploreItems } from '../Shared';
import { getFieldValues } from '../../../../shared/Form/Shared';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import { addRecords, deleteRecords, getRecord, getRecords, updateRecords } from '../../../../shared/RecordCache.js';
import InlineSVG from '../../../../shared/InlineSVG/index.js';

const ModalBody = ( {
  selectedItem,
  setSelectedItem,
  availableEscalations,
  selectedEscalation,
  setSelectedEscalation,
  vulnNotIDs,
  setVulnNotIDs,
  setModalActionButton,
  setChanges,
  changes,
  setShow,
  loadingEscalations,
  escalations,
  fetchAndRedrawGraph,
  isSaving,
  setIsSaving,
  nodes,
} ) => {

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // fields for tab 2
  const [ vulnerabilityFields, setVulnerabilityFields ] = React.useState( null );
  const [ updatedVulnerability, setUpdatedVulnerability ] = React.useState( null );
  const [ vulnerabilityIsValid, setVulnerabilityIsValid ] = React.useState( null );

  // combined fields on tab 1
  const [ escalationFields, setEscalationFields ] = React.useState( null );
  const [ updatedEscalation, setUpdatedEscalation ] = React.useState( null );

  const [ selectedResult, setSelectedResult ] = React.useState( null );

  const [ activeTab, setActiveTab ] = React.useState( 'existing' );

  const [ newVulnChoice, setNewVulnChoice ] = React.useState( 'existing' );

  const [ , setLoading ] = React.useState( false );

  // eslint-disable-next-line no-unused-vars
  let isMounted = true;

  React.useEffect( () => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [] );

  // if we are creating a new segment (via node menu option, start on the new tab)
  React.useEffect( () => {
    if ( isEmpty( selectedItem ) || isEmpty( selectedItem.id ) ) {
      setActiveTab( 'new' );
    } else {
      setActiveTab( 'existing' );
    }
  }, [ selectedItem ] );

  // logic for whenever a vuln is added
  const setChangeForNewAddedVuln = async ( vuln, isNew=false ) => {
    /*  eslint-disable camelcase */
    const ruleResponse = await makeRequest( 'FETCH', '/rule', { ids: [ vuln.id ], key_field: 'vulnerability_id' } );
    const _escalation = { id: uuidv4(), vulnerability_id: vuln.id };
    /* eslint-enable camelcase */

    if( ruleResponse && ruleResponse.results )  {
      // eslint-disable-next-line
      const rule = ruleResponse.results[0];

      if ( isNotEmpty( rule ) ) {
        Object.entries( ESCALATION_DEFAULTS ).map( ( [ attr, defaultValue ] ) => {
          _escalation[attr] = rule[attr] || defaultValue;
        } );
      } else {
        Object.entries( ESCALATION_DEFAULTS ).map( ( [ attr, defaultValue ] ) => {
          _escalation[attr] = defaultValue;
        } );
      }
    }

    // if this was added, then deleted, and now added again, need to check for that case
    const _deleted = { ...changes.deleted };

    if ( isNotEmpty( _deleted ) && isNotEmpty( _deleted[_escalation.id] ) ) {
      delete _deleted[_escalation.id];
    }

    const newEscalation = {
      isAdded: true,
      isNew,
      vulnerabilityData: vuln,
      escalationData: _escalation,
    };

    setChanges( {
      ...changes,
      added: {
        [_escalation.id]: newEscalation,
        ...changes.added,
      },
      deleted: {
        ..._deleted,
      },
    } );

    setVulnNotIDs( [ ...vulnNotIDs, vuln.id ] );

    setActiveTab( 'existing' );
    selectEscalationForEdit( newEscalation );
  };

  // saves a newly created vuln on tab 2
  const createVulnerability = async( ) => {
    if ( isNotEmpty( updatedVulnerability ) ) {
      setIsSaving( true );
      const formValues = getFieldValues( updatedVulnerability.fieldStates, 'new_vulnerability' );

      const newVuln = {};

      Object.entries( formValues ).map( ( [ attr, val ] ) => {
        if ( attr === 'identifier' ) {
          newVuln[attr] = `CUSTOM-${val}`;
        } else {
          newVuln[attr] = val;
        }
      } );
      const vulnResponse = await addRecords( 'vulnerability', [ newVuln ] );

      if ( vulnResponse.errors ) {
        vulnResponse.errors.map( e => {
          addFlashMessage( {
            type: 'alert',
            body: e,
          } );
        } );
      } else if ( isNotEmpty( vulnResponse ) ) {
        // eslint-disable-next-line
        const vuln = vulnResponse[0];

        setChangeForNewAddedVuln( vuln, true );

      } else {
        addFlashMessage( {
          type: 'alert',
          body: 'Error saving new vulnerability',
        } );
      }
    } else {
      addFlashMessage( {
        type: 'alert',
        body: 'Error saving new vulnerability',
      } );
    }
    setIsSaving( false );
  };

  // adds an existing vuln and associated escalation
  const selectExistingVulnerability = async( existing ) => {
    if ( isNotEmpty( existing ) ) {
      const vuln = await getRecord( 'vulnerability', existing.id );
      setChangeForNewAddedVuln( vuln );
    }
  };

  // the final save that applies all of the additions, changes, and deletions
  const saveChanges = async () => {

    setIsSaving( true );

    const vulnChanges = {};
    const escalationChanges = {};

    let escalationDeletions = [];

    const errors = [];

    const changeCounts = {
      vulnUpdates: 0,
      escalationUpdates: 0,
      additions: 0,
      removals: 0,
    };

    const addedEscalations = [];

    const confirmMessageSections = [];

    const isEdit = isNotEmpty( selectedItem.id );
    // if there is no id, this is a new edge being created (via the node menu)
    let segment = { ...selectedItem };

    if ( isEmpty( segment.id ) ) {
      const segmentResponse = await addRecords( 'edge', [ segment ] );

      if ( segmentResponse.errors ) {
        segmentResponse.errors.map( error => errors.push( error ) );
      } else {
        // eslint-disable-next-line
        segment = segmentResponse[0];
      }
    }

    // need to associate it with the correct destination host
    const destScopeID = segment.to_scope || nodes[segment.to_node].scope_id;
    let hostID = null;
    // eslint-disable-next-line camelcase
    const scope = await getRecord( 'scope', destScopeID, { extra_columns: [ 'type' ] } );

    if ( scope.type === 'host' ) {
      hostID = destScopeID;
    } else {
      const ancestors = await getRecords( 'scope', {
        // eslint-disable-next-line camelcase
        id_list: scope.ancestors,
        // eslint-disable-next-line camelcase
        extra_columns: [ 'type' ],
      } );

      ancestors.map( a => {
        if ( a.type === 'host' ) {
          hostID = a.id;
        }
      } );
    }

    // loop through each of the additions that happened
    if ( isNotEmpty( changes.added ) ) {
      Object.entries( changes.added ).map( ( [ eID, escalation ] ) => {
        const _vulnChanges = { id: escalation.vulnerabilityData.id };
        // eslint-disable-next-line camelcase
        const _escalationChanges = { id: escalation.escalationData.id, edge_id: segment.id };

        // if it was deleted, don't add it
        if ( isEmpty( changes.deleted ) || isEmpty( changes.deleted[eID] ) ) {

          changeCounts.additions += 1;

          // add the new escalations for creation
          addedEscalations.push( {
            ...escalation.escalationData,
            // eslint-disable-next-line camelcase
            edge_id: segment.id,
            // eslint-disable-next-line camelcase
            vulnerability_id: escalation.vulnerabilityData.id,
          } );

          // it was also updated, use that data
          if ( isNotEmpty( changes.updated ) && isNotEmpty( changes.updated[eID] ) ) {
            const updatedEscalation = changes.updated[eID];

            // check for any vuln changes
            if ( isNotEmpty( updatedEscalation.vulnerabilityData.changes ) ) {

              changeCounts.vulnUpdates += 1;
              Object.entries( updatedEscalation.vulnerabilityData.changes ).map( ( [ attribute, change ] ) => {
                _vulnChanges[attribute] = change.updatedValue;
              } );

            }

            vulnChanges[escalation.vulnerabilityData.id] = _vulnChanges;

            // check for escalation changes
            if ( isNotEmpty( updatedEscalation.escalationData.changes ) ) {

              changeCounts.escalationUpdates += 1;
              Object.entries( updatedEscalation.escalationData.changes ).map( ( [ attribute, change ] ) => {
                _escalationChanges[attribute] = change.updatedValue;
              } );

            }
            escalationChanges[escalation.escalationData.id] = _escalationChanges;
          // it was not updated, need to add all of the escalation changes,
          // there where no vuln changes since it was created
          } else {
            vulnChanges[escalation.vulnerabilityData.id] = _vulnChanges;

            const _changes = { id: escalation.escalationData.id };

            Object.keys( ESCALATION_DEFAULTS ).map( attribute => {
              _changes[attribute] = escalation.escalationData[attribute];
            } );

            escalationChanges[escalation.escalationData.id] = _escalationChanges;
          }
        }
      } );
    }

    // loop through each of the updates that happened
    if ( isNotEmpty( changes.updated ) ) {
      Object.entries( changes.updated ).map( ( [ eID, escalation ] ) => {

        const _vulnChanges = { id: escalation.vulnerabilityData.id };
        // eslint-disable-next-line camelcase
        const _escalationChanges = { id: escalation.escalationData.id, edge_id: segment.id };

        // if it was deleted, don't add
        if ( isEmpty( changes.deleted ) || isEmpty( changes.deleted[eID] ) ) {
          // check if this change was already taken care of in previous check
          if ( isEmpty( vulnChanges[escalation.vulnerabilityData.id] ) ) {
            // see if there are any changes at all
            if ( isNotEmpty( escalation.vulnerabilityData.changes ) ) {
              Object.entries( escalation.vulnerabilityData.changes ).map( ( [ attribute, change ] ) => {
                _vulnChanges[attribute] = change.updatedValue;
              } );
            }

            changeCounts.vulnUpdates += 1;

            vulnChanges[escalation.vulnerabilityData.id] = _vulnChanges;
          }

          // check if this change was already taken care of in previous check
          if ( isEmpty( escalationChanges[escalation.escalationData.id] ) ) {
            // see if there are any changes at all
            if ( isNotEmpty( escalation.escalationData.changes ) ) {

              changeCounts.escalationUpdates += 1;

              Object.entries( escalation.escalationData.changes ).map( ( [ attribute, change ] ) => {
                _escalationChanges[attribute] = change.updatedValue;
              } );
            }
            escalationChanges[escalation.escalationData.id] = _escalationChanges;
          }
        }
      } );
    }

    // loop through any deleted escalations
    if ( isNotEmpty( changes.deleted ) ) {
      changeCounts.removals += Object.keys( changes.deleted ).length;
      escalationDeletions = Object.keys( changes.deleted );
    }
    // now just add the escalation ids and the vuln ids to the objects of anything that was not changed
    if ( isNotEmpty( availableEscalations ) ) {
      const vulnKeys = [];
      const escalationKeys = [];

      Object.values( availableEscalations ).map( data => {
        // eslint-disable-next-line max-len
        if ( isEmpty( vulnChanges[data.vulnerabilityData.id] ) && !escalationDeletions.includes( data.escalationData.id ) ) {
          vulnKeys.push( data.vulnerabilityData.id );
        }
        // eslint-disable-next-line max-len
        if ( isEmpty( escalationChanges[data.escalationData.id] ) && !escalationDeletions.includes( data.escalationData.id ) ) {
          escalationKeys.push( data.escalationData.id );
        }
      } );

      vulnKeys.map( id => {
        vulnChanges[id] = { id };
      } );
      escalationKeys.map( id => {
        // eslint-disable-next-line camelcase
        escalationChanges[id] = { id, edge_id: selectedItem.id };
      } );
    }

    // now that the changes and deletions for vulnerabilities and escalations are all stored,
    // get them ready for the backend
    if (
      changeCounts.vulnUpdates > 0
      || changeCounts.escalationUpdates > 0
      || changeCounts.additions > 0
      || changeCounts.removals > 0
    ) {

      Object.entries( changeCounts ).map( ( [ changeType, count ] ) => {
        if ( count > 0 ) {
          if ( changeType === 'additions' ) {
            confirmMessageSections.push( `add ${count} escalation(s)` );
          }
          if ( changeType === 'vulnUpdates' ) {
            confirmMessageSections.push( `update ${count} vulnerabilities` );
          }
          if ( changeType === 'escalationUpdates' ) {
            confirmMessageSections.push( `update ${count} escalation(s)` );
          }
          if ( changeType === 'removals' ) {
            confirmMessageSections.push( `remove ${count} escalation(s)` );
          }
        }
      } );

      const message = `You are about to ${confirmMessageSections.join( ', ' )}. Are you sure?`;

      if ( confirm( message ) ) {

        setLoading( true );

        // first create any new escalations
        if ( isNotEmpty( addedEscalations ) ) {

          const escalations = [];

          addedEscalations.map( e => {
            const escalation = { ...e };
            delete escalation.id;

            escalations.push( escalation );
          } );

          const newEscalationsResponse = await addRecords( 'escalation', escalations );

          if ( isNotEmpty( newEscalationsResponse ) ) {
            // errors
            if ( newEscalationsResponse?.errors ) {
              newEscalationsResponse.errors.map( e => {
                errors.push( e );
              } );
            // success, need to updated the details based on the response
            } else if ( isNotEmpty( newEscalationsResponse ) && isNotEmpty( hostID ) ) {
              const escalationDetails = [];

              newEscalationsResponse.map( e => {
                // eslint-disable-next-line camelcase
                escalationDetails.push( { escalation_id: e.id, host_id: hostID } );
              } );

              const detailsResponse = await addRecords( 'escalation_details', escalationDetails );
              if ( detailsResponse?.errors ) {
                detailsResponse.errors.map( e => {
                  errors.push( e );
                } );
              }
            }
          // likley 500
          } else {
            errors.push( 'There was an error saving changes to escaltions and escaltion details, please try again' );
          }
        }

        // next, deletions
        if ( isNotEmpty( escalationDeletions ) ) {
          const deleteRequest = await deleteRecords( 'escalation', escalationDeletions );

          if ( isNotEmpty( deleteRequest ) ) {
            // errors
            if ( deleteRequest?.errors ) {
              deleteRequest?.errors.map( e => {
                errors.push( e );
              } );
            }
          // likley 500
          } else {
            errors.push( 'There was an error removing escalations, please try again' );
          }
        }

        // only need updates when editing
        if ( isEdit ) {
          // next vulnerabilities
          if ( isNotEmpty( vulnChanges ) ) {
            const changesForSubmit = Object.values( vulnChanges );
            const vulnRequest = await updateRecords( 'vulnerability', changesForSubmit );

            if ( isNotEmpty( vulnRequest ) ) {
              // errors
              if ( vulnRequest?.errors ) {
                vulnRequest.errors.map( e => {
                  errors.push( e );
                } );
              }
            // likley 500
            } else {
              errors.push( 'There was an error saving vulnerability changes, please try again' );
            }
          }
          // last escalations
          if ( isNotEmpty( escalationChanges ) ) {
            const changesForSubmit = Object.values( escalationChanges );
            const escalationRequest = await updateRecords( 'escalation', changesForSubmit );

            if ( isNotEmpty( escalationRequest ) ) {
              // errors
              if ( escalationRequest?.errors ) {
                escalationRequest.errors.map( e => {
                  errors.push( e );
                } );
              }
            // likley 500
            } else {
              errors.push( 'There was an error updating existing escalations, please try again' );
            }
          }
        // need to select the newly created edge so it appears in the graph
        } else {
          selectExploreItems( 'edge', [ segment ] );
        }

        setLoading( false );

        if ( isEmpty( errors ) ) {
          setShow( false );
          const _selectedItem = { ...selectedItem };

          setSelectedItem( null );
          setSelectedItem( _selectedItem );
          addFlashMessage( {
            type: 'success',
            body: 'Successfully changed all vulnerabilities',
          } );
        } else {
          addFlashMessage( {
            type: 'alert',
            body:  <React.Fragment>
              <span>The Following error(s) occured while trying to save your changes:</span>
              { errors.map( ( e, i ) => <p key={i} >{ e }</p> ) }
            </React.Fragment>,
          } );
        }
      }
    } else {
      setShow( false );
      addFlashMessage( {
        type: 'info',
        body: 'There were no changes made',
      } );
    }
    setVulnNotIDs( [] );
    setIsSaving( false );
    console.log( 'reloading the graph' );
    fetchAndRedrawGraph( true );
  };

  // used to switch to the add new vuln tab (tab 2) special logic to prevent
  // changes from being overwritten
  const addNewVuln = () => {
    setUpdatedEscalation( null );
    setSelectedEscalation( null );
    setActiveTab( 'new' );
  };

  // called within the toggleSelection, declarative so that I have more control over the state
  const selectEscalationForEdit = ( _escalation ) => {
    if ( isNotEmpty( _escalation ) ) {
      const _fields = { ...EMPTY_FIELDS };

      const allEscalationFields = [ ..._fields.exploitLeft.fields, ..._fields.exploitRight.fields ];

      // start with a clean slate everytime
      _fields.vulnerability.fields = _fields.vulnerability.fields.filter( f => f.attribute !== 'effort' );
      allEscalationFields.map( f => {
        delete f.originalValueOverride;
      } );
      _fields.vulnerability.fields.map( f => {
        delete f.originalValueOverride;
      } );

      const remediationInput = {
        type: 'select',
        attribute: 'effort',
        label: 'Remediation Effort',
        help: <HelpTrigger helpKey="remediation_effort" />,
        defaultValue: 'minimal',
        options: {
          minimal: 'Minimal',
          low: 'Low',
          moderate: 'Moderate',
          high: 'High',
          nofix: 'N/A',
        },
      };

      // users are allowed to edit the remediation effort of newly created vulns only
      if ( _escalation.isNew ) {
        _fields.vulnerability.fields.push( remediationInput );
      }

      // override the original values for each field if needed so that the form correctly
      // shows that a field has been changed if a user is re-visiting a previously edited value
      if ( isNotEmpty( _escalation.vulnerabilityData.changes ) ) {
        Object.entries( _escalation.vulnerabilityData.changes ).map( ( [ attr, change ] ) => {
          const _field = _fields.vulnerability.fields.find( f => f.attribute === attr );
          _field.originalValueOverride = change.originalValue || '';
        } );
      }
      if ( isNotEmpty( _escalation.escalationData.changes ) ) {
        Object.entries( _escalation.escalationData.changes ).map( ( [ attr, change ] ) => {
          const _field = allEscalationFields.find( f => f.attribute === attr );
          _field.originalValueOverride = change.originalValue || '';
        } );
      }

      setEscalationFields( _fields );
      setSelectedEscalation( _escalation );
    } else {
      setEscalationFields( null );
      setSelectedEscalation( {} );
    }
  };

  // set the appropriate modal action for the button depending on what screen the user is on
  React.useEffect( () => {
    if ( activeTab === 'existing' ) {
      setModalActionButton(
        <button
          onClick={ saveChanges }
        >
          Save all Changes
        </button>,
      );
    } else if ( newVulnChoice === 'new' ) {
      setModalActionButton(
        <button
          className={ `${vulnerabilityIsValid ? '' : 'disabled'}`}
          onClick={ () => createVulnerability( updatedVulnerability ) }
          disabled={ !vulnerabilityIsValid }
        >
            Create New Vulnerability
        </button>,
      );
    } else {
      setModalActionButton(
        <button
          className={ `${isEmpty( selectedResult ) ? 'disabled' : ''}` }
          disabled={ isEmpty( selectedResult ) }
          onClick={ () => selectExistingVulnerability( selectedResult ) }
        >
          Add Vulnerability
        </button>,
      );
    }

  }, [
    newVulnChoice,
    updatedVulnerability,
    selectedResult,
    vulnerabilityIsValid,
    activeTab,
    changes,
    availableEscalations,
    selectedItem,
  ] );

  return (
    <React.Fragment>
      <div
        // eslint-disable-next-line max-len
        className={ `setupTabsContainer tabCount--2 ${ isNotEmpty( activeTab ) ? `selectedTabIndex--${activeTab === 'existing' ? 0 : 1 }`: 'noSelectedTab' }`}
      >
        <div
          className={`setupTab ${activeTab === 'existing' ? 'isCurrent' : ''}`}
          onClick={ () => setActiveTab( 'existing' )}
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_1" />
              <label>Edit Existing Vulnerabilities</label>
            </span>
          </div>
        </div>
        <div
          className={`setupTab ${activeTab === 'new' ? 'isCurrent' : ''}`}
          onClick={ addNewVuln }
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_2" />
              <label>Add Vulnerability</label>
            </span>
          </div>

        </div>
      </div>
      <div className={`tabWrapper ${activeTab}`}>
        {
          isSaving &&
          <div className="loadingWrapperPlaceholder">
            <Loading text="Saving Changes..." />
          </div>
        }
        {
          activeTab === 'existing' &&
          <ExistingTab
            loadingEscalations={loadingEscalations}
            changes={changes}
            setChanges={setChanges}
            selectedEscalation={selectedEscalation}
            setSelectedEscalation={setSelectedEscalation}
            setVulnNotIDs={setVulnNotIDs}
            vulnNotIDs={vulnNotIDs}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
            availableEscalations={availableEscalations}
            setActiveTab={setActiveTab}
            escalations={escalations}
            updatedEscalation={updatedEscalation}
            setUpdatedEscalation={setUpdatedEscalation}
            escalationFields={escalationFields}
            setEscalationFields={setEscalationFields}
            addNewVuln={addNewVuln}
            selectEscalationForEdit={selectEscalationForEdit}
          />
        }
        {
          activeTab === 'new' &&
          <NewTab
            newVulnChoice={newVulnChoice}
            setNewVulnChoice={setNewVulnChoice}
            vulnerabilityFields={vulnerabilityFields}
            setVulnerabilityFields={setVulnerabilityFields}
            updatedVulnerability={updatedVulnerability}
            setUpdatedVulnerability={setUpdatedVulnerability}
            vulnerabilityIsValid={vulnerabilityIsValid}
            setVulnerabilityIsValid={setVulnerabilityIsValid}
            vulnNotIDs={vulnNotIDs}
            selectedResult={selectedResult}
            setSelectedResult={setSelectedResult}
          />
        }
      </div>
    </React.Fragment>
  );
};

const ModalAction = ( { modalActionButton } ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( modalActionButton )
          ? modalActionButton
          : <button
            disabled
            className="disabled placeholderAction"
          >
            Save
          </button>
      }
    </React.Fragment>
  );
};

const EditSegment = ( {
  selectedItem,
  setSelectedItem,
  selectedItemType,
  show,
  setShow,
  fetchAndRedrawGraph,
  nodes,
} ) => {

  const [ item, setItem ] = React.useState( null );
  const [ selectedEscalation, setSelectedEscalation ] = React.useState( {} );

  const [ loadingEscalations, setLoadingEscalations ] = React.useState( false );
  // all escalations
  const [ escalations, setEscalations ] = React.useState( {} );
  // only ones that are available (have not been deleted)
  const [ availableEscalations, setAvailableEscalations ] = React.useState( {} );

  // keeping track for step 2 so we dont return ones we already have
  const [ vulnNotIDs, setVulnNotIDs ] = React.useState( [] );
  const [ modalActionButton, setModalActionButton ] = React.useState( null );

  const [ isSaving, setIsSaving ] = React.useState( false );

  const [ changes, setChanges ] = React.useState( {
    deleted: {},
    updated: {},
    added: {},
  } );

  // NOTE: not currently being used, need to re-implement
  // called on load, fetches all escalations and vulnerabilities associated with this segment
  const setEscalationsAndVulnerabilities = async ( segment ) => {
    const _escalationsFetch = await makeRequest( 'FETCH', '/model/escalation', {
      project: 'default',
      model: 'base',
      ids: [ segment.id ],
      // eslint-disable-next-line camelcase
      key_field: 'edge_id',
    } );

    if ( isNotEmpty( _escalationsFetch ) && isNotEmpty( _escalationsFetch.results ) ) {
      const _escalations = _escalationsFetch.results;

      const vulnIDs = _escalations.map( e => e.vulnerability_id );

      const _vulnerabilitiesFetch = await getRecords( 'vulnerability', {
        // eslint-disable-next-line camelcase
        id_list: vulnIDs,
        rownums: [ 0, vulnIDs.length + 1 ],
        // eslint-disable-next-line camelcase
        extra_columns: [
          'identifier',
          'description',
          'urls',
          'cvss_base_score',
          'public_notes',
          'allows_escalation',
          'effort',
          'incomplete_reason',

          'vulnerability_analysis.model_id',
          'vulnerability_analysis.vulnerability_id',
          'vulnerability_analysis.patches',
          'vulnerability_analysis.hosts',
          'vulnerability_analysis.scan_results',
          'vulnerability_analysis.risk',
          'vulnerability_analysis.risk_percentile',
        ],
      } );

      const _vulnerabilities = {};

      _vulnerabilitiesFetch.map( v => {
        _vulnerabilities[v.id] = v;
      } );

      setVulnNotIDs( vulnIDs );

      const _escalationsWithVulnData = {};

      _escalations.map( e => {
        _escalationsWithVulnData[e.id] = {
          escalationData: e,
          vulnerabilityData: _vulnerabilities[e.vulnerability_id],
        };
      } );

      setEscalations( _escalationsWithVulnData );
    }
  };

  // when a user selects a segment from the graph to edit, this sets up the whole modal,
  // gets vulns and escalations
  React.useEffect( () => {
    if ( isNotEmpty( selectedItem ) && show && ( selectedItemType === 'segment' || selectedItemType === 'edge' ) ) {
      setLoadingEscalations( true );
      setItem( selectedItemType );
      setShow( true );
      setChanges( {
        added: {},
        updated: {},
        deleted: {},
      } );

      // creating a new segment, or editing an existing
      // creating new
      if ( isEmpty( selectedItem ) || isEmpty( selectedItem.id ) ) {
        setEscalations( {} );
      // editing existing
      } else {
        setEscalationsAndVulnerabilities( selectedItem );
      }
    } else {
      setLoadingEscalations( false );
      // setShow(false);
      setItem( null );
      setEscalations( {} );
      setSelectedEscalation( {} );
    }
  }, [ selectedItem, show, selectedItemType ] );

  // when the modal closes, need to clear out some things
  React.useEffect( ( ) => {
    if ( show === false ) {
      setVulnNotIDs( [] );
    }
  }, [ show ] );

  // any time changes are made, update the list of escalations including vulnerability data
  React.useEffect( () => {
    var options = {};

    // add any added escalations to the list
    if ( isNotEmpty( changes.added ) ) {
      Object.entries( changes.added ).map( ( [ key, value ] ) => {
        // check to see if this vuln was deleted
        if ( isNotEmpty( changes.deleted ) && isNotEmpty( changes.deleted[key] ) ) {
          console.log( 'was deleted' );
        } else if ( isNotEmpty( changes.updated ) && isNotEmpty( changes.updated[key] ) ) {
          options[key] = changes.updated[key];
        } else {
          options[key] = value;
        }
      } );
    }

    if ( isNotEmpty( escalations ) ) {
      const sorted = Object.values( escalations ).sort( ( a, b ) => {
        const aid = a.vulnerabilityData ? a.vulnerabilityData.identifier : '';
        const bid = b.vulnerabilityData ? b.vulnerabilityData.identifier : '';
        if ( aid > bid ) {
          return 1;
        }
        if ( bid > aid ) {
          return -1;
        }
        return 0;
      } );

      sorted.map( e => {
        // There are deleted items
        if ( isNotEmpty( changes.deleted ) ) {
          // it was not deleted
          if ( !Object.keys( changes.deleted ).includes( e.escalationData.id ) ) {

            // there are updates
            if ( isNotEmpty( changes.updated ) ) {
              // it was not updated
              if ( !Object.keys( changes.updated ).includes( e.escalationData.id ) ) {
                options[e.escalationData.id] = e;
              // it was updated
              } else {
                options[e.escalationData.id] = changes.updated[e.escalationData.id];
              }
            // there are no updates
            } else {
              options[e.escalationData.id] = e;
            }
          }
        // there are no deleted items
        } else if ( isNotEmpty( changes.updated ) ) {
          // it was not updated
          if ( !Object.keys( changes.updated ).includes( e.escalationData.id ) ) {
            options[e.escalationData.id] = e;
          // it was updated
          } else {
            options[e.escalationData.id] = changes.updated[e.escalationData.id];
          }
        // there are no updates
        } else {
          options[e.escalationData.id] = e;
        }
      } );
    }

    setLoadingEscalations( false );
    setAvailableEscalations( options );
  }, [ changes, escalations ] );

  return (
    <React.Fragment>
      <Modal
        elementClass="exploreModal segment"
        visible={show}
        setVisible={setShow}
        header={'Edit Segment'}
        size="large"
        body={  <ModalBody
          item={item}
          availableEscalations={availableEscalations}
          setAvailableEscalations={setAvailableEscalations}
          selectedEscalation={selectedEscalation}
          setSelectedEscalation={setSelectedEscalation}
          vulnNotIDs={vulnNotIDs}
          setVulnNotIDs={setVulnNotIDs}
          setModalActionButton={setModalActionButton}
          changes={changes}
          setChanges={setChanges}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setShow={setShow}
          loadingEscalations={loadingEscalations}
          setLoadingEscalations={setLoadingEscalations}
          escalations={escalations}
          fetchAndRedrawGraph={fetchAndRedrawGraph}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          nodes={nodes}
        />
        }
        action={ <ModalAction modalActionButton={modalActionButton} setIsSaving={setIsSaving} />}
      />
    </React.Fragment>
  );
};

export default EditSegment;