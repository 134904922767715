/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { decodeURLHash, encodeURLHash, isNotEmpty } from '../../../shared/Utilities';

import './style.scss';
import Tabs from './Tabs';
import Body from './Body';

const MainPanel = ( {
  record,
  recordType,
  riskType,
  isCollapsed,
  currentScannerSignature,
  setCurrentScannerSignature,
  thirdPartyData,
  setThirdPartyData,
  recordInstanceData,
  relatedPaths,
  setRelatedPaths,
  pathCount,
  setPathCount,
  handlePathCountChange,
  latestThirdParty,
  getScannerSignatureInfoAndSetAsCurrent,
  thirdPartySettings,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,

  // path recordType vars
  pathSensitiveAsset,
  pathTopVulnerabilities,
  loading,
  pathFormattedEdges,
} ) => {

  const [ currentTab, setCurrentTab ] = React.useState( 'risk_and_remediation' );

  React.useEffect( () => {
    const hash = decodeURLHash();

    if ( isNotEmpty( record ) && isNotEmpty( recordType ) ) {
      if ( recordType === 'host' && !record.has_host ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { panel_tab: 'third_party_scanning' } );
        setCurrentTab( 'third_party_scanning' );
      } else if ( isNotEmpty( hash ) && isNotEmpty( hash.panel_tab ) ) {
        setCurrentTab( hash.panel_tab );
      } else if ( recordType === 'path' ) {
        // eslint-disable-next-line camelcase
        encodeURLHash( { panel_tab: 'path_analysis' } );
        setCurrentTab( 'path_analysis' );
      } else {
        // eslint-disable-next-line camelcase
        encodeURLHash( { panel_tab: 'risk_and_remediation' } );
        setCurrentTab( 'risk_and_remediation' );
      }
    }
  }, [ record, recordType ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) ) &&
        <div className={ `recordDetailsMainPanel ${recordType} ${isCollapsed() ? 'isCollapsed' : ''}`}>
          <Tabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            record={record}
            recordType={recordType}
            isCollapsed={isCollapsed}
            riskType={riskType}
            currentScannerSignature={currentScannerSignature}
            setCurrentScannerSignature={setCurrentScannerSignature}
            latestThirdParty={latestThirdParty}
            thirdPartyData={thirdPartyData}
            setThirdPartyData={setThirdPartyData}
          />
          <Body
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            record={record}
            recordType={recordType}
            isCollapsed={isCollapsed}
            riskType={riskType}
            recordInstanceData={recordInstanceData}
            currentScannerSignature={currentScannerSignature}
            setCurrentScannerSignature={setCurrentScannerSignature}
            thirdPartyData={thirdPartyData}
            setThirdPartyData={setThirdPartyData}
            relatedPaths={relatedPaths}
            setRelatedPaths={setRelatedPaths}
            pathCount={pathCount}
            setPathCount={setPathCount}
            handlePathCountChange={handlePathCountChange}
            latestThirdParty={latestThirdParty}
            getScannerSignatureInfoAndSetAsCurrent={getScannerSignatureInfoAndSetAsCurrent}
            thirdPartySettings={thirdPartySettings}

            // recordCard variables
            recordCardRecord={recordCardRecord}
            setRecordCardRecord={setRecordCardRecord}
            recordCardType={recordCardType}
            setRecordCardType={setRecordCardType}
            showRecordCard={showRecordCard}
            setShowRecordCard={setShowRecordCard}

            // path recordType vars
            pathSensitiveAsset={pathSensitiveAsset}
            pathTopVulnerabilities={pathTopVulnerabilities}
            loading={loading}
            pathFormattedEdges={pathFormattedEdges}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default MainPanel;