/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  isNotEmpty, paramsToFilters,
} from '../../Utilities';

import './Checkbox.scss';

const Checkbox = ( {
  input,
  value,
  handleChange,
} ) => {

  const inputRef = React.useRef( null );

  const [ checkboxValue, setCheckboxValue ] = React.useState( false );

  const applyCheckboxValue = value => {
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      if ( value ) {
        inputRef.current.checked = true;
      } else {
        inputRef.current.checked = false;
      }
    }
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute];
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      applyCheckboxValue( newValue || false );
    }
  };

  React.useEffect( () => {
    applyCheckboxValue( value );
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, inputRef ] );

  const handleCheckboxChange = e => {
    setCheckboxValue( e.target.checked );
    handleChange( input.attribute, e.target.checked );
  };

  React.useEffect( () => {
    setCheckboxValue( value || false );
  }, [ value ] );

  return (
    <label>
      {/* eslint-disable-next-line max-len */}
      <div className={ `checkboxFieldWrapper ${checkboxValue ? 'checked' : ''} ${input.disabled ? 'disabled' : ''} ${input.asToggle ? 'asToggle' : ''}` }>
        <input
          ref={inputRef}
          disabled={input.disabled}
          type="checkbox"
          onChange={ handleCheckboxChange }
          { ...input.htmlProps }
        />
      </div>

      <span className="labelWrapper">
        { input.label }
        { input.helpItem }
      </span>
    </label>
  );
};

export default Checkbox;
