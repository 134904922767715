/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { complianceFrameworkLabelMap, isEmpty, isNotEmpty, percentileToGrade } from '../../../../../shared/Utilities';

const ComplianceGrade = ( { data, settings } ) => {

  const [ average, setAverage ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( data ) && isNotEmpty( data.end ) ) {
      const _groupedComplianceData = {};
      const sortedControls = [];
      data.end.map( point => {
        if ( isNotEmpty( point ) && isNotEmpty( point.regulation ) && isNotEmpty( point.created ) ) {
          // very first one
          if ( isEmpty( _groupedComplianceData[point.created] ) ) {
            _groupedComplianceData[point.created] = { [point.regulation]: { [point.control]: point } };
          // timestamp already exists, first of this regulation
          } else if (
            isNotEmpty( _groupedComplianceData[point.created] )
            && isEmpty( _groupedComplianceData[point.created][point.regulation] )
          ) {
            _groupedComplianceData[point.created][point.regulation] = { [point.control]: point };
          // timestamp and regulation already exist
          } else {
            _groupedComplianceData[point.created][point.regulation][point.control] = point;
          }
        }
      } );

      if ( isNotEmpty( _groupedComplianceData ) ) {
        const mostCurrent = Math.max( ...Object.keys( _groupedComplianceData ) );
        if (
          isNotEmpty( settings )
          && isNotEmpty( settings.regulation )
          && isNotEmpty( mostCurrent )
          && isNotEmpty( _groupedComplianceData[mostCurrent] )
          && isNotEmpty( _groupedComplianceData[mostCurrent][settings.regulation] )
        ) {
          Object.values( _groupedComplianceData[mostCurrent][settings.regulation] ).map( control => {
            const controlWithPercentage = {
              ...control,
              passingPercentage: control.num_passing_hosts + control.num_failing_hosts === 0
                ? 0
                : control.num_passing_hosts / ( control.num_passing_hosts + control.num_failing_hosts ),
            };
            sortedControls.push( controlWithPercentage );
          } );

          const percents = sortedControls.map( control => {
            if ( control.num_passing_hosts + control.num_failing_hosts === 0 ) {
              return 0;
            }
            return control.num_passing_hosts / ( control.num_passing_hosts + control.num_failing_hosts );
          } );

          if ( isNotEmpty( percents ) && isNotEmpty( sortedControls.length ) && sortedControls.length > 0 ) {
            const sumPercents = percents.reduce( ( accum, current ) => accum + current, 0 );
            const averagePercent = sumPercents / sortedControls.length;

            setAverage( averagePercent );
          }
        }
      }
    }
  }, [ data ] );

  const getFillClass = average => {
    if ( average <= 0.25 ) {
      return 'high';
    }
    if ( average <= 0.5 ) {
      return 'low';
    }
    if ( average <= 0.75 ) {
      return 'minimal';
    }
    return 'primaryBlue';
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( average ) &&
        <div className={ `riskGradeWidgetWrapper risk--${getFillClass( average )}` }>
          <div className="riskGradeContentWrapper">
            <div className="scoreCircle">
              <strong className="gradeWrapper">{ percentileToGrade( average ) }</strong>
            </div>
            <span className="chartLabel">
              <strong>{ complianceFrameworkLabelMap[settings?.regulation]}: </strong> Compliance Grade
            </span>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ComplianceGrade;