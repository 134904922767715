/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  isEmpty,
  isNotEmpty,
  itemIsArray,
  riskToRating,
  vulnScannerNameMap,
  vulnerabilityScannerInstanceName,
  vulnerabilityScannerLogo,
} from '../../../shared/Utilities';
import RatingBadge from '../../../shared/RatingBadge';
import EmptyState from '../../../shared/EmptyState';
import InlineSVG from '../../../shared/InlineSVG';

// eslint-disable-next-line max-len
const noEvidence = evidence => isEmpty( evidence.evidence ) || evidence.evidence === '' || isEmpty( evidence.evidence?.trim() );

import './EvidenceItem.scss';
import ExploitStatus from '../../../shared/ExploitStatus';

const EvidenceItem = ( { evidence, record, recordType, thirdPartySettings } ) => {

  const [ collapsed, setCollapsed ] = React.useState( true );
  const [ evidenceSource, setEvidenceSource ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( evidence ) ) {
      if ( noEvidence( evidence ) ) {
        setCollapsed( true );
      } else {
        setCollapsed( false );
      }

      if ( isNotEmpty( evidence.third_party_settings ) && isNotEmpty( thirdPartySettings ) ) {
        const [ settingID ] = evidence.third_party_settings;

        const _evidenceSource = thirdPartySettings[settingID];

        if ( isNotEmpty( _evidenceSource ) ) {
          setEvidenceSource( _evidenceSource );
        }
      }
    }

  }, [ evidence, thirdPartySettings ] );

  const headerForEvidenceAndRecordType = ( evidence, recordType ) => {

    const riskClass = riskToRating( parseFloat( evidence.filtered_risk ) );

    if ( recordType === 'vulnerability' ) {
      return (
        <React.Fragment>
          <div className="headerLeft">
            <div className={ `recordIconWrapper ${riskClass}` }>
              <InlineSVG type="host_record" />
            </div>
            { evidence.local_name }
          </div>
          <div className="headerRight">
            <RatingBadge rating={riskClass} />
            <span className="carretWrapper">
              <InlineSVG type="carretUp"/>
            </span>
          </div>
        </React.Fragment>
      );
    }

    if ( recordType === 'patch' ) {
      return  <React.Fragment>
      </React.Fragment>;
    }

    if ( recordType === 'host' ) {
      return (
        <React.Fragment>
          <div className="headerLeft">
            <div className={ `recordIconWrapper ${riskClass}` }>
              <InlineSVG type="host_record" />
            </div>
            { evidence.identifier }
          </div>
          <div className="headerRight">
            <RatingBadge rating={riskClass} />
            <span className="carretWrapper">
              <InlineSVG type="carretUp"/>
            </span>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="headerLeft">
          Evidence
        </div>
        <div className="headerRight">
          <span className="carretWrapper">
            <InlineSVG type="carretUp"/>
          </span>
        </div>
      </React.Fragment>
    );
  };

  const scoreClass = score => {
    if ( score >= 9 ) {
      return 'critical';
    } else if ( score >= 7.5 ) {
      return 'high';
    } else if ( score >= 6 ) {
      return 'moderate';
    } else if ( score >= 3 ) {
      return 'low';
    }
    return 'minimal';
  };

  const contentForEvidenceAndRecordType = ( evidence, recordType ) => {
    if ( recordType === 'vulnerability' ) {
      return <div className="recordDetails__InformationPanel__Body_Section">
        <div className="bodySectionInformationWrapper">
          <div className="bodySectionItem inline">
            <label>
              <span>IP Address(es): </span>
            </label>
            {
              ( isNotEmpty( evidence.ip_addresses ) && itemIsArray( evidence.ip_addresses ) ) &&
              <code>{ evidence.ip_addresses.join( ', ' ) }</code>
            }
          </div>
          <div className="bodySectionItem inline">
            <ExploitStatus status={ evidence.exploit_status } alignment="horizontal" />
          </div>
        </div>
      </div>;
    }
    if ( recordType === 'host' ) {
      return <div className="recordDetails__InformationPanel__Body_Section">
        { evidence.description }
        <div className="bodySectionInformationWrapper">
          <div className="bodySectionItem inline">
            <label>
              <span>CVSS Score: </span>
            </label>
            <strong className={ scoreClass( evidence.cvss_base_score )}>
              { evidence.cvss_base_score }
            </strong>
          </div>
          <div className="bodySectionItem inline">
            <ExploitStatus status={ evidence.exploit_status } alignment="horizontal" />
          </div>
        </div>
      </div>;
    }
    return <React.Fragment>
    </React.Fragment>;
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( evidence ) && isNotEmpty( record ) && isNotEmpty( recordType ) ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `collapsibleSectionWrapper ${collapsed ? 'collapsed' : '' } vulnerabilityEvidenceCollapsibleSection` }
        >
          <div
            className="collapsibleSectionHeader"
            onClick={ () => setCollapsed( !collapsed ) }
          >
            { headerForEvidenceAndRecordType( evidence, recordType ) }
          </div>
          <div className="collapsibleSectionBody">
            { contentForEvidenceAndRecordType( evidence, recordType ) }
            {
              isNotEmpty( evidenceSource ) &&
              <div className="sourceLabel">
                <label>Source: </label>
                { vulnerabilityScannerLogo( evidenceSource.tool ) }
                <span>{ vulnScannerNameMap[evidenceSource.tool] }</span>
                <span>({ vulnerabilityScannerInstanceName( evidenceSource ) })</span>
              </div>
            }
            {
              (
                isNotEmpty( evidence.evidence )
                && isNotEmpty( evidence.evidence.trim() )
              )
                ? <pre>
                  { evidence.evidence.trim() }
                </pre>
                : <EmptyState message="No evidence provided" />
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default EvidenceItem;