/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { getSubnets } from '../../../shared/SetupComponents/shared';

const CliqueItem = ( { clique, recordData } ) => {

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2>{ clique.label || 'No Label' }</h2>
        </div>
      </section>
      <section>
        <strong className="sectionLabel">
          Subnets
        </strong>
        <span className="sectionDetails" >
          { getSubnets( clique, recordData ) }
        </span>
      </section>
    </React.Fragment>
  );
};

export default CliqueItem;