/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';

import './SpecificRecord.scss';
import RecordCard from '../../../../RecordDetails/RecordCard';

const SpecificRecord = ( {
  settings,
  data,
} ) => {

  const [ record, setRecord ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      if ( isNotEmpty( settings ) && settings.report_type === 'host' ) {
        setRecord( { type: 'host', ...data } );
      } else {
        setRecord( data );
      }

    }

  }, [ settings, data ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( record ) ) &&
        <RecordCard
          record={record}
          type={ settings?.report_type === 'host' ? 'scope' : settings?.report_type }
          options={ {
            // eslint-disable-next-line camelcase
            include_risk:  settings?.include_risk,
            // eslint-disable-next-line camelcase
            include_vulnerability_instances: settings?.include_instances,
            // eslint-disable-next-line camelcase
            include_cvss_breakdown: settings?.include_cvss,
          } }
          show={true}
          context="dashboard"
        />
      }
    </React.Fragment>
  );
};

export default SpecificRecord;