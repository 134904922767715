/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { isNotEmpty } from '../Utilities';

const PageAlerts = ( { children, elementClass } ) => {

  const [ root, setRoot ] = React.useState( null );

  let timeout = null;

  const findRoot = () => {
    const _root = document.getElementById( 'pageAlertsRoot' );
    if ( isNotEmpty( _root ) ) {
      setRoot( _root );
    } else {
      timeout = setTimeout( () => {
        findRoot();
      }, 100 );
    }
  };

  React.useEffect( () => {
    findRoot();

    return () => clearTimeout( timeout );
  }, [] );

  if ( isNotEmpty( root ) ) {
    return ReactDOM.createPortal(
      <div id="pageAlertsContainer" className={ elementClass } >
        { children }
      </div>,
      root,
    );
  }
  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default PageAlerts;