/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import UserDetails from '../../../../UserDetails';
import InlineSVG from '../../../../InlineSVG';
import { userDisplayName } from '../../../../Utilities';

const RecipientItem = ( { recipient, toggleRecipient, isSelected=false } ) => {
  return (
    <li
      className={ `recipientItem ${isSelected ? 'isSelected' : ''}` }
    >
      <UserDetails user={recipient} elementClass="small" />
      <h3>{ userDisplayName( recipient ) }</h3>
      <button
        className="toggleButton"
        onClick={ () => toggleRecipient( recipient ) }
      >
        {
          isSelected
            ? <InlineSVG type="remove" />
            : <InlineSVG type="add" />
        }
      </button>
    </li>
  );
};

export default RecipientItem;