/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import InlineSVG from '../../../../InlineSVG';


const SelectedItemWrapper = ( {
  id,
  order,
  items,
  setItems,
  field,
  index,
} ) => {

  // removes item from group
  const removeItem = e => {
    e.preventDefault();
    e.stopPropagation();

    const tmpItems = Object.assign( {}, items );
    delete tmpItems[id];
    setItems( tmpItems );
  };

  return (
    <React.Fragment>
      {
        field.needsDraggable
          ? <Draggable
            draggableId={`record-draggable-${index}`}
            index={index}
            isDragDisabled={field.disabled}
          >
            {
              ( provided, snapshot ) => (
                <li
                  // eslint-disable-next-line max-len
                  className={`selectedItemWrapper ${ field.disabled ? 'disabled' : 'isDraggable' }  ${snapshot.isDragging ? 'isDragging' : ''}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <span>{ order }</span>
                  <span className="itemLabel">{ items[id] }</span>
                  <button
                    className={ `removeButton ${ field.disabled ? 'disabled' : '' }` }
                    disabled={field.disabled}
                    onClick={ removeItem }
                  >
                    <InlineSVG type="remove" />
                  </button>
                  <button className="draggableHandle">
                    {
                      snapshot.isDragging
                        ? <InlineSVG type="draggable" version="primary" />
                        : <InlineSVG type="draggable" />
                    }
                  </button>
                </li>
              )
            }
          </Draggable>
          : <li className="selectedItemWrapper">
            <span>{ order }</span>
            { items[id] }
            <button
              onClick={ removeItem }
            >
              <InlineSVG type="remove" />
            </button>
          </li>
      }
    </React.Fragment>
  );
};

export default SelectedItemWrapper;
