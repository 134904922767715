/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import InlineSVG from '../InlineSVG';

import './style.scss';

const DropdownButton = ( { options, trigger } ) => {

  const [ visible, setVisible ] = React.useState( false );

  return (
    <React.Fragment>
      <div
        className={`dropdownButtonShade ${visible ? 'visible' : ''}`}
        onClick={ () => setVisible( false ) }
      ></div>
      <div className="dropdownButtonWrapper" >
        <div
          className="dropdownButtonTrigger"
          onClick={ () => setVisible( true ) }
        >
          <InlineSVG type="new" version="light" />
          { trigger }
        </div>
        <div className={`dropdownButtonMenu ${visible ? 'visible' : ''}`}>
          <ul>
            {
              options.map( ( option, index ) => {
                return <li
                  key={ index }
                  onClick={ () => setVisible( false ) }
                >
                  { option }
                </li>;
              } )
            }
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DropdownButton;
