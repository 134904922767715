/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../legacy/io';
import Area from '../Charts/Area';
import InlineSVG from '../InlineSVG';
import { formatNumber, isNotEmpty, formatUnixDate } from '../Utilities';

import './SetupPageVisualization.scss';

const SetupPageVisualization = ( {
  recordType,
} ) => {

  const [ content, setContent ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ collapsed, setCollapsed ] = React.useState( false );

  const refreshRemediationData = () => {
    setLoading( true );
    makeRequest(
      'FETCH',
      '/project/default/model/base/chart/risk_remediation_history',
      {
        width: window.innerWidth / 2,
        height: 100,
        days: '100000000',
      },
    ).then( response => {
      setLoading( false );
      if (
        isNotEmpty( response )
        && isNotEmpty( response.results ) && isNotEmpty( response.results.data_points )
      ) {
        let max = 0;
        const _data = [];
        response.results.data_points.map( ( point, i ) => {
          let addedHosts = 0;
          let removedHosts = 0;
          if ( isNotEmpty( point.scan_events ) ) {
            point.scan_events.map( e => {
              if ( e.event === 'identified' ) {
                addedHosts += 1;
              }
              if ( e.event === 'expired' ) {
                removedHosts -= 1;
              }
            } );
          }

          if ( point.risk > max ) {
            max = point.risk;
          }

          _data.push( {
            originalIndex: i,
            totalPoints: response.results.data_points.length,
            risk: point.risk,
            timestamp: point.timestamp,
            addedEscalations: isNotEmpty( point.import_events )
              ? point.import_events.added_escalations
              : 0,
            removedEscalations: isNotEmpty( point.import_events )
              ? 0 - point.import_events.removed_escalations
              : 0,
            addedHosts,
            removedHosts,
            original: point,
          } );
        } );

        const _overTimeData = {
          original: response.results.data_points,
          transformed: _data,
          max,
        };

        setContent(
          <div className="riskOverTimeChartWrapper">
            <h3>Risk Remediation Over Time</h3>
            <div className="yAxis">
              <span>{ formatNumber( Math.floor( max ) ) }</span>
              <span>{ formatNumber( Math.floor( max * ( 2 / 3 ) ) ) }</span>
              <span>{ formatNumber( Math.floor( max * ( 1 / 3 ) ) ) }</span>
            </div>
            <Area
              // eslint-disable-next-line camelcase
              settings={ { include_hosts: false, include_escalations: false } }
              data={ _overTimeData }
              loading={ loading }
            />
            <div className="xAxis">
              <span>{ formatUnixDate( _data[1].timestamp ) }</span>
              <span>{ formatUnixDate( _data[ Math.floor( ( _data.length - 1 ) * ( 1 / 5 ) )].timestamp ) }</span>
              <span>{ formatUnixDate( _data[ Math.floor( ( _data.length - 1 ) * ( 2 / 5 ) )].timestamp ) }</span>
              <span>{ formatUnixDate( _data[ Math.floor( ( _data.length - 1 ) * ( 3 / 5 ) )].timestamp ) }</span>
              <span>{ formatUnixDate( _data[ Math.floor( ( _data.length - 1 ) * ( 4 / 5 ) )].timestamp ) }</span>
              <span>{ formatUnixDate( _data[ _data.length - 1 ].timestamp ) }</span>
            </div>
          </div>,

        );
      }
    } );
  };

  React.useEffect( ( ) => {
    if ( isNotEmpty( recordType ) ) {
      if ( recordType === 'remediation_plan' ) {
        // commenting this out for now until we really dial in if we want a visualization or not
        // refreshRemediationData( );
      }
    }
  }, [ recordType ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( content ) &&
        <div className={ `setupPageVisualizationWrapper ${ collapsed ? 'collapsed' : '' } ${recordType}` }>
          { content }
          <button
            className="collapseToggle"
            onClick={ () => setCollapsed( !collapsed )}
          >
            {
              collapsed
                ? <React.Fragment>
                  Expand
                  <InlineSVG type="carretDown" />
                </React.Fragment>
                : <React.Fragment>
                  Collapse
                  <InlineSVG type="carretUp" />
                </React.Fragment>
            }
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default SetupPageVisualization;