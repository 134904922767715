/** *************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ***************************************************************/
import React from 'react';

import {
  isNotEmpty,
  debounce,
  paramsToFilters,
} from '../../Utilities';

const DebouncedText = ( {
  input,
  value,
  handleChange,
} ) => {
  const [ isFocused, setIsFocused ] = React.useState( false );

  const inputRef = React.useRef( null );

  const handleHashChange = () => {

    const filterValues = paramsToFilters();
    const newValue = filterValues[input.attribute] || '';

    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
      inputRef.current.value = newValue || '';
    }
  };

  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input, inputRef ] );

  // debounces the value so it does not hammer the server
  const handleTextInputChange = debounce( value => {
    handleChange( input.attribute, value );
  }, 1000 );

  return (
    <label className={`${isFocused ? 'focus' : ''}`}>
      <span className="labelWrapper">
        { input.label }
        { input.helpItem }
      </span>
      <input
        ref={inputRef}
        onFocus={ () => setIsFocused( true ) }
        onBlur={ () => setIsFocused( false ) }
        placeholder={ input.placeholder ? input.placeholder : ''}
        disabled={input.disabled}
        type="text"
        onChange={ e => handleTextInputChange( e.target.value ) }
        { ...input.htmlProps }
      />
    </label>
  );
};

export default DebouncedText;
