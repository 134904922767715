/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, reportTypeDisplayName, riskToRating } from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import RatingBadge from '../../../../shared/RatingBadge';
import { TagsContext } from '../../../../Contexts/Tags';
import TagList from '../../../RiskInsight/Tags/List';

const ScopeHeader = ( { scope, options, handleClose, context, collapsed=false, setCollapsed=() => {} } ) => {

  const [ tagMemberships, setTagMemberships ] = React.useState( null );

  const [ tags ] = React.useContext( TagsContext );

  React.useEffect( () => {
    if ( isNotEmpty( scope ) && scope.type === 'host' && isNotEmpty( scope.asset_tags ) ) {
      const _tags = {};

      scope.asset_tags.map( id => {
        _tags[id] = tags[id];
      } );

      setTagMemberships( _tags );
    }
  }, [ scope ] );

  const getExploreLink = ( record, recordType ) => {
    // eslint-disable-next-line max-len
    return `#.=explore&page=explore_model&type=scope&explore_${ recordType === 'host' ? 'scope' : recordType }=%5B"${record.id}"%5D`;
  };

  const handleExploreClick = () => {
    if ( isNotEmpty( scope ) ) {
      window.open( getExploreLink( scope, 'scope' ) );
    }
  };

  const riskClass = () => {

    if ( scope.type === 'host' ) {
      if ( scope.riskRating ) {
        return scope.riskRating;
      }
      if ( scope.has_host ) {
        if ( 'filtered_risk' in scope ) {
          return riskToRating( scope.filtered_risk );
        }
        return riskToRating( scope.risk );
      }
      return 'unknown';
    }
    return 'unknown';
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( scope ) &&
        <React.Fragment>
          <div className="recordCardHeaderRow">
            <h2>
              {
                ( isNotEmpty( options ) && options.isDraggable ) &&
                <InlineSVG type="draggable" elementClass="draggableIcon" />
              }
              <div className={ `recordIconWrapper ${ riskClass() }` } >
                <InlineSVG type="host_record" />
              </div>
              <span className="label">
                { reportTypeDisplayName( scope, 'host' ) }
              </span>
            </h2>
            <div className="right">
              {
                ( isNotEmpty( options ) && 'include_risk' in options )
                  ? <React.Fragment>
                    {
                      ( options.include_risk === true && scope?.type === 'host' ) &&
                      <RatingBadge rating={ riskClass() } />
                    }
                  </React.Fragment>
                  : <React.Fragment>
                    {
                      ( scope?.type === 'host' ) &&
                      <RatingBadge rating={ riskClass() } />
                    }
                  </React.Fragment>
              }
              {
                ( isNotEmpty( options ) && options.isDismissable ) &&
                <button
                  className="recordCardCloseButton roundGlyphButton light"
                  onClick={ handleClose }
                >
                  <InlineSVG type="close" elementClass="closeIcon" />
                </button>
              }
            </div>
          </div>
          <div className={ `recordCardHeaderRow__dividerBar ${ riskClass() }` } />
          <div className="recordCardHeaderRow">
            <div className="left">
              {
                ( scope?.type === 'host' && isNotEmpty( tagMemberships ) ) &&
                <TagList tags={tagMemberships} trancation={ 2 } />
              }
            </div>
            <div className="right">
              {
                context !== 'explore' &&
                <button
                  className="roundGlyphButton light"
                  // eslint-disable-next-line max-len
                  onClick={ handleExploreClick }
                >
                  <InlineSVG type="explore_nav" />
                </button>
              }
              {
                context === 'explore' &&
                <React.Fragment>
                  {
                    options?.isCollapsible &&
                    <button
                      className="roundGlyphButton light"
                      // eslint-disable-next-line max-len
                      onClick={ () => setCollapsed( !collapsed ) }
                    >
                      {
                        collapsed
                          ? <InlineSVG type="expand" />
                          : <InlineSVG type="collapse" />
                      }
                    </button>
                  }
                </React.Fragment>
              }
              {
                ( context !== 'record_details' && scope.type === 'host' ) &&
                <button
                  className="roundGlyphButton light goToRecordButton"
                  // eslint-disable-next-line max-len
                  onClick={ () => window.open( `#.=risk_insight&report=hosts&item_count=100&sensitive_assets=any&order_by=filtered_risk&order_direction=DESC&item=${scope.id}&current_page=1` ) }
                >
                  <InlineSVG type="newTabLink" />
                </button>
              }
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ScopeHeader;