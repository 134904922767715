/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  reportTypeDisplayName,
  riskToRating,
  shortenedVulnerabilityScannerNameMap,
} from '../../../../shared/Utilities';
import InlineSVG from '../../../../shared/InlineSVG';
import { getMarkdown } from '../shared';

import './SignatureContent.scss';
import VulnerabilityContent from './VulnerabilityContent';
import VulnerabilityHeader from '../Header/VulnerabilityHeader';
import RiskPercentageBar from '../../../../shared/RiskPercentageBar';
import RiskReduction from '../../../../shared/RiskReduction';
import EmptyLoading from '../../../../shared/EmptyLoading';
import RatingBadge from '../../../../shared/RatingBadge';

const SignatureContent = ( {
  fullVersion=true,
  // escalation,
  escalationDetails,
  instance,
  signature,
  loadingSignature,
  vulnerabilities,
  loadingVulnerabilities,
  setAdditionalContent,
  options,
  containerRef,
  setStyle,
  context,
  selectItem,
  highestRisk,
} ) => {

  const serviceInfoIsEmpty = info => {
    if ( isEmpty( info ) ) {
      return true;
    }
    if ( info.port === 0 && info.protocol === null ) {
      return true;
    }
    return false;
  };

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
      && isNotEmpty( containerRef.current )
      && isNotEmpty( signature )
      && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( signature.clickEvent ) ) {
        _clickEvent = signature.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 32 * 16;

        const reactContainer = document.getElementById( 'react_container' );
        const scrollOffset = isNotEmpty( reactContainer ) ? reactContainer.scrollTop : 0;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height + scrollOffset ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ signature, containerRef, options, context ] );

  const [ showInfo, setShowInfo ] = React.useState( true );
  const [ showEvidence, setShowEvidence ] = React.useState( false );
  const [ showRecommendation, setShowRecommendation ] = React.useState( false );

  const [ selectedVulnerabilityID, setSelectedVulnerabilityID ] = React.useState( null );
  const [ showVulnerabilities, setShowVulnerabilities ] = React.useState( true );

  const toggleAdditional = ( vulnerability ) => {
    if ( isNotEmpty( selectedVulnerabilityID ) && selectedVulnerabilityID === vulnerability.id ) {
      setSelectedVulnerabilityID( null );
      setAdditionalContent( null );
    } else if ( isNotEmpty( vulnerability ) ) {
      setSelectedVulnerabilityID( vulnerability.id );
      setAdditionalContent(
        <React.Fragment>
          <div className="recordCardHeader" >
            <VulnerabilityHeader
              vulnerability={vulnerability}
              context={ context }
              selectItem={ selectItem }
            />
          </div>
          <div className="recordCardContent" >
            <VulnerabilityContent
              vulnerability={ vulnerability }
              fullVersion
            />
          </div>
        </React.Fragment>,
      );
    }
  };

  // re-position after the content loads
  React.useEffect( () => {
    if (
      isNotEmpty( containerRef )
        && isNotEmpty( containerRef.current )
        && isNotEmpty( signature )
        && context !== 'explore'
    ) {
      let _clickEvent;
      if ( isNotEmpty( signature.clickEvent ) ) {
        _clickEvent = signature.clickEvent;
      } else if ( isNotEmpty( options?.clickEvent ) ) {
        _clickEvent = options.clickEvent;
      }

      if ( isNotEmpty( _clickEvent ) ) {

        const width = 32 * 16;

        let left = 'unset';
        let right = 16;
        let top = 16;
        let bottom = 'unset';

        setTimeout( () => {
          const dimensions = getDimensionsAndOffset( containerRef.current );

          if ( isNotEmpty( dimensions ) ) {
            const { height } = dimensions;

            const { pageX, pageY } = _clickEvent;

            left = pageX + 16;
            right = 'unset';
            top = pageY;
            bottom = 'unset';

            // it would flow over the right side of the screen, need to put on the left side instead
            if ( ( left + width ) > window.innerWidth ) {
              left = pageX - 16 - width;

              if ( left < 16 ) {
                left = 16;
              }
            }

            // it would be too low on the screen
            if ( ( top + height ) > window.innerHeight ) {
              top = 'unset';
              bottom = 16;
            }

            setStyle( { top, bottom, left, right, width } );
          }
        }, 100 );
      }
    }
  }, [ signature, containerRef, options, context ] );

  return (
    <div className="signatureRecordCardContentWrapper">
      {
        ( isNotEmpty( instance ) && isNotEmpty( instance.manual_html ) ) &&
        <div
          className="rawContentWrapper detailsSection"
          dangerouslySetInnerHTML={ instance.manual_html }
        />
      }
      {
        isNotEmpty( signature ) &&
        <React.Fragment>
          {
            signature.scanner !== 'internal' &&
            <div className="infoSection inline">
              <label>Signature:</label>
              <span>
                {/* eslint-disable-next-line max-len */}
                { ` ${shortenedVulnerabilityScannerNameMap[signature.scanner]} ${signature.signature}` }
              </span>
            </div>
          }
          {
            ( isNotEmpty( signature.title ) && isNotEmpty( signature.title.trim() ) ) &&
            <div className="infoSection inline">
              <label>Title:</label>
              <span>
                { signature.title.trim() }
              </span>
            </div>
          }
          <div className="infoSection inline rating">
            <label>Scanner Rating:</label>
            <span>
              {
                isNotEmpty( signature.scanner_rating )
                  ? <RatingBadge rating={ signature.scanner_rating }/>
                  : 'NA'
              }
            </span>
          </div>
          {
            (
              isNotEmpty( signature.description )
              && isNotEmpty( signature.description.trim() )
            ) &&
            <div className="infoSection">
              <label>Source Output:</label>
              {
                signature.scanner === 'rapid7'
                  ? <div
                    className="markdownWrapper vulnerabilityScannerContent"
                    dangerouslySetInnerHTML={ getMarkdown( signature.description ) }
                  />
                  : <pre>{ signature.description }</pre>
              }

            </div>
          }
          {
            (
              isNotEmpty( instance )
              && isNotEmpty( instance.service_info )
              && !serviceInfoIsEmpty( instance.service_info )
              && fullVersion
            ) &&
            <div className="infoSection">
              <label>Service Information:</label>
              <ul>
                {
                  isNotEmpty( instance.service_info.host_name ) &&
                  <li>
                    <label>Host Name: </label>
                    <span>{ instance.service_info.host_name }</span>
                  </li>
                }
                {
                  isNotEmpty( instance.service_info.ipv4 ) &&
                  <li>
                    <label>IPv4 Address: </label>
                    <span>{ instance.service_info.ipv4 }</span>
                  </li>
                }
                {
                  (
                    isNotEmpty( instance.service_info.port )
                    && instance.service_info.port !== 0
                  ) &&
                  <React.Fragment>
                    <li>
                      <label>Port: </label>
                      <span>{ instance.service_info.port }</ span>
                    </li>
                    {
                      isNotEmpty( instance.service_info.protocol ) &&
                      <li>
                        <label>Protocol: </label>
                        <span>{ instance.service_info.protocol }</span>
                      </li>
                    }
                  </React.Fragment>
                }
              </ul>
            </div>
          }
          {/* collapsible sections */}
          {
            (
              isNotEmpty( signature.recommendation )
              && isNotEmpty( signature.recommendation.trim() )
              && fullVersion
            ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `collapsibleSectionWrapper ${showRecommendation ? '' : 'collapsed' }` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowRecommendation( !showRecommendation ) }
              >
                <div className="headerLeft">
                  Recommendation
                </div>
                <div className="headerRight">
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                {
                  signature.scanner === 'rapid7'
                    ? <div
                      className="markdownWrapper vulnerabilityScannerContent"
                      dangerouslySetInnerHTML={ getMarkdown( signature.recommendation ) }
                    />
                    : <pre>{ signature.recommendation.trim() }</pre>
                }
              </div>
            </div>
          }
          {
            (
              isNotEmpty( escalationDetails )
              && isNotEmpty( escalationDetails.raw_info )
              && isNotEmpty( escalationDetails.raw_info.trim() )
              && fullVersion
            ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `collapsibleSectionWrapper ${showInfo ? '' : 'collapsed' }` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowInfo( !showInfo ) }
              >
                <div className="headerLeft">
                  Information
                </div>
                <div className="headerRight">
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                {
                  signature.scanner === 'rapid7'
                    ? <div
                      className="markdownWrapper vulnerabilityScannerContent"
                      dangerouslySetInnerHTML={ getMarkdown( escalationDetails.raw_info ) }
                    />
                    : <pre>{ escalationDetails.raw_info.trim() }</pre>
                }
              </div>
            </div>
          }
          {
            (
              isNotEmpty( instance )
              && isNotEmpty( instance.evidence )
              && isNotEmpty( instance.evidence.trim() )
              && fullVersion
            ) &&
            <div
              // eslint-disable-next-line max-len
              className={ `collapsibleSectionWrapper ${showEvidence ? '' : 'collapsed' }` }
            >
              <div
                className="collapsibleSectionHeader"
                onClick={ () => setShowEvidence( !showEvidence ) }
              >
                <div className="headerLeft">
                  Evidence
                </div>
                <div className="headerRight">
                  <span className="carretWrapper">
                    <InlineSVG type="carretUp"/>
                  </span>
                </div>
              </div>
              <div className="collapsibleSectionBody">
                {
                  signature.scanner === 'rapid7'
                    ? <div
                      className="markdownWrapper vulnerabilityScannerContent"
                      dangerouslySetInnerHTML={ getMarkdown( instance.evidence ) }
                    />
                    : <pre>{ instance.evidence.trim() }</pre>
                }
              </div>
            </div>
          }
          {/* vulnerabilities, only for full version, otherwise it would be too confusing */}
          {
            fullVersion &&
            <div className="signatureContentvulnerabilitiesSectionWrapper">
              <EmptyLoading
                loading={ loadingVulnerabilities }
                payload={ vulnerabilities }
              />
              {
                (
                  isNotEmpty( vulnerabilities )
                  && isNotEmpty( highestRisk )
                  && fullVersion
                ) &&
                <div
                  // eslint-disable-next-line max-len
                  className={ `collapsibleSectionWrapper ${showVulnerabilities ? '' : 'collapsed' }` }
                >
                  <div
                    className="collapsibleSectionHeader"
                    onClick={ () => setShowVulnerabilities( !showVulnerabilities ) }
                  >
                    <div className="headerLeft">
                      <InlineSVG
                        elementClass="itemTypeIcon"
                        type="vulnerabilitiesAlt"
                      />
                      <h3>
                        Vulnerabilities
                      </h3>
                    </div>
                    <div className="headerRight">
                      <strong className="sectionCount">
                        ({ Object.values( vulnerabilities ).length })
                      </strong>
                      <span className="carretWrapper">
                        <InlineSVG type="carretUp"/>
                      </span>
                    </div>
                  </div>
                  <div className="collapsibleSectionBody">
                    <ul>
                      {
                        vulnerabilities.map( ( vulnerability, index ) => {
                          return <li
                            // eslint-disable-next-line max-len
                            className={ `signatureContentvulnerabilityItemWrapper ${ selectedVulnerabilityID === vulnerability.id ? 'selected' : '' }`}
                            key={index}
                            onClick={ () => toggleAdditional( vulnerability ) }
                          >
                            <span
                              className="name vulnerability"
                            >
                              { reportTypeDisplayName( vulnerability, 'vulnerability' ) }
                            </span>
                            {
                              isNotEmpty( highestRisk ) &&
                              <RiskPercentageBar
                                itemRisk={ vulnerability.risk }
                                itemRating={ riskToRating( vulnerability.risk ) }
                                totalRisk={ highestRisk }
                                minimalVariant
                              />
                            }
                            <RiskReduction item={vulnerability} riskType="risk" />
                          </li>;
                        } )
                      }
                    </ul>
                  </div>
                </div>
              }
            </div>
          }
        </React.Fragment>
      }
      {
        fullVersion &&
        <EmptyLoading
          loading={ loadingSignature }
          payload={ signature }
          emptyMessage="No signature information"
        />
      }

    </div>
  );
};

export default SignatureContent;