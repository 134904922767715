/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { VALIDATION_REGEXS } from '../../../../shared/Form/Validators';
import { isEmpty, isNotEmpty } from '../../../../shared/Utilities';

const ModalAction = ( {
  taskMappings,
  setTaskMappings,
  selectedTaskIDs,
  activeIntegrations,
  setShow,
} ) => {

  const [ isValid, setIsValid ] = React.useState( false );
  const [ currentMappings, setCurrentMappings ] = React.useState( {} );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  // whenever the mappings change, and the selected tasks change, need to set the currentMappings,
  // aka the mappings that are selected
  React.useEffect( ( ) => {
    let _current = {};

    if ( isNotEmpty( taskMappings ) ) {
      if ( isNotEmpty( selectedTaskIDs ) ) {
        Object.entries( taskMappings ).map( ( [ id, mapping ] ) => {
          if ( selectedTaskIDs.includes( id ) ) {
            _current[id] = mapping;
          }
        } );
      } else {
        _current = taskMappings;
      }
    }
    setCurrentMappings( _current );
  }, [ taskMappings, selectedTaskIDs ] );

  // anytime the above sets the currentMappings, check each if they are valid and then set the isValid for the group
  React.useEffect( ( ) => {
    const rowValidations = [];

    if ( isNotEmpty( currentMappings ) && isNotEmpty( activeIntegrations ) ) {
      Object.entries( currentMappings ).map( ( [ id, mapping ] ) => {
        // DIRECT EMAIL option
        if ( isEmpty( mapping.integrationID ) || mapping.integrationID === 'null' ) {
          // has an email valid
          if (
            isNotEmpty( mapping.ownerID )
            && isNotEmpty( mapping.emailAddress )
            && mapping.emailAddress.match( VALIDATION_REGEXS.email )
          ) {
            rowValidations.push( { id, isValid: true } );
          // missing email invalid
          } else {
            rowValidations.push( { id, isValid: false } );
          }
        // Either JIRA or EMAIL integration
        } else {
          // find the referenced integration
          let integration = activeIntegrations?.jira?.find( i => i.id ===  mapping.integrationID );
          if ( isEmpty( integration ) ) {
            integration = activeIntegrations?.email?.find( i => i.id ===  mapping.integrationID );
          }
          if ( isEmpty( integration ) ) {
            integration = activeIntegrations?.tanium?.find( i => i.id ===  mapping.integrationID );
          }
          if ( isNotEmpty( integration ) ) {
            // EMAIL integration
            if ( integration.destination_email_address ) {
              // has an email valid
              if ( isNotEmpty( mapping.emailAddress ) ) {
                rowValidations.push( { id, isValid: true } );
              // missing email invalid
              } else {
                rowValidations.push( { id, isValid: false } );
              }
            // JIRA integration, doesn't actually need a mappedUser, can be unassigned
            } else {
              rowValidations.push( { id, isValid: true } );
            }
          }
        }
      } );
    }

    // if there are no rows, set invalid, otherwise, if every row is set to true, set as valid
    if ( isEmpty( rowValidations ) ) {
      setIsValid( false );
    } else {
      setIsValid( rowValidations.every( v => v.isValid === true ) );
    }
  }, [ currentMappings, activeIntegrations ] );

  const exportTickets = async () => {
    // collecting each task that first needs to be updated for the owner mapping to work
    const taskUpdates = [];
    // collecting all the necessary information for each export
    const exports = [];

    // used to normalize the nulls and empty vals
    const getOwnerMapping = mapping => {
      if ( isEmpty( mapping.ownerID ) || mapping.ownerID === 'null' ) {
        return null;
      }
      return mapping.ownerID;
    };

    let exportRequest = null;
    // should never hit this, just used as a safeguard
    if ( isEmpty( currentMappings ) ) {
      addFlashMessage( { type: 'info', body: 'No tasks to export' } );
    // current mappings are valid
    } else if ( isValid ) {
      /* eslint-disable camelcase */
      Object.entries( currentMappings ).map( ( [ id, mapping ] ) => {

        console.log( mapping );

        // if it is a JIRA mapping
        if ( mapping.integrationType === 'jira' ) {
          taskUpdates.push( { id: id, owner: getOwnerMapping( mapping )  } );
          exports.push( {
            task_id: id,
            third_party_setting_id: mapping.integrationID,
            external_user_id: mapping.externalUserID,
          } );
        // if it is a Tanium mapping
        } else if ( mapping.integrationType === 'tanium' ) {
          exports.push( {
            task_id: id,
            third_party_setting_id: mapping.integrationID,
          } );
        // if it is an EMAIL mapping
        } else if ( mapping.integrationType === 'email' ) {
          taskUpdates.push( { id: id, owner: getOwnerMapping( mapping )  } );
          exports.push( {
            task_id: id,
            third_party_setting_id: mapping.integrationID,
            email_address: mapping.emailAddress,
          } );
        // DIRECT mapping
        } else {
          taskUpdates.push( { id: id, owner: getOwnerMapping( mapping )  } );
          exports.push( {
            task_id: id,
            third_party_setting_id: null,
            email_address: mapping.emailAddress,
          } );
        }
      } );

      // first update the tasks and await it so that the export can rely on current owner information for each task
      if ( isNotEmpty( taskUpdates ) ) {
        await makeRequest( 'UPDATE', '/project/default/model/base/remediation_task', { updates: taskUpdates } );
      }

      /* eslint-enable camelcase */
      // now send all the actual export updates
      if ( isNotEmpty( exports ) ) {
        exportRequest = await makeRequest( 'COMPUTE', '/model/base/remediation_task_export', { tasks: exports } );

        if ( isNotEmpty( exportRequest ) ) {
          // the job was successfully queued
          if ( exportRequest.status === 'ok' ) {
            addFlashMessage( { type: 'success', body: 'Successfully exported tasks'} );
            setShow( false );
            setTaskMappings( {} );
          // there was an error attempting to queue the job
          } else if ( exportRequest.status === 'error' && isNotEmpty( exportRequest.results ) ) {
            const body = exportRequest.results.map( ( e, i ) => <p key={i} >{ e }</p> );
            addFlashMessage( { type: 'alert', body } );
          // something else happend
          } else {
            addFlashMessage( { type: 'info', body: exportRequest.status } );
            setShow( false );
            setTaskMappings( {} );
          }
        // likely 500
        } else {
          addFlashMessage( { type: 'alert', body: 'There was an error attempting to export the tasks'} );
        }
      }
    // should never hit this, just used as a safeguard
    } else {
      addFlashMessage( { type: 'alert', body: 'Please add all required information before exporting tasks' } );
    }
  };

  return (
    <button
      disabled={ !isValid }
      className={ `primaryButton ${!isValid ? 'disabled' : ''}`}
      onClick={ exportTickets }
    >
      Export { selectedTaskIDs.length } Task(s)
    </button>
  );
};

export default ModalAction;