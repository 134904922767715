/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import Duration from './Duration';

import './Override.scss';

import {
  isNotEmpty,
} from '../../../Utilities';

const Override = ( {
  field,
  formState,
  onChange,
  originalValue,
  existingRecord,
  fieldRef,
} ) => {

  const [ value, setValue ] = React.useState( false );
  const [ isOverridden, setIsOverridden ] = React.useState( false );

  const isDisabled = field.disabled || !isOverridden || formState.fieldStates[field.attribute].disabled;

  // on init, setup the field and values
  React.useEffect( () => {

    if ( isNotEmpty( originalValue ) ) {
      setIsOverridden( originalValue.isOverridden );
      setValue( originalValue.isOverridden ? originalValue.overrideValue : originalValue.globalDefault );
    }
  }, [ existingRecord, field, originalValue ] );

  // whenever the user toggles whether they want to override the value or not
  // keep in mind the user can override and then not change the value
  const handleToggleOverride = () => {
    let _isOverridden = false;
    if ( isOverridden ) {
      setIsOverridden( false );
    } else {
      setIsOverridden( true );
      _isOverridden = true;
    }
    setValue( originalValue.globalDefault );
    onChange( field, { isOverridden: _isOverridden, overrideValue: originalValue.globalDefault } );
  };

  // called when the user is overriding the value within the input itself
  const handleInputChange = ( field, _value ) => {
    setValue( _value );
    onChange( field, { isOverridden: true, overrideValue: _value } );
  };

  return (
    <React.Fragment>
      <label>
        <div
          // eslint-disable-next-line max-len
          className={ `${ field.disabled ? 'disabled' : ''} checkboxFieldWrapper ${isOverridden ? 'checked' : ''} asToggle` }
        >
          <input
            type="checkbox"
            checked={ isOverridden }
            onChange={ handleToggleOverride }
            disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
            className={ field.disabled ? 'disabled' : ''}
          />
          <span className="labelWrapper">
            Override
          </span>
        </div>
      </label>

      <label className={`${isOverridden ? 'overridden' : ''} inline`}>
        <span className="labelWrapper">
          { field.label }
          {
            field.required &&
            <span className="required">*</span>
          }
          { field.help && field.help }
        </span>
        {
          field.overrideInputType === 'number' &&
          <React.Fragment>
            <input
              ref={fieldRef}
              placeholder={ field.placeholder ? field.placeholder : ''}
              disabled={isDisabled}
              type="number"
              onChange={ e => handleInputChange( field, e.target.valueAsNumber ) }
              value={ value || '' }
              { ...field.htmlProps }
            />
          </React.Fragment>
        }
        {
          field.overrideInputType === 'checkbox' &&
          <React.Fragment>
            <div
              // eslint-disable-next-line max-len
              className={ `checkboxFieldWrapper ${value ? 'checked' : ''} ${( isDisabled ) ? 'disabled' : ''} inline` }
            >
              <input
                disabled={ isDisabled }
                type="checkbox"
                onChange={ e => handleInputChange( field, e.target.checked ) }
                checked={ value || false }
              />
            </div>
          </React.Fragment>
        }
        {
          field.overrideInputType === 'duration' &&
          <Duration
            field={field}
            onChange={ handleInputChange }
            originalValue={ originalValue }
            withinOverride={true}
            shouldDisable={!isOverridden}
          />
        }
      </label>
    </React.Fragment>
  );
};

export default Override;
