/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import { getRecord } from '../../../shared/RecordCache';
import InlineSVG from '../../../shared/InlineSVG';

// eslint-disable-next-line camelcase
const extra_columns = [
  'details',
  'extra',
  'flags',
];

const Details = ( { record } ) => {

  const [ nodeDetails, setNodeDetails ] = React.useState( null );
  const [ nodeDescription, setNodeDescription ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( record.node_id ) ) {

      // eslint-disable-next-line camelcase
      getRecord( 'node',  record.node_id, { extra_columns }  ).then( response => {

        const _nodeDetails = [];
        if (
          isNotEmpty( response.extra )
          && isNotEmpty( response.extra['user_flags'] )
          && isNotEmpty( response.extra['user_flags']['DONT_EXPIRE_PASSWD'] )
        ) {
          _nodeDetails.push( <span>This user account's password does not expire.</span> );
        }
        if ( isNotEmpty( response.details ) ) {
          setNodeDescription( response.details );
        }

        if ( isNotEmpty( response.flags ) ) {
          if ( response.flags['recently_active'] ) {
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                This user account has recently logged in to this system.
              </span>,
            );
          }
          if ( response.flags['has_rdp_access'] ) {
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                This user account has Remote Desktop (RDP) access to this system.
              </span>,
            );
          }
          if ( response.flags['rdp_user'] ) {
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                This user has recently used an RDP client to connect to another host.
              </span>,
            );
          }
          if ( response.flags['outlook_user'] ) {
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                {/* eslint-disable-next-line max-len */}
                This user has recently used the Outlook desktop mail client and may be susceptible to related vulnerabilities.
              </span>,
            );
          }
          if ( response.flags['windowsmail_user'] ) {
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                {/* eslint-disable-next-line max-len */}
                This user has recently used the Microsoft Windows Mail desktop mail client and may be susceptible to related vulnerabilities.
              </span>,
            );
          }

          if ( response.flags['browser_user'] ) {
            const bMap = {
              // eslint-disable-next-line camelcase
              ie_user: 'Internet Explorer',
              // eslint-disable-next-line camelcase
              edge_user: 'Edge',
              // eslint-disable-next-line camelcase
              chrome_user: 'Chrome',
              // eslint-disable-next-line camelcase
              firefox_user: 'Firefox',
              // eslint-disable-next-line camelcase
              safari_user: 'Safari',
            };
            const browsers = [];

            for ( const bflag in bMap ) {
              if ( response.flags[bflag] ) {
                browsers.push( bMap[bflag] );
              }
            }
            _nodeDetails.push(
              <span className="nodeInformationPiece">
                {/* eslint-disable-next-line max-len */}
                This user account has recently used the following browsers on this system, which could make them susceptible to any related vulnerabilities: {browsers.join( ', ' )}
              </span>,
            );
          }
        }
        if ( isNotEmpty( _nodeDetails ) ) {
          setNodeDetails( _nodeDetails );
        }

      } );
    }
  }, [ record ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( record ) &&
        <div className="recordDetails__InformationPanel__Body_Section details user">
          <div className="bodySectionItem">
            <label>Details</label>
            {
              isNotEmpty( nodeDescription ) &&
              <span>{ nodeDescription }</span>
            }
            {
              isNotEmpty( nodeDetails ) &&
              <ul>
                {
                  nodeDetails.map( ( detail, i ) => {
                    return <li className="nodeDetailItem" key={i}>
                      <InlineSVG type="notificationAlert" />
                      { detail }
                    </li>;
                  } )
                }
              </ul>
            }
          </div>
          <div className="bodySectionItem">
            <label>SID</label>
            { record.sid || '--' }
          </div>
          <div className="bodySectionItem">
            <label>Domain</label>
            { record.domain_name || '--' }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Details;