/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import InputGroup from './InputGroup';

import {
  isNotEmpty,
  paramsToFilters,
  encodeURLHash,
  isEqual,
} from '../Utilities';
import AdvancedFilterForm from './AdvancedFilterForm';

const FilterForm = ( {
  // the Form component has moved to a 'fields' prop that has better support for grouping, adding this, in order to be
  // able to support that but still be backwards compatible with all of the filter forms already in place, a FilterForm
  // needs to have either fields or inputs to be valid
  fields,
  inputs,
  onRefresh,
  reportType,
  horizontal=false,
  disabled=false,
  noRefresh=false,
  // the new drawer-style form with an apply and save button differs so much from the regular filters, that a different
  // type of form is needed, while still preserving backwards compatibility. This flag adds the drawer, the saving,
  // the applied filter minified view, and most importantly, the 'Apply' button, so that the inputs no longer have
  // automatic onChange filter application, but an explicit apply will be necessary
  advanced=false,
  // drawer open/close, only necessary for advanced filter form
  collapsed=false,
  setCollapsed=() => {},
  visualCollapsed=false,
  showAdvancedFilters=false,
} ) => {

  const [ values, setValues ] = React.useState( {} );

  // for standard filter form
  const [ inputGroups, setInputGroups ] = React.useState( {} );

  // for advanced filter form
  const [ fieldGroups, setFieldGroups ] = React.useState( null );

  // DEPRECATED: supports existing inputs structure (always an array, each input needs its own 'grouped' attr)
  React.useEffect( () => {
    const _values = {};
    const paramFilters = paramsToFilters();

    if ( isNotEmpty( inputs ) && isNotEmpty( paramFilters ) ) {
      inputs.map( input => {
        let _value;
        if ( isNotEmpty( paramFilters[input.attribute] ) ) {
          _value = paramFilters[input.attribute];
        } else {
          _value = input.value ? input.value : '';
        }
        _values[input.attribute] = _value;
      } );
    }

    setValues( _values );
    encodeURLHash( _values );

    if ( !noRefresh ) {
      onRefresh();
    }

    if ( isNotEmpty( inputs ) ) {
      window.addEventListener( 'hashchange', onRefresh );
      mapInputsToGroups( inputs );
    }
    return () => window.removeEventListener( 'hashchange', onRefresh );
  }, [ inputs ] );

  // DEPRECATED: inputs -> group mapping
  const mapInputsToGroups = inputs => {
    const groups = {
      all: [],
    };

    inputs.map( i => {
      if ( i.grouped ) {
        if ( groups[i.grouped.name] ) {
          groups[i.grouped.name].push( i );
        } else {
          groups[i.grouped.name] = [ i ];
        }
      } else {
        groups.all.push( i );
      }
    } );
    setInputGroups( groups );
  };

  // support for fields that can be an object or an array
  React.useEffect( ( ) => {
    if ( isNotEmpty( fields ) ) {
      mapFieldsToGroups( fields );
      if ( !noRefresh ) {
        onRefresh();
      }
      if ( isNotEmpty( fields ) && !advanced ) {
        window.addEventListener( 'hashchange', onRefresh );
      }
    }

    return () => {
      if ( !advanced && isNotEmpty( fields ) ) {
        window.removeEventListener( 'hashchange', onRefresh );
      }
    };
  }, [ fields, advanced ] );

  // called if the form is using newer 'fields' prop and structure
  const mapFieldsToGroups = fields => {
    const _values = {};
    const paramFilters = paramsToFilters();

    let _fieldGroups;
    if ( advanced ) {
      _fieldGroups = [];
      // not grouped
      if ( Array.isArray( fields ) ) {
        _fieldGroups.push( {
          name: 'all',
          fields: fields,
        } );
        fields.map( field => {
          let _value;
          if ( isNotEmpty( paramFilters[field.attribute] ) ) {
            _value = paramFilters[field.attribute];
          } else {
            _value = field.value ? field.value : '';
          }
          _values[field.attribute] = _value;
        } );
      // fields are grouped
      } else {
        Object.entries( fields ).map( ( [ groupKey, groupData ] ) => {
          _fieldGroups.push(
            {
              name: groupKey,
              fields: groupData.fields,
              help: groupData.help,
              header: groupData.header,
            },
          );
          groupData.fields.map( field => {
            let _value;
            if ( isNotEmpty( paramFilters[field.attribute] ) ) {
              _value = paramFilters[field.attribute];
            } else {
              _value = field.value ? field.value : '';
            }
            _values[field.attribute] = _value;
          } );
        } );
      }
      setFieldGroups( _fieldGroups );
    } else {
      _fieldGroups = {
        all: [],
      };
      // not grouped
      if ( Array.isArray( fields ) ) {
        _fieldGroups.all = fields;
      // fields are grouped
      } else {
        Object.entries( fields ).map( ( [ groupKey, groupData ] ) => {
          _fieldGroups[groupKey] = groupData.fields;
        } );
      }
      setInputGroups( _fieldGroups );
    }
    setValues( _values );

    // need to adjust the values for gt_map and lt_map attrs
    /* eslint-disable camelcase */
    if ( isNotEmpty( _values ) ) {
      const adjustedValues = {};
      Object.entries( _values ).map( ( [ attribute, value ] ) => {
        if ( !isEqual( [ 'gt_map', '' ], value ) ) {
          adjustedValues[attribute] = value;
        }
      } );

      encodeURLHash( adjustedValues );
    }
    /* eslint-disable camelcase */
  };

  return (
    <React.Fragment>
      {
        advanced
          ? <AdvancedFilterForm
            fieldGroups={fieldGroups}
            values={values}
            disabled={disabled}
            onRefresh={onRefresh}
            collapsed={collapsed}
            visualCollapsed={visualCollapsed}
            setCollapsed={setCollapsed}
            reportType={reportType}
            showAdvancedFilters={showAdvancedFilters}
          />
          : <div className={`filterForm ${reportType} ${horizontal ? 'horizontal' : ''}`}>
            {
              ( isNotEmpty( inputGroups ) ) &&
              Object.entries( inputGroups ).map( ( [ groupName, inputs ], index ) => {
                return  <InputGroup
                  name={groupName}
                  inputs={inputs}
                  values={values}
                  key={index}
                  horizontal={horizontal}
                  disabled={disabled}
                />;
              } )
            }
          </div>
      }
    </React.Fragment>

  );
};

export default FilterForm;