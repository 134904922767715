/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../shared/Form';
import { isNotEmpty } from '../../../shared/Utilities';
import InlineSVG from '../../../shared/InlineSVG';

const ScanGroupModal = ( {
  trackUpdates,
  fields,
  setIsValid,
  existingRecord,
  recordType,
  onChangeCallback,
  showModal,
} ) => {

  const [ activeTab, setActiveTab ] = React.useState( 'general' );

  // whenever the modal opens or closes, reset the activeTab
  React.useEffect( ( ) => {
    setActiveTab( 'general' );
  }, [ showModal ] );

  return (
    <React.Fragment>
      <div
        // eslint-disable-next-line max-len
        className={ `setupTabsContainer tabCount--2 ${ isNotEmpty( activeTab ) ? `selectedTabIndex--${activeTab === 'general' ? 0 : 1}`: 'noSelectedTab' }`}
      >
        <div
          className={`setupTab ${activeTab === 'general' ? 'isCurrent' : ''}`}
          onClick={ () => setActiveTab( 'general' )}
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_1" />
              <label>General</label>
            </span>
          </div>
        </div>
        <div
          className={`setupTab ${activeTab === 'settings' ? 'isCurrent' : ''}`}
          onClick={ () => setActiveTab( 'settings' )}
        >
          <div className="setupTabContentWrapper">
            <span className="stepAndLabelWrapper">
              <InlineSVG type="progress_circle_2" />
              <label>Settings</label>
            </span>
          </div>
        </div>
      </div>
      <div className={`tabWrapper ${activeTab}`}>
        <Form
          trackUpdates={ trackUpdates }
          fields={fields}
          setIsValid={setIsValid}
          existingRecord={existingRecord}
          recordType={recordType}
          onChangeCallback={ onChangeCallback }
        />
      </div>
    </React.Fragment>
  );
};

export default ScanGroupModal;