/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isEmpty, isNotEmpty, riskToRating, vulnerabilityScannerLogo } from '../../../shared/Utilities';
import RecordList from '../../../shared/RecordList';
// import { getMarkdown } from '../shared';
import InlineSVG from '../../../shared/InlineSVG';
import RatingBadge from '../../../shared/RatingBadge';
import { getMarkdown } from '../RecordCard/shared';

const ScannerSignatureContent = ( { scannerSignature } ) => {

  const [ signatureRecord, setSignatureRecord ] = React.useState( null );
  const [ labelPrefix, setLabelPrefix ] = React.useState( '' );
  const [ timestamp,  setTimestamp ] = React.useState( '' );
  const [ instance, setInstance ] = React.useState( null );
  const [ escalation, setEscalation ] = React.useState( null );
  const [ vulnerabilities, setVulnerabilities ] = React.useState( null );

  const [ showInfo, setShowInfo ] = React.useState( true );
  // const [ showEvidence, setShowEvidence ] = React.useState( false );
  const [ showRecommendation, setShowRecommendation ] = React.useState( false );
  const [ showVulnerabilities, setShowVulnerabilities ] = React.useState( true );

  const serviceInfoIsEmpty = info => {
    if ( isEmpty( info ) ) {
      return true;
    }
    if ( info.port === 0 && info.protocol === null ) {
      return true;
    }
    return false;
  };

  React.useEffect( () => {

    if ( isNotEmpty( scannerSignature ) ) {
      if ( isNotEmpty( scannerSignature.record ) ) {
        setLabelPrefix( scannerSignature.record.label );
        setTimestamp( scannerSignature.record.timestamp );
      }

      if (
        isNotEmpty( scannerSignature )
        && isNotEmpty( scannerSignature.record )
        && isNotEmpty( scannerSignature.record.record )
      ) {
        setSignatureRecord( scannerSignature.record.record );
      }
      if ( isNotEmpty( scannerSignature.instance ) ) {
        setInstance( scannerSignature.instance );
      }
      if ( isNotEmpty( scannerSignature.vulnerabilities ) ) {
        setVulnerabilities( scannerSignature.vulnerabilities );
      }
      if ( isNotEmpty( scannerSignature.escalation ) ) {
        setEscalation( scannerSignature.escalation );
      }
    }
  }, [ scannerSignature ] );

  return (
    <div className="scannerSignatureContentWrapper">
      {
        ( isNotEmpty( instance ) && isNotEmpty( instance.manual_html ) )
          ? <div
            className="rawContentWrapper detailsSection"
            dangerouslySetInnerHTML={ instance.manual_html }
          />
          : <React.Fragment>
            {
              isNotEmpty( signatureRecord ) &&
              <React.Fragment>
                <h4>
                  <span className="label">
                    { vulnerabilityScannerLogo( signatureRecord.scanner ) }
                    {
                      signatureRecord.scanner === 'internal'
                        ? <strong>{ labelPrefix }</strong>
                        : <span><strong>{ labelPrefix }</strong><span>{ signatureRecord.signature }</span></span>
                    }
                    {/* <RatingBadge rating={ riskToRating( signatureRecord?.risk ) }/> */}
                  </span>
                  <span className="timestamp">
                    { timestamp || 'N/A' }
                  </span>
                </h4>
                {
                  ( isNotEmpty( signatureRecord.title ) && isNotEmpty( signatureRecord.title.trim() ) ) &&
                  <span><strong>Title: </strong> { signatureRecord.title || 'N/A' }</span>
                }
                {
                  ( isNotEmpty( signatureRecord.title ) && isNotEmpty( signatureRecord.title.trim() ) ) &&
                  <span className="scannerRating" >
                    {/* eslint-disable-next-line max-len */}
                    <strong>Scanner Rating: </strong> <RatingBadge rating={ riskToRating( signatureRecord?.scanner_rating ) }/>
                  </span>
                }
                {
                  (
                    isNotEmpty( signatureRecord.description )
                    && isNotEmpty( signatureRecord.description.trim() )
                  ) &&
                  <React.Fragment>
                    {
                      signatureRecord.scanner === 'rapid7'
                        ? <div
                          className="markdownWrapper vulnerabilityScannerContent"
                          dangerouslySetInnerHTML={ getMarkdown( signatureRecord.description ) }
                        />
                        : <pre className="scannerOutput">{ signatureRecord.description }</pre>
                    }
                  </React.Fragment>
                }
                {
                  (
                    isNotEmpty( instance )
                    && isNotEmpty( instance.service_info )
                    && !serviceInfoIsEmpty( instance.service_info )
                  ) &&
                  <div className="infoSection">
                    <label>Service Information</label>
                    <ul>
                      {
                        isNotEmpty( instance.service_info.host_name ) &&
                        <li>
                          <label>Host Name: </label>
                          <span>{ instance.service_info.host_name }</span>
                        </li>
                      }
                      {
                        isNotEmpty( instance.service_info.ipv4 ) &&
                        <li>
                          <label>IPv4 Address: </label>
                          <span>{ instance.service_info.ipv4 }</span>
                        </li>
                      }
                      {
                        (
                          isNotEmpty( instance.service_info.port )
                          && instance.service_info.port !== 0
                        ) &&
                        <React.Fragment>
                          <li>
                            <label>Port: </label>
                            <span>{ instance.service_info.port }</ span>
                          </li>
                          {
                            isNotEmpty( instance.service_info.protocol ) &&
                            <li>
                              <label>Protocol: </label>
                              <span>{ instance.service_info.protocol }</span>
                            </li>
                          }
                        </React.Fragment>
                      }
                    </ul>
                  </div>
                }
                {
                  (
                    isNotEmpty( signatureRecord.recommendation )
                    && isNotEmpty( signatureRecord.recommendation.trim() )
                  ) &&
                      <div
                        // eslint-disable-next-line max-len
                        className={ `collapsibleSectionWrapper ${showRecommendation ? '' : 'collapsed' }` }
                      >
                        <div
                          className="collapsibleSectionHeader"
                          onClick={ () => setShowRecommendation( !showRecommendation ) }
                        >
                          <div className="headerLeft">
                            Recommendation
                          </div>
                          <div className="headerRight">
                            <span className="carretWrapper">
                              <InlineSVG type="carretUp"/>
                            </span>
                          </div>
                        </div>
                        <div className="collapsibleSectionBody">
                          {
                            signatureRecord.scanner === 'rapid7'
                              ? <div
                                className="markdownWrapper vulnerabilityScannerContent"
                                dangerouslySetInnerHTML={ getMarkdown( signatureRecord.recommendation ) }
                              />
                              : <pre>{ signatureRecord.recommendation.trim() }</pre>
                          }
                        </div>
                      </div>
                }
                {
                  (
                    isNotEmpty( escalation )
                        && isNotEmpty( escalation.raw_info )
                        && isNotEmpty( escalation.raw_info.trim() )
                  ) &&
                      <div
                        // eslint-disable-next-line max-len
                        className={ `collapsibleSectionWrapper ${showInfo ? '' : 'collapsed' }` }
                      >
                        <div
                          className="collapsibleSectionHeader"
                          onClick={ () => setShowInfo( !showInfo ) }
                        >
                          <div className="headerLeft">
                            Information
                          </div>
                          <div className="headerRight">
                            <span className="carretWrapper">
                              <InlineSVG type="carretUp"/>
                            </span>
                          </div>
                        </div>
                        <div className="collapsibleSectionBody">
                          {
                            signatureRecord.scanner === 'rapid7'
                              ? <div
                                className="markdownWrapper"
                                dangerouslySetInnerHTML={ getMarkdown( escalation.raw_info ) }
                              />
                              : <pre>{ escalation.raw_info.trim() }</pre>
                          }
                        </div>
                      </div>
                }
                {/* {
                  (
                    isNotEmpty( instance )
                        && isNotEmpty( instance.evidence )
                        && isNotEmpty( instance.evidence.trim() )
                  ) &&
                      <div
                        // eslint-disable-next-line max-len
                        className={ `collapsibleSectionWrapper ${showEvidence ? '' : 'collapsed' }` }
                      >
                        <div
                          className="collapsibleSectionHeader"
                          onClick={ () => setShowEvidence( !showEvidence ) }
                        >
                          <div className="headerLeft">
                            Evidence
                          </div>
                          <div className="headerRight">
                            <span className="carretWrapper">
                              <InlineSVG type="carretUp"/>
                            </span>
                          </div>
                        </div>
                        <div className="collapsibleSectionBody">
                          {
                            signatureRecord.scanner === 'rapid7'
                              ? <div
                                className="markdownWrapper"
                                dangerouslySetInnerHTML={ getMarkdown( instance.evidence ) }
                              />
                              : <pre>{ instance.evidence.trim() }</pre>
                          }
                        </div>
                      </div>
                } */}
                {
                  isNotEmpty( vulnerabilities ) &&
                  <div
                    // eslint-disable-next-line max-len
                    className={ `collapsibleSectionWrapper ${showVulnerabilities ? '' : 'collapsed' }` }
                  >
                    <div
                      className="collapsibleSectionHeader"
                      onClick={ () => setShowVulnerabilities( !showVulnerabilities ) }
                    >
                      <div className="headerLeft">
                        <InlineSVG type="vulnerability_record" />
                        Identified Vulnerabilities
                      </div>
                      <div className="headerRight">
                        <span className="carretWrapper">
                          <InlineSVG type="carretUp"/>
                        </span>
                      </div>
                    </div>
                    <div className="collapsibleSectionBody">
                      <RecordList
                        orderBy="risk"
                        records={ vulnerabilities }
                        reportType="vulnerabilities"
                        sectionKey="num_vulnerabilities"
                      />
                    </div>
                  </div>
                }
              </React.Fragment>
            }
          </React.Fragment>
      }
    </div>
  );
};

export default ScannerSignatureContent;