/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';

import { decodeURLHash, getDimensionsAndOffset, isEmpty, isNotEmpty } from '../Utilities';

import './style.scss';
import RatingBadge from '../RatingBadge';
import { isBetaPage } from '../../components/App/Routing';

const DropdownMenu = ( {
  menuItems,
  menuStyle,
  visible,
  setVisible,
  grouped,
  currentPage,
  menuElementClass,
  asSelect,
} ) => {

  const rootEl = document.getElementById( 'dropdownMenuItemsPortal' );

  return ReactDOM.createPortal( <React.Fragment>
    {
      isNotEmpty( menuItems ) &&
      <div
        className={`dropdownMenu ${visible ? 'visible' : ''} ${menuElementClass} ${asSelect ? 'asSelect' : ''}`}
        style={ menuStyle }
      >
        {
          grouped
            ? <ul>
              {
                isNotEmpty( menuItems ) &&
                  Object.entries( menuItems ).map( ( [ sectionTitle, tab ], index ) => {
                    return  <li
                      key={index}
                      onClick={ () => setVisible( false ) }
                    >
                      {
                        isEmpty( tab.items )
                          ? <a
                            href={tab.link}
                            className={`${currentPage === sectionTitle ? 'current' : ''} menuLink`}
                          >
                            <span>{ tab.label }</span>
                            {
                              isBetaPage( decodeURLHash()['.'], sectionTitle ) &&
                              <RatingBadge rating="beta" />
                            }
                          </a>
                          : <React.Fragment>
                            <strong>{ tab.label }</strong>
                            <ul>
                              {
                                Object.entries( tab.items ).map( ( [ name, entry ], i ) => {
                                  return  <li
                                    key={ i }
                                  >
                                    <a
                                      href={entry.link}
                                      className={
                                        `${currentPage === name ? 'current' : ''} menuLink`
                                      }
                                    >
                                      <span>{ entry.label }</span>
                                      {
                                        isBetaPage( decodeURLHash()['.'], entry.slug ) &&
                                        <RatingBadge rating="beta" />
                                      }
                                    </a>
                                  </li>;
                                } )
                              }
                            </ul>
                          </React.Fragment>
                      }
                    </li>;

                  } )
              }
            </ul>
            : <ul>
              {
                menuItems.map( ( item, index ) => {
                  return <li
                    key={ index }
                    onClick={ () => setVisible( false ) }
                    className={ item.props?.className === 'asDivider' ? 'asDivider' : '' }
                  >
                    { item }
                  </li>;
                } )
              }
            </ul>
        }

      </div>
    }
  </React.Fragment>,
  rootEl,
  );
};

const Dropdown = ( {
  trigger,
  menuItems,
  asSelect=false,
  grouped=false,
  elementClass='',
  menuElementClass='',
  currentPage='',
  scrollOffsetContainerID=null,
  disabled=false,
} ) => {

  const [ visible, setVisible ] = React.useState( false );
  const [ menuStyle, setMenuStyle ] = React.useState( {} );

  const triggerRef = React.useRef( null );

  // this adjusts the styling of the menu to be fixed position so that it can pop out of its container
  React.useEffect( ( ) => {
    if ( isNotEmpty( triggerRef ) && isNotEmpty( triggerRef.current ) ) {
      const container = document.getElementById( scrollOffsetContainerID );

      const triggerOffset = getDimensionsAndOffset( triggerRef.current, container );

      if ( isNotEmpty( triggerOffset ) ) {
        const spacer = 10;

        const top = triggerOffset.top + triggerOffset.height + spacer;

        const style = {
          position: 'fixed',
          width: asSelect === true ? triggerOffset.width : 'unset',
          top,
          right: 'unset',
          left: triggerOffset.left,
          maxHeight: window.innerHeight - top - spacer,
        };
        setMenuStyle( style );
      }
    }
  }, [ asSelect, triggerRef, scrollOffsetContainerID, visible ] );

  const handleTriggerClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if ( !disabled ) {
      setVisible( true );
    }
  };

  return(
    <React.Fragment>
      <div
        className={`dropdownShade ${visible ? 'visible' : ''}`}
        onClick={ () => setVisible( false ) }
      />
      <div className={ `${asSelect ? 'asSelect' : ''} dropdownWrapper ${elementClass}` } >
        <div
          // eslint-disable-next-line max-len
          className={`dropdownTrigger ${visible ? 'active' : ''} ${disabled ? 'disabled' : ''} ${asSelect ? 'asSelect' : ''}`}
          onClick={ handleTriggerClick }
          ref={triggerRef}
        >
          { trigger }
        </div>
        <DropdownMenu
          menuItems={menuItems}
          menuStyle={menuStyle}
          visible={visible}
          setVisible={setVisible}
          grouped={grouped}
          currentPage={currentPage}
          menuElementClass={menuElementClass}
          asSelect={asSelect}
        />
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
