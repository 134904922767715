/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import EmptyState from '../../../../EmptyState';

import Loading from '../../../../Loading';
import { isNotEmpty, userDisplayName } from '../../../../Utilities';

const Summary = ( {
  selectedRecipients,
  hasFetchedUsers,
  validUsers,
} ) => {

  const [ formattedSummary, setFormattedSummary ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( selectedRecipients ) ) {
      const _formatted = [];

      const _recipients = Object.values( selectedRecipients );

      _recipients.map( ( r, i ) => {
        if ( i <= 2 ) {
          _formatted.push( userDisplayName( r ) );
        }
      } );

      if ( _recipients.length > 3 ) {
        _formatted.push( `and ${ _recipients.length - 3 } more...` );
      }
      setFormattedSummary( _formatted );
    }
  }, [ selectedRecipients ] );

  return (
    <div className="summaryWrapper">
      {
        isNotEmpty( validUsers )
          ? <React.Fragment>
            {
              ( isNotEmpty( selectedRecipients ) && isNotEmpty( formattedSummary ) )
                ? <div>
                  <strong>{ `Recipients (${Object.values( selectedRecipients ).length})` }</strong>
                  <span>{ formattedSummary.join( ', ' ) }</span>
                </div>
                : <EmptyState message="Email Recipients (0)" />
            }
          </React.Fragment>
          : <div className="loadingEmptyWrapper">
            {
              hasFetchedUsers
                ? <EmptyState message={
                  <span>
                    You do not have any users configured with email address. If you would like
                    to add recipients, you will need to add
                    some <a href="#.=setup&page=users">here</a>.
                  </span>
                }
                />
                : <Loading />
            }
          </div>
      }
    </div>
  );
};

export default Summary;