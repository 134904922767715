/* eslint-disable camelcase */
/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

import React from 'react';
import InsightTable from '../../Table';

import {
  isNotEmpty,
  paramsToFilters,
  decodeURLHash,
  isInequalityType,
} from '../../../../shared/Utilities';

import './style.scss';
import { FlashMessageQueueContext } from '../../../../Contexts/FlashMessageQueue';
import { makeRequest } from '../../../../../legacy/io';
import { getRowNums, pageIterator } from '../../../../shared/Pagination/IndeterminantPagination';
import PageHeader from '../../../../shared/PageHeader';
import RecordCard from '../../../RecordDetails/RecordCard';
import ReportHeader from '../../ReportHeader';
import { defaultRemdiationItems } from '../../Table/shared';

const VulnerabilityInstances = () => {
  const [ loading, setLoading ] = React.useState( true );

  const [ visualData, setVisualData ] = React.useState( null );
  const [ remediationItems, setRemediationItems ] = React.useState( defaultRemdiationItems );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );

  // controls the top overview panel collapsed state, storing here to pass down to multiple components
  const [ visualCollapsed, setVisualCollapsed ] = React.useState( false );

  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ selectedSignature, setSelectedSignature ] = React.useState( null );

  // eslint-disable-next-line no-unused-vars
  const isMounted = true;

  // main fetch method called whenever the filters change
  const onRefresh = async ( refreshTallies=true ) => {
    const onCorrectPage = decodeURLHash()['report'] === 'instances';

    if ( onCorrectPage ) {
      setLoading( true );

      const filterValues = paramsToFilters();

      const params = {
        project: 'default',
        model: 'base',
        filters: {},
      };

      const _groupType = decodeURLHash()['group_type'] || 'host';

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {

        const gt_map = {};
        const lt_map = {};

        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) && unit !== 'null' && unit !== null && !isNaN( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );

        if ( isNotEmpty( params.filters ) ) {
          delete params.filters.group_type;
        }

        const tallyParams = { ...params };

        const _groupParams = {
          ...tallyParams,
          // eslint-disable-next-line camelcase
          group_type: _groupType,
          rownums: _rowNums,
        };

        if ( !refreshTallies ) {
          delete tallyParams.filters.exploit_statuses;
        }

        makeRequest( 'TALLY', '/analysis/instance', tallyParams ).then( response => {
          // transforming the server data into the structure necessary for icicle chart
          if ( isNotEmpty( response ) ) {
            if ( isMounted ) {
              const _visualData ={
                tally: response,
              };
              setVisualData( _visualData );
              setLoading( false );
            }
          } else if ( isMounted ) {
            addFlashMessage( {
              type: 'alert',
              body: 'Your filters returned an error',
            } );
            setVisualData( {} );
            setLoading( false );
          }
        } );
        if ( isMounted ) {
          refreshTable( _groupParams );
        }
      }
    }
  };

  const refreshTable = () => {

    if ( decodeURLHash()['report'] === 'instances' ) {

      // clear out existing results
      setCurrentPageResults( [] );
      setNextPageResults( [] );

      const params = {
        project: 'default',
        model: 'base',
        filters: {},
      };

      const filterValues = paramsToFilters();

      const _rowNums = getRowNums( filterValues );

      if ( isNotEmpty( filterValues ) ) {

        const gt_map = {};
        const lt_map = {};

        const _orderBy = filterValues?.order_by || [ 'filtered_risk' ];
        const _orderDirection = filterValues?.order_direction || 'DESC';

        Object.entries( filterValues ).map( ( [ key, val ] ) => {
          if ( key === 'item_count' ) {
            params.rownums = _rowNums;
          } else if ( isInequalityType.includes( key ) ) {
            const [ inequality, unit ] = val;

            if ( isNotEmpty( unit ) ) {
              if ( inequality === 'gt_map' ) {
                gt_map[key] = parseFloat( unit );
              } else if ( inequality === 'lt_map' ) {
                lt_map[key] = parseFloat( unit );
              }
            }
            if ( isNotEmpty( gt_map ) ) {
              params.filters.gt_map = gt_map;
            }
            if ( isNotEmpty( lt_map ) ) {
              params.filters.lt_map = lt_map;
            }
          } else {
            params.filters[key] = val;
          }
        } );

        const _groupType = filterValues.group_type || 'host';
        // eslint-disable-next-line camelcase
        const _groupParams = { ...params, group_type: _groupType };

        if ( isNotEmpty( params.filters ) ) {
          delete params.filters.group_type;
        }

        if ( isNotEmpty( _groupParams ) ) {
          setLoading( true );
          // eslint-disable-next-line camelcase
          const params = {
            ..._groupParams,
            // eslint-disable-next-line camelcase
            group_type: _groupType,
            // eslint-disable-next-line camelcase
            order_by: [],
            rownums: _rowNums,
          };
          if (
            isNotEmpty( _orderBy )
            && isNotEmpty( _orderDirection )
          ) {
            // eslint-disable-next-line camelcase
            params.order_by = [ [ _orderBy, _orderDirection ] ];
          }
          makeRequest( 'GROUP', '/analysis/instance', params ).then( response => {
            if ( isMounted ) {
              const pagedResults = pageIterator( response.results, filterValues );

              if ( isNotEmpty( pagedResults ) ) {
                setCurrentPageNumber(
                  pagedResults.currentPageNumber ? parseInt( pagedResults.currentPageNumber ) : 1,
                );
                setCurrentPageResults( pagedResults.firstPage );
                setNextPageResults( pagedResults.secondPage );
                setLoading( false );
              } else {
                setCurrentPageNumber( 1 );
                setCurrentPageResults( [] );
                setNextPageResults( [] );
              }
            }
          } );
        }
      }
    }
  };

  // callback closing the signature card
  const handleRecordCardClose = () => {
    setSelectedSignature( null );
  };

  return (
    <React.Fragment>
      <PageHeader>
        <ReportHeader
          reportType="instance"
          onRefresh={onRefresh}
          visualCollapsed={visualCollapsed}
          setVisualCollapsed={setVisualCollapsed}
          currentPageNumber={currentPageNumber}
          nextPageResults={nextPageResults}
          currentPageResults={currentPageResults}
          loading={loading}
          instancesVisualData={visualData}
          remediationItems={remediationItems}
          setRemediationItems={setRemediationItems}
        />
      </PageHeader>
      <InsightTable
        records={currentPageResults}
        nextRecords={nextPageResults}
        currentPageNumber={currentPageNumber}
        loading={loading}
        reportType="instance"
        onRefresh={onRefresh}
        refreshTable={refreshTable}
        visualCollapsed={visualCollapsed}
        selectedSignature={selectedSignature}
        setSelectedSignature={setSelectedSignature}
        remediationItems={remediationItems}
        setRemediationItems={setRemediationItems}
      />
      {
        isNotEmpty( selectedSignature ) &&
        <RecordCard
          record={ selectedSignature }
          type="signature"
          context="risk_insight_signature_instances"
          show={ isNotEmpty( selectedSignature ) }
          setShow={ handleRecordCardClose }
          fetchRecordDetails
          options={ {
            isDismissable: true,
            // eslint-disable-next-line camelcase
            include_shade: true,
            isDraggable: true,
          } }
        />
      }
    </React.Fragment>
  );
};

export default VulnerabilityInstances;