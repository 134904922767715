/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeRequest } from '../../../../../../legacy/io';
import {
  formatTZOffset,
  isEmpty,
  // isEmpty,
  isNotEmpty,
} from '../../../../Utilities';

import Occurrence from './Occurrence';

import './style.scss';
import InlineSVG from '../../../../InlineSVG';

export const EMPTY_OCCURRENCE = {
  type: 'manual',
  time: '12:00',
  week: '1st',
  dow: 'mon',
};

const Schedule = ( {
  field,
  originalValue,
  formState,
  onChange,
  allowMultiple=false,
  // existingRecord,
} ) => {

  const EMPTY_VALUE = {
    enabled: false,
    recurring: [],
  };

  // const [ occurrencesValid, setOccurrencesValid ] = React.useState( true );
  const [ serverTimeZone, setServerTimeZone ] = React.useState( '' );
  const [ occurrences, setOccurrences ] = React.useState( {} );

  // 1) grab the server time from global settings
  React.useEffect( () => {
    makeRequest( 'INDEX', '/global_setting', {} ).then( response => {
      if ( response && response.results ) {

        const tz = response.results.server_tz;
        const offset = response.results.server_tzoffset;

        setServerTimeZone( `${tz} (UTC${formatTZOffset( offset )})` );
      }
    } );
  }, [] );

  // 2) if there is an original value with recurring, give each a temp uuid for better adding/removing etc.
  React.useEffect( () => {
    if ( isNotEmpty( originalValue ) && isNotEmpty( originalValue.recurring ) ) {
      const _occurences = {};

      originalValue.recurring.map( o => _occurences[uuidv4()] = o );

      setOccurrences( _occurences );
    } else {
      setOccurrences( { [uuidv4()]: EMPTY_OCCURRENCE } );
    }
  }, [ originalValue ] );

  // 4) If the occurrences change (user has added/removed/edited) update the value of the field, this needs to be in the
  // format { enabled: true/false, recurring: [ ...occurrences ] }
  React.useEffect( () => {
    if ( isNotEmpty( occurrences ) ) {
      let _value = { ...EMPTY_VALUE };

      const _recurring = [];
      if ( isNotEmpty( occurrences ) ) {
        Object.values( occurrences ).map( o => {
          let { type, time, dow, week } = o;

          if ( isEmpty( type ) ) {
            ( { type } = EMPTY_OCCURRENCE );
          }
          if ( isEmpty( time ) ) {
            ( { time } = EMPTY_OCCURRENCE );
          }
          if ( isEmpty( dow ) ) {
            ( { dow } = EMPTY_OCCURRENCE );
          }
          if ( isEmpty( week ) ) {
            ( { week } = EMPTY_OCCURRENCE );
          }

          switch ( o.type ) {
          case 'daily':
            _recurring.push( { type, time  } );
            break;
          case 'weekly':
            _recurring.push( { type, time, dow } );
            break;
          case 'monthly':
            _recurring.push( { type, time, dow, week } );
            break;
          default:
            break;
          }
        } );

        if ( isNotEmpty( _recurring ) ) {
          _value.enabled = true;
          _value.recurring = _recurring;
        } else {
          _value = { enabled: false, recurring: [] };
        }
      }
      onChange( field, _value );
    } else {
      onChange( field, EMPTY_VALUE );
    }
  }, [ occurrences ] );

  const addEmptyOccurence = () => {
    setOccurrences( {
      ...occurrences,
      [uuidv4()]: EMPTY_OCCURRENCE,
    } );
  };

  const canAddOccurrence = () => {
    if ( isNotEmpty( occurrences ) ) {
      return !Object.values( occurrences ).some( o => o.type === 'manual' );
    }
    return true;
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( serverTimeZone ) &&
        <div className={ `timezoneInfo ${field.disabled ? 'disabled' : ''}`}>
          <span>Server Timezone:</span>
          <strong> { serverTimeZone}</strong>
        </div>
      }
      {
        isNotEmpty( occurrences ) &&
        Object.entries( occurrences ).map( ( [ uuid, occurrence ], index ) => {
          return <Occurrence
            allowMultiple={allowMultiple}
            formState={formState}
            field={field}
            key={ index }
            uuid={uuid}
            occurrence={ occurrence }
            occurrences={ occurrences }
            setOccurrences={ setOccurrences }
          />;
        } )
      }
      {
        allowMultiple &&
        <div className="actions">
          <button
            className={ `${( canAddOccurrence || field.disabled ) ? '' : 'disabled'} addNewOccurrenceButton`}
            disabled={ !canAddOccurrence || field.disabled }
            onClick={ addEmptyOccurence }
          >
            <InlineSVG type="new" version="light" />
            <span>Add</span>
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default Schedule;