/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import EmptyState from '../../../EmptyState';
import Loading from '../../../Loading';
import {
  triggerHashRefresh,
  removeFromURLHash,
  isNotEmpty,
  riskToRating,
  getDimensionsAndOffset,
  recordTypeDisplayName,
} from '../../../Utilities';

import RatingBadge from '../../../RatingBadge';
import RiskReduction from '../../../RiskReduction';

const SearchResults = ( {
  recordType,
  results,
  setResults,
  input,
  searchBarInput,
  selectResult,
  loading,
  noResults,
  shouldShowResults=false,
  setShouldShowResults,
} ) => {

  const resultsPortal = document.getElementById( 'searchResultsFieldPortal' );
  const [ style, setStyle ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( searchBarInput ) && isNotEmpty( searchBarInput.current ) ) {
      const inputOffset = getDimensionsAndOffset( searchBarInput.current );
      if ( isNotEmpty( inputOffset ) ) {
        const { top, left, width } = inputOffset;

        let _left = left;
        let _width = 24 * 16;

        if ( _width > width ) {
          _left = left - ( ( _width - width ) / 2 );
        } else if ( _width < width ) {
          _width = width;
        }
        setStyle( {
          top: top + ( 16 * 3.5 ),
          left: _left,
          width: _width,
        } );
      }
    }
  }, [ loading, results, searchBarInput ] );

  const clearAndHide = () => {
    setResults( [] );
    // searchBarInput.current.value = '';
    removeFromURLHash( input.attribute );
    setShouldShowResults( false );
    triggerHashRefresh();
  };

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        ( isNotEmpty( results ) && shouldShowResults ) &&
        <div
          onClick={ () => clearAndHide() }
          className="searchResultsListShade"
        />
      }
      {
        ( shouldShowResults && isNotEmpty( searchBarInput?.current?.value ) ) &&
        <div style={ style } className={ `${noResults ? 'empty' : ''} ${loading ? 'loading' : ''} searchResultsList` }>
          { loading && <Loading /> }
          { noResults && <EmptyState message="No results found" /> }
          {
            isNotEmpty( results ) &&
            <ul>
              {
                results.map( ( result, index ) => {
                  return  <li
                    className="searchResultItem"
                    key={index}
                    onClick={ ( e ) => selectResult( e, result ) }
                  >
                    <div className="labelWrapper">
                      <RatingBadge
                        rating={ riskToRating( 'filtered_risk' in result ? result.filtered_risk : result.risk ) }
                      />
                      <label>
                        { recordTypeDisplayName( result, recordType ) }
                      </label>
                    </div>
                    <RiskReduction item={result} riskType={ 'filtered_risk' in result ? 'filtered_risk' : 'risk' } />
                  </li>;
                } )
              }
            </ul>
          }
        </div>
      }
    </React.Fragment>,
    resultsPortal,
  );
};

export default SearchResults;
