/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../Utilities';

import './style.scss';

const YAxisLines = ( {
  lineCount=3,
  lineStyle='dashed',
  elementClass='',
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( lineCount ) &&
        <div className={ `axisLinesContainer yAxis lineCountClass--${lineCount} ${lineStyle} ${elementClass}` } >
          { Array( lineCount ).fill().map( ( p, i ) => <div key={i} className="YAxisLineBox" /> ) }
        </div>
      }
    </React.Fragment>
  );
};

export default YAxisLines;