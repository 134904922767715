/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../Utilities';

import Modal from '../../../../Modal';
import EmptyState from '../../../../EmptyState';
import RecipientItem from './RecipientItem';

const ModalBody = ( {
  selectedRecipients,
  setSelectedRecipients,
  availableRecipients,
  setAvailableRecipients,
} ) => {

  const toggleRecipient = recipient => {
    const _selected = { ...selectedRecipients };
    const _available = { ...availableRecipients };

    // recipient is already selected, need to remove
    if (
      isNotEmpty( selectedRecipients )
      && Object.keys( selectedRecipients ).includes( recipient.id )
    ) {
      delete _selected[recipient.id];
      _available[recipient.id] = recipient;
    // recipient is not selected, need to select
    } else {
      delete _available[recipient.id];
      _selected[recipient.id] = recipient;
    }

    setAvailableRecipients( _available );
    setSelectedRecipients( _selected );
  };

  return (
    <React.Fragment>
      <div className="column">
        <h2>
          {/* eslint-disable-next-line max-len */}
          { `Available (${ isNotEmpty( availableRecipients ) ? Object.keys( availableRecipients ).length : 0 })` }
        </h2>
        <ul
          className="recipientList available"
        >
          {
            isNotEmpty( availableRecipients )
              ? Object.values( availableRecipients ).map( ( recipient, index ) => {
                return <RecipientItem
                  key={index}
                  recipient={recipient}
                  toggleRecipient={toggleRecipient}
                />;
              } )
              : <EmptyState
                message={
                  <span>
                    You do not have any more users configured with an email address,
                    you can add more <a href="#.=setup&page=users">here</a>.
                  </span>
                }
              />
          }
        </ul>
      </div>
      <div className="column">
        <h2>
          {/* eslint-disable-next-line max-len */}
          { `Added (${ isNotEmpty( selectedRecipients ) ? Object.keys( selectedRecipients ).length : 0 })` }
        </h2>
        <ul
          className="recipientList selected"
        >
          {
            isNotEmpty( selectedRecipients )
              ? Object.values( selectedRecipients ).map( ( recipient, index ) => {
                return <RecipientItem
                  key={index}
                  recipient={recipient}
                  toggleRecipient={toggleRecipient}
                  isSelected
                />;
              } )
              : <EmptyState message="No Recipients added" />
          }
        </ul>
      </div>

    </React.Fragment>
  );
};

const RecipientModal = ( {
  selectedRecipients,
  setSelectedRecipients,
  availableRecipients,
  setAvailableRecipients,
  hasFetchedUsers,
  validUsers,
} ) => {

  const [ showModal, setShowModal ] = React.useState( false );
  return (
    <React.Fragment>
      <Modal
        visible={showModal}
        setVisible={setShowModal}
        elementClass="emailRecipientsModal"
        header="Email Recipients"
        body={
          <ModalBody
            selectedRecipients={selectedRecipients}
            setSelectedRecipients={setSelectedRecipients}
            availableRecipients={availableRecipients}
            setAvailableRecipients={setAvailableRecipients}
          />
        }
      />
      {
        ( isNotEmpty( validUsers ) && hasFetchedUsers ) &&
        <button
          className="addRecipientsButton"
          onClick={ () => setShowModal( true ) }
        >
          {
            isNotEmpty( selectedRecipients )
              ? 'Edit Recipients'
              : 'Add Recipients'
          }
        </button>
      }
    </React.Fragment>
  );
};

export default RecipientModal;