/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { isNotEmpty } from '../../../Utilities';

import './File.scss';

const File = ( {
  field,
  formState,
  originalValue,
  onChange,
  existingRecord,
  fieldRef,
} ) => {

  const [ encodedValue, setEncodedValue ] = React.useState( null );

  React.useEffect( () => {
    if (
      isNotEmpty( formState )
      && isNotEmpty( field )
      && isNotEmpty( formState.fieldStates )
      && isNotEmpty( formState.fieldStates[field.attribute] )
      && isNotEmpty( formState.fieldStates[field.attribute].updatedValue )
    ) {
      setEncodedValue( formState.fieldStates[field.attribute].updatedValue );
    } else if ( isNotEmpty( originalValue ) ) {
      setEncodedValue( originalValue );
    } else {
      setEncodedValue( null );
      if ( isNotEmpty( fieldRef ) && isNotEmpty( fieldRef.current ) ) {
        fieldRef.current.value = null;
      }
    }
  }, [ originalValue, fieldRef, field, existingRecord, formState ] );

  // if we need base64 encoding we will read as an array buffer and encode ourselves,
  // otherwise read as text
  const handleFileChange = async ( e ) => {

    if ( isNotEmpty( field.encoding ) && field.encoding === 'base64' ) {

      const base64encode = async ( array ) => {
        return new Promise( resolve => {
          const blob = new Blob( [ array ] );
          const reader = new FileReader();

          reader.onload = e => {
            const dataUrl = e.target.result;
            const [ , base64 ] = dataUrl.split( ',' );

            resolve( base64 );
          };

          reader.readAsDataURL( blob );
        } );
      };
      const encoded = await base64encode( e.target.files[0] );
      setEncodedValue( encoded );
      onChange( field, encoded );
    } else {
      const reader = new FileReader();

      reader.onload = e => {
        // if ( isNotEmpty( fieldRef ) && isNotEmpty( fieldRef.current ) ) {
        //   fieldRef.current.value = e.target.result;
        // }

        onChange( field, e.target.result );
      };

      reader.readAsText( e.target.files[0] );
    }

  };

  return (
    <React.Fragment>
      <input
        accept={field.mime ? field.mime : ''}
        disabled={field.disabled || formState.fieldStates[field.attribute].disabled}
        type={field.type}
        onChange={ handleFileChange }
        ref={ fieldRef }
      />
      {
        ( field?.encoding === 'base64' && isNotEmpty( encodedValue ) ) &&
        <React.Fragment>
          <label className="previewLabel" >Preview</label>
          <pre>
            { encodedValue }
          </pre>
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default File;
