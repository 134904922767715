/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';
import { getDimensionsAndOffset, isNotEmpty, riskToRating } from '../../../../Utilities';
import EmptyState from '../../../../EmptyState';
import Loading from '../../../../Loading';

import {
  displayValueForResultType,
} from './SearchData';
import RatingBadge from '../../../../RatingBadge';
import RiskReduction from '../../../../RiskReduction';

// searchResultsFieldPortal

const SearchResults = ( {
  field,
  recordType,
  results,
  setResults,
  setSelectedResult,
  fieldRef,
  loading,
  noResults,
  shouldShowResults=false,
  setShouldShowResults,
} ) => {

  const resultsPortal = document.getElementById( 'searchResultsFieldPortal' );
  const [ style, setStyle ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( fieldRef ) && isNotEmpty( fieldRef.current ) ) {
      const inputOffset = getDimensionsAndOffset( fieldRef.current );
      if ( isNotEmpty( inputOffset ) ) {
        const { top, left, width } = inputOffset;

        let _left = left;
        let _width = 28 * 16;

        if ( _width > width ) {
          _left = left - ( ( _width - width ) / 2 );
        } else if ( _width < width ) {
          _width = width;
        }
        setStyle( {
          top: top + ( 16 * 3.5 ),
          left: _left,
          width: _width,
        } );
      }
    }
  }, [ loading, results, fieldRef ] );

  const selectResult = ( e, result ) => {
    e.stopPropagation();
    e.preventDefault();
    setResults( [] );
    setSelectedResult( result );
  };

  const clearAndHide = () => {
    setResults( [] );
    setSelectedResult( null );
    setShouldShowResults( false );
    // fieldRef.current.value = '';
  };

  return ReactDOM.createPortal(
    <React.Fragment>
      {
        ( isNotEmpty( results ) && shouldShowResults ) &&
        <div
          onClick={ () => clearAndHide() }
          className="searchResultsListShade"
        />
      }
      {
        ( shouldShowResults && isNotEmpty( fieldRef?.current?.value ) ) &&
        <div style={ style } className={ `${noResults ? 'empty' : ''} ${loading ? 'loading' : ''} searchResultsList` }>
          { loading && <Loading /> }
          { noResults && <EmptyState message="No results found" /> }
          {
            isNotEmpty( results ) &&
            <ul>
              {
                results.map( ( result, index ) => {
                  return  <li
                    className="searchResultItem"
                    key={index}
                    onClick={ ( e ) => selectResult( e, result ) }
                  >
                    {
                      field?.noRisk
                        ? <div className="labelWrapper">
                          <label>
                            { displayValueForResultType( recordType, result ) }
                          </label>
                        </div>
                        : <React.Fragment>
                          <div className="labelWrapper">
                            <RatingBadge
                              rating={ riskToRating( 'filtered_risk' in result ? result.filtered_risk : result.risk ) }
                            />
                            <label>
                              { displayValueForResultType( recordType, result ) }
                            </label>
                          </div>
                          <RiskReduction
                            item={result}
                            riskType={ 'filtered_risk' in result ? 'filtered_risk' : 'risk' }
                          />
                        </React.Fragment>
                    }

                  </li>;
                } )
              }
            </ul>
          }
        </div>
      }
    </React.Fragment>,
    resultsPortal,
  );
};

export default SearchResults;