/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import './style.scss';

import {
  decodeURLHash,
  isEmpty,
  isNotEmpty,
} from '../Utilities';
import InlineSVG from '../InlineSVG';

export const pageIterator = ( response, _filterValues, _count=100 ) => {
  const firstPage = [];
  const secondPage = [];

  if ( isNotEmpty( response ) ) {
    response.map( ( result, index ) => {
      const lessThanValue = _filterValues.item_count ? _filterValues.item_count : _count;
      if ( index < lessThanValue ) {
        firstPage.push( result );
      } else {
        secondPage.push( result );
      }
    } );
  }

  return {
    currentPageNumber: _filterValues.current_page ? parseInt( _filterValues.current_page ) : 1,
    firstPage: firstPage,
    secondPage: secondPage,
  };
};

export const getRowNums = ( filterValues, count=100 ) => {

  let start, end;
  if ( isEmpty( filterValues.current_page ) ) {
    start = 0;
    end = filterValues.item_count ? parseInt( filterValues.item_count ) * 2 : count;
  } else {
    // eslint-disable-next-line max-len
    start = ( parseInt( filterValues.current_page ) - 1 ) * ( filterValues.item_count ? parseInt( filterValues.item_count ) : count );
    end = start + ( filterValues.item_count ? parseInt( filterValues.item_count ) * 2 : count * 2 );
  }

  if ( isEmpty( start ) || isNaN( start ) ) {
    start = 0;
  }
  if ( isEmpty( end ) || isNaN( end ) ) {
    end = 200;
  }
  return [ start, end ];
};

const IndeterminantPagination = ( {
  currentPageNumber,
  nextPageResults,
  goToPage=() => {},
  elementClass='',
} ) => {
  return (
    <div className={`paginationContainer indeterminantPagination ${ elementClass}`}>
      <button
        disabled={ parseInt( currentPageNumber ) <= 1 }
        className={`paginationButton previous ${parseInt( currentPageNumber ) <= 1 ? 'disabled' : ''}`}
        onClick={ () => goToPage( parseInt( currentPageNumber ) - 1 ) }
      >
        <InlineSVG type="carretLeft" />
        {/* eslint-disable-next-line max-len */}
        <span>Prev. { currentPageNumber === 1 ? '' : `${decodeURLHash()['item_count'] }` }</span>
      </button>
      <button
        disabled={ isEmpty( nextPageResults ) }
        className={
          `paginationButton next ${ isNotEmpty( nextPageResults ) ? '' : 'disabled'}`
        }
        onClick={ () => goToPage( parseInt( currentPageNumber ) + 1 ) }
      >
        {/* eslint-disable-next-line max-len */}
        <span>Next { isEmpty( nextPageResults ) ? '' : `${nextPageResults.length}` }</span>
        <InlineSVG type="carretRight" />
      </button>
    </div>
  );
};

export default IndeterminantPagination;
