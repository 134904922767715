/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../Utilities';

import './style.scss';

const RiskPercentageBar = ( { itemRisk, totalRisk, itemRating, minimalVariant=false } ) => {

  const [ barWidth, setBarWidth ] = React.useState( 0 );

  React.useEffect( ( ) => {
    if ( isNotEmpty( itemRisk ) && isNotEmpty( totalRisk ) ) {
      const width = ( itemRisk / totalRisk ) * 100;
      setBarWidth( width );
    } else {
      setBarWidth( 0.01 );
    }
  }, [ itemRisk, totalRisk ] );
  return (
    <React.Fragment>
      {
        isNotEmpty( barWidth ) &&
        <div className={ `riskPercentageBarWrapper ${minimalVariant ? 'minimal' : ''}` }>
          {
            ( isNotEmpty( itemRating ) && isNotEmpty( barWidth ) ) &&
            <div className={ `riskPercentageBar ${itemRating}`}  style={ { width: `${barWidth}%` } } />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default RiskPercentageBar;